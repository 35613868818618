import { BreakpointObserver } from '@angular/cdk/layout/';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PageSizeService {
  private MAX_PAGE_SIZE = '(max-width: 768px)';

  public isSmallScreen$: Observable<boolean>;

  constructor(private breakpointObserver: BreakpointObserver) {
    this.isSmallScreen$ = this.breakpointObserver
      .observe(this.MAX_PAGE_SIZE)
      .pipe(map((x) => x.matches));
  }
}
