import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { LoadAnalysisAssignment } from 'src/app/assignments/modules/analysis/store/actions/analysis-assignment.actions';

import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';

import { AssignmentsService } from 'src/app/core/services/api/assignments/assignments.service';
import { LoadAssignment } from 'src/app/core/store/actions/assignments.action';
import {
  ChangeOwner,
  ChangeOwnerActionTypes,
  ChangeOwnerFail,
  ChangeOwnerSuccess,
} from 'src/app/core/store/actions/change-owner.action';

@Injectable()
export class ChangeOwnerEffects {
  constructor(
    private actions$: Actions,
    private assignmentService: AssignmentsService
  ) {}

  public changeOwner$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChangeOwnerActionTypes.ChangeOwner),
      switchMap((action: ChangeOwner) =>
        this.assignmentService
          .changeOwner(
            action.payload.changeOwnerRequest,
            action.payload.assignmentId
          )
          .pipe(
            map(() => new ChangeOwnerSuccess(action.payload.assignmentId)),
            catchError((error: ErrorResponseWithIdI) =>
              of(new ChangeOwnerFail(error))
            )
          )
      )
    )
  );

  public handleChangeOwnerSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChangeOwnerActionTypes.ChangeOwnerSuccess),
      switchMap((action: ChangeOwnerSuccess) =>
        of(
          new LoadAssignment(action.payload),
          new LoadAnalysisAssignment(action.payload)
        )
      )
    )
  );
}
