<app-mobile-card
  *ngFor="let alert of elementsList"
  (click)="onGoToDetailsMobile(alert)"
>
  <span slot="body">
    <app-display-value
      [label]="'notifications.tableHeaders.issue'"
      [value]="alert"
      [template]="issue"
      [addSpaceTop]="false"
    ></app-display-value>

    <app-display-value
      [label]="'notifications.tableHeaders.assignment'"
      [value]="alert.assignmentName"
    ></app-display-value>

    <app-display-value
      [label]="'notifications.tableHeaders.date'"
      [value]="alert.created | date"
    ></app-display-value>
  </span>
</app-mobile-card>

<ng-template #issue let-value>
  <div class="status">
    <mat-icon
      class="status__icon"
      *ngIf="value.status"
      [ngClass]="getColour(value.status).colour"
      >{{ getColour(value.status).icon }}</mat-icon
    >
    <span
      class="status__title"
      [translate]="'assignmentStatus.' + value.status"
    ></span>
  </div>
</ng-template>

<div class="mobile-footer-pagination">
  <div class="footer-pagination-select">
    <span [translate]="'table.footer.show'"></span>
    <ng-select
      class="footer-pagination-select__button"
      appendTo="body"
      [items]="tableSizeLimitOptions"
      [ngModel]="tableSizeLimit"
      [searchable]="false"
      [clearable]="false"
      (change)="onPaginationLimitChange($event)"
    >
    </ng-select>
    <span [translate]="'table.footer.perPage'"></span>
  </div>

  <div class="footer-results-count">
    {{ allElementsTotal }}
    <span [translate]="'table.footer.results'"></span>
  </div>

  <div class="footer-pager">
    <datatable-pager
      [pagerLeftArrowIcon]="'datatable-icon-left'"
      [pagerRightArrowIcon]="'datatable-icon-right'"
      [pagerPreviousIcon]="'datatable-icon-prev'"
      [pagerNextIcon]="'datatable-icon-skip'"
      [page]="currentPage"
      [size]="tableSizeLimit"
      [count]="allElementsTotal"
      [hidden]="!(allElementsTotal / tableSizeLimit > 1)"
      (change)="changePagination($event)"
    >
      >
    </datatable-pager>
  </div>
</div>
