import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import {
  MyActionI,
  StartPageI,
} from 'src/app/core/models/user/start-page.interface';
import {
  StartPageActions,
  StartPageActionTypes,
} from 'src/app/core/store/actions/start-page.action';

export interface StartPageReducerState {
  resource: StartPageI;
  loading: boolean;
  loaded: boolean;
  showMobileAction: MyActionI;
  error: ErrorResponseWithIdI;
}

export const initialStartPageState: StartPageReducerState = {
  resource: null,
  loading: false,
  loaded: false,
  showMobileAction: null,
  error: null,
};

export function startPageReducer(
  state: StartPageReducerState = initialStartPageState,
  action: StartPageActions
): StartPageReducerState {
  switch (action.type) {
    case StartPageActionTypes.LoadStartPage: {
      return {
        ...initialStartPageState,
        loading: true,
      };
    }

    case StartPageActionTypes.LoadedStartPageSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        resource: action.payload,
      };
    }

    case StartPageActionTypes.LoadedStartPageFail: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload,
      };
    }

    case StartPageActionTypes.ShowStartPageMobileActions: {
      return {
        ...state,
        showMobileAction: action.payload,
      };
    }

    case StartPageActionTypes.CleanStartPageState: {
      return {
        ...initialStartPageState,
      };
    }

    default: {
      return state;
    }
  }
}

export const getStartPageResource = (state: StartPageReducerState) =>
  state.resource;
export const getStartPageLoading = (state: StartPageReducerState) =>
  state.loading;
export const getStartPageLoaded = (state: StartPageReducerState) =>
  state.loaded;
export const getStartPageError = (state: StartPageReducerState) => state.error;
export const getStartPageShowMobileAction = (state: StartPageReducerState) =>
  state.showMobileAction;
