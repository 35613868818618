import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Action } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { IdNameResourceI } from 'src/app/core/models/property-register/id-name-resource.interface';
import { PropertyAddressChanged } from 'src/app/core/modules/start/store/actions/property-address.actions';
import { PropertyBuildingsChanged } from 'src/app/core/modules/start/store/actions/property-building.actions';
import { PropertyDistrictsChanged } from 'src/app/core/modules/start/store/actions/property-district.actions';
import { PropertyRealEstatesChanged } from 'src/app/core/modules/start/store/actions/property-real-estate.actions';
import { BaseFormComponent } from 'src/app/shared/components/base-form/base-form.component';

@Component({
  selector: 'app-property-register',
  templateUrl: './property-register.component.html',
  styleUrls: ['./property-register.component.scss'],
})
export class PropertyRegisterComponent
  extends BaseFormComponent<PropertyRegisterComponent>
  implements OnInit, OnDestroy {
  private subscription: Subscription;

  @Input()
  propertyDistrict: IdNameResourceI[];

  @Input()
  propertyRealEstate: IdNameResourceI[];

  @Input()
  propertyBuilding: IdNameResourceI[];

  @Input()
  propertyAddress: IdNameResourceI[];

  @Input()
  propertyObject: IdNameResourceI[];

  @Output()
  propertyChanged: EventEmitter<Action> = new EventEmitter<Action>();

  constructor() {
    super();
  }

  ngOnInit() {
    this.handleEnablingAndDisablingFields();
  }

  handleEnablingAndDisablingFields() {
    this.subscription = this.formGroup.valueChanges
      .pipe()
      .subscribe((value) =>
        this.enableOrDisableControlsBasedOnPreviousSelectedValueWhenFormIsNotDisabled(
          value
        )
      );
  }

  handleDistrictChange() {
    const { districtId } = this.formGroup.value;
    this.resetControls(['realEstateId', 'buildingId', 'addressId', 'objectId']);

    const action: PropertyDistrictsChanged = new PropertyDistrictsChanged({
      districtId: districtId,
    });

    this.propertyChanged.emit(action);
  }

  handleRealEstateChange() {
    const { districtId, realEstateId } = this.formGroup.value;
    this.resetControls(['buildingId', 'addressId', 'objectId']);

    const action: PropertyRealEstatesChanged = new PropertyRealEstatesChanged({
      districtId: districtId,
      realEstateId: realEstateId,
    });

    this.propertyChanged.emit(action);
  }

  handleBuildingChange() {
    const { districtId, realEstateId, buildingId } = this.formGroup.value;
    this.resetControls(['addressId', 'objectId']);

    const action: PropertyBuildingsChanged = new PropertyBuildingsChanged({
      districtId: districtId,
      realEstateId: realEstateId,
      buildingId: buildingId,
    });

    this.propertyChanged.emit(action);
  }

  handleAddressChange() {
    const {
      districtId,
      realEstateId,
      buildingId,
      addressId,
    } = this.formGroup.value;
    this.resetControls(['objectId']);

    const action: PropertyAddressChanged = new PropertyAddressChanged({
      districtId: districtId,
      realEstateId: realEstateId,
      buildingId: buildingId,
      addressId: addressId,
    });

    this.propertyChanged.emit(action);
  }

  private resetControls(controlsNames: string[]): void {
    controlsNames.forEach((name) => this.formGroup.controls[name].reset(''));
  }

  private enableOrDisableControlsBasedOnPreviousSelectedValueWhenFormIsNotDisabled(
    controlsValues
  ) {
    if (this.formGroup.status !== 'DISABLED') {
      this.enableOrDisableSingleControlBasedOnPreviousSelectedValue(
        'realEstateId',
        controlsValues.districtId
      );
      this.enableOrDisableSingleControlBasedOnPreviousSelectedValue(
        'buildingId',
        controlsValues.realEstateId
      );
      this.enableOrDisableSingleControlBasedOnPreviousSelectedValue(
        'addressId',
        controlsValues.buildingId
      );
      this.enableOrDisableSingleControlBasedOnPreviousSelectedValue(
        'objectId',
        controlsValues.addressId
      );
    }
  }

  private enableOrDisableSingleControlBasedOnPreviousSelectedValue(
    controlName: string,
    previousControlValue: string
  ) {
    if (!previousControlValue) {
      this.formGroup.controls[controlName].disable({ emitEvent: false });
    } else {
      this.formGroup.controls[controlName].enable({ emitEvent: false });
    }
  }

  ngOnDestroy(): void {
    if (!!this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
