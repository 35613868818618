import { Action } from '@ngrx/store';
import {
  HideLoader,
  ShowLoader,
} from 'src/app/core/decorators/loader.decorator';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import { ProjectRequestI } from 'src/app/core/models/projects/project-request.interface';

export enum ManageProjectActionTypes {
  CreateProject = '[ManageProject] Create Project',
  CreateProjectSuccess = '[ManageProject] Create Project Success',
  CreateProjectFail = '[ManageProject] Create Project Fail',

  UpdateProject = '[ManageProject] Update Project',
  UpdateProjectSuccess = '[ManageProject] Update Project Success',
  UpdateProjectFail = '[ManageProject] Update Project Fail',

  CleanManageProjectState = '[ManageProject] Clean',
}

@ShowLoader()
export class CreateProject implements Action {
  readonly type = ManageProjectActionTypes.CreateProject;

  constructor(public payload: ProjectRequestI) {}
}

@HideLoader(ManageProjectActionTypes.CreateProject)
export class CreateProjectSuccess implements Action {
  readonly type = ManageProjectActionTypes.CreateProjectSuccess;

  constructor(public payload: number) {}
}

@HideLoader(ManageProjectActionTypes.CreateProject)
export class CreateProjectFail implements Action {
  readonly type = ManageProjectActionTypes.CreateProjectFail;

  constructor(public payload: ErrorResponseWithIdI) {}
}

@ShowLoader()
export class UpdateProject implements Action {
  readonly type = ManageProjectActionTypes.UpdateProject;

  constructor(public payload: ProjectRequestI) {}
}

@HideLoader(ManageProjectActionTypes.UpdateProject)
export class UpdateProjectSuccess implements Action {
  readonly type = ManageProjectActionTypes.UpdateProjectSuccess;

  constructor(public payload: number) {}
}

@HideLoader(ManageProjectActionTypes.UpdateProject)
export class UpdateProjectFail implements Action {
  readonly type = ManageProjectActionTypes.UpdateProjectFail;

  constructor(public payload: ErrorResponseWithIdI) {}
}

export class CleanManageProjectState implements Action {
  readonly type = ManageProjectActionTypes.CleanManageProjectState;

  constructor(public payload?: any) {}
}

export type ManageProjectActions =
  | CreateProject
  | CreateProjectSuccess
  | CreateProjectFail
  | UpdateProject
  | UpdateProjectSuccess
  | UpdateProjectFail
  | CleanManageProjectState;
