import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnInit {
  @Input()
  isMobile: boolean;

  @Input()
  isMobileFull: boolean;

  @Input()
  headerTranslateKey: string;

  @Input()
  optionsTranslateKey: string;

  @Input()
  filter: any;

  @Input()
  isSortedMobile: boolean;

  @Input()
  filterOptions: any[];

  @Input()
  skipAllOption: boolean;

  @Output()
  filterChanged: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  openMobileFilter: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}

  onFilterChanged(filter: any) {
    this.filterChanged.emit(filter);
  }

  onOpenMobileFilter() {
    this.openMobileFilter.emit();
  }
}
