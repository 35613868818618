import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
} from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';

import { LoadAssignment } from 'src/app/core/store/actions/assignments.action';
import { AssignmentsReducerState } from 'src/app/core/store/reducers/assignments.reducer';
import { getAssignmentForGivenUrlIdSelector } from 'src/app/core/store/selectors/assignment.selectors';

@Injectable()
export class AssignmentGuard implements CanActivate, CanActivateChild {
  constructor(private store: Store<AssignmentsReducerState>) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.checkIfCanActivate(route);
  }

  canActivateChild(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.checkIfCanActivate(route);
  }

  checkIfCanActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.waitForAssignmentToLoad(route.params.assignmentId).pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  waitForAssignmentToLoad(id: string): Observable<boolean> {
    return this.store.pipe(
      select(getAssignmentForGivenUrlIdSelector),
      map((assignment) => !!assignment),
      tap((exist: boolean) => {
        if (!exist) {
          this.store.dispatch(new LoadAssignment(id));
        }
      }),
      filter((exist: boolean) => exist),
      take(1)
    );
  }
}
