import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import { ProjectsService } from 'src/app/core/services/api/projects/projects.service';
import {
  CleanManageProjectState,
  CreateProject,
  CreateProjectFail,
  CreateProjectSuccess,
  ManageProjectActionTypes,
  UpdateProject,
  UpdateProjectFail,
  UpdateProjectSuccess,
} from 'src/app/core/store/actions/manage-project.action';
import { LoadProjectDetails } from 'src/app/projects/store/actions/project-details.action';
import { Store } from '@ngrx/store';
import { RouterGo } from 'src/app/core/store/actions/router.action';
import { ProjectsUrlEnum } from 'src/app/core/enums/url-paths.enum';
import getProjectDetailsUrl = ProjectsUrlEnum.getProjectDetailsUrl;

@Injectable()
export class ManageProjectEffects {
  constructor(
    private actions$: Actions,
    private projectsService: ProjectsService,
    private store: Store<any>
  ) {}

  public createProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManageProjectActionTypes.CreateProject),
      switchMap((action: CreateProject) =>
        this.projectsService.createProject(action.payload).pipe(
          map((id: any) => new CreateProjectSuccess(id.value)),
          catchError((error: ErrorResponseWithIdI) =>
            of(new CreateProjectFail(error))
          )
        )
      )
    )
  );

  public updateProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManageProjectActionTypes.UpdateProject),
      switchMap((action: UpdateProject) =>
        this.projectsService.updateProject(action.payload).pipe(
          map((_) => new UpdateProjectSuccess(action.payload.projectId)),
          catchError((error: ErrorResponseWithIdI) =>
            of(new UpdateProjectFail(error))
          )
        )
      )
    )
  );

  public createProjectSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManageProjectActionTypes.CreateProjectSuccess),
      tap(console.log),
      map(
        (action: CreateProjectSuccess) =>
          new RouterGo({ path: [getProjectDetailsUrl(action.payload)] })
      )
    )
  );

  public updatedProjectSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManageProjectActionTypes.UpdateProjectSuccess),
      map(
        (action: UpdateProjectSuccess) => new LoadProjectDetails(action.payload)
      )
    )
  );

  public handleCreateProjectFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManageProjectActionTypes.CreateProjectFail),
      map(() => new CleanManageProjectState())
    )
  );

  public handleUpdateProjectFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManageProjectActionTypes.UpdateProjectFail),
      map(() => new CleanManageProjectState())
    )
  );
}
