<div class="actions-dropdown">
  <div
    class="actions-dropdown__export btn-group"
    dropdown
    *ngIf="assignmentList?.length > 0 || !!assignment"
  >
    <div
      class="actions-dropdown__button-text dropdown-toggle btn btn-sm"
      dropdownToggle
      (click)="onShowExportMobile(true)"
    >
      <span
        class="actions-dropdown__text"
        [translate]="'dropdown-menu.export'"
      ></span>
    </div>
    <span *ngIf="!isSmall">
      <div *dropdownMenu class="dropdown-menu dropdown-menu-right">
        <app-actions-dropdown-export
          [assignment]="assignment"
          [assignmentList]="assignmentList"
          [selectedAssignmentList]="selectedAssignmentList"
          [selectedCalculationIds]="selectedCalculationIds"
          (exportToExcelSingle)="onExportToExcel($event)"
          (exportToSimpleExcelSingle)="onExportToSimpleExcel($event)"
          (exportToExcel)="onExportToExcel($event)"
          (exportToSimpleExcel)="onExportToSimpleExcel($event)"
          (exportAllToExcel)="onExportAllToExcel($event)"
          (exportAllToSimpleExcel)="onExportAllToSimpleExcel($event)"
          (printToPDF)="onPrintPdf($event)"
        ></app-actions-dropdown-export>
      </div>
    </span>
  </div>

  <div class="actions-dropdown__more btn-group" dropdown *ngIf="!!assignment">
    <div
      class="actions-dropdown__button-icon dropdown-toggle btn btn-sm"
      dropdownToggle
      (click)="onShowActionMobile(true)"
    >
      <mat-icon class="actions-dropdown__icon">more_horiz</mat-icon>
    </div>

    <span *ngIf="!isSmall">
      <div *dropdownMenu class="dropdown-menu dropdown-menu-right">
        <app-actions-dropdown-single-assignment
          *ngIf="!!assignment"
          [assignment]="assignment"
          (goToChatInMeps)="onGoToChatInMeps($event)"
          (goToMeps)="onGoToMeps($event)"
          (remove)="onRemove($event)"
          (close)="onClose($event)"
          (reopen)="onReopen($event)"
        >
        </app-actions-dropdown-single-assignment></div
    ></span>
  </div>
</div>

<app-actions-dropdown-single-assignment-mobile
  *ngIf="showMobileActions"
  [assignment]="assignment"
  (goToChatInMeps)="onGoToChatInMeps($event)"
  (goToMeps)="onGoToMeps($event)"
  (remove)="onRemove($event)"
  (close)="onClose($event)"
  (reopen)="onReopen($event)"
  (closeActionMobile)="onShowActionMobile(false)"
></app-actions-dropdown-single-assignment-mobile>

<app-actions-dropdown-export-mobile
  *ngIf="showMobileExport"
  [assignment]="assignment"
  [assignmentList]="assignmentList"
  [selectedAssignmentList]="selectedAssignmentList"
  [selectedCalculationIds]="selectedCalculationIds"
  (exportToExcelSingle)="onExportToExcel($event)"
  (exportToSimpleExcelSingle)="onExportToSimpleExcel($event)"
  (exportToExcel)="onExportToExcel($event)"
  (exportToSimpleExcel)="onExportToSimpleExcel($event)"
  (exportAllToExcel)="onExportAllToExcel($event)"
  (exportAllToSimpleExcel)="onExportAllToSimpleExcel($event)"
  (printToPDF)="onPrintPdf($event)"
  (closeMobileExport)="onShowExportMobile(false)"
></app-actions-dropdown-export-mobile>
