<div class="side-nav">
  <div class="side-nav__logo">
    <span class="side-nav__logo-text" [translate]="'common.main-page'"></span>
    <mat-icon class="side-nav__logo-icon" (click)="onHideSideBar()"
      >close
    </mat-icon>
  </div>

  <div
    class="side-nav__header"
    [ngClass]="{ 'side-nav__header--active': isActive('assignments') }"
  >
    <div class="side-nav__header-item" (click)="setActive('assignments')">
      <span
        class="side-nav__header-item-text"
        [translate]="'go.elements.assignment'"
      ></span>
      <mat-icon
        class="side-nav__header-item-icon"
        *ngIf="!isActive('assignments')"
        >expand_more
      </mat-icon>
      <mat-icon
        class="side-nav__header-item-icon-less"
        *ngIf="isActive('assignments')"
        >expand_less
      </mat-icon>
    </div>

    <div class="side-nav-submenu" *ngIf="activeItem === 'assignments'">
      <div
        class="side-nav-submenu__item"
        (click)="onGoToMakeAction()"
        [translate]="'go.assignmentPanel.makeAction'"
      ></div>
      <div
        class="side-nav-submenu__item"
        (click)="onGoToWatchlist()"
        [translate]="'go.assignmentPanel.watchList'"
      ></div>
      <div
        class="side-nav-submenu__item"
        (click)="onGoToProduction()"
        [translate]="'go.assignmentPanel.assignments'"
      ></div>
      <div
        class="side-nav-submenu__item"
        (click)="onGoToStartAssignment()"
        [translate]="'go.assignmentPanel.newAssignment'"
      ></div>
    </div>
  </div>

  <div
    class="side-nav__header"
    [ngClass]="{ 'side-nav__header--active': isActive('projects') }"
  >
    <div class="side-nav__header-item" (click)="setActive('projects')">
      <span
        class="side-nav__header-item-text"
        [translate]="'go.elements.projects'"
      ></span>
      <mat-icon class="side-nav__header-item-icon" *ngIf="!isActive('projects')"
        >expand_more
      </mat-icon>
      <mat-icon
        class="side-nav__header-item-icon-less"
        *ngIf="isActive('projects')"
        >expand_less
      </mat-icon>
    </div>

    <div class="side-nav-submenu" *ngIf="activeItem === 'projects'">
      <div
        class="side-nav-submenu__item"
        (click)="onGoToProjects()"
        [translate]="'projects.menu.all'"
      ></div>
      <div
        class="side-nav-submenu__item"
        (click)="onGoToStartProject()"
        [translate]="'projects.menu.new'"
      ></div>
    </div>
  </div>

  <!-- TODO: Hide until it will not be implemented -->

  <!--  <div-->
  <!--    class="side-nav__header"-->
  <!--    [ngClass]="{ 'side-nav__header&#45;&#45;active': isActive('numbers') }"-->
  <!--  >-->
  <!--    <div class="side-nav__header-item" (click)="setActive('numbers')">-->
  <!--      <span-->
  <!--        class="side-nav__header-item-text"-->
  <!--        [translate]="'go.elements.numbers'"-->
  <!--      ></span>-->
  <!--      <mat-icon class="side-nav__header-item-icon" *ngIf="!isActive('numbers')"-->
  <!--        >expand_more-->
  <!--      </mat-icon>-->
  <!--      <mat-icon-->
  <!--        class="side-nav__header-item-icon-less"-->
  <!--        *ngIf="isActive('numbers')"-->
  <!--        >expand_less-->
  <!--      </mat-icon>-->
  <!--    </div>-->
  <!--    <div class="side-nav-submenu" *ngIf="activeItem === 'numbers'"></div>-->
  <!--  </div>-->

  <div
    class="side-nav__header-single"
    [translate]="'header.newAssignment'"
    (click)="onGoToStartAssignment()"
  ></div>

  <div
    class="side-nav__header"
    [ngClass]="{ 'side-nav__header--active': isActive('profile') }"
  >
    <div class="side-nav__header-item" (click)="setActive('profile')">
      <span
        class="side-nav__header-item-text"
        [translate]="'go.elements.profile'"
      ></span>
      <mat-icon class="side-nav__header-item-icon" *ngIf="!isActive('profile')"
        >expand_more
      </mat-icon>
      <mat-icon
        class="side-nav__header-item-icon-less"
        *ngIf="isActive('profile')"
        >expand_less
      </mat-icon>
    </div>

    <div class="side-nav-submenu" *ngIf="activeItem === 'profile'">
      <div
        class="side-nav-submenu__item"
        (click)="onGoToAccountDetails()"
        [translate]="'go.elements.accountDetails'"
      ></div>
      <div
        class="side-nav-submenu__item"
        *ngIf="isAdmin"
        (click)="onGoToAdministration()"
        [translate]="'go.elements.administration'"
      ></div>
      <div
        class="side-nav-submenu__item"
        (click)="onLogout()"
        [translate]="'header.logout'"
      ></div>
    </div>
  </div>

  <div class="side-nav__footer">
    <span class="side-nav__footer-phone">
      <mat-icon class="side-nav__footer-phone-icon">help</mat-icon>
      <span class="side-nav__footer-phone-text">+46 771 793 336</span>
    </span>
    <span
      class="side-nav__footer-help"
      (click)="onGoToSupport()"
      [translate]="'go.elements.moreHelp'"
    ></span>
  </div>
</div>
