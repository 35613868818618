<div class="filter filter--mobile" *ngIf="isMobile; else desktop">
  <div class="filter__list">
    <div
      class="filter__title"
      *ngIf="!!headerTranslateKey"
      [translate]="headerTranslateKey"
    ></div>

    <span
      class="filter__list-item btn-third filter__list-item--active"
      [translate]="!!filter ? optionsTranslateKey + filter : 'all'"
    ></span>

    <mat-icon
      class="filter__list-button"
      [ngClass]="{
        'filter__list-button--active': !!filter || isSortedMobile
      }"
      (click)="onOpenMobileFilter()"
      >filter_list
    </mat-icon>
  </div>
</div>

<ng-template #desktop>
  <div
    [ngClass]="{ 'filter--mobile-full': isMobileFull }"
    class="filter float-xl-right"
  >
    <div
      class="filter__title"
      *ngIf="!!headerTranslateKey"
      [translate]="headerTranslateKey"
    ></div>

    <div class="filter__list">
      <span
        class="filter__list-item btn-third"
        *ngIf="!skipAllOption"
        [ngClass]="{
          'filter__list-item--active': filter === null
        }"
        [translate]="'all'"
        (click)="onFilterChanged(null)"
      ></span>
      <span
        class="filter__list-item btn-third"
        *ngFor="let phaseFilterItem of filterOptions"
        [ngClass]="{
          'filter__list-item--active': filter === phaseFilterItem
        }"
        [translate]="optionsTranslateKey + phaseFilterItem"
        (click)="onFilterChanged(phaseFilterItem)"
      ></span>
    </div>
  </div>
</ng-template>
