import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';

import { getStartPageStateSelector } from 'src/app/core/store';
import { StartPageReducerState } from 'src/app/core/store/reducers/start-page.reducer';
import { LoadStartPage } from 'src/app/core/store/actions/start-page.action';

@Injectable()
export class StartPageGuard implements CanActivate, CanActivateChild {
  constructor(private store: Store<StartPageReducerState>) {}

  canActivate(): Observable<boolean> {
    return this.checkIfCanActivate();
  }

  canActivateChild(): Observable<boolean> {
    return this.checkIfCanActivate();
  }

  checkIfCanActivate(): Observable<boolean> {
    return this.waitForStartPageToLoad().pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  waitForStartPageToLoad(): Observable<boolean> {
    return this.store.pipe(
      select(getStartPageStateSelector),
      tap((startPageReducerState: StartPageReducerState) => {
        if (
          !startPageReducerState.loaded &&
          !startPageReducerState.loading &&
          !startPageReducerState.error
        ) {
          this.store.dispatch(new LoadStartPage());
        }
      }),
      filter(
        (startPageReducerState: StartPageReducerState) =>
          startPageReducerState.loaded || !!startPageReducerState.error
      ),
      map((startPageReducerState: StartPageReducerState) => {
        if (!!startPageReducerState.error) {
          throw new Error(startPageReducerState.error.error.message);
        } else {
          return startPageReducerState.loaded;
        }
      }),
      take(1)
    );
  }
}
