import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { getPusherMessagesStateSelector } from 'src/app/core/store';
import { PusherReducerState } from 'src/app/core/store/reducers/pusher.reducer';
import { LoadAllPusherMessages } from 'src/app/core/store/actions/pusher.action';

@Injectable()
export class PusherMessagesGuard implements CanActivate {
  constructor(private store: Store<PusherReducerState>) {}

  canActivate() {
    return this.waitForAlertsToLoad().pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  waitForAlertsToLoad(): Observable<boolean> {
    return this.store.pipe(
      select(getPusherMessagesStateSelector),
      tap((pusherReducerState: PusherReducerState) => {
        if (
          !pusherReducerState.loaded &&
          !pusherReducerState.loading &&
          !pusherReducerState.error
        ) {
          this.store.dispatch(new LoadAllPusherMessages());
        }
      }),
      filter(
        (pusherReducerState: PusherReducerState) =>
          pusherReducerState.loaded || !!pusherReducerState.error
      ),
      map((pusherReducerState: PusherReducerState) => {
        if (!!pusherReducerState.error) {
          throw new Error(pusherReducerState.error.error.message);
        } else {
          return pusherReducerState.loaded;
        }
      }),
      take(1)
    );
  }
}
