<div [formGroup]="formGroup">
  <div class="form-row">
    <div class="form-group col-12 col-md-6">
      <label
        for="create-tenant-name"
        [translate]="'create.formControls.tenant.name'"
      ></label>

      <span
        class="form-control-mandatory"
        *ngIf="isRequired('name')"
        [translate]="'mandatory'"
      ></span>

      <input
        id="create-tenant-name"
        formControlName="name"
        type="text"
        class="form-control"
        [ngClass]="{
          'is-invalid': isValid(formGroup.controls['name'])
        }"
      />

      <div
        class="invalid-feedback"
        *ngIf="formGroup.controls['name']?.invalid"
        [translate]="'create.errorMessages.name'"
      ></div>
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col-12 col-md-6">
      <label
        for="create-tenant-email"
        [translate]="'create.formControls.tenant.email'"
      ></label>

      <span
        class="form-control-mandatory"
        *ngIf="isRequired('email')"
        [translate]="'mandatory'"
      ></span>

      <input
        id="create-tenant-email"
        formControlName="email"
        type="email"
        class="form-control"
        [ngClass]="{
          'is-invalid': isValid(formGroup.controls['email'])
        }"
      />

      <div
        class="invalid-feedback"
        *ngIf="formGroup.controls['email']?.invalid"
        [translate]="'create.errorMessages.email'"
      ></div>
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col-12 col-md-6">
      <label
        for="create-tenant-phone"
        [translate]="'create.formControls.tenant.phone'"
      ></label>

      <span
        class="form-control-mandatory"
        *ngIf="isRequired('phone')"
        [translate]="'mandatory'"
      ></span>

      <input
        id="create-tenant-phone"
        formControlName="phone"
        type="tel"
        class="form-control"
      />
    </div>
  </div>
</div>
