import { Action } from '@ngrx/store';
import {
  HideLoader,
  ShowLoader,
} from 'src/app/core/decorators/loader.decorator';

export enum AssignmentChangeStatusActionTypes {
  CloseAssignment = '[AssignmentChangeStatus] Close Assignment',
  CloseAssignmentSuccess = '[AssignmentChangeStatus] Close Assignment Success',
  CloseAssignmentFail = '[AssignmentChangeStatus] Close Assignment Fail',

  ReopenAssignment = '[AssignmentChangeStatus] Reopen Assignment',
  ReopenAssignmentSuccess = '[AssignmentChangeStatus] Reopen Assignment Success',
  ReopenAssignmentFail = '[AssignmentChangeStatus] Reopen Assignment Fail',

  RemoveAssignment = '[AssignmentChangeStatus] Remove Assignment',
  RemoveAssignmentSuccess = '[AssignmentChangeStatus] Remove Assignment Success',
  RemoveAssignmentFail = '[AssignmentChangeStatus] Remove Assignment Fail',

  CleanAssignmentChangeStatusState = '[AssignmentChangeStatus] Clean',
}

@ShowLoader()
export class CloseAssignment implements Action {
  readonly type = AssignmentChangeStatusActionTypes.CloseAssignment;

  constructor(public payload: string) {}
}

@HideLoader(AssignmentChangeStatusActionTypes.CloseAssignment)
export class CloseAssignmentSuccess implements Action {
  readonly type = AssignmentChangeStatusActionTypes.CloseAssignmentSuccess;

  constructor(public payload: string) {}
}

@HideLoader(AssignmentChangeStatusActionTypes.CloseAssignment)
export class CloseAssignmentFail implements Action {
  readonly type = AssignmentChangeStatusActionTypes.CloseAssignmentFail;

  constructor(public payload: string) {}
}

@ShowLoader()
export class ReopenAssignment implements Action {
  readonly type = AssignmentChangeStatusActionTypes.ReopenAssignment;

  constructor(public payload: string) {}
}

@HideLoader(AssignmentChangeStatusActionTypes.ReopenAssignment)
export class ReopenAssignmentSuccess implements Action {
  readonly type = AssignmentChangeStatusActionTypes.ReopenAssignmentSuccess;

  constructor(public payload: string) {}
}

@HideLoader(AssignmentChangeStatusActionTypes.ReopenAssignment)
export class ReopenAssignmentFail implements Action {
  readonly type = AssignmentChangeStatusActionTypes.ReopenAssignmentFail;

  constructor(public payload: string) {}
}

@ShowLoader()
export class RemoveAssignment implements Action {
  readonly type = AssignmentChangeStatusActionTypes.RemoveAssignment;

  constructor(
    public payload: { assignmentId: string; wasCreatedInUw: boolean }
  ) {}
}

@HideLoader(AssignmentChangeStatusActionTypes.RemoveAssignment)
export class RemoveAssignmentSuccess implements Action {
  readonly type = AssignmentChangeStatusActionTypes.RemoveAssignmentSuccess;

  constructor(
    public payload: { assignmentId: string; wasCreatedInUw: boolean }
  ) {}
}

@HideLoader(AssignmentChangeStatusActionTypes.RemoveAssignment)
export class RemoveAssignmentFail implements Action {
  readonly type = AssignmentChangeStatusActionTypes.RemoveAssignmentFail;

  constructor(public payload: string) {}
}

export class CleanAssignmentChangeStatusState implements Action {
  readonly type =
    AssignmentChangeStatusActionTypes.CleanAssignmentChangeStatusState;

  constructor(public payload?: string) {}
}

export type AssignmentChangeStatusAction =
  | CloseAssignment
  | CloseAssignmentSuccess
  | CloseAssignmentFail
  | ReopenAssignment
  | ReopenAssignmentSuccess
  | ReopenAssignmentFail
  | RemoveAssignment
  | RemoveAssignmentSuccess
  | RemoveAssignmentFail
  | CleanAssignmentChangeStatusState;
