<app-modal-template
  [header]="'removeAssignmentTemplate.title'"
  [button]="'removeAssignmentTemplate.button'"
  (closeModal)="closeModal()"
  (submit)="goToMeps()"
>
  <div class="mt-4 mb-4">
    <p [translate]="">
      {{ 'removeAssignmentTemplate.info' | translate }}
      <b>{{ 'removeAssignmentTemplate.message' | translate }}</b>
    </p>
  </div>
</app-modal-template>
