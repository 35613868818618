import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import { PropertyBuildingI } from 'src/app/core/models/property-register/property-building.interface';
import {
  CleanPropertyAddress,
  LoadPropertyAddress,
} from 'src/app/core/modules/start/store/actions/property-address.actions';
import {
  LoadPropertyBuildings,
  LoadPropertyBuildingsFail,
  LoadPropertyBuildingsSuccess,
  PropertyBuildingActionTypes,
  PropertyBuildingsChanged,
} from 'src/app/core/modules/start/store/actions/property-building.actions';
import { CleanPropertyObject } from 'src/app/core/modules/start/store/actions/property-object.actions';
import { PropertyRegisterService } from 'src/app/core/services/api/property-register/property-register.service';

@Injectable()
export class PropertyBuildingEffects {
  constructor(
    private actions$: Actions,
    private propertyRegisterService: PropertyRegisterService
  ) {}

  public handleNewPropertyBuilding$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PropertyBuildingActionTypes.PropertyBuildingsChanged),
      switchMap((action: PropertyBuildingsChanged) => [
        new CleanPropertyAddress(),
        new CleanPropertyObject(),
        new LoadPropertyAddress(action.payload),
      ])
    )
  );

  public getPropertyBuilding$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PropertyBuildingActionTypes.LoadPropertyBuildings),
      switchMap((action: LoadPropertyBuildings) =>
        this.propertyRegisterService.getBuilding(action.payload).pipe(
          map(
            (propertyBuilding: PropertyBuildingI) =>
              new LoadPropertyBuildingsSuccess(propertyBuilding)
          ),
          catchError((error: ErrorResponseWithIdI) =>
            of(new LoadPropertyBuildingsFail(error))
          )
        )
      )
    )
  );
}
