import { createSelector } from '@ngrx/store';
import { getPropertyDistrictStateSelector } from 'src/app/core/modules/start/store/index';
import {
  getPropertyDistrictError,
  getPropertyDistrictLoaded,
  getPropertyDistrictLoading,
} from 'src/app/core/modules/start/store/reducers/property-district.reducer';
import { idNameResourceAdapter } from 'src/app/core/modules/start/store/utils/id-name-resource.adapter';

export const getPropertyDistrictLoadingSelector = createSelector(
  getPropertyDistrictStateSelector,
  getPropertyDistrictLoading
);

export const getPropertyDistrictLoadedSelector = createSelector(
  getPropertyDistrictStateSelector,
  getPropertyDistrictLoaded
);

export const getPropertyDistrictErrorSelector = createSelector(
  getPropertyDistrictStateSelector,
  getPropertyDistrictError
);

export const getPropertyDistrictEntitiesSelector = idNameResourceAdapter.getSelectors(
  getPropertyDistrictStateSelector
).selectEntities;
export const getPropertyDistrictTotalSelector = idNameResourceAdapter.getSelectors(
  getPropertyDistrictStateSelector
).selectTotal;
export const getPropertyDistrictIdsSelector = idNameResourceAdapter.getSelectors(
  getPropertyDistrictStateSelector
).selectIds;
export const getPropertyAllDistrictSelector = idNameResourceAdapter.getSelectors(
  getPropertyDistrictStateSelector
).selectAll;
