import { EntityState } from '@ngrx/entity';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import { IdNameResourceI } from 'src/app/core/models/property-register/id-name-resource.interface';
import {
  PropertyBuildingActions,
  PropertyBuildingActionTypes,
} from 'src/app/core/modules/start/store/actions/property-building.actions';
import {
  StartAssignmentsActions,
  StartAssignmentsActionTypes,
} from 'src/app/core/modules/start/store/actions/start-assignments.action';
import { idNameResourceAdapter } from 'src/app/core/modules/start/store/utils/id-name-resource.adapter';

export interface PropertyBuildingReducerState
  extends EntityState<IdNameResourceI> {
  loading: boolean;
  loaded: boolean;
  error: ErrorResponseWithIdI;
}

export const initialPropertyBuildingReducerState: PropertyBuildingReducerState = idNameResourceAdapter.getInitialState(
  {
    loading: false,
    loaded: false,
    error: null,
  }
);

export function propertyBuildingReducer(
  state = initialPropertyBuildingReducerState,
  action: PropertyBuildingActions | StartAssignmentsActions
): PropertyBuildingReducerState {
  switch (action.type) {
    case PropertyBuildingActionTypes.LoadPropertyBuildings: {
      return {
        ...state,
        loading: true,
      };
    }

    case PropertyBuildingActionTypes.LoadPropertyBuildingsSuccess: {
      return idNameResourceAdapter.addMany(action.payload.buildings, {
        ...initialPropertyBuildingReducerState,
        loaded: true,
      });
    }

    case PropertyBuildingActionTypes.LoadPropertyBuildingsFail: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload,
      };
    }

    case StartAssignmentsActionTypes.CleanStartAssignmentsState:
    case PropertyBuildingActionTypes.CleanPropertyBuilding: {
      return initialPropertyBuildingReducerState;
    }

    default:
      return state;
  }
}

export const getPropertyBuildingLoading = (
  state: PropertyBuildingReducerState
) => state.loading;
export const getPropertyBuildingLoaded = (
  state: PropertyBuildingReducerState
) => state.loaded;
export const getPropertyBuildingError = (state: PropertyBuildingReducerState) =>
  state.error;
