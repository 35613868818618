import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-with-header',
  templateUrl: './card-with-header.component.html',
  styleUrls: ['./card-with-header.component.scss'],
})
export class CardWithHeaderComponent implements OnInit {
  @Input()
  header: string;

  @Input()
  border = true;

  constructor() {}

  ngOnInit(): void {}
}
