import { Action } from '@ngrx/store';

export enum ExternalRedirectActionTypes {
  GoToSearch = '[External Redirect] Go To Search',

  GoToMeps = '[External Redirect] Go to MEPS',
  GoToMepsConversation = '[External Redirect] Go to MEPS Conversation',

  GoToNewWindow = '[External Redirect] Go to New Window',
  GoToEmail = '[External Redirect] Go to Email',
  GoToPhoneNumber = '[External Redirect] Go to Phone Number',
}

export class GoToSearch implements Action {
  readonly type = ExternalRedirectActionTypes.GoToSearch;

  constructor(public payload: string) {}
}

export class GoToMeps implements Action {
  readonly type = ExternalRedirectActionTypes.GoToMeps;

  constructor(public payload: string) {}
}

export class GoToMepsConversation implements Action {
  readonly type = ExternalRedirectActionTypes.GoToMepsConversation;

  constructor(public payload: string) {}
}

export class GoToNewWindow implements Action {
  readonly type = ExternalRedirectActionTypes.GoToNewWindow;

  constructor(public payload: string) {}
}

export class GoToEmail implements Action {
  readonly type = ExternalRedirectActionTypes.GoToEmail;

  constructor(public payload: string) {}
}

export class GoToPhoneNumber implements Action {
  readonly type = ExternalRedirectActionTypes.GoToPhoneNumber;

  constructor(public payload: string) {}
}

export type ExternalRedirectActions =
  | GoToSearch
  | GoToMeps
  | GoToMepsConversation
  | GoToNewWindow
  | GoToEmail
  | GoToPhoneNumber;
