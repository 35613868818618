<app-bottom-drower-mobile
  [header]="'mobileActions.headerActions' | translate"
  [small]="true"
  (close)="onOpenMobileActions(null)"
>
  <div class="my-action-card-button-mobile__options">
    <button
      *ngFor="let actionName of action?.secondaryActions"
      class="my-action-card-button-mobile__options-button btn btn-third"
      (click)="onMakeAction(actionName)"
      [translate]="'assignmentActions.' + actionName"
    ></button>
  </div>
</app-bottom-drower-mobile>
