<app-page-header [header]="'notifications.header'"></app-page-header>

<div *ngIf="pageSizeService.isSmallScreen$ | async; else desktop">
  <app-notification-list-mobile
    [elementsList]="alertMessagesForMobile$ | async"
    [allElementsTotal]="alertMessagesTotal$ | async"
    [tableSizeLimit]="tableSizeLimit$ | async"
    [tableSizeLimitOptions]="tableSizeLimitOptions$ | async"
    [currentPage]="currentPage$ | async"
    (paginationChanged)="onPaginationChanged($event)"
    (paginationLimitChanged)="onPaginationLimitChanged($event)"
    (goToDetails)="onGoToDetails($event)"
  ></app-notification-list-mobile>
</div>

<ng-template #desktop>
  <app-notifications-list
    [elementsList]="alertMessages$ | async"
    [allElementsTotal]="alertMessagesTotal$ | async"
    [tableSizeLimit]="tableSizeLimit$ | async"
    [tableSizeLimitOptions]="tableSizeLimitOptions$ | async"
    [currentPage]="currentPage$ | async"
    (paginationChanged)="onPaginationChanged($event)"
    (paginationLimitChanged)="onPaginationLimitChanged($event)"
    (goToDetails)="onGoToDetails($event)"
  ></app-notifications-list>
</ng-template>
