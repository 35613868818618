import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BlockedComponent } from 'src/app/auth/containers/blocked/blocked.component';

import { LoginComponent } from 'src/app/auth/containers/login/login.component';
import { RegisterComponent } from 'src/app/auth/containers/register/register.component';
import { AuthGuard } from 'src/app/auth/guards/auth.guard';
import { BlockedGuard } from 'src/app/auth/guards/blocked.guard';
import { LoginPageGuard } from 'src/app/auth/guards/login-page.guard';
import { UserGuard } from 'src/app/auth/guards/user.guard';
import { CoreUrlEnums } from 'src/app/core/enums/url-paths.enum';
import { AccountComponent } from 'src/app/auth/containers/account/account.component';

export const authRoutes: Routes = [
  {
    path: CoreUrlEnums.LOGIN,
    canActivate: [LoginPageGuard],
    component: LoginComponent,
  },
  {
    path: CoreUrlEnums.BLOCKED,
    canActivate: [BlockedGuard],
    component: BlockedComponent,
  },
  {
    path: CoreUrlEnums.REGISTER,
    canActivate: [AuthGuard, UserGuard],
    component: RegisterComponent,
  },
  {
    path: CoreUrlEnums.ACCOUNT,
    canActivate: [AuthGuard, UserGuard],
    component: AccountComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(authRoutes)],
  providers: [LoginPageGuard],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
