<div [formGroup]="formGroup">
  <div class="form-row" formGroupName="contact">
    <div class="form-group col-12 col-md-4">
      <label
        for="edit-user-name"
        [translate]="'administration.users.name'"
      ></label>

      <span
        class="form-control-mandatory"
        *ngIf="isRequired('contact.name')"
        [translate]="'mandatory'"
      ></span>

      <input
        [ngClass]="{
          'is-invalid':
            formGroup.get('contact.name').invalid &&
            (submitted || formGroup.get('contact.name').touched)
        }"
        id="edit-user-name"
        formControlName="name"
        type="text"
        class="form-control"
      />

      <div
        class="invalid-feedback"
        *ngIf="formGroup.get('contact.name').invalid"
        [translate]="'administration.users.errorMessages.name'"
      ></div>
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col-12 col-md-4">
      <label
        for="edit-user-department"
        [translate]="'administration.users.department'"
      ></label>

      <span
        class="form-control-mandatory"
        *ngIf="isRequired('department')"
        [translate]="'mandatory'"
      ></span>

      <input
        id="edit-user-department"
        formControlName="department"
        type="text"
        class="form-control"
      />
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col-12 col-md-4">
      <label
        for="edit-user-role"
        [translate]="'administration.users.role'"
      ></label>

      <span
        class="form-control-mandatory"
        *ngIf="isRequired('role')"
        [translate]="'mandatory'"
      ></span>

      <select class="form-control" formControlName="role">
        <option
          id="edit-user-role"
          *ngFor="let userRole of userRolesTypes | keyvalue"
          [value]="[userRole.value]"
        >
          {{ 'administration.userRoles.' + userRole.value | translate }}
        </option>
      </select>
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col-12 col-md-4">
      <label
        for="inspector"
        [translate]="'administration.users.inspector'"
      ></label>

      <span
        class="form-control-mandatory"
        *ngIf="isRequired('inspector')"
        [translate]="'mandatory'"
      ></span>

      <select id="inspector" class="form-control" formControlName="inspector">
        <option [value]="true" [translate]="'yes'"></option>
        <option [value]="false" [translate]="'no'"></option>
      </select>
    </div>
  </div>

  <span formGroupName="contact">
    <div class="form-row">
      <div class="form-group col-12 col-md-4">
        <label
          for="edit-user-email"
          [translate]="'administration.users.email'"
        ></label>

        <span
          class="form-control-mandatory"
          *ngIf="isRequired('contact.email')"
          [translate]="'mandatory'"
        ></span>

        <input
          #email
          [ngClass]="{
            'is-invalid':
              formGroup.get('contact.email').invalid &&
              (submitted || formGroup.get('contact.email').touched)
          }"
          id="edit-user-email"
          formControlName="email"
          type="text"
          class="form-control"
        />

        <div
          class="invalid-feedback"
          *ngIf="formGroup.get('contact.email').invalid"
          [translate]="'administration.users.errorMessages.email'"
        ></div>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col-12 col-md-4">
        <label
          for="edit-user-phone"
          [translate]="'administration.users.phone'"
        ></label>

        <span
          class="form-control-mandatory"
          *ngIf="isRequired('contact.phone')"
          [translate]="'mandatory'"
        ></span>

        <input
          id="edit-user-phone"
          formControlName="phone"
          type="text"
          class="form-control"
        />
      </div>
    </div>
  </span>

  <div class="form-row">
    <div class="form-group col-12 col-md-4">
      <label
        for="edit-user-externalId"
        [translate]="'administration.users.externalId'"
      ></label>

      <span
        class="form-control-mandatory"
        *ngIf="isRequired('externalId')"
        [translate]="'mandatory'"
      ></span>

      <input
        id="edit-user-externalId"
        formControlName="externalId"
        type="text"
        class="form-control"
      />
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col-12 col-md-4">
      <label
        for="edit-user-mobile-language"
        [translate]="'administration.users.language'"
      ></label>

      <span
        class="form-control-mandatory"
        *ngIf="isRequired('language')"
        [translate]="'mandatory'"
      ></span>

      <select
        id="edit-user-mobile-language"
        class="form-control"
        formControlName="language"
      >
        <option
          *ngFor="let language of languageTypes | keyvalue"
          [value]="[language.value]"
        >
          {{ language.value | translate }}
        </option>
      </select>
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col-12 col-md-4">
      <div class="custom-control-inline w-100 justify-content-between">
        <label
          for="blocked"
          [translate]="'administration.users.blocked'"
        ></label>

        <span
          class="form-control-mandatory"
          *ngIf="isRequired('blocked')"
          [translate]="'mandatory'"
        ></span>

        <input
          id="blocked"
          class="checkbox-switch"
          type="checkbox"
          formControlName="blocked"
        />
      </div>
    </div>
  </div>

  <div class="form-row mt-3">
    <div class="form-group col-12 col-md-4 d-flex align-items-center">
      <span
        class="btn-discard"
        [translate]="'discard'"
        (click)="onDiscard()"
      ></span>

      <button
        class="btn btn-primary btn-secondary btn-save"
        [translate]="'save'"
        (click)="onSave()"
      ></button>
    </div>
  </div>
</div>
