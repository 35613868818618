import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BaseActionsDropdownSingleAssignmentComponent } from 'src/app/shared/components/base-actions-dropdown-single-assignment/base-actions-dropdown-single-assignment.component';

@Component({
  selector: 'app-actions-dropdown-single-assignment-mobile',
  templateUrl: './actions-dropdown-single-assignment-mobile.component.html',
  styleUrls: ['./actions-dropdown-single-assignment-mobile.component.scss'],
})
export class ActionsDropdownSingleAssignmentMobileComponent
  extends BaseActionsDropdownSingleAssignmentComponent
  implements OnInit {
  @Output()
  closeActionMobile: EventEmitter<void> = new EventEmitter<void>();

  constructor() {
    super();
  }

  onCloseMobileActions() {
    this.closeActionMobile.emit();
  }
}
