import { createSelector } from '@ngrx/store';
import { getPropertyBuildingStateSelector } from 'src/app/core/modules/start/store/index';
import {
  getPropertyBuildingError,
  getPropertyBuildingLoaded,
  getPropertyBuildingLoading,
} from 'src/app/core/modules/start/store/reducers/property-building.reducer';
import { idNameResourceAdapter } from 'src/app/core/modules/start/store/utils/id-name-resource.adapter';

export const getPropertyBuildingLoadingSelector = createSelector(
  getPropertyBuildingStateSelector,
  getPropertyBuildingLoading
);

export const getPropertyBuildingLoadedSelector = createSelector(
  getPropertyBuildingStateSelector,
  getPropertyBuildingLoaded
);

export const getPropertyBuildingErrorSelector = createSelector(
  getPropertyBuildingStateSelector,
  getPropertyBuildingError
);

export const getPropertyBuildingEntitiesSelector = idNameResourceAdapter.getSelectors(
  getPropertyBuildingStateSelector
).selectEntities;
export const getPropertyBuildingTotalSelector = idNameResourceAdapter.getSelectors(
  getPropertyBuildingStateSelector
).selectTotal;
export const getPropertyBuildingIdsSelector = idNameResourceAdapter.getSelectors(
  getPropertyBuildingStateSelector
).selectIds;
export const getPropertyAllBuildingSelector = idNameResourceAdapter.getSelectors(
  getPropertyBuildingStateSelector
).selectAll;
