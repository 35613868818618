<div class="row">
  <div class="col-12 col-md-6">
    <div class="location-search card bg-light" [formGroup]="formGroup">
      <div class="location-search__body card-body">
        <div
          class="location-search__title card-title"
          [translate]="'create.addressType.address'"
        ></div>

        <div class="form-row">
          <div class="form-group col-12">
            <app-autocomplete
              (placeChanged)="onPlaceChanged($event)"
            ></app-autocomplete>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-12">
            <label
              for="create-address"
              [translate]="'create.formControls.address'"
            ></label>

            <span
              class="form-control-mandatory"
              *ngIf="isRequired('address')"
              [translate]="'mandatory'"
            ></span>

            <input
              id="create-address"
              formControlName="address"
              type="text"
              class="form-control inverted"
              [ngClass]="{
                'is-invalid': isValid(formGroup.controls['address'])
              }"
            />

            <div
              class="invalid-feedback"
              *ngIf="formGroup.controls['address']?.invalid"
              [translate]="'create.errorMessages.address'"
            ></div>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-12">
            <label
              for="create-city"
              [translate]="'create.formControls.city'"
            ></label>

            <span
              class="form-control-mandatory"
              *ngIf="isRequired('city')"
              [translate]="'mandatory'"
            ></span>

            <input
              id="create-city"
              formControlName="city"
              type="text"
              class="form-control inverted"
              [ngClass]="{
                'is-invalid': isValid(formGroup.controls['city'])
              }"
            />

            <div
              class="invalid-feedback"
              *ngIf="formGroup.controls['city']?.invalid"
              [translate]="'create.errorMessages.city'"
            ></div>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-12">
            <label
              for="create-postalCode"
              [translate]="'create.formControls.postalCode'"
            ></label>

            <span
              class="form-control-mandatory"
              *ngIf="isRequired('postalCode')"
              [translate]="'mandatory'"
            ></span>

            <input
              id="create-postalCode"
              formControlName="postalCode"
              type="text"
              class="form-control inverted"
              [ngClass]="{
                'is-invalid': isValid(formGroup.controls['postalCode'])
              }"
            />

            <div
              class="invalid-feedback"
              *ngIf="formGroup.controls['postalCode']?.invalid"
              [translate]="'create.errorMessages.postalCode'"
            ></div>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-12">
            <label
              for="create-objectNumber"
              [translate]="'create.formControls.objectNumber'"
            ></label>

            <span
              class="form-control-mandatory"
              *ngIf="isRequired('objectNumber')"
              [translate]="'mandatory'"
            ></span>

            <input
              id="create-objectNumber"
              formControlName="objectNumber"
              type="text"
              class="form-control inverted"
              [ngClass]="{
                'is-invalid': isValid(formGroup.controls['objectNumber'])
              }"
            />

            <div
              class="invalid-feedback"
              *ngIf="formGroup.controls['objectNumber']?.invalid"
              [translate]="'create.errorMessages.objectNumber'"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
