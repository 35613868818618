import { Params } from '@angular/router';
import { Dictionary } from '@ngrx/entity/src/models';
import { createSelector } from '@ngrx/store';

import { AgreementI } from 'src/app/core/models/agreements.interface';
import { SelectI } from 'src/app/core/models/select.interface';
import { getAgreementsStateSelector } from 'src/app/core/store/index';
import {
  getAgreementsEntities,
  getAgreementsError,
  getAgreementsIds,
  getAgreementsLoaded,
  getAgreementsLoading,
  getAgreementsTotal,
  getAllAgreements,
} from 'src/app/core/store/reducers/agreements.reducer';
import {
  getRouterParamsSelector,
  getRouterQueryParamsSelector,
} from 'src/app/core/store/selectors/router.selectors';
import { createSelectObject } from 'src/app/core/utils/select-utils';

export const getAgreementsIdsSelector = createSelector(
  getAgreementsStateSelector,
  getAgreementsIds
);

export const getAgreementsEntitiesSelector = createSelector(
  getAgreementsStateSelector,
  getAgreementsEntities
);

export const getAllAgreementsSelector = createSelector(
  getAgreementsStateSelector,
  getAllAgreements
);

export const getAgreementsTotalSelector = createSelector(
  getAgreementsStateSelector,
  getAgreementsTotal
);

export const getAgreementsLoadingSelector = createSelector(
  getAgreementsStateSelector,
  getAgreementsLoading
);

export const getAgreementsLoadedSelector = createSelector(
  getAgreementsStateSelector,
  getAgreementsLoaded
);

export const getAgreementsErrorSelector = createSelector(
  getAgreementsStateSelector,
  getAgreementsError
);

// Custom Selectors

export const getContractorForGivenQueryParamSelector = createSelector(
  getAgreementsEntitiesSelector,
  getRouterQueryParamsSelector,
  (agreements: Dictionary<AgreementI>, queryParams: Params): AgreementI =>
    agreements[queryParams && queryParams['contractor']]
);

export const getContractorFromAgreementAsSelectObjectSelector = createSelector(
  getAllAgreementsSelector,
  (agreements: AgreementI[] = []): SelectI[] =>
    agreements
      .filter((agreement) => agreement && !agreement.blocked)
      .map((agreement) => {
        return createSelectObject(agreement.agreementId, agreement.name);
      })
);

export const getAgreementByIdSelector = createSelector(
  getAgreementsEntitiesSelector,
  getRouterParamsSelector,
  (agreements, params) => {
    return agreements[params.id];
  }
);
