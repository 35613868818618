import { Action } from '@ngrx/store';
import {
  HideLoader,
  ShowLoader,
} from 'src/app/core/decorators/loader.decorator';
import { AssignmentEditPayloadI } from 'src/app/core/models/edit-assignment/assignment-edit-payload.interface';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';

export enum AssignmentEditActionTypes {
  EditAssignment = '[AssignmentEdit] Edit Assignment',
  EditAssignmentSuccess = '[AssignmentEdit] Edit Assignment Success',
  EditAssignmentFail = '[AssignmentEdit] Edit Assignment Fail',

  CleanAssignmentEditState = '[AssignmentEdit] Clean',
}

@ShowLoader()
export class EditAssignment implements Action {
  readonly type = AssignmentEditActionTypes.EditAssignment;

  constructor(public payload: AssignmentEditPayloadI) {}
}

@HideLoader(AssignmentEditActionTypes.EditAssignment)
export class EditAssignmentSuccess implements Action {
  readonly type = AssignmentEditActionTypes.EditAssignmentSuccess;

  constructor(public payload: string) {}
}

@HideLoader(AssignmentEditActionTypes.EditAssignment)
export class EditAssignmentFail implements Action {
  readonly type = AssignmentEditActionTypes.EditAssignmentFail;

  constructor(public payload: ErrorResponseWithIdI) {}
}

export class CleanAssignmentEditState implements Action {
  readonly type = AssignmentEditActionTypes.CleanAssignmentEditState;

  constructor(public payload?: string) {}
}

export type AssignmentEditAction =
  | EditAssignment
  | EditAssignmentSuccess
  | EditAssignmentFail
  | CleanAssignmentEditState;
