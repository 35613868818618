import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserRegisterI } from 'src/app/core/models/user/user-register.interface';
import {
  UserPublicI,
  UserResourceI,
} from 'src/app/core/models/user/user-resource.interface';
import { environment } from 'src/environments/environment';

@Injectable()
export class UserService {
  private userUrl = environment.url + 'api/v1/user/';
  private companyUserUrl = this.userUrl + 'company/';
  private customerIdUrl = this.userUrl + 'with-cid/';
  private register = this.userUrl + 'register';
  private resend = this.userUrl + 'resend';

  constructor(private http: HttpClient) {}

  createUser(user: UserPublicI): Observable<number> {
    return this.http.post<number>(this.userUrl, user);
  }

  registerUser(userRegister: UserRegisterI): Observable<any> {
    return this.http.put(this.register, userRegister);
  }

  updateUser(userPublic: UserPublicI): Observable<any> {
    return this.http.put(this.userUrl, userPublic);
  }

  getUser(id: number): Observable<UserPublicI> {
    return this.http.get<UserPublicI>(this.userUrl + id);
  }

  resendEmailToUser(userId: string): Observable<void> {
    return this.http.put<void>(`${this.resend}/${userId}`, {});
  }

  getCompanyUsersList(): Observable<UserResourceI[]> {
    return this.http.get<UserResourceI[]>(this.companyUserUrl);
  }

  getCustomerUsers(): Observable<UserPublicI[]> {
    return this.http.get<UserPublicI[]>(this.customerIdUrl);
  }
}
