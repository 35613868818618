import { Component, Input, OnInit } from '@angular/core';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BaseFormComponent } from 'src/app/shared/components/base-form/base-form.component';
import { BookInspectionFormI } from 'src/app/core/modals/models/book-inspection-form.interface';
import { InspectionSystemTypeEnum } from 'src/app/core/enums/inspection-system-type.enum';

import { UserResourceI } from 'src/app/core/models/user/user-resource.interface';

@Component({
  selector: 'app-inspection-form',
  templateUrl: './inspection-form.component.html',
  styleUrls: ['./inspection-form.component.scss'],
})
export class InspectionFormComponent
  extends BaseFormComponent<BookInspectionFormI>
  implements OnInit {
  @Input()
  inspectors: UserResourceI[];

  @Input()
  isSystemEnabled: boolean;

  @Input()
  systemTypes: InspectionSystemTypeEnum[];

  bsConfig: Partial<BsDatepickerConfig> = {
    containerClass: 'theme-default',
    adaptivePosition: true,
  };
}
