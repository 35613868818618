<app-a2hs
  [disablePWA]="disablePWA$ | async"
  (redirectToSupport)="onRedirectToSupport($event)"
  (skipPWA)="onSkipPWA($event)"
  (decisionPWA)="dispatchAction($event)"
></app-a2hs>

<ngx-spinner
  class="spinner"
  bdColor="rgba(51, 51, 51, 0.8)"
  size="large"
  color="#fff"
  type="ball-elastic-dots"
>
  <p style="font-size: 20px; color: white" [translate]="'loading'"></p>
</ngx-spinner>

<span *ngIf="doesTokenExists$ | async">
  <app-alert-messages
    [alerts]="alerts$ | async"
    [showMissingExternalId]="showMissingExternalId$ | async"
    (dismissed)="dispatchAction($event)"
  >
  </app-alert-messages>

  <app-header
    [mostRecentAssignments]="mostRecentAssignments | async"
    [mostRecentProjects]="mostRecentProjects | async"
    (goToRecentAssignment)="onGoToRecentAssignment($event)"
    (goToRecentProject)="onGoToRecentProject($event)"
  ></app-header>
</span>

<div id="app-container" class="container">
  <router-outlet></router-outlet>
</div>
