<div class="header-title" [ngClass]="{ 'header-title--small': smallHeader }">
  <span [translate]="headerTitle"></span>
  <span
    class="header-title__subText"
    [ngClass]="{ 'header-title__subText--small': smallSubHeader }"
    [translate]="subText"
  ></span>
</div>

<div class="empty-section" *ngIf="showEmptyText; else content">
  <span [translate]="emptyText"></span>
</div>

<ng-template #content>
  <span>
    <ng-content></ng-content>
  </span>
</ng-template>

<div class="d-flex justify-content-center justify-content-md-start">
  <button
    *ngIf="!!buttonLabel"
    class="btn-secondary-link"
    [translate]="buttonLabel"
    (click)="onButtonClicked()"
  ></button>
</div>
