<div [formGroup]="formGroup">
  <div class="form-row">
    <div class="form-group col-12 col-md-6">
      <label
        for="create-property-designation"
        [translate]="'create.formControls.propertyDesignation'"
      ></label>

      <span
        class="form-control-mandatory"
        *ngIf="isRequired('propertyDesignation')"
        [translate]="'mandatory'"
      ></span>

      <input
        id="create-property-designation"
        formControlName="propertyDesignation"
        type="text"
        class="form-control"
      />
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col-12 col-md-6">
      <label
        for="create-property-type"
        [translate]="'create.formControls.propertyType'"
      ></label>

      <span
        class="form-control-mandatory"
        *ngIf="isRequired('propertyType')"
        [translate]="'mandatory'"
      ></span>

      <select
        id="create-property-type"
        formControlName="propertyType"
        class="custom-select"
        [ngClass]="{
          'is-invalid': isValid(formGroup.controls['propertyType'])
        }"
      >
        <option
          value=""
          selected
          disabled
          [translate]="'common.select'"
        ></option>
        <option
          *ngFor="let realEstateTypes of realEstateTypes"
          [value]="realEstateTypes.id"
        >
          {{ realEstateTypes.value }}
        </option>
      </select>

      <div
        class="invalid-feedback"
        *ngIf="formGroup.controls['propertyType']?.invalid"
        [translate]="'create.errorMessages.propertyType'"
      ></div>
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col-12 col-md-6">
      <label
        for="create-property-designation"
        [translate]="'create.formControls.establishmentType'"
      ></label>

      <span
        class="form-control-mandatory"
        *ngIf="isRequired('establishmentType')"
        [translate]="'mandatory'"
      ></span>

      <select
        id="create-establishment-type"
        formControlName="establishmentType"
        class="custom-select"
        [ngClass]="{
          'is-invalid': isValid(formGroup.controls['establishmentType'])
        }"
      >
        <option
          value=""
          selected
          disabled
          [translate]="'common.select'"
        ></option>
        <option
          *ngFor="let establishmentTypes of establishmentTypes"
          [value]="establishmentTypes.id"
        >
          {{ establishmentTypes.value }}
        </option>
      </select>

      <div
        class="invalid-feedback"
        *ngIf="formGroup.controls['establishmentType']?.invalid"
        [translate]="'create.errorMessages.establishmentType'"
      ></div>
    </div>
  </div>

  <div class="form-row" *ngIf="isPropertyRegisterEnabled">
    <div class="form-group col-12 col-md-6">
      <label
        for="property-address"
        [translate]="'create.formControls.propertyAddress'"
      ></label>

      <span
        class="form-control-mandatory"
        *ngIf="isRequired('propertyAddress')"
        [translate]="'mandatory'"
      ></span>

      <select
        id="property-address"
        class="custom-select"
        (change)="onPropertyAddressChange($event.target['value'])"
        [value]="currentAddressType"
      >
        <option
          *ngFor="let addressType of addressTypeList"
          [value]="addressType"
          [selected]="addressType === currentAddressType"
        >
          {{ 'create.subHeaders.' + addressType | translate }}
        </option>
      </select>
    </div>
  </div>
</div>
