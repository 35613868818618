import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent implements OnInit {
  _percent: number;
  timeoutNumber: number;

  @Input()
  set percent(percent: number) {
    this.setPercent(percent);
  }

  constructor() {}

  ngOnInit(): void {}

  setPercent(percent: number) {
    if (!this.timeoutNumber) {
      this.timeoutNumber = setTimeout(() => {
        this._percent = this.formatPercent(percent);
        this.timeoutNumber = null;
      });
    }
  }

  formatPercent(percent: number) {
    if (percent === null || percent === undefined) {
      return 0;
    }

    return percent > 100 ? 100 : percent;
  }
}
