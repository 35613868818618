import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { BaseTemplateComponent } from 'src/app/shared/components/base-template/base-template.component';
import { ProjectDetailsInfoI } from 'src/app/core/models/projects/project-details.interface';
import {
  CreateProject,
  UpdateProject,
} from 'src/app/core/store/actions/manage-project.action';
import { UserResourceI } from 'src/app/core/models/user/user-resource.interface';
import { getAllCompanyUsersSelector } from 'src/app/core/store/selectors/company-users.selectors';

@Component({
  selector: 'app-project-template',
  templateUrl: './project-template.component.html',
  styleUrls: ['./project-template.component.scss'],
})
export class ProjectTemplateComponent
  extends BaseTemplateComponent
  implements OnInit {
  formGroup: FormGroup;
  submitted = false;

  projectInfo: ProjectDetailsInfoI;
  users$: Observable<UserResourceI[]>;

  @HostListener('document:click', ['$event'])
  clickout(event) {
    this.checkIfShouldCloseModal(event);
  }

  constructor(
    store: Store<any>,
    eRef: ElementRef,
    private formBuilder: FormBuilder
  ) {
    super(store, eRef);
  }

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      projectId: [(this.projectInfo || {}).projectId],
      name: [(this.projectInfo || {}).name, Validators.required],
      externalProjectId: [
        (this.projectInfo || {}).externalProjectId,
        Validators.required,
      ],
      projectManagerId: [
        (this.projectInfo || {}).projectManagerId,
        Validators.required,
      ],
      budget: [(this.projectInfo || {}).budget, Validators.required],
      endDate: [
        this.getDate((this.projectInfo || {}).endDate),
        Validators.required,
      ],
      scope: [(this.projectInfo || {}).scope],
    });

    this.users$ = this.store.pipe(select(getAllCompanyUsersSelector));
  }

  private getDate(date: number): Date | null {
    return !!date ? new Date(date) : null;
  }

  canDeactivate(): boolean | Observable<boolean> {
    return !this.formGroup.dirty || this.submitted;
  }

  submit() {
    this.submitted = true;

    if (this.formGroup.valid) {
      const action = !!this.projectInfo
        ? new UpdateProject({ ...this.formGroup.value })
        : new CreateProject({ ...this.formGroup.value });

      this.dispatchActionAndCloseModal(action);
    }
  }
}
