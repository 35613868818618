<div class="nav-header-icon">
  <span
    *ngIf="!isSmallScreen"
    class="nav-header-icon__support pointer"
    (click)="onGoToSupport()"
    [translate]="'go.elements.support'"
  ></span>

  <div class="btn-group" dropdown>
    <span class="nav-header-icon__message-icon-wrapper" dropdownToggle>
      <svg
        width="22px"
        height="22px"
        viewBox="0 0 22 22"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        class="nav-header-icon__message-icon pointer"
        *ngIf="false; else noMessagesIcon"
      >
        <!--   TODO: Enable this ngIf when we will add message read/unread function    -->
        <g
          id="Symbols"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
        >
          <g id="Group-5" transform="translate(0.484375, 0.000000)">
            <path
              d="M10.5,20.015625 L10.4924769,20.1898148 C10.4523534,20.6466049 10.2517361,21.0416667 9.890625,21.375 L9.76244213,21.5028935 C9.41213349,21.8238812 8.99392361,21.984375 8.5078125,21.984375 C7.9609375,21.984375 7.4921875,21.7890625 7.1015625,21.3984375 C6.7109375,21.0078125 6.515625,20.546875 6.515625,20.015625 L6.515625,20.015625 L10.5,20.015625 Z M8.5078125,2.015625 C8.9296875,2.015625 9.28125,2.15625 9.5625,2.4375 L9.51602601,2.39591417 C9.19322664,3.2008591 9.015625,4.07968901 9.015625,5 C9.015625,8.52116394 11.6154866,11.4350977 15.0003123,11.9269035 L15,15.984375 L17.015625,18 L17.015625,18.984375 L0,18.984375 L0,18 L2.015625,15.984375 L2.015625,10.5 L2.021875,10.1961458 C2.08020833,8.78934028 2.546875,7.5390625 3.421875,6.4453125 C4.359375,5.2734375 5.546875,4.515625 6.984375,4.171875 L6.984375,4.171875 L6.984375,3.515625 L6.99133301,3.35522461 C7.02380371,2.98950195 7.16992188,2.68359375 7.4296875,2.4375 C7.7265625,2.15625 8.0859375,2.015625 8.5078125,2.015625 Z"
              id="Combined-Shape"
              fill="#181818"
              class="bell-icon"
              fill-rule="nonzero"
            ></path>
            <circle
              id="Oval"
              fill="#FF5A55"
              cx="16.015625"
              cy="5"
              r="5"
            ></circle>
          </g>
        </g>
      </svg>
    </span>

    <div
      *dropdownMenu
      class="dropdown-menu dropdown-menu-right"
      [ngClass]="{ 'dropdown-menu--wide': messages?.length > 0 }"
    >
      <span *ngIf="messages?.length > 0">
        <div class="dropdown-menu__dropdown-title">
          <span [translate]="'go.messagesPanel.notifications'"></span>
        </div>
        <div class="dropdown-menu__recent">
          <span
            class="dropdown-item dropdown-menu__recent-item"
            (click)="onGoToAssignmentDetails(mostRecentMessages.assignmentId)"
            *ngFor="let mostRecentMessages of messages; let first = first"
            [ngClass]="{
              'first-recent-item': first,
              'font-weight-bold': false
            }"
          >
            <!--   TODO: Enable bold when we will add message read/unread function    -->
            <span class="dropdown-menu__message-wrapper">
              <mat-icon
                class="dropdown-menu__mat-message-icon {{ icon.colour }}"
                *ngIf="getIcon(mostRecentMessages.status); let icon"
              >
                {{ icon.icon }}
              </mat-icon>
              <span class="dropdown-menu__mat-message-text">
                <span
                  [translate]="'assignmentStatus.' + mostRecentMessages.status"
                ></span>
              </span>
            </span>
          </span>
        </div>
      </span>

      <span
        class="dropdown-item btn-third text-center text-md-left"
        (click)="onGoToMessages()"
        [translate]="'go.messagesPanel.title'"
      ></span>
    </div>
  </div>

  <div class="nav-header-icon__hamburger-mat pointer" *ngIf="isSmallScreen">
    <mat-icon
      class="nav-header-icon__hamburger-mat-icon"
      (click)="onShowSideBar()"
      >menu
    </mat-icon>
  </div>

  <div class="btn-group" dropdown *ngIf="!isSmallScreen">
    <mat-icon dropdownToggle class="nav-header-icon__account-mat pointer"
      >account_circle
    </mat-icon>
    <div *dropdownMenu class="dropdown-menu dropdown-menu-right">
      <span class="dropdown-menu__dropdown-title">{{ userName }}</span>

      <span
        class="dropdown-item btn-third"
        (click)="onGoToAccountDetails()"
        [translate]="'go.elements.accountDetails'"
      ></span>

      <span
        class="dropdown-item btn-third"
        *ngIf="isAdmin"
        (click)="onGoToAdministration()"
        [translate]="'go.elements.administration'"
      ></span>

      <span
        class="dropdown-item btn-third"
        (click)="onLogout()"
        [translate]="'header.logout'"
      ></span>
    </div>
  </div>
</div>

<ng-template #noMessagesIcon>
  <mat-icon class="nav-header-icon__message-icon pointer">
    notifications
  </mat-icon>
</ng-template>
