import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { AssignmentTableTypeEnum } from 'src/app/core/enums/assignment-table-type.enum';
import { AssignmentI } from 'src/app/core/models/assignment/assignment.interface';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import { QueryUrlParams } from 'src/app/core/models/query-url-params.interface';
import { AssignmentSearchRequestBuilder } from 'src/app/core/models/search/assignment-search-request.interface';
import { AssignmentSearchResponse } from 'src/app/core/models/search/assignment-search-response.interface';
import {
  AssignmentsActionTypes,
  ChangeAssignmentsPhaseFilter,
  ChangeAssignmentsQuery,
  ChangeAssignmentsSearchType,
  ChangeAssignmentsUserFilter,
  CleanAssignmentsState,
  LoadAssignment,
  LoadAssignmentsList,
  LoadedAssignmentFail,
  LoadedAssignmentsListFail,
  LoadedAssignmentsListSuccess,
  LoadedAssignmentSuccess,
} from 'src/app/core/store/actions/assignments.action';
import { RouterUpdateQueryParams } from 'src/app/core/store/actions/router.action';
import { getAssignmentsStateSelector } from 'src/app/core/store/index';
import { AssignmentsReducerState } from 'src/app/core/store/reducers/assignments.reducer';
import { AssignmentsService } from 'src/app/core/services/api/assignments/assignments.service';
import { AssignmentUserEnum } from 'src/app/core/enums/assignment-user.enum';
import { AssignmentSearchTypeEnum } from 'src/app/core/enums/assignment-search-type.enum';

@Injectable()
export class AssignmentsEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AssignmentsReducerState>,
    private assignmentsService: AssignmentsService
  ) {}

  public tableChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        AssignmentsActionTypes.ChangeAssignmentsPagination,
        AssignmentsActionTypes.SetAssignmentsTableType,
        AssignmentsActionTypes.RefreshAssignmentTable,
        AssignmentsActionTypes.ChangeAssignmentsQuery,
        AssignmentsActionTypes.ChangeAssignmentsPaginationLimit,
        AssignmentsActionTypes.ChangeAssignmentSorting,
        AssignmentsActionTypes.ChangeAssignmentsUserFilter,
        AssignmentsActionTypes.ChangeAssignmentsSearchType,
        AssignmentsActionTypes.ChangeAssignmentMobileStatusFilterAndSort,
        AssignmentsActionTypes.ClearAssignmentMobileStatusFilterAndSort
      ),
      withLatestFrom(this.store.pipe(select(getAssignmentsStateSelector))),
      map(([action, state]) => {
        const searchRequest = new AssignmentSearchRequestBuilder().setPagination(
          state.pagination.limit,
          state.pagination.page
        );

        if (!!state.sorting) {
          searchRequest.setOrdering(
            state.sorting.ordering,
            state.sorting.field
          );
        }

        if (state.userFilter === AssignmentUserEnum.MY || !state.userFilter) {
          searchRequest.setFilterOnUser(true);
        }

        if (state.userFilter === AssignmentUserEnum.ALL) {
          searchRequest.setFilterOnUser(false);
        }

        switch (state.searchType) {
          case AssignmentSearchTypeEnum.NAME:
            searchRequest.setName(state.query);
            break;
          case AssignmentSearchTypeEnum.ADDRESS:
            searchRequest.setAddress(state.query);
            break;
          case AssignmentSearchTypeEnum.CONTRACTOR:
            searchRequest.setMainContractorName(state.query);
            break;
          case AssignmentSearchTypeEnum.PROJECT_NUMBER:
            searchRequest.setExternalProjectId(state.query);
            break;
          case AssignmentSearchTypeEnum.ASSIGNMENT_NUMBER:
            searchRequest.setExternalAssignmentId(state.query);
            break;
          case AssignmentSearchTypeEnum.OBJECT_NUMBER:
            searchRequest.setObjectNumber(state.query);
            break;
          default:
            searchRequest.setQuery(state.query);
        }

        switch (state.tableType) {
          case AssignmentTableTypeEnum.WATCHLIST:
            searchRequest.setIsOnWatchList(true);
            break;
          case AssignmentTableTypeEnum.MAKE_ACTION:
            searchRequest.setIncludeAction(true);
            break;
          default:
            searchRequest.setPhase(state.phasesFilter);
        }

        return new LoadAssignmentsList(searchRequest.build());
      })
    )
  );

  public userFilterChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssignmentsActionTypes.ChangeAssignmentsUserFilter),
      map((action: ChangeAssignmentsUserFilter) => {
        const query = new QueryUrlParams();
        query.filterOnUser = action.payload;

        return new RouterUpdateQueryParams({
          query: query,
        });
      })
    )
  );

  public searchTypeChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssignmentsActionTypes.ChangeAssignmentsSearchType),
      map((action: ChangeAssignmentsSearchType) => {
        const query = new QueryUrlParams();
        query.searchType = action.payload;

        return new RouterUpdateQueryParams({
          query: query,
        });
      })
    )
  );

  public queryChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssignmentsActionTypes.ChangeAssignmentsQuery),
      map((action: ChangeAssignmentsQuery) => {
        const query = new QueryUrlParams();
        query.query = action.payload;

        return new RouterUpdateQueryParams({
          query: query,
        });
      })
    )
  );

  public phaseFilterChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssignmentsActionTypes.ChangeAssignmentsPhaseFilter),
      map((action: ChangeAssignmentsPhaseFilter) => {
        const query = new QueryUrlParams();
        query.phase = action.payload;

        return new RouterUpdateQueryParams({
          query: query,
        });
      })
    )
  );

  public loadAssignment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssignmentsActionTypes.LoadAssignment),
      switchMap((action: LoadAssignment) =>
        this.assignmentsService.getAssignment(action.payload).pipe(
          map(
            (assignment: AssignmentI) => new LoadedAssignmentSuccess(assignment)
          ),
          catchError((error: ErrorResponseWithIdI) =>
            of(new LoadedAssignmentFail(error))
          )
        )
      )
    )
  );

  public loadAssignmentList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssignmentsActionTypes.LoadAssignmentsList),
      switchMap((action: LoadAssignmentsList) =>
        this.assignmentsService.searchForAssignments(action.payload).pipe(
          map(
            (assignmentSearchResponse: AssignmentSearchResponse) =>
              new LoadedAssignmentsListSuccess(assignmentSearchResponse)
          ),
          catchError((error: ErrorResponseWithIdI) =>
            of(new LoadedAssignmentsListFail(error))
          )
        )
      )
    )
  );

  public handleFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        AssignmentsActionTypes.LoadedAssignmentFail,
        AssignmentsActionTypes.LoadedAssignmentsListFail
      ),
      map((_) => new CleanAssignmentsState())
    )
  );
}
