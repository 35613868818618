import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import { AssignmentsService } from 'src/app/core/services/api/assignments/assignments.service';
import {
  BookInspection,
  BookInspectionFail,
  BookInspectionSuccess,
  InspectionActionTypes,
} from 'src/app/core/store/actions/inspection.action';

@Injectable()
export class InspectionEffects {
  constructor(
    private actions$: Actions,
    private assignmentService: AssignmentsService
  ) {}

  public bookInspection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InspectionActionTypes.BookInspection),
      switchMap((action: BookInspection) =>
        this.assignmentService.bookInspection(action.payload).pipe(
          map(() => new BookInspectionSuccess(action.payload.assignmentId)),
          catchError((error: ErrorResponseWithIdI) =>
            of(new BookInspectionFail(error))
          )
        )
      )
    )
  );
}
