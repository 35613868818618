import { Component, OnInit } from '@angular/core';
import { BaseMyActionCardButtonComponent } from 'src/app/core/modules/overview/components/base-my-action-card-button/base-my-action-card-button.component';

@Component({
  selector: 'app-my-actions-card-button',
  templateUrl: './my-actions-card-button.component.html',
  styleUrls: ['./my-actions-card-button.component.scss'],
})
export class MyActionsCardButtonComponent
  extends BaseMyActionCardButtonComponent
  implements OnInit {
  constructor() {
    super();
  }
}
