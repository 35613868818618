import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { BaseTemplateComponent } from 'src/app/shared/components/base-template/base-template.component';
import { GoToMeps } from 'src/app/core/store/actions/external-redirect.action';
import { AssignmentI } from 'src/app/core/models/assignment/assignment.interface';
import { getAssignmentForGivenUrlIdSelector } from 'src/app/core/store/selectors/assignment.selectors';

@Component({
  selector: 'app-assignment-not-from-meps-deleted-template',
  templateUrl: './assignment-not-from-meps-deleted-template.component.html',
  styleUrls: ['./assignment-not-from-meps-deleted-template.component.scss'],
})
export class AssignmentNotFromMepsDeletedTemplateComponent
  extends BaseTemplateComponent
  implements OnInit {
  assignment$: Observable<AssignmentI>;

  @HostListener('document:click', ['$event'])
  clickout(event) {
    this.checkIfShouldCloseModal(event);
  }

  constructor(store: Store<any>, eRef: ElementRef) {
    super(store, eRef);
  }

  ngOnInit() {
    this.assignment$ = this.store.pipe(
      select(getAssignmentForGivenUrlIdSelector)
    );
  }

  goToMeps() {
    this.assignment$.pipe(take(1)).subscribe((assignment: AssignmentI) => {
      const action = new GoToMeps(assignment.mepsId);
      this.dispatchActionAndCloseModal(action);
    });
  }
}
