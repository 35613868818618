import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import { PusherMessageI } from 'src/app/core/models/message/pusher-message.interface';
import {
  PusherActions,
  PusherActionTypes,
} from 'src/app/core/store/actions/pusher.action';
import { TablePaginationI } from 'src/app/shared/models/pagination.interface';

function sortByDate(a: PusherMessageI, b: PusherMessageI): number {
  return new Date(b.created).valueOf() - new Date(a.created).valueOf();
}

const selectId = (message: PusherMessageI) => message.messageInfoId;
export const adapter: EntityAdapter<PusherMessageI> = createEntityAdapter<PusherMessageI>(
  { selectId, sortComparer: sortByDate }
);

export interface PusherReducerState extends EntityState<PusherMessageI> {
  pagination: TablePaginationI;

  loading: boolean;
  loaded: boolean;
  error: ErrorResponseWithIdI;
}

export const initialPusherReducerState: PusherReducerState = adapter.getInitialState(
  {
    pagination: {
      page: 1,
      limit: 25,
      limitOptions: [10, 25, 50],
    },

    loading: false,
    loaded: false,
    error: null,
  }
);

export function pusherReducer(
  state: PusherReducerState = initialPusherReducerState,
  action: PusherActions
): PusherReducerState {
  switch (action.type) {
    case PusherActionTypes.LoadAllPusherMessages: {
      return {
        ...state,
        loading: true,
      };
    }

    case PusherActionTypes.LoadPusherAllMessagesSuccess: {
      return adapter.upsertMany(<PusherMessageI[]>action.payload.messages, {
        ...state,
        loading: false,
        loaded: true,
      });
    }

    case PusherActionTypes.LoadPusherAllMessagesFail: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload,
      };
    }

    case PusherActionTypes.ChangePusherMessagesPaginationLimit: {
      return {
        ...state,
        pagination: {
          ...initialPusherReducerState.pagination,
          limit: action.payload,
        },
      };
    }

    case PusherActionTypes.ChangePusherMessagesPagination: {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: action.payload,
        },
      };
    }

    case PusherActionTypes.ClearPusherMessages: {
      return {
        ...initialPusherReducerState,
      };
    }

    default: {
      return state;
    }
  }
}

export const getPusherMessagesIds = adapter.getSelectors().selectIds;
export const getPusherMessagesEntities = adapter.getSelectors().selectEntities;
export const getAllPusherMessages = adapter.getSelectors().selectAll;
export const getPusherMessagesTotal = adapter.getSelectors().selectTotal;

export const getPusherMessagesPagination = (state: PusherReducerState) =>
  state.pagination;

export const getPusherMessagesLoading = (state: PusherReducerState) =>
  state.loading;
export const getPusherMessagesLoaded = (state: PusherReducerState) =>
  state.loaded;
export const getPusherMessagesError = (state: PusherReducerState) =>
  state.error;
