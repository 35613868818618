import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {StringResourceI} from 'src/app/core/models/string-resource.interface';
import {map} from 'rxjs/operators';

@Injectable()
export class ExcelService {
  private excelExportUrl = environment.url + 'api/v1/excel-export/';
  private createWithAssignmentUrl =
    this.excelExportUrl + 'create-with-assignments/';
  private createWithSimpleAssignmentUrl =
    this.createWithAssignmentUrl + 'simple/';
  private downloadExcelWithAssignmentEventIdsUrl =
    this.excelExportUrl + 'create-with-assignment-events/';
  private downloadExcelWithProjectIdUrl =
    this.excelExportUrl + 'create-with-project/simple/';

  constructor(private http: HttpClient) {}

  exportExcel(ids: string[]): Observable<string> {
    return this.http
      .post<StringResourceI>(this.createWithAssignmentUrl, ids)
      .pipe(map((response) => response.value));
  }

  exportSimpleExcel(ids: string[]): Observable<string> {
    return this.http
      .post<StringResourceI>(this.createWithSimpleAssignmentUrl, ids)
      .pipe(map((response) => response.value));
  }

  exportToAssignmentEventsExcel(ids: string[]): Observable<string> {
    return this.http
      .post<StringResourceI>(this.downloadExcelWithAssignmentEventIdsUrl, ids)
      .pipe(map((response) => response.value));
  }

  exportToProjectSimpleExcel(id: string): Observable<string> {
    return this.http
      .get<StringResourceI>(this.downloadExcelWithProjectIdUrl + id)
      .pipe(map((response) => response.value));
  }
}
