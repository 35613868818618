export const CHART_COLOURS = ['#DEF1FE', '#E1E3FE', '#FFDDE8', '#DDFEE4'];

export const CHART_CONFIG = {
  showXAxis: true,
  showYAxis: true,
  showLegend: false,
  colorScheme: {
    domain: CHART_COLOURS,
  },
};

export const COST_DEVELOPMENT_CHART_CONFIG = {
  ...CHART_CONFIG,
  customColors: [
    { name: 'newCalculation', value: '#DEF1FE' },
    { name: 'approved', value: '#DDFEE4' },
    { name: 'rejected', value: '#FFDDE8' },
  ],
  showLegend: false,
};

export const LOG_CHART_CONFIG = {
  ...CHART_CONFIG,
  showLegend: true,
};

export const GROUP_CHART_CONFIG = {
  ...CHART_CONFIG,
  groupPadding: 8,
  barPadding: 4,
};

export const NUMBERS_CHART_CONFIG = {
  ...CHART_CONFIG,
  colorScheme: {
    // TODO: Add more colours
    domain: ['#181818', '#FF5A55', '#FFA055'],
  },
  showLegend: false,
};
