import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { getAuthUserStateSelector } from 'src/app/auth/store';
import { UserState } from 'src/app/auth/store/reducers/user.reducer';
import { getPropertyDistrictStateSelector } from 'src/app/core/modules/start/store';
import { LoadPropertyDistricts } from 'src/app/core/modules/start/store/actions/property-district.actions';
import { PropertyDistrictReducerState } from 'src/app/core/modules/start/store/reducers/property-district.reducer';

@Injectable()
export class PropertyDistrictGuard implements CanActivate {
  constructor(private store: Store<PropertyDistrictReducerState>) {}

  canActivate() {
    return this.waitForDistrictToLoadOrReturnTrueIfUserDontEnableDistrictList().pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  waitForDistrictToLoadOrReturnTrueIfUserDontEnableDistrictList() {
    return this.checkIfUserEnablePropertyList().pipe(
      switchMap((enabled: boolean) => {
        if (enabled) {
          return this.waitForDistrictToLoad();
        } else {
          return of(true);
        }
      })
    );
  }

  waitForDistrictToLoad(): Observable<boolean> {
    return this.store.pipe(
      select(getPropertyDistrictStateSelector),
      tap((state: PropertyDistrictReducerState) => {
        if (!state.loaded && !state.loading && !state.error) {
          this.store.dispatch(new LoadPropertyDistricts());
        }
      }),
      filter(
        (state: PropertyDistrictReducerState) => state.loaded || !!state.error
      ),
      map((state: PropertyDistrictReducerState) => {
        if (!!state.error) {
          throw new Error(state.error.message);
        } else {
          return state.loaded;
        }
      }),
      take(1)
    );
  }

  checkIfUserEnablePropertyList(): Observable<boolean> {
    return this.store.pipe(
      select(getAuthUserStateSelector),
      filter((user: UserState) => user?.loaded),
      map(
        (user: UserState) =>
          user?.resource?.customerPublic?.enablePropertyListSelection
      )
    );
  }
}
