import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { SelectI } from 'src/app/core/models/select.interface';
import { PropertyTypeChanged } from 'src/app/core/modules/start/store/actions/start-assignments.action';
import { BaseFormComponent } from 'src/app/shared/components/base-form/base-form.component';

@Component({
  selector: 'app-general-object-information',
  templateUrl: './general-object-information.component.html',
  styleUrls: ['./general-object-information.component.scss'],
})
export class GeneralObjectInformationComponent
  extends BaseFormComponent<GeneralObjectInformationComponent>
  implements OnInit, OnDestroy {
  @Input()
  realEstateTypes: SelectI[];

  @Input()
  establishmentTypes: SelectI[];

  @Input()
  isPropertyRegisterEnabled: boolean;

  @Input()
  addressTypeList: string[];

  @Input()
  currentAddressType: string;

  @Output()
  propertyTypeChanged: EventEmitter<PropertyTypeChanged> = new EventEmitter<PropertyTypeChanged>();

  @Output()
  addressTypeChanged: EventEmitter<string> = new EventEmitter<string>();

  subscription: Subscription;

  constructor() {
    super();
  }

  ngOnInit() {
    this.subscription = this.formGroup.controls[
      'propertyType'
    ].valueChanges.subscribe((value) => this.handlePropertyTypeChange(value));
  }

  handlePropertyTypeChange(value) {
    this.formGroup.controls['establishmentType'].reset(
      { value: '', disabled: !value },
      { onlySelf: true }
    );
    this.propertyTypeChanged.emit(new PropertyTypeChanged(value));
  }

  ngOnDestroy(): void {
    if (!!this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  onPropertyAddressChange(addressType: string) {
    this.addressTypeChanged.emit(addressType);
  }
}
