import { Component, Input, OnInit } from '@angular/core';
import { BaseFormComponent } from 'src/app/shared/components/base-form/base-form.component';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { UserResourceI } from 'src/app/core/models/user/user-resource.interface';

@Component({
  selector: 'app-project-form',
  templateUrl: './project-form.component.html',
  styleUrls: ['./project-form.component.scss'],
})
export class ProjectFormComponent
  extends BaseFormComponent<ProjectFormComponent>
  implements OnInit {
  bsConfig: Partial<BsDatepickerConfig> = {
    containerClass: 'theme-default',
    adaptivePosition: true,
  };

  @Input()
  users: UserResourceI[];

  ngOnInit(): void {}
}
