import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import { PropertyObjectI } from 'src/app/core/models/property-register/property-object.interface';
import {
  LoadPropertyObjects,
  LoadPropertyObjectsFail,
  LoadPropertyObjectsSuccess,
  PropertyObjectActionTypes,
} from 'src/app/core/modules/start/store/actions/property-object.actions';
import { PropertyRegisterService } from 'src/app/core/services/api/property-register/property-register.service';

@Injectable()
export class PropertyObjectEffects {
  constructor(
    private actions$: Actions,
    private propertyRegisterService: PropertyRegisterService
  ) {}

  public getPropertyObject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PropertyObjectActionTypes.LoadPropertyObjects),
      switchMap((action: LoadPropertyObjects) =>
        this.propertyRegisterService.getObject(action.payload).pipe(
          map(
            (propertyObject: PropertyObjectI) =>
              new LoadPropertyObjectsSuccess(propertyObject)
          ),
          catchError((error: ErrorResponseWithIdI) =>
            of(new LoadPropertyObjectsFail(error))
          )
        )
      )
    )
  );
}
