import { Component, OnInit } from '@angular/core';
import { BaseMyActionCardButtonComponent } from 'src/app/core/modules/overview/components/base-my-action-card-button/base-my-action-card-button.component';

@Component({
  selector: 'app-my-action-card-button-mobile',
  templateUrl: './my-action-card-button-mobile.component.html',
  styleUrls: ['./my-action-card-button-mobile.component.scss'],
})
export class MyActionCardButtonMobileComponent
  extends BaseMyActionCardButtonComponent
  implements OnInit {
  constructor() {
    super();
  }

  ngOnInit(): void {}
}
