import { Action } from '@ngrx/store';
import {
  HideLoader,
  ShowLoader,
} from 'src/app/core/decorators/loader.decorator';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import { DictionaryI } from 'src/app/dictionaries/models/dictionary.interface';

export enum RoomElementsTypesActionTypes {
  LoadRoomElementsTypes = '[RoomElementsTypes] Load RoomElements Types',
  LoadedRoomElementsTypesSuccess = '[RoomElementsTypes] Loaded RoomElements Types',
  LoadedRoomElementsTypesFail = '[RoomElementsTypes] Fail To Load RoomElements Types',

  CleanRoomElementsTypesState = '[RoomElementsTypes] Clean',
}

@ShowLoader()
export class LoadRoomElementsTypes implements Action {
  readonly type = RoomElementsTypesActionTypes.LoadRoomElementsTypes;

  constructor(public payload?: any) {}
}

@HideLoader(RoomElementsTypesActionTypes.LoadRoomElementsTypes)
export class LoadedRoomElementsTypesSuccess implements Action {
  readonly type = RoomElementsTypesActionTypes.LoadedRoomElementsTypesSuccess;

  constructor(public payload: DictionaryI[]) {}
}

@HideLoader(RoomElementsTypesActionTypes.LoadRoomElementsTypes)
export class LoadedRoomElementsTypesFail implements Action {
  readonly type = RoomElementsTypesActionTypes.LoadedRoomElementsTypesFail;

  constructor(public payload: ErrorResponseWithIdI) {}
}

export class CleanRoomElementsTypesState implements Action {
  readonly type = RoomElementsTypesActionTypes.CleanRoomElementsTypesState;

  constructor(public payload?: string) {}
}

export type RoomElementsTypesAction =
  | LoadRoomElementsTypes
  | LoadedRoomElementsTypesSuccess
  | LoadedRoomElementsTypesFail
  | CleanRoomElementsTypesState;
