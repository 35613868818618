import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DictionaryI } from 'src/app/dictionaries/models/dictionary.interface';

@Injectable()
export class AssetService {
  assetUrl = '/assets/dict/';

  // TODO: Move this to backend
  roomElementsTypesUrl = this.assetUrl + 'room-elements-types.json';
  errorReasonTypesUrl = this.assetUrl + 'error-reason-types.json';
  errorExtraTypesUrl = this.assetUrl + 'error-extra-types.json';

  constructor(private http: HttpClient, private location: Location) {}

  getErrorReasonTypes(): Observable<DictionaryI[]> {
    // This is required because we are using --base-href to build our package
    // so we need to fix url to match this
    const url = this.location.prepareExternalUrl(this.errorReasonTypesUrl);

    return this.http.get<DictionaryI[]>(this.removeHashIfNeeded(url));
  }

  getErrorExtraTypes(): Observable<DictionaryI[]> {
    // This is required because we are using --base-href to build our package
    // so we need to fix url to match this
    const url = this.location.prepareExternalUrl(this.errorExtraTypesUrl);

    return this.http.get<DictionaryI[]>(this.removeHashIfNeeded(url));
  }

  getRoomElementsTypes(): Observable<DictionaryI[]> {
    // This is required because we are using --base-href to build our package
    // so we need to fix url to match this
    const url = this.location.prepareExternalUrl(this.roomElementsTypesUrl);

    return this.http.get<DictionaryI[]>(this.removeHashIfNeeded(url));
  }

  // We need to remove # sign right now from path because we are using hash strategy
  // but in future if we switch to HTML5 strategy this will be not needed so to
  // avoid problems we are checking it here
  removeHashIfNeeded(url: string): string {
    if (url) {
      return url.charAt(0) === '#' ? url.substring(1) : url;
    } else {
      return '';
    }
  }
}
