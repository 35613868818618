import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RecentItem } from 'src/app/core/containers/app/app.component';

@Component({
  selector: 'app-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss'],
})
export class HeaderMenuComponent implements OnInit {
  @Input()
  isSmallScreen: boolean;

  @Input()
  mostRecentAssignments: RecentItem[];

  @Input()
  mostRecentProjects: RecentItem[];

  @Output()
  goToMainPage: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  goToWatchlist: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  goToMakeAction: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  goToProduction: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  goToProjects: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  goToStartProject: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  goToNumbers: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  goToStartAssignment: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  goToRecentAssignment: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  goToRecentProject: EventEmitter<string> = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {}

  onGoToMainPage() {
    this.goToMainPage.emit();
  }

  onGoToWatchlist() {
    this.goToWatchlist.emit();
  }

  onGoToMakeAction() {
    this.goToMakeAction.emit();
  }

  onGoToProduction() {
    this.goToProduction.emit();
  }

  onGoToProjects() {
    this.goToProjects.emit();
  }

  onGoToStartProject() {
    this.goToStartProject.emit();
  }

  onGoToNumbers() {
    this.goToNumbers.emit();
  }

  onGoToStartAssignment() {
    this.goToStartAssignment.emit();
  }

  onGoToRecentAssignment(assignmentId: string) {
    this.goToRecentAssignment.emit(assignmentId);
  }

  onGoToRecentProject(projectId: string) {
    this.goToRecentProject.emit(projectId);
  }
}
