import { EntityState } from '@ngrx/entity';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import { IdNameResourceI } from 'src/app/core/models/property-register/id-name-resource.interface';
import {
  PropertyDistrictActions,
  PropertyDistrictActionTypes,
} from 'src/app/core/modules/start/store/actions/property-district.actions';
import { StartAssignmentsActions } from 'src/app/core/modules/start/store/actions/start-assignments.action';
import { idNameResourceAdapter } from 'src/app/core/modules/start/store/utils/id-name-resource.adapter';

export interface PropertyDistrictReducerState
  extends EntityState<IdNameResourceI> {
  loading: boolean;
  loaded: boolean;
  error: ErrorResponseWithIdI;
}

export const initialPropertyDistrictReducerState: PropertyDistrictReducerState = idNameResourceAdapter.getInitialState(
  {
    loading: false,
    loaded: false,
    error: null,
  }
);

export function propertyDistrictReducer(
  state = initialPropertyDistrictReducerState,
  action: PropertyDistrictActions | StartAssignmentsActions
): PropertyDistrictReducerState {
  switch (action.type) {
    case PropertyDistrictActionTypes.LoadPropertyDistricts: {
      return {
        ...state,
        loading: true,
      };
    }

    case PropertyDistrictActionTypes.LoadPropertyDistrictsSuccess: {
      return idNameResourceAdapter.addMany(action.payload.districts, {
        ...initialPropertyDistrictReducerState,
        loaded: true,
      });
    }

    case PropertyDistrictActionTypes.LoadPropertyDistrictsFail: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload,
      };
    }

    default:
      return state;
  }
}

export const getPropertyDistrictLoading = (
  state: PropertyDistrictReducerState
) => state.loading;
export const getPropertyDistrictLoaded = (
  state: PropertyDistrictReducerState
) => state.loaded;
export const getPropertyDistrictError = (state: PropertyDistrictReducerState) =>
  state.error;
