<app-bottom-drower-mobile
  [header]="'mobileFilter.header' | translate"
  [small]="true"
  (close)="onCloseMobileFilter()"
>
  <div class="filter-mobile">
    <div class="filter-mobile__section" *ngIf="!!filter">
      <div
        class="filter-mobile__section-header"
        [translate]="filter.name"
      ></div>
      <div class="filter-mobile__section-content">
        <span
          class="filter-mobile__section-item"
          *ngIf="showAllOption"
          [ngClass]="{
            'filter-mobile__section-item--active': currentFilter === null
          }"
          [translate]="'all'"
          (click)="setCurrentFilter(null)"
        ></span>

        <span
          class="filter-mobile__section-item"
          *ngFor="let item of filter.values"
          [ngClass]="{
            'filter-mobile__section-item--active': item === currentFilter
          }"
          [translate]="filter.translateKey + item"
          (click)="setCurrentFilter(item)"
        ></span>
      </div>
    </div>

    <div
      class="filter-mobile__section filter-mobile__section--padding"
      *ngIf="!!sort"
    >
      <div class="filter-mobile__section-header" [translate]="sort.name"></div>
      <div class="filter-mobile__section-content">
        <span
          class="filter-mobile__section-item"
          [ngClass]="{
            'filter-mobile__section-item--active':
              item === currentSort?.ordering &&
              sort?.field === currentSort?.field
          }"
          *ngFor="let item of sort.values"
          [translate]="sort.translateKey + item"
          (click)="setCurrentSort(item)"
        ></span>
      </div>
    </div>

    <div class="filter-mobile__buttons">
      <button
        class="filter-mobile__buttons-approve btn btn-primary btn-info w-100"
        [translate]="'mobileFilter.apply'"
        (click)="onApply()"
      ></button>
      <button
        class="filter-mobile__buttons-clear btn btn-third w-100"
        [translate]="'mobileFilter.clear'"
        (click)="onClear()"
      ></button>
    </div>
  </div>
</app-bottom-drower-mobile>
