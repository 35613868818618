import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss'],
})
export class SectionComponent implements OnInit {
  @Input()
  headerTitle: string;

  @Input()
  subText: string;

  @Input()
  emptyText: string;

  @Input()
  buttonLabel: string;

  @Input()
  showEmptyText: boolean;

  @Input()
  smallHeader: boolean;

  @Input()
  smallSubHeader: boolean;

  @Output()
  buttonClicked: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}

  onButtonClicked() {
    this.buttonClicked.emit();
  }
}
