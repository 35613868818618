import { createSelector } from '@ngrx/store';

import { SelectI } from 'src/app/core/models/select.interface';
import { getSelectedLanguageSelector } from 'src/app/core/store/selectors/language.selectors';
import {
  mapDictionaryToSelectForGivenLanguage,
  mapSelectArrayToSelectMap,
} from 'src/app/core/utils/dictionary-utils';
import { DictionaryI } from 'src/app/dictionaries/models/dictionary.interface';
import { getRealEstateTypesStateSelector } from 'src/app/dictionaries/store/index';
import {
  getAllRealEstateTypes,
  getRealEstateTypesEntities,
  getRealEstateTypesError,
  getRealEstateTypesIds,
  getRealEstateTypesLoaded,
  getRealEstateTypesLoading,
  getRealEstateTypesTotal,
} from 'src/app/dictionaries/store/reducers/real-estate-types.reducer';

export const getRealEstateIdsSelector = createSelector(
  getRealEstateTypesStateSelector,
  getRealEstateTypesIds
);

export const getRealEstateTypesEntitiesSelector = createSelector(
  getRealEstateTypesStateSelector,
  getRealEstateTypesEntities
);

export const getAllRealEstateTypesSelector = createSelector(
  getRealEstateTypesStateSelector,
  getAllRealEstateTypes
);

export const getRealEstateTypesTotalSelector = createSelector(
  getRealEstateTypesStateSelector,
  getRealEstateTypesTotal
);

export const getRealEstateTypesLoadingSelector = createSelector(
  getRealEstateTypesStateSelector,
  getRealEstateTypesLoading
);

export const getRealEstateTypesLoadedSelector = createSelector(
  getRealEstateTypesStateSelector,
  getRealEstateTypesLoaded
);

export const getRealEstateTypesErrorSelector = createSelector(
  getRealEstateTypesStateSelector,
  getRealEstateTypesError
);

// Custom Selectors

export const getAllRealEstateTypesAsSelectObjectForCurrentLanguageSelector = createSelector(
  getAllRealEstateTypesSelector,
  getSelectedLanguageSelector,
  (realEstateTypes: DictionaryI[], selectedLanguage: string): SelectI[] =>
    mapDictionaryToSelectForGivenLanguage(realEstateTypes, selectedLanguage)
);

export const getAllRealEstateTypesAsMapForCurrentLanguageSelector = createSelector(
  getAllRealEstateTypesAsSelectObjectForCurrentLanguageSelector,
  (selectIList: SelectI[]) => mapSelectArrayToSelectMap(selectIList)
);
