import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { AutocompleteComponent } from 'src/app/core/modules/start/components/autocomplete/autocomplete.component';
import { GeneralInformationComponent } from 'src/app/core/modules/start/components/general-information/general-information.component';
import { GeneralObjectInformationComponent } from 'src/app/core/modules/start/components/general-object-information/general-object-information.component';
import { LocationSearchComponent } from 'src/app/core/modules/start/components/location-search/location-search.component';
import { PropertyRegisterComponent } from 'src/app/core/modules/start/components/property-register/property-register.component';
import { PropertyDistrictGuard } from 'src/app/core/modules/start/guards/property-district.guard';
import { StartFormTemplateComponent } from 'src/app/core/modules/start/modals/start/start-form-template.component';
import {
  startAssignmentReducerProvider,
  startAssignmentReducerToken,
} from 'src/app/core/modules/start/store';
import { PropertyAddressEffects } from 'src/app/core/modules/start/store/effects/property-address.effects';
import { PropertyBuildingEffects } from 'src/app/core/modules/start/store/effects/property-building.effects';
import { PropertyDistrictEffects } from 'src/app/core/modules/start/store/effects/property-district.effects';
import { PropertyObjectEffects } from 'src/app/core/modules/start/store/effects/property-object.effects';
import { PropertyRealEstateEffects } from 'src/app/core/modules/start/store/effects/property-real-estate.effects';
import { StartAssignmentsEffects } from 'src/app/core/modules/start/store/effects/start-assignments.effects';
import { SharedModule } from 'src/app/shared/shared.module';
import { TenantInformationComponent } from './components/tenant-information/tenant-information.component';

@NgModule({
  declarations: [
    StartFormTemplateComponent,
    AutocompleteComponent,
    GeneralInformationComponent,
    LocationSearchComponent,
    PropertyRegisterComponent,
    GeneralObjectInformationComponent,
    TenantInformationComponent,
  ],
  providers: [PropertyDistrictGuard, startAssignmentReducerProvider],
  imports: [
    SharedModule,
    BsDatepickerModule,
    StoreModule.forFeature('startAssignment', startAssignmentReducerToken),
    EffectsModule.forFeature([
      StartAssignmentsEffects,
      PropertyRealEstateEffects,
      PropertyAddressEffects,
      PropertyBuildingEffects,
      PropertyDistrictEffects,
      PropertyObjectEffects,
    ]),
  ],
})
export class StartModule {}
