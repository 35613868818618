import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { AlertModule } from 'ngx-bootstrap/alert';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ActionsDropdownComponent } from 'src/app/shared/components/actions-dropdown/actions-dropdown.component';

import { AlertMessagesComponent } from 'src/app/shared/components/alert-messages/alert-messages.component';
import { BaseListTableComponent } from 'src/app/shared/components/base-list-table/base-list-table.component';
import { DropdownSearchableComponent } from 'src/app/shared/components/dropdown-searchable/dropdown-searchable.component';
import { InfoItemComponent } from 'src/app/shared/components/info-item/info-item.component';
import { LanguageSelectComponent } from 'src/app/shared/components/language-select/language-select.component';
import { PageHeaderWithMenuComponent } from 'src/app/shared/components/page-header-with-menu/page-header-with-menu.component';
import { PageHeaderComponent } from 'src/app/shared/components/page-header/page-header.component';
import { FilterComponent } from 'src/app/shared/components/filter/filter.component';
import { SectionComponent } from 'src/app/shared/components/section/section.component';
import { ClickOutsideDirective } from 'src/app/shared/directives/click-outside.directive';
import { ConnectFormDirective } from 'src/app/shared/directives/connect-form.directive';
import { DisableControlDirective } from 'src/app/shared/directives/disable-control.directive';
import { ExtraTranslatePipe } from 'src/app/shared/pipes/extra-translate.pipe';
import { ReasonTranslatePipe } from 'src/app/shared/pipes/reason-translate.pipe';
import { UwCurrencyPipe } from 'src/app/shared/pipes/uw-currency.pipe';
import { BreadcrumbModule } from 'xng-breadcrumb';
import { ActionsDropdownSingleAssignmentComponent } from './components/actions-dropdown-single-assignment/actions-dropdown-single-assignment.component';
import { BaseActionComponent } from './components/base-action/base-action.component';
import { CardComponent } from './components/card/card.component';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { ActionsLabelPipe } from './pipes/action-button-label.pipe';
import { AgoPipe } from './pipes/ago.pipe';
import { ActionsDropdownExportComponent } from './components/actions-dropdown-export/actions-dropdown-export.component';
import { InputsHaveValueDirective } from './directives/inputs-have-value.directive';
import { DisplayValueComponent } from './components/display-value/display-value.component';
import { CardWithHeaderComponent } from './components/card-with-header/card-with-header.component';
import { SimpleListComponent } from './components/simple-list/simple-list.component';
import { ModalTemplateFormSectionComponent } from './components/modal-template-form-section/modal-template-form-section.component';
import { ModalTemplateComponent } from './components/modal-template/modal-template.component';
import { BaseFormComponent } from 'src/app/shared/components/base-form/base-form.component';
import { UserDetailsComponent } from 'src/app/shared/components/user-details/user-details.component';
import { UserViewComponent } from 'src/app/shared/components/user-view/user-view.component';
import { UserFormComponent } from 'src/app/shared/components/user-form/user-form.component';
import { MobileCardComponent } from './components/mobile-card/mobile-card.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { MobileFilterComponent } from './components/mobile-filter/mobile-filter.component';
import { ActionsDropdownExportMobileComponent } from './components/actions-dropdown-export-mobile/actions-dropdown-export-mobile.component';
import { ActionsDropdownSingleAssignmentMobileComponent } from './components/actions-dropdown-single-assignment-mobile/actions-dropdown-single-assignment-mobile.component';
import { BaseActionsDropdownSingleAssignmentComponent } from './components/base-actions-dropdown-single-assignment/base-actions-dropdown-single-assignment.component';
import { BaseActionsDropdownExportComponent } from './components/base-actions-dropdown-export/base-actions-dropdown-export.component';
import { PhaseChartMobileComponent } from './components/phase-chart-mobile/phase-chart-mobile.component';
import { BottomDrowerMobileComponent } from './components/bottom-drower-mobile/bottom-drower-mobile.component';
import { PageHeaderWithMidComponent } from 'src/app/shared/components/page-header-with-mid/page-header-with-mid.component';

const EXPORT_COMPONENTS = [
  ActionsDropdownComponent,
  AlertMessagesComponent,
  LanguageSelectComponent,
  CardComponent,
  SearchBarComponent,
  DropdownSearchableComponent,
  PageHeaderComponent,
  InfoItemComponent,
  BaseListTableComponent,
  FilterComponent,
  DisplayValueComponent,
  SectionComponent,
  PageHeaderWithMenuComponent,
  CardWithHeaderComponent,
  SimpleListComponent,
  ModalTemplateFormSectionComponent,
  ModalTemplateComponent,
  BaseFormComponent,
  UserDetailsComponent,
  UserViewComponent,
  UserFormComponent,
  MobileCardComponent,
  ProgressBarComponent,
  MobileFilterComponent,
  ActionsDropdownExportMobileComponent,
  ActionsDropdownSingleAssignmentMobileComponent,
  BaseActionsDropdownSingleAssignmentComponent,
  BaseActionsDropdownExportComponent,
  PhaseChartMobileComponent,
  BottomDrowerMobileComponent,
  PageHeaderWithMidComponent,
];

const COMPONENTS = [
  ...EXPORT_COMPONENTS,
  ActionsDropdownSingleAssignmentComponent,
  BaseActionComponent,
  ActionsDropdownExportComponent,
];

const PIPES = [
  UwCurrencyPipe,
  ExtraTranslatePipe,
  ReasonTranslatePipe,
  ActionsLabelPipe,
  AgoPipe,
];

const DIRECTIVES = [
  ConnectFormDirective,
  DisableControlDirective,
  ClickOutsideDirective,
  InputsHaveValueDirective,
];

const EXPORT_MODULES = [
  NgSelectModule,
  AlertModule,
  PaginationModule,
  NgxDatatableModule,
  TranslateModule,
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  CollapseModule,
  BsDropdownModule,
  BsDatepickerModule,
  TimepickerModule,
  AccordionModule,
  TabsModule,
  TooltipModule,
  MatIconModule,
  BreadcrumbModule,
];

const MODULES = [...EXPORT_MODULES, CollapseModule, BsDropdownModule];

@NgModule({
  declarations: [...DIRECTIVES, ...COMPONENTS, ...PIPES],
  imports: [...MODULES],
  exports: [...EXPORT_COMPONENTS, ...DIRECTIVES, ...PIPES, ...EXPORT_MODULES],
})
export class SharedModule {}
