<form [formGroup]="formGroup" novalidate>
  <app-modal-template-form-section
    [title]="'projects.add.form.title'"
    [showLine]="false"
  >
    <div class="row">
      <div class="form-group col-12 col-md-6">
        <label
          for="project-name"
          [translate]="'projects.add.form.name'"
        ></label>

        <span
          class="form-control-mandatory"
          *ngIf="isRequired('name')"
          [translate]="'mandatory'"
        ></span>

        <input
          id="project-name"
          formControlName="name"
          type="text"
          class="form-control"
          [ngClass]="{
            'is-invalid': isValid(formGroup.controls['name'])
          }"
        />

        <div
          class="invalid-feedback"
          *ngIf="formGroup.controls['name']?.invalid"
          [translate]="'projects.add.errorMessages.name'"
        ></div>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-12 col-md-6">
        <label
          for="external-project-id"
          [translate]="'projects.add.form.externalProjectId'"
        ></label>

        <span
          class="form-control-mandatory"
          *ngIf="isRequired('externalProjectId')"
          [translate]="'mandatory'"
        ></span>

        <input
          id="external-project-id"
          formControlName="externalProjectId"
          type="text"
          class="form-control"
          [ngClass]="{
            'is-invalid': isValid(formGroup.controls['externalProjectId'])
          }"
        />

        <div
          class="invalid-feedback"
          *ngIf="formGroup.controls['externalProjectId']?.invalid"
          [translate]="'projects.add.errorMessages.externalProjectId'"
        ></div>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-12 col-md-6">
        <label
          for="external-project-id"
          [translate]="'projects.add.form.projectManager'"
        ></label>

        <span
          class="form-control-mandatory"
          *ngIf="isRequired('projectManagerId')"
          [translate]="'mandatory'"
        ></span>

        <select
          id="external-project-manager"
          formControlName="projectManagerId"
          class="custom-select"
          [ngClass]="{
            'is-invalid': isValid(formGroup.controls['projectManagerId'])
          }"
        >
          <option
            value=""
            selected
            disabled
            [translate]="'common.select'"
          ></option>
          <option *ngFor="let user of users" [value]="user.userId">
            {{ user.name }}
          </option>
        </select>

        <div
          class="invalid-feedback"
          *ngIf="formGroup.controls['projectManagerId']?.invalid"
          [translate]="'projects.add.errorMessages.projectManager'"
        ></div>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-12 col-md-6">
        <label
          for="project-budget"
          [translate]="'projects.add.form.budget'"
        ></label>

        <span
          class="form-control-mandatory"
          *ngIf="isRequired('budget')"
          [translate]="'mandatory'"
        ></span>

        <input
          id="project-budget"
          formControlName="budget"
          type="number"
          class="form-control"
          [ngClass]="{
            'is-invalid': isValid(formGroup.controls['budget'])
          }"
        />

        <div
          class="invalid-feedback"
          *ngIf="formGroup.controls['budget']?.invalid"
          [translate]="'projects.add.errorMessages.budget'"
        ></div>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-12 col-md-6">
        <label
          for="project-end-date"
          [translate]="'projects.add.form.endDate'"
        ></label>

        <span
          class="form-control-mandatory"
          *ngIf="isRequired('endDate')"
          [translate]="'mandatory'"
        ></span>

        <input
          id="project-end-date"
          formControlName="endDate"
          class="form-control"
          autocomplete="off"
          [bsConfig]="bsConfig"
          bsDatepicker
          [ngClass]="{
            'is-invalid': isValid(formGroup.controls['endDate'])
          }"
        />

        <div
          class="invalid-feedback"
          *ngIf="formGroup.controls['endDate']?.invalid"
          [translate]="'projects.add.errorMessages.endDate'"
        ></div>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-12 col-md-6">
        <label
          for="project-name"
          [translate]="'projects.add.form.scope'"
        ></label>

        <span
          class="form-control-mandatory"
          *ngIf="isRequired('scope')"
          [translate]="'mandatory'"
        ></span>

        <input
          id="project-scope"
          formControlName="scope"
          type="text"
          class="form-control"
          [ngClass]="{
            'is-invalid': isValid(formGroup.controls['scope'])
          }"
        />

        <div
          class="invalid-feedback"
          *ngIf="formGroup.controls['scope']?.invalid"
          [translate]="'projects.add.errorMessages.scope'"
        ></div>
      </div>
    </div>
  </app-modal-template-form-section>
</form>
