<div class="simple-list" [ngClass]="{ 'simple-list--border': border }">
  <div
    class="simple-list__item"
    [ngClass]="{
      'simple-list__item--clickable':
        clickable || clickableFunction(option, border),
      'simple-list__item--simple': simple
    }"
    *ngFor="let option of options"
    (click)="onClick(option, border)"
  >
    <span class="simple-list__item-name">
      <ng-container
        [ngTemplateOutlet]="template"
        [ngTemplateOutletContext]="{ $implicit: option }"
      ></ng-container>
    </span>

    <mat-icon
      class="simple-list__button"
      *ngIf="clickable || clickableFunction(option, border)"
      [ngClass]="{ 'simple-list__button--no-space': !border }"
      >chevron_right
    </mat-icon>
  </div>
</div>
