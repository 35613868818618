import { Action } from '@ngrx/store';
import {
  HideLoader,
  ShowLoader,
} from 'src/app/core/decorators/loader.decorator';
import { CreateAssignmentDetailsI } from 'src/app/core/models/assignment/assignment-details.interface';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';

export enum StartAssignmentsActionTypes {
  StartAssignment = '[Start Assignments] Start Assignment',
  AssignmentStartedSuccess = '[Start Assignments] Start Assignment Success',
  AssignmentStartedFail = '[Start Assignments] Fail To Start Assignment',

  PropertyTypeChanged = '[Start Assignments] Property Type Changed',

  CleanStartAssignmentsState = '[Start Assignment] Clean',
}

// ------ Create Assignment ------

@ShowLoader()
export class StartAssignment implements Action {
  readonly type = StartAssignmentsActionTypes.StartAssignment;

  constructor(public payload: CreateAssignmentDetailsI) {}
}

@HideLoader(StartAssignmentsActionTypes.StartAssignment)
export class AssignmentStartedSuccess implements Action {
  readonly type = StartAssignmentsActionTypes.AssignmentStartedSuccess;

  constructor(public payload: string) {}
}

@HideLoader(StartAssignmentsActionTypes.StartAssignment)
export class AssignmentStartedFail implements Action {
  readonly type = StartAssignmentsActionTypes.AssignmentStartedFail;

  constructor(public payload: ErrorResponseWithIdI) {}
}

export class PropertyTypeChanged implements Action {
  readonly type = StartAssignmentsActionTypes.PropertyTypeChanged;

  constructor(public payload: string) {}
}

export class CleanStartAssignmentsState implements Action {
  readonly type = StartAssignmentsActionTypes.CleanStartAssignmentsState;

  constructor(public payload?: string) {}
}

export type StartAssignmentsActions =
  | StartAssignment
  | AssignmentStartedSuccess
  | AssignmentStartedFail
  | PropertyTypeChanged
  | CleanStartAssignmentsState;
