import { createSelector } from '@ngrx/store';
import { getStartPageStateSelector } from 'src/app/core/store/index';
import {
  getStartPageError,
  getStartPageLoaded,
  getStartPageLoading,
  getStartPageResource,
  getStartPageShowMobileAction,
} from 'src/app/core/store/reducers/start-page.reducer';
import { StartPageI } from 'src/app/core/models/user/start-page.interface';

export const getStartPageResourceSelector = createSelector(
  getStartPageStateSelector,
  getStartPageResource
);

export const getStartPageLoadingSelector = createSelector(
  getStartPageStateSelector,
  getStartPageLoading
);

export const getStartPageLoadedSelector = createSelector(
  getStartPageStateSelector,
  getStartPageLoaded
);

export const getStartPageErrorSelector = createSelector(
  getStartPageStateSelector,
  getStartPageError
);

export const getStartPageShowMobileActionSelector = createSelector(
  getStartPageStateSelector,
  getStartPageShowMobileAction
);

// Custom Selectors
export const getStartPagePhaseChartSelector = createSelector(
  getStartPageResourceSelector,
  (startPage: StartPageI) => startPage?.phaseChart
);

export const getStartPageAggregatedVolumeChartSelector = createSelector(
  getStartPageResourceSelector,
  (startPage: StartPageI) => startPage?.aggregatedVolumeChart
);

export const getStartPageMyActionsSelector = createSelector(
  getStartPageResourceSelector,
  (startPage: StartPageI) => startPage?.myActions
);

export const getStartPageWatchListSelector = createSelector(
  getStartPageResourceSelector,
  (startPage: StartPageI) => startPage?.watchlist
);
