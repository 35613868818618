import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  PusherAllMessagesI,
  PusherMessageI,
} from 'src/app/core/models/message/pusher-message.interface';

@Injectable({
  providedIn: 'root',
})
export class PusherMessageService {
  private baseMessageUrl = environment.url + 'api/v1/message/';
  private infoMessageInfoUrl = this.baseMessageUrl + 'info/';
  private allMessageInfoUrl = this.baseMessageUrl + 'all/';

  public authMessageUrl = this.baseMessageUrl + 'auth';

  constructor(private http: HttpClient) {}

  getMessageInfo(messageId: string): Observable<PusherMessageI> {
    return this.http.get<PusherMessageI>(this.infoMessageInfoUrl + messageId);
  }

  getAllMessages(): Observable<PusherAllMessagesI> {
    return this.http.get<PusherAllMessagesI>(this.allMessageInfoUrl);
  }
}
