<app-page-header [header]="headerTranslation" [border]="true">
  <span
    *ngIf="user?.blocked"
    slot="sub-text"
    class="user-blocked"
    [translate]="blockedTranslation"
  ></span>
  <button
    class="btn btn-small btn-header"
    slot="content"
    [translate]="resendTranslation"
    (click)="onResendInvitation(user.userId)"
  ></button>
</app-page-header>

<app-user-form
  *ngIf="editDetails; else labels"
  [userPublic]="user"
  (discard)="onDiscard()"
  (save)="onSave($event)"
></app-user-form>

<ng-template #labels>
  <app-user-view [user]="user" (editUser)="onEditUser()"></app-user-view>
</ng-template>
