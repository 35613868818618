<div class="row">
  <div class="col-12 col-md-6">
    <div class="property-register card bg-light" [formGroup]="formGroup">
      <div class="property-register__body card-body">
        <div
          class="property-register__title card-title"
          [translate]="'create.addressType.propertyRegister'"
        ></div>

        <div class="row">
          <div class="form-group col-12">
            <label
              for="create-property-2"
              [translate]="'create.formControls.property.district'"
            ></label>

            <span
              class="form-control-mandatory"
              *ngIf="isRequired('districtId')"
              [translate]="'mandatory'"
            ></span>

            <select
              id="create-property-2"
              class="custom-select inverted"
              formControlName="districtId"
              (change)="handleDistrictChange()"
              [ngClass]="{
                'is-invalid': isValid(formGroup.controls['districtId'])
              }"
            >
              <option
                value=""
                selected
                disabled
                [translate]="'common.select'"
              ></option>
              <option
                *ngFor="let district of propertyDistrict"
                [value]="district.id"
              >
                {{ district.name }}
              </option>
            </select>

            <div
              class="invalid-feedback"
              *ngIf="formGroup.controls['districtId']?.invalid"
              [translate]="'create.errorMessages.property.district'"
            ></div>
          </div>

          <div class="form-group col-12">
            <label
              for="create-property-1"
              [translate]="'create.formControls.property.realEstate'"
            ></label>

            <span
              class="form-control-mandatory"
              *ngIf="isRequired('realEstateId')"
              [translate]="'mandatory'"
            ></span>

            <select
              id="create-property-1"
              class="custom-select inverted"
              formControlName="realEstateId"
              (change)="handleRealEstateChange()"
              [ngClass]="{
                'is-invalid': isValid(formGroup.controls['realEstateId'])
              }"
            >
              <option
                value=""
                selected
                disabled
                [translate]="'common.select'"
              ></option>
              <option
                *ngFor="let realEstate of propertyRealEstate"
                [value]="realEstate.id"
              >
                {{ realEstate.name }}
              </option>
            </select>

            <div
              class="invalid-feedback"
              *ngIf="formGroup.controls['realEstateId']?.invalid"
              [translate]="'create.errorMessages.property.realEstate'"
            ></div>
          </div>

          <div class="form-group col-12">
            <label
              for="create-property-3"
              [translate]="'create.formControls.property.building'"
            ></label>

            <span
              class="form-control-mandatory"
              *ngIf="isRequired('buildingId')"
              [translate]="'mandatory'"
            ></span>

            <select
              id="create-property-3"
              class="custom-select inverted"
              formControlName="buildingId"
              (change)="handleBuildingChange()"
              [ngClass]="{
                'is-invalid': isValid(formGroup.controls['buildingId'])
              }"
            >
              <option
                value=""
                selected
                disabled
                [translate]="'common.select'"
              ></option>
              <option
                *ngFor="let building of propertyBuilding"
                [value]="building.id"
              >
                {{ building.name }}
              </option>
            </select>

            <div
              class="invalid-feedback"
              *ngIf="formGroup.controls['buildingId']?.invalid"
              [translate]="'create.errorMessages.property.building'"
            ></div>
          </div>

          <div class="form-group col-12">
            <label
              for="create-property-4"
              [translate]="'create.formControls.property.address'"
            ></label>

            <span
              class="form-control-mandatory"
              *ngIf="isRequired('addressId')"
              [translate]="'mandatory'"
            ></span>

            <select
              id="create-property-4"
              class="custom-select inverted"
              formControlName="addressId"
              (change)="handleAddressChange()"
              [ngClass]="{
                'is-invalid': isValid(formGroup.controls['addressId'])
              }"
            >
              <option
                value=""
                selected
                disabled
                [translate]="'common.select'"
              ></option>
              <option
                *ngFor="let address of propertyAddress"
                [value]="address.id"
              >
                {{ address.name }}
              </option>
            </select>

            <div
              class="invalid-feedback"
              *ngIf="formGroup.controls['addressId']?.invalid"
              [translate]="'create.errorMessages.property.address'"
            ></div>
          </div>

          <div class="form-group col-12">
            <label
              for="create-property-5"
              [translate]="'create.formControls.property.object'"
            ></label>

            <span
              class="form-control-mandatory"
              *ngIf="isRequired('objectId')"
              [translate]="'mandatory'"
            ></span>

            <select
              id="create-property-5"
              class="custom-select inverted"
              formControlName="objectId"
              [ngClass]="{
                'is-invalid': isValid(formGroup.controls['objectId'])
              }"
            >
              <option
                value=""
                selected
                disabled
                [translate]="'common.select'"
              ></option>
              <option *ngFor="let object of propertyObject" [value]="object.id">
                {{ object.name }}
              </option>
            </select>

            <div
              class="invalid-feedback"
              *ngIf="formGroup.controls['objectId']?.invalid"
              [translate]="'create.errorMessages.property.object'"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
