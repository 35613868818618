import { Component, OnInit } from '@angular/core';
import { BaseActionsDropdownExportComponent } from 'src/app/shared/components/base-actions-dropdown-export/base-actions-dropdown-export.component';

@Component({
  selector: 'app-actions-dropdown-export',
  templateUrl: './actions-dropdown-export.component.html',
  styleUrls: ['./actions-dropdown-export.component.scss'],
})
export class ActionsDropdownExportComponent
  extends BaseActionsDropdownExportComponent
  implements OnInit {
  constructor() {
    super();
  }
}
