import { Component, Input, OnInit } from '@angular/core';
import { BaseFormComponent } from 'src/app/shared/components/base-form/base-form.component';
import { AssignmentPartI } from 'src/app/core/models/assignment/assignment-part.interface';
import { WarningI } from 'src/app/core/models/warning.interface';

@Component({
  selector: 'app-make-decision-form',
  templateUrl: './make-decision-form.component.html',
  styleUrls: ['./make-decision-form.component.scss'],
})
export class MakeDecisionFormComponent
  extends BaseFormComponent<MakeDecisionFormComponent>
  implements OnInit {
  @Input()
  assignmentParts: AssignmentPartI[];

  @Input()
  warnings: WarningI[];

  @Input()
  decisionTypeApproved: string;

  @Input()
  decisionTypeDisproved: string;

  ngOnInit(): void {}
}
