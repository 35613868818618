import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ChartResultI } from 'src/app/core/models/log/chart-result.interface';
import { CHART_CONFIG } from 'src/app/shared/charts/chart-config.utils';
import { ChartSingleCalculation } from 'src/app/shared/models/chart.interface';

@Component({
  selector: 'app-phase-chart-mobile',
  templateUrl: './phase-chart-mobile.component.html',
  styleUrls: ['./phase-chart-mobile.component.scss'],
})
export class PhaseChartMobileComponent implements OnInit {
  config = CHART_CONFIG;

  @Input()
  series: ChartResultI[] | ChartSingleCalculation[];

  @Output()
  goToPhase: EventEmitter<string> = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {}

  onGoToPhase(phase: string) {
    this.goToPhase.emit(phase);
  }
}
