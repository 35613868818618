<div class="card-with-header card bg-light">
  <div class="card-with-header__body card-body">
    <div
      class="card-with-header__title"
      [ngClass]="{ 'card-with-header__title--border': border }"
    >
      <span [translate]="header" *ngIf="header"></span>

      <ng-content select="[slot=header]"></ng-content>
    </div>

    <div class="card-with-header__content">
      <ng-content></ng-content>
    </div>
  </div>
</div>
