import { Action } from '@ngrx/store';
import { PusherEventI } from 'src/app/core/models/message/pusher-event.interface';
import {
  PusherAllMessagesI,
  PusherMessageI,
} from 'src/app/core/models/message/pusher-message.interface';
import {
  HideLoader,
  ShowLoader,
} from 'src/app/core/decorators/loader.decorator';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';

export enum PusherActionTypes {
  MessageConnectToChanel = '[Pusher] Message Connect To Channel',
  MessageDisconnectFromChanel = '[Pusher] Message Disconnect From Channel',

  MessageEventReceived = '[Pusher] Message Event Received',
  MessageEventErrorReceived = '[Pusher] Message Event Error Received',

  LoadPusherMessage = '[Pusher] Load Pusher Message',
  LoadPusherMessageSuccess = '[Pusher] Load Pusher Message Success',
  LoadPusherMessageFail = '[Pusher] Load Pusher Message Fail',

  LoadAllPusherMessages = '[Pusher] Load All Pusher Messages',
  LoadPusherAllMessagesSuccess = '[Pusher] Load All Pusher Messages Success',
  LoadPusherAllMessagesFail = '[Pusher] Load All Pusher Messages Fail',

  ChangePusherMessagesPagination = '[Assignments] Change Pusher Messages Pagination',
  ChangePusherMessagesPaginationLimit = '[Assignments] Change Pusher Messages Pagination Limit',

  ClearPusherMessages = '[Pusher] Clear Pusher Messages',
}

export class MessageConnectToChanel implements Action {
  readonly type = PusherActionTypes.MessageConnectToChanel;

  constructor(public payload: string) {}
}

export class MessageDisconnectFromChanel implements Action {
  readonly type = PusherActionTypes.MessageDisconnectFromChanel;
}

export class MessageEventReceived implements Action {
  readonly type = PusherActionTypes.MessageEventReceived;

  constructor(public payload: PusherEventI) {}
}

export class MessageEventErrorReceived implements Action {
  readonly type = PusherActionTypes.MessageEventErrorReceived;

  constructor(public payload: string) {}
}

export class LoadPusherMessage implements Action {
  readonly type = PusherActionTypes.LoadPusherMessage;

  constructor(public payload: string) {}
}

export class LoadPusherMessageSuccess implements Action {
  readonly type = PusherActionTypes.LoadPusherMessageSuccess;

  constructor(public payload: PusherMessageI) {}
}

export class LoadPusherMessageFail implements Action {
  readonly type = PusherActionTypes.LoadPusherMessageFail;

  constructor(public payload: ErrorResponseWithIdI) {}
}

@ShowLoader()
export class LoadAllPusherMessages implements Action {
  readonly type = PusherActionTypes.LoadAllPusherMessages;
}

@HideLoader(PusherActionTypes.LoadAllPusherMessages)
export class LoadPusherAllMessagesSuccess implements Action {
  readonly type = PusherActionTypes.LoadPusherAllMessagesSuccess;

  constructor(public payload: PusherAllMessagesI) {}
}

@HideLoader(PusherActionTypes.LoadAllPusherMessages)
export class LoadPusherAllMessagesFail implements Action {
  readonly type = PusherActionTypes.LoadPusherAllMessagesFail;

  constructor(public payload: ErrorResponseWithIdI) {}
}

export class ChangePusherMessagesPagination implements Action {
  readonly type = PusherActionTypes.ChangePusherMessagesPagination;

  constructor(public payload: number) {}
}

export class ChangePusherMessagesPaginationLimit implements Action {
  readonly type = PusherActionTypes.ChangePusherMessagesPaginationLimit;

  constructor(public payload: number) {}
}

export class ClearPusherMessages implements Action {
  readonly type = PusherActionTypes.ClearPusherMessages;
}

export type PusherActions =
  | MessageConnectToChanel
  | MessageDisconnectFromChanel
  | MessageEventReceived
  | LoadPusherMessage
  | LoadPusherMessageSuccess
  | LoadPusherMessageFail
  | LoadAllPusherMessages
  | LoadPusherAllMessagesSuccess
  | LoadPusherAllMessagesFail
  | ChangePusherMessagesPagination
  | ChangePusherMessagesPaginationLimit
  | ClearPusherMessages;
