import { Action } from '@ngrx/store';
import {
  HideLoader,
  ShowLoader,
} from 'src/app/core/decorators/loader.decorator';
import {
  MyActionI,
  StartPageI,
} from 'src/app/core/models/user/start-page.interface';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';

export enum StartPageActionTypes {
  LoadStartPage = '[StartPage] Load Start Page',
  LoadedStartPageSuccess = '[StartPage] Loaded Start Page',
  LoadedStartPageFail = '[StartPage] Fail To Load Start Page',

  ShowStartPageMobileActions = '[StartPage] Show Start Page Mobile Actions',

  CleanStartPageState = '[StartPage] Clean',
}

@ShowLoader()
export class LoadStartPage implements Action {
  readonly type = StartPageActionTypes.LoadStartPage;

  constructor(public payload?: any) {}
}

@HideLoader(StartPageActionTypes.LoadStartPage)
export class LoadedStartPageSuccess implements Action {
  readonly type = StartPageActionTypes.LoadedStartPageSuccess;

  constructor(public payload: StartPageI) {}
}

@HideLoader(StartPageActionTypes.LoadStartPage)
export class LoadedStartPageFail implements Action {
  readonly type = StartPageActionTypes.LoadedStartPageFail;

  constructor(public payload: ErrorResponseWithIdI) {}
}

export class ShowStartPageMobileActions implements Action {
  readonly type = StartPageActionTypes.ShowStartPageMobileActions;

  constructor(public payload: MyActionI) {}
}

export class CleanStartPageState implements Action {
  readonly type = StartPageActionTypes.CleanStartPageState;

  constructor(public payload?: string) {}
}

export type StartPageActions =
  | LoadStartPage
  | LoadedStartPageSuccess
  | LoadedStartPageFail
  | ShowStartPageMobileActions
  | CleanStartPageState;
