import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent implements OnInit {
  activeItem: string;

  @Input()
  isAdmin: boolean;

  @Output()
  goToStartAssignment: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  goToNumbers: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  goToMainPage: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  goToProduction: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  goToWatchlist: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  goToMakeAction: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  goToProjects: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  goToStartProject: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  goToAdministration: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  goToMessages: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  goToSupport: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  goToAccountDetails: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  logout: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  hideSideBar: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}

  isActive(item: string) {
    return this.activeItem === item;
  }

  setActive(item: string) {
    this.activeItem = item === this.activeItem ? undefined : item;
  }

  onGoToStartAssignment() {
    this.goToStartAssignment.emit();
    this.onHideSideBar();
  }

  onGoToNumbers() {
    this.goToNumbers.emit();
    this.onHideSideBar();
  }

  onGoToMainPage() {
    this.goToMainPage.emit();
    this.onHideSideBar();
  }

  onGoToProduction() {
    this.goToProduction.emit();
    this.onHideSideBar();
  }

  onGoToWatchlist() {
    this.goToWatchlist.emit();
    this.onHideSideBar();
  }

  onGoToMakeAction() {
    this.goToMakeAction.emit();
    this.onHideSideBar();
  }

  onGoToProjects() {
    this.goToProjects.emit();
    this.onHideSideBar();
  }

  onGoToStartProject() {
    this.goToStartProject.emit();
    this.onHideSideBar();
  }

  onGoToAdministration() {
    this.goToAdministration.emit();
    this.onHideSideBar();
  }

  onGoToSupport() {
    this.goToSupport.emit();
    this.onHideSideBar();
  }

  onGoToAccountDetails() {
    this.goToAccountDetails.emit();
    this.onHideSideBar();
  }

  onLogout() {
    this.logout.emit();
    this.onHideSideBar();
  }

  onHideSideBar() {
    this.hideSideBar.emit();
  }
}
