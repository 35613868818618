import { Action } from '@ngrx/store';

export enum SideBar {
  OpenSideBar = '[SideBar] Open Side Bar',
  CloseSideBar = '[SideBar] Close Side Bar',
}

export class OpenSideBar implements Action {
  readonly type = SideBar.OpenSideBar;
}

export class CloseSideBar implements Action {
  readonly type = SideBar.CloseSideBar;
}

export type SideBarActions = OpenSideBar | CloseSideBar;
