import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  LoadPusherMessageSuccess,
  PusherActionTypes,
} from 'src/app/core/store/actions/pusher.action';
import { filter, map } from 'rxjs/operators';
import {
  ShowMessage,
  ShowMessageMissingExternalId,
} from 'src/app/core/store/actions/alert-messages.action';
import { PusherMessageI } from 'src/app/core/models/message/pusher-message.interface';
import { AlertMessagesTypes } from 'src/app/core/enums/alert-messages-types.enum';
import { AlertMessage } from 'src/app/core/models/alert-message.interface';
import { TranslateService } from '@ngx-translate/core';
import {
  LoadedUserResourceSuccess,
  UserActionTypes,
} from 'src/app/auth/store/actions/user.action';
import { AssignmentStatusEnum } from 'src/app/core/enums/assignment-status.enum';

@Injectable()
export class AlertMessagesEffects {
  showMessageAfterLoadedUserWithMissingExternalId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.LoadedUserResourceSuccess),
      map(
        (loadedUserResourceSuccess: LoadedUserResourceSuccess) =>
          loadedUserResourceSuccess.payload.userPublic.externalId
      ),
      filter((externalId) => !externalId),
      map(
        (pusherMessage: PusherMessageI) =>
          new ShowMessageMissingExternalId(true)
      )
    )
  );

  showMessageAfterLoadedPusherMessageSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PusherActionTypes.LoadPusherMessageSuccess),
      map(
        (loadPusherMessageSuccess: LoadPusherMessageSuccess) =>
          loadPusherMessageSuccess.payload
      ),
      filter(
        (pusherMessage: PusherMessageI) =>
          pusherMessage.status === AssignmentStatusEnum.INVITATION_ACCEPTED ||
          pusherMessage.status === AssignmentStatusEnum.INVITATION_DECLINED ||
          pusherMessage.status === AssignmentStatusEnum.NEW_CALCULATION ||
          pusherMessage.status === AssignmentStatusEnum.NEW_FINAL_CALCULATION ||
          !!pusherMessage.error
      ),
      map((pusherMessage: PusherMessageI) =>
        this.createMessagesListFromPusherMessage(pusherMessage)
      )
    )
  );

  constructor(
    private actions$: Actions,
    private translateService: TranslateService
  ) {}

  private createMessagesListFromPusherMessage(
    pusherMessage: PusherMessageI
  ): ShowMessage {
    return !!pusherMessage.error
      ? this.createFailMessageAction(pusherMessage)
      : this.createSuccessMessageAction(pusherMessage);
  }

  private createSuccessMessageAction(
    pusherMessage: PusherMessageI
  ): ShowMessage {
    const alertMessage = this.createBaseAlertMessageFromPusherMessage(
      pusherMessage
    );

    const name = pusherMessage.assignmentName;
    const status = this.translateService.instant(
      'assignmentStatus.' + pusherMessage.status
    );

    const message = this.translateService.instant('alert.messages.updated', {
      name,
      status,
    });

    return new ShowMessage({
      ...alertMessage,
      message,
      type: AlertMessagesTypes.SUCCESS,
    });
  }

  private createFailMessageAction(pusherMessage: PusherMessageI): ShowMessage {
    const alertMessage = this.createBaseAlertMessageFromPusherMessage(
      pusherMessage
    );

    const name = pusherMessage.assignmentName;
    const error = pusherMessage.error;

    const message = this.translateService.instant('alert.messages.error', {
      name,
      error,
    });

    return new ShowMessage({
      ...alertMessage,
      message,
      type: AlertMessagesTypes.DANGER,
    });
  }

  private createBaseAlertMessageFromPusherMessage(
    pusherMessage: PusherMessageI
  ): AlertMessage {
    return {
      id: pusherMessage.messageInfoId,
      assignmentId: pusherMessage.assignmentId,
      timestamp: pusherMessage.created,
      message: null,
      type: null,
    };
  }
}
