import { Action } from '@ngrx/store';
import {
  HideLoader,
  ShowLoader,
} from 'src/app/core/decorators/loader.decorator';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import { ProjectDetailsI } from 'src/app/core/models/projects/project-details.interface';

export enum ProjectDetailsActionTypes {
  LoadProjectDetails = '[ProjectDetails] Load Project Details',
  LoadedProjectDetailsSuccess = '[ProjectDetails] Loaded Project Details Success',
  LoadedProjectDetailsFail = '[ProjectDetails] Fail To Load Project Details',

  CleanProjectDetailsState = '[ProjectDetails] Clean',
}

@ShowLoader()
export class LoadProjectDetails implements Action {
  readonly type = ProjectDetailsActionTypes.LoadProjectDetails;

  constructor(public payload: number) {}
}

@HideLoader(ProjectDetailsActionTypes.LoadProjectDetails)
export class LoadedProjectDetailsSuccess implements Action {
  readonly type = ProjectDetailsActionTypes.LoadedProjectDetailsSuccess;

  constructor(public payload: ProjectDetailsI) {}
}

@HideLoader(ProjectDetailsActionTypes.LoadProjectDetails)
export class LoadedProjectDetailsFail implements Action {
  readonly type = ProjectDetailsActionTypes.LoadedProjectDetailsFail;

  constructor(public payload: ErrorResponseWithIdI) {}
}

export class CleanProjectDetailsState implements Action {
  readonly type = ProjectDetailsActionTypes.CleanProjectDetailsState;

  constructor(public payload?: any) {}
}

export type ProjectDetailsActions =
  | LoadProjectDetails
  | LoadedProjectDetailsSuccess
  | LoadedProjectDetailsFail
  | CleanProjectDetailsState;
