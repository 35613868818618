import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { GoogleAnalyticsService } from 'src/app/core/services/google/analytics/google-analytics.service';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { SettingsI } from './app/core/models/settings.interface';
import { Settings } from './app/core/utils/settings';

async function loadSettingsAndStartAngular() {
  const backendUrl = await fetch('config/settings.json')
    .then((response: Response) => response.json())
    .catch(() => alert('Unable to fetch backend url'));

  if (!backendUrl || !backendUrl.propertiesUrl) {
    throw new Error('Unable to fetch backend url');
  }

  const settings: SettingsI = await fetch(backendUrl.propertiesUrl)
    .then((response) => response.json())
    .then((responseBody) => {
      if (responseBody['error']) {
        throw new Error();
      } else {
        return responseBody;
      }
    })
    .catch(() => alert('Unable to fetch settings'));

  if (!!settings && !!settings.url) {
    startAngular(settings);
  } else {
    throw new Error('No properties were found, Angular will not start');
  }
}

function startAngular(settings: SettingsI) {
  Settings.appSettings = settings;

  if (environment.production) {
    enableProdMode();
  }

  if (settings.googleAnalyticsCode) {
    GoogleAnalyticsService.load();
  }

  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.log(err));
}

loadSettingsAndStartAngular();
