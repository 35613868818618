import { Action } from '@ngrx/store';
import {
  HideLoader,
  ShowLoader,
} from 'src/app/core/decorators/loader.decorator';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';

import { PropertyRealEstateI } from 'src/app/core/models/property-register/property-real-estate.interface';
import { PropertyBuildingRequestI } from 'src/app/core/models/property-register/request/property-building-request.interface';
import { PropertyRealEstateRequestI } from 'src/app/core/models/property-register/request/property-real-estate-request.interface';

export enum PropertyRealEstateActionTypes {
  PropertyRealEstatesChanged = '[PropertyRealEstate] Property Real Estates Changed',

  LoadPropertyRealEstates = '[PropertyRealEstate] Load Property Real Estates',
  LoadPropertyRealEstatesSuccess = '[PropertyRealEstate] Load Property Real Estates Success',
  LoadPropertyRealEstatesFail = '[PropertyRealEstate] Load Property Real Estates Fail',

  CleanPropertyRealEstate = '[PropertyRealEstate] Clean Property Real Estate',
}

export class PropertyRealEstatesChanged implements Action {
  readonly type = PropertyRealEstateActionTypes.PropertyRealEstatesChanged;

  constructor(public payload: PropertyBuildingRequestI) {}
}

@ShowLoader()
export class LoadPropertyRealEstates implements Action {
  readonly type = PropertyRealEstateActionTypes.LoadPropertyRealEstates;

  constructor(public payload: PropertyRealEstateRequestI) {}
}

@HideLoader(PropertyRealEstateActionTypes.LoadPropertyRealEstates)
export class LoadPropertyRealEstatesSuccess implements Action {
  readonly type = PropertyRealEstateActionTypes.LoadPropertyRealEstatesSuccess;

  constructor(public payload: PropertyRealEstateI) {}
}

@HideLoader(PropertyRealEstateActionTypes.LoadPropertyRealEstates)
export class LoadPropertyRealEstatesFail implements Action {
  readonly type = PropertyRealEstateActionTypes.LoadPropertyRealEstatesFail;

  constructor(public payload: ErrorResponseWithIdI) {}
}

export class CleanPropertyRealEstate implements Action {
  readonly type = PropertyRealEstateActionTypes.CleanPropertyRealEstate;
}

export type PropertyRealEstateActions =
  | PropertyRealEstatesChanged
  | LoadPropertyRealEstates
  | LoadPropertyRealEstatesSuccess
  | LoadPropertyRealEstatesFail
  | CleanPropertyRealEstate;
