import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AssignmentI } from 'src/app/core/models/assignment/assignment.interface';
import { PdfPayloadI } from 'src/app/core/models/pdf-payload.interface';

@Component({
  selector: 'app-base-actions-dropdown-export',
  template: '',
  styles: [''],
})
export class BaseActionsDropdownExportComponent implements OnInit {
  assignmentId: string[];
  assignmentIds: string[];
  selectedAssignmentIds: string[];

  @Input()
  selectedCalculationIds: { newId: string; oldId: string };

  @Input()
  set assignment(assignment: AssignmentI) {
    this.assignmentId = !!assignment?.assignmentId
      ? [assignment?.assignmentId]
      : [];
  }

  @Input()
  set assignmentList(assignmentList: AssignmentI[]) {
    this.assignmentIds = this.getAssignmentIds(assignmentList || []);
  }

  @Input()
  set selectedAssignmentList(assignmentList: AssignmentI[]) {
    this.selectedAssignmentIds = this.getAssignmentIds(assignmentList || []);
  }

  @Output()
  exportToExcelSingle: EventEmitter<string[]> = new EventEmitter<string[]>();

  @Output()
  exportToSimpleExcelSingle: EventEmitter<string[]> = new EventEmitter<
    string[]
  >();

  @Output()
  exportToExcel: EventEmitter<string[]> = new EventEmitter<string[]>();

  @Output()
  exportToSimpleExcel: EventEmitter<string[]> = new EventEmitter<string[]>();

  @Output()
  exportAllToExcel: EventEmitter<string[]> = new EventEmitter<string[]>();

  @Output()
  exportAllToSimpleExcel: EventEmitter<string[]> = new EventEmitter<string[]>();

  @Output()
  printToPDF: EventEmitter<PdfPayloadI> = new EventEmitter<PdfPayloadI>();

  constructor() {}

  ngOnInit(): void {}

  onExportToExcelSingle() {
    this.exportToExcelSingle.emit(this.assignmentId);
  }

  onExportToSimpleExcelSingle() {
    this.exportToSimpleExcelSingle.emit(this.assignmentId);
  }

  onExportToExcel() {
    this.exportToExcel.emit(this.selectedAssignmentIds);
  }

  onExportToSimpleExcel() {
    this.exportToSimpleExcel.emit(this.selectedAssignmentIds);
  }

  onExportAllToExcel() {
    this.exportAllToExcel.emit(this.assignmentIds);
  }

  onExportAllToSimpleExcel() {
    this.exportAllToSimpleExcel.emit(this.assignmentIds);
  }

  private getAssignmentIds(assignmentList: AssignmentI[]) {
    return assignmentList
      .filter((assignment) => assignment?.assignmentId)
      .map((assignment) => assignment.assignmentId);
  }

  onPrintToPDF() {
    this.printToPDF.emit({
      assignmentId: this.assignmentId[0],
      newEventId: this.selectedCalculationIds.newId,
      oldEventId: this.selectedCalculationIds.oldId,
    });
  }
}
