import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserPublicI } from 'src/app/core/models/user/user-resource.interface';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss'],
})
export class UserDetailsComponent implements OnInit, OnDestroy {
  @Input()
  user: UserPublicI;

  @Input()
  editDetails: boolean;

  @Input()
  headerTranslation: string;

  @Input()
  blockedTranslation: string;

  @Input()
  resendTranslation: string;

  @Output()
  resendInvitation: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  enableEditUser: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  editUser: EventEmitter<UserPublicI> = new EventEmitter<UserPublicI>();

  confirmationKey = 'administration.users.resendInvitationConfirmation';

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {}

  onResendInvitation(userId: string) {
    const confirmationText = this.translateService.instant(
      this.confirmationKey
    );

    if (confirm(confirmationText)) {
      this.resendInvitation.emit(userId);
    }
  }

  onEditUser() {
    this.enableEditUser.emit(true);
  }

  onDiscard() {
    this.disableEdit();
  }

  onSave(userPublic: UserPublicI) {
    this.editUser.emit(userPublic);
    this.disableEdit();
  }

  private disableEdit() {
    this.enableEditUser.emit(false);
  }

  ngOnDestroy(): void {
    this.disableEdit();
  }
}
