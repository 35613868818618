import { createSelector } from '@ngrx/store';

import { SelectI } from 'src/app/core/models/select.interface';
import { getSelectedLanguageSelector } from 'src/app/core/store/selectors/language.selectors';
import {
  mapDictionaryToSelectForGivenLanguage,
  mapSelectArrayToSelectMap,
} from 'src/app/core/utils/dictionary-utils';
import { DictionaryI } from 'src/app/dictionaries/models/dictionary.interface';
import { getClientAssignmentTypesStateSelector } from 'src/app/dictionaries/store/index';
import {
  getAllClientAssignmentTypes,
  getClientAssignmentTypesEntities,
  getClientAssignmentTypesError,
  getClientAssignmentTypesIds,
  getClientAssignmentTypesLoaded,
  getClientAssignmentTypesLoading,
  getClientAssignmentTypesTotal,
} from 'src/app/dictionaries/store/reducers/client-assignment-types.reducer';

export const getClientAssignmentTypesIdsSelector = createSelector(
  getClientAssignmentTypesStateSelector,
  getClientAssignmentTypesIds
);

export const getClientAssignmentTypesEntitiesSelector = createSelector(
  getClientAssignmentTypesStateSelector,
  getClientAssignmentTypesEntities
);

export const getAllClientAssignmentTypesTypesSelector = createSelector(
  getClientAssignmentTypesStateSelector,
  getAllClientAssignmentTypes
);

export const getClientAssignmentTypesTotalSelector = createSelector(
  getClientAssignmentTypesStateSelector,
  getClientAssignmentTypesTotal
);

export const getClientAssignmentTypesLoadingSelector = createSelector(
  getClientAssignmentTypesStateSelector,
  getClientAssignmentTypesLoading
);

export const getClientAssignmentTypesLoadedSelector = createSelector(
  getClientAssignmentTypesStateSelector,
  getClientAssignmentTypesLoaded
);

export const getClientAssignmentTypesErrorSelector = createSelector(
  getClientAssignmentTypesStateSelector,
  getClientAssignmentTypesError
);

// Custom Selectors

export const getAllClientAssignmentTypesAsSelectObjectForCurrentLanguageSelector = createSelector(
  getAllClientAssignmentTypesTypesSelector,
  getSelectedLanguageSelector,
  (ClientAssignmentTypes: DictionaryI[], selectedLanguage: string): SelectI[] =>
    mapDictionaryToSelectForGivenLanguage(
      ClientAssignmentTypes,
      selectedLanguage
    )
);

export const getAllClientAssignmentTypesAsMapForCurrentLanguageSelector = createSelector(
  getAllClientAssignmentTypesAsSelectObjectForCurrentLanguageSelector,
  (selectIList: SelectI[]) => mapSelectArrayToSelectMap(selectIList)
);
