import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AssignmentStatusEnum } from 'src/app/core/enums/assignment-status.enum';
import { getStatusToIcon } from 'src/app/core/utils/status-to-icon';
import { MyActionI } from 'src/app/core/models/user/start-page.interface';

@Component({
  selector: 'app-my-actions-card-header',
  templateUrl: './my-actions-card-header.component.html',
  styleUrls: ['./my-actions-card-header.component.scss'],
})
export class MyActionsCardHeaderComponent implements OnInit {
  @Input()
  action: MyActionI;

  @Output()
  goToDetails: EventEmitter<string> = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {}

  getColour(type: AssignmentStatusEnum): { icon: string; colour: string } {
    return getStatusToIcon(type);
  }

  onGoToDetails(assignmentId: string) {
    this.goToDetails.emit(assignmentId);
  }
}
