import {
  SideBar,
  SideBarActions,
} from 'src/app/core/store/actions/side-bar.action';

export interface SideBarReducerState {
  show: boolean;
}

export const initialSideBarReducerState: SideBarReducerState = {
  show: false,
};

export function sideBarReducer(
  state: SideBarReducerState = initialSideBarReducerState,
  action: SideBarActions
): SideBarReducerState {
  switch (action.type) {
    case SideBar.OpenSideBar: {
      return {
        show: true,
      };
    }
    case SideBar.CloseSideBar: {
      return {
        show: false,
      };
    }

    default: {
      return state;
    }
  }
}

export const getShowSideBar = (state: SideBarReducerState) => state.show;
