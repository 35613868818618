<div class="modal-template">
  <div class="modal-template__header">
    <mat-icon *ngIf="!!icon" class="modal-template__header-icon">{{
      icon
    }}</mat-icon>
    <span class="modal-template__header-title" [translate]="header"></span>
    <span
      class="modal-template__header-close"
      (click)="onCloseModal()"
      [translate]="'close'"
    ></span>
  </div>

  <ng-content></ng-content>

  <div class="modal-template__bottom">
    <span
      class="btn-discard"
      (click)="onCloseModal()"
      [translate]="'discard'"
    ></span>
    <button
      type="button"
      class="btn btn-primary btn-secondary btn-save"
      (click)="onSubmit()"
      [translate]="button"
    ></button>
  </div>
</div>
