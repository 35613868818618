import { Component, OnDestroy, OnInit } from '@angular/core';
import { RouterState } from '@ngrx/router-store';
import { Action, select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable } from 'rxjs';
import { AssignmentPhaseEnum } from 'src/app/core/enums/assignment-phase.enum';
import {
  AssignmentUrlEnum,
  CoreUrlEnums,
  NumbersUrlEnum,
} from 'src/app/core/enums/url-paths.enum';
import {
  HomePageChartClickedAnalytics,
  HomePageNavigationClickedAnalytics,
} from 'src/app/core/store/actions/google-analytics.actions';
import { RouterGo } from 'src/app/core/store/actions/router.action';
import { StoreDispatcher } from 'src/app/shared/base-classes/store-dispatcher/store-dispatcher';
import {
  MyActionI,
  WatchListItemI,
} from 'src/app/core/models/user/start-page.interface';
import { ChartResultI } from 'src/app/core/models/log/chart-result.interface';
import {
  getStartPageAggregatedVolumeChartSelector,
  getStartPageMyActionsSelector,
  getStartPagePhaseChartSelector,
  getStartPageShowMobileActionSelector,
  getStartPageWatchListSelector,
} from 'src/app/core/store/selectors/start-page.selectors';
import { filter, map } from 'rxjs/operators';
import {
  CleanStartPageState,
  ShowStartPageMobileActions,
} from 'src/app/core/store/actions/start-page.action';
import { PageSizeService } from 'src/app/core/services/page-size/page-size.service';
import getNumbersUrl = NumbersUrlEnum.getNumbersUrl;
import getAssignmentUrl = AssignmentUrlEnum.getAssignmentUrl;
import getAssignmentListAnalysisUrl = AssignmentUrlEnum.getAssignmentListAnalysisUrl;

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
})
export class OverviewComponent
  extends StoreDispatcher<any>
  implements OnInit, OnDestroy {
  phaseChart$: Observable<ChartResultI[]>;
  aggregatedVolumeChart$: Observable<ChartResultI[]>;
  myActions$: Observable<MyActionI[]>;
  watchlist$: Observable<WatchListItemI[]>;

  showMobileAction$: Observable<MyActionI>;

  constructor(
    store: Store<RouterState>,
    private translateService: TranslateService,
    public pageSizeService: PageSizeService
  ) {
    super(store);
  }

  ngOnInit(): void {
    this.getTranslatedPhaseChart();
  }

  private getTranslatedPhaseChart() {
    this.myActions$ = this.store.pipe(select(getStartPageMyActionsSelector));
    this.watchlist$ = this.store.pipe(select(getStartPageWatchListSelector));

    this.phaseChart$ = combineLatest([
      this.translateService.stream('phases'),
      this.store.pipe(select(getStartPagePhaseChartSelector)),
    ]).pipe(
      filter(([translations, phaseChart]) => !!phaseChart),
      map(([translations, phaseChart]) => {
        return phaseChart.map((element) => {
          return {
            ...element,
            name: translations[element.name],
          };
        });
      })
    );

    this.aggregatedVolumeChart$ = this.store.pipe(
      select(getStartPageAggregatedVolumeChartSelector)
    );

    this.showMobileAction$ = this.store.pipe(
      select(getStartPageShowMobileActionSelector)
    );
  }

  goToWatchlist() {
    const url = getAssignmentUrl(AssignmentUrlEnum.WATCH_LIST);
    const gaAction = new HomePageNavigationClickedAnalytics(url);
    const action = new RouterGo({
      path: [url],
    });

    this.dispatchActions([action, gaAction]);
  }

  onBrowseVolume() {
    const url = getNumbersUrl(CoreUrlEnums.NUMBERS);
    const gaAction = new HomePageNavigationClickedAnalytics(url);
    const action = new RouterGo({
      path: [url],
    });
    this.dispatchActions([action, gaAction]);
  }

  onBrowseWatchlist() {
    const url = getAssignmentUrl(AssignmentUrlEnum.WATCH_LIST);
    const gaAction = new HomePageNavigationClickedAnalytics(url);
    const action = new RouterGo({
      path: [url],
    });
    this.dispatchActions([action, gaAction]);
  }

  onBrowseAssignments() {
    const url = getAssignmentUrl(AssignmentUrlEnum.ASSIGNMENT_LIST);
    const action = new RouterGo({
      path: [url],
    });
    const gaAction = new HomePageNavigationClickedAnalytics(url);
    this.dispatchActions([action, gaAction]);
  }

  onGoToDetails(assignmentId: string) {
    const url = getAssignmentListAnalysisUrl(
      AssignmentUrlEnum.ASSIGNMENT,
      assignmentId
    );
    const gaAction = new HomePageNavigationClickedAnalytics(
      // To not send assignment id to GA
      getAssignmentListAnalysisUrl(AssignmentUrlEnum.ASSIGNMENT, '')
    );
    const action = new RouterGo({
      path: [url],
    });
    this.dispatchActions([action, gaAction]);
  }

  onMakeAction(action: Action) {
    this.dispatchAction(action);
  }

  onGoToAssignments() {
    const url = getAssignmentUrl(AssignmentUrlEnum.ASSIGNMENT_LIST);
    const action = new RouterGo({
      path: [url],
    });
    const gaAction = new HomePageNavigationClickedAnalytics(url);
    this.dispatchActions([action, gaAction]);
  }

  onGoToPhase(phase: AssignmentPhaseEnum) {
    const gaAction = new HomePageChartClickedAnalytics(
      getAssignmentUrl(AssignmentUrlEnum.ASSIGNMENT_LIST) + phase
    );
    const action = new RouterGo({
      path: [getAssignmentUrl(AssignmentUrlEnum.ASSIGNMENT_LIST)],
      query: { phase },
    });

    this.dispatchActions([action, gaAction]);
  }

  onOpenMobileActions(myAction: MyActionI) {
    this.dispatchAction(new ShowStartPageMobileActions(myAction));
  }

  ngOnDestroy(): void {
    // TODO: when we will add pusher consider to remove this logic
    // and reload overview page only if we get new event
    this.dispatchAction(new CleanStartPageState());
  }
}
