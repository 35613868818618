<app-user-details
  [user]="user$ | async"
  [editDetails]="enableEdit$ | async"
  [blockedTranslation]="'accountDetails.blocked'"
  [headerTranslation]="'accountDetails.header'"
  [resendTranslation]="'accountDetails.resend'"
  (editUser)="onEditUser($event)"
  (resendInvitation)="onResendInvitation($event)"
  (enableEditUser)="onEnableEdit($event)"
></app-user-details>
