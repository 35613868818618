import { Action } from '@ngrx/store';
import {
  HideLoader,
  ShowLoader,
} from 'src/app/core/decorators/loader.decorator';
import { DecisionRequestI } from 'src/app/core/models/decision/decision-request.interface';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';

export enum DecisionActionTypes {
  MakeDecision = '[Decision] Make Decision',
  MadeDecisionSuccess = '[Decision] Made Decision Success',
  MadeDecisionFail = '[Decision] Made Decision Fail',

  CleanDecisionState = '[Decision] Clean',
}

// ------ Make Decision ------

@ShowLoader()
export class MakeDecision implements Action {
  readonly type = DecisionActionTypes.MakeDecision;

  constructor(public payload: DecisionRequestI) {}
}

@HideLoader(DecisionActionTypes.MakeDecision)
export class MadeDecisionSuccess implements Action {
  readonly type = DecisionActionTypes.MadeDecisionSuccess;

  constructor(public payload: string) {}
}

@HideLoader(DecisionActionTypes.MakeDecision)
export class MadeDecisionFail implements Action {
  readonly type = DecisionActionTypes.MadeDecisionFail;

  constructor(public payload: ErrorResponseWithIdI) {}
}

export class CleanDecisionState implements Action {
  readonly type = DecisionActionTypes.CleanDecisionState;

  constructor(public payload?: any) {}
}

export type DecisionActions =
  | MakeDecision
  | MadeDecisionSuccess
  | MadeDecisionFail
  | CleanDecisionState;
