<div [formGroup]="formGroup">
  <div class="form-row">
    <div class="form-group col-12 col-md-6">
      <label
        for="create-assignment-Name"
        [translate]="'create.formControls.assignmentName'"
      ></label>

      <span
        class="form-control-mandatory"
        *ngIf="isRequired('assignmentName')"
        [translate]="'mandatory'"
      ></span>

      <input
        id="create-assignment-Name"
        formControlName="assignmentName"
        type="text"
        class="form-control"
        [ngClass]="{
          'is-invalid': isValid(formGroup.controls['assignmentName'])
        }"
      />

      <div
        class="invalid-feedback"
        *ngIf="formGroup.controls['assignmentName']?.invalid"
        [translate]="'create.errorMessages.assignmentName'"
      ></div>
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col-12 col-md-6">
      <label
        for="create-assignment-type"
        [translate]="'create.formControls.clientAssignmentType'"
      ></label>

      <span
        class="form-control-mandatory"
        *ngIf="isRequired('clientAssignmentType')"
        [translate]="'mandatory'"
      ></span>

      <select
        id="create-assignment-type"
        formControlName="clientAssignmentType"
        class="custom-select"
        [ngClass]="{
          'is-invalid': isValid(formGroup.controls['clientAssignmentType'])
        }"
      >
        <option
          value=""
          selected
          disabled
          [translate]="'common.select'"
        ></option>
        <option
          *ngFor="let clientAssignmentType of clientAssignmentTypes"
          [value]="clientAssignmentType.id"
        >
          {{ clientAssignmentType.value }}
        </option>
      </select>

      <div
        class="invalid-feedback"
        *ngIf="formGroup.controls['clientAssignmentType']?.invalid"
        [translate]="'create.errorMessages.clientAssignmentType'"
      ></div>
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col-12 col-md-6">
      <label
        for="create-external-project-id"
        [translate]="'create.formControls.externalProjectId'"
      ></label>

      <span
        class="form-control-mandatory"
        *ngIf="isRequired('externalProjectId')"
        [translate]="'mandatory'"
      ></span>

      <input
        id="create-external-project-id"
        formControlName="externalProjectId"
        type="text"
        class="form-control"
      />
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col-12 col-md-6">
      <label
        for="create-external-assignment-id"
        [translate]="'create.formControls.externalAssignmentId'"
      ></label>

      <span
        class="form-control-mandatory"
        *ngIf="isRequired('externalAssignmentId')"
        [translate]="'mandatory'"
      ></span>

      <input
        id="create-external-assignment-id"
        formControlName="externalAssignmentId"
        type="text"
        class="form-control"
      />
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col-12 col-md-3">
      <label
        for="create-start-date"
        [translate]="'create.formControls.startDate'"
      ></label>

      <span
        class="form-control-mandatory"
        *ngIf="isRequired('startDate')"
        [translate]="'mandatory'"
      ></span>

      <input
        id="create-start-date"
        formControlName="startDate"
        class="form-control"
        autocomplete="off"
        placeholder="XXXX/XX/XX"
        [bsConfig]="bsConfig"
        [maxDate]="formGroup.get('endDate').value"
        bsDatepicker
        [ngClass]="{
          'is-invalid': isValid(formGroup.controls['startDate'])
        }"
      />

      <div
        class="invalid-feedback"
        *ngIf="formGroup.controls['startDate']?.invalid"
        [translate]="'create.errorMessages.invalidDate'"
      ></div>
    </div>

    <div class="form-group col-12 col-md-3">
      <label
        for="create-end-date"
        [translate]="'create.formControls.endDate'"
      ></label>

      <span
        class="form-control-mandatory"
        *ngIf="isRequired('endDate')"
        [translate]="'mandatory'"
      ></span>

      <input
        id="create-end-date"
        formControlName="endDate"
        class="form-control"
        autocomplete="off"
        placeholder="XXXX/XX/XX"
        [bsConfig]="bsConfig"
        bsDatepicker
        [minDate]="formGroup.get('startDate').value"
        [ngClass]="{
          'is-invalid': isValid(formGroup.controls['endDate'])
        }"
      />

      <div
        class="invalid-feedback"
        *ngIf="formGroup.controls['endDate']?.invalid"
        [translate]="'create.errorMessages.invalidDate'"
      ></div>
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col-12 col-md-3">
      <label
        for="create-budget"
        [translate]="'create.formControls.budget'"
      ></label>

      <span
        class="form-control-mandatory"
        *ngIf="isRequired('budget')"
        [translate]="'mandatory'"
      ></span>

      <input
        id="create-budget"
        formControlName="budget"
        class="form-control"
        autocomplete="off"
      />
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col-12">
      <label
        for="create-scope"
        [translate]="'create.formControls.scope'"
      ></label>

      <span
        class="form-control-mandatory"
        *ngIf="isRequired('scope')"
        [translate]="'mandatory'"
      ></span>

      <textarea
        id="create-scope"
        formControlName="scope"
        class="form-control"
        rows="3"
        [ngClass]="{
          'is-invalid': isValid(formGroup.controls['scope'])
        }"
      >
      </textarea>

      <div
        class="invalid-feedback"
        *ngIf="formGroup.controls['scope']?.invalid"
        [translate]="'create.errorMessages.scope'"
      ></div>
    </div>
  </div>
</div>
