import { Component, Input, OnInit } from '@angular/core';
import { BaseFormComponent } from 'src/app/shared/components/base-form/base-form.component';
import { InviteContractorFormI } from 'src/app/core/modals/models/invite-contractor-form.interface';
import { SelectI } from 'src/app/core/models/select.interface';

@Component({
  selector: 'app-invite-form',
  templateUrl: './invite-form.component.html',
  styleUrls: ['./invite-form.component.scss'],
})
export class InviteFormComponent
  extends BaseFormComponent<InviteContractorFormI>
  implements OnInit {
  @Input()
  contractors: SelectI[] = [];
  @Input()
  assignmentTypes: SelectI[] = [];
}
