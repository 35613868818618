import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import Auth0Lock from 'auth0-lock';
import { BehaviorSubject, Observable, of, timer } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { AuthTokenEnum } from 'src/app/auth/enums/auth-token.enum';
import {
  LoggedIn,
  LoggedOut,
  Logout,
  RefreshToken,
} from 'src/app/auth/store/actions/auth.action';
import { LanguageEnum } from 'src/app/core/enums/language.enum';
import { ApplicationState } from 'src/app/core/store';
import { enLock } from 'src/assets/auth0-dict/en-dict';
import { svLock } from 'src/assets/auth0-dict/sv-dict';
import { Settings } from '../../../core/utils/settings';

@Injectable()
export class AuthorizationService {
  private tokenSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public doesTokenExists$: Observable<boolean> = this.tokenSubject$.asObservable();

  lockTitle = 'UnityWorks';

  private auth0Options = {
    container: 'login-auth0lock',
    auth: {
      sso: false,
      redirect: false,
      audience: Settings.appSettings.auth0.audience,
      responseType: Settings.appSettings.auth0.responseType,
      params: {
        scope: 'openid email',
      },
    },
    theme: {
      primaryColor: '#1992D4',
      logo: './assets/img/logo-64.png',
    },
    avatar: null,
  };

  private lock = new Auth0Lock(
    Settings.appSettings.auth0.clientID,
    Settings.appSettings.auth0.domain,
    this.auth0Options
  );

  refreshTokenSubscription: any;

  constructor(public router: Router, private store: Store<ApplicationState>) {
    this.lock.on('authenticated', (authResult: AuthResult) => {
      this.handleAuthentication(authResult);
    });

    this.scheduleRenewal();
  }

  private handleAuthentication(authResult: AuthResult): void {
    this.storeToken(authResult);
    this.hideLoginPanel();

    this.store.dispatch(new LoggedIn());
  }

  private storeToken(authResult: AuthResult): void {
    this.setToken(authResult.accessToken);
    this.setExpireAt(authResult.expiresIn);

    this.scheduleRenewal();
  }

  showLoginPanel(language: string): void {
    this.hideLoginPanel();

    switch (language) {
      case LanguageEnum.SE: {
        this.lock.show({
          languageDictionary: {
            ...svLock,
            title: this.lockTitle,
          },
        });
        break;
      }
      case LanguageEnum.EN: {
        this.lock.show({
          languageDictionary: {
            ...enLock,
            title: this.lockTitle,
          },
        });
        break;
      }
      default: {
        console.log('no language selected');
        this.lock.show();
        break;
      }
    }
  }

  hideLoginPanel() {
    this.lock.hide();
  }

  logout(): void {
    this.removeTokenInfo();

    this.unscheduleRenewal();
    this.store.dispatch(new LoggedOut());
  }

  renewToken() {
    this.lock.checkSession({}, (err, result) => {
      if (err) {
        console.log(err);
        this.store.dispatch(new Logout());
      } else {
        this.storeToken(result);
      }
    });
  }

  private scheduleRenewal() {
    if (!this.isTokenValid()) {
      return;
    }

    this.unscheduleRenewal();
    this.refreshTokenSubscription = this.refreshTokenObservable().subscribe(
      () => this.store.dispatch(new RefreshToken())
    );
  }

  private refreshTokenObservable() {
    return of(this.getExpireAt()).pipe(
      mergeMap((expireAt) => {
        const now = Date.now();
        return timer(Math.max(1, expireAt - 10 - now));
      })
    );
  }

  private unscheduleRenewal() {
    if (this.refreshTokenSubscription) {
      this.refreshTokenSubscription.unsubscribe();
    }
  }

  isTokenValid(): boolean {
    const token = this.getToken();
    const isTokenExpired = this.isTokenExpired();

    return token != null && !isTokenExpired;
  }

  isTokenExpired(): boolean {
    const expiresAt = this.getExpireAt();
    const currentTime = new Date().getTime();

    return currentTime > expiresAt;
  }

  private setExpireAt(expiresIn: number): void {
    localStorage.setItem(
      AuthTokenEnum.EXPIRES_AT,
      JSON.stringify(expiresIn * 1000 + Date.now())
    );
  }

  private setToken(token: string): void {
    localStorage.setItem(AuthTokenEnum.ACCESS_TOKEN, token);
    this.emitDoseTokenExists(token);
  }

  private getExpireAt(): number {
    return JSON.parse(localStorage.getItem(AuthTokenEnum.EXPIRES_AT));
  }

  private getToken(): string {
    const token = localStorage.getItem(AuthTokenEnum.ACCESS_TOKEN);
    this.emitDoseTokenExists(token);
    return token;
  }

  private emitDoseTokenExists(token?: string): void {
    !!token ? this.tokenSubject$.next(true) : this.tokenSubject$.next(false);
  }

  private removeTokenInfo() {
    localStorage.removeItem(AuthTokenEnum.EXPIRES_AT);
    localStorage.removeItem(AuthTokenEnum.ACCESS_TOKEN);
    sessionStorage.clear();
    this.emitDoseTokenExists();
  }
}
