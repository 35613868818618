<div class="page-header" [ngClass]="{ 'page-header-border': border }">
  <span class="page-header__main">
    <span class="page-header__text" *ngIf="header" [translate]="header"> </span>
    <span class="page-header__sub-text">
      <ng-content select="[slot=sub-text]"></ng-content>
    </span>
  </span>
  <span class="page-header__mid">
    <ng-content select="[slot=mid]"></ng-content>
  </span>
  <span class="page-header__content">
    <ng-content select="[slot=content]"></ng-content>
  </span>
</div>
