import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { IdNameResourceI } from 'src/app/core/models/property-register/id-name-resource.interface';

function sortComparer(a: IdNameResourceI, b: IdNameResourceI): number {
  return a.name.localeCompare(b.name);
}

const selectId = (idNameResource: IdNameResourceI) => idNameResource.id;

export const idNameResourceAdapter: EntityAdapter<
  IdNameResourceI
> = createEntityAdapter<IdNameResourceI>({ selectId, sortComparer });
