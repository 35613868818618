import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import {
  CleanDecisionState,
  DecisionActionTypes,
  MadeDecisionFail,
  MadeDecisionSuccess,
  MakeDecision,
} from 'src/app/core/store/actions/decision.action';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import { AssignmentEventService } from 'src/app/core/services/api/assignment-event/assignment-event.service';
import { AssignmentsService } from 'src/app/core/services/api/assignments/assignments.service';
import { select, Store } from '@ngrx/store';
import { getRouterUrlWithSlashAtBeginningAndQueryParamsSelector } from 'src/app/core/store/selectors/router.selectors';
import {
  AssignmentUrlEnum,
  CoreUrlEnums,
} from 'src/app/core/enums/url-paths.enum';
import { LoadStartPage } from 'src/app/core/store/actions/start-page.action';
import { LoadAssignment } from 'src/app/core/store/actions/assignments.action';

@Injectable()
export class DecisionEffects {
  constructor(
    private actions$: Actions,
    private assignmentEventService: AssignmentEventService,
    private assignmentService: AssignmentsService,
    private store$: Store
  ) {}

  public makeDecision$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DecisionActionTypes.MakeDecision),
      switchMap((action: MakeDecision) =>
        this.assignmentService.makeDecision(action.payload).pipe(
          map(() => new MadeDecisionSuccess(action.payload.assignmentId)),
          catchError((error: ErrorResponseWithIdI) =>
            of(new MadeDecisionFail(error))
          )
        )
      )
    )
  );

  public handleMakeDecisionSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DecisionActionTypes.MadeDecisionSuccess),
      withLatestFrom(
        this.store$.pipe(
          select(getRouterUrlWithSlashAtBeginningAndQueryParamsSelector)
        )
      ),
      filter(
        ([action, url]: [MadeDecisionSuccess, string]) =>
          CoreUrlEnums.OVERVIEW.includes(url) ||
          url.includes(
            AssignmentUrlEnum.ANALYSIS +
              '/' +
              AssignmentUrlEnum.ASSIGNMENT +
              '/' +
              action.payload
          )
      ),
      switchMap(([action, url]: [MadeDecisionSuccess, string]) => {
        if (CoreUrlEnums.OVERVIEW.includes(url)) {
          return of(new LoadStartPage());
        }

        if (
          url.includes(
            AssignmentUrlEnum.ANALYSIS +
              '/' +
              AssignmentUrlEnum.ASSIGNMENT +
              '/' +
              action.payload
          )
        ) {
          return of(new LoadAssignment(action.payload));
        }
      })
    )
  );

  public handleDecisionFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DecisionActionTypes.MadeDecisionFail),
      map(() => new CleanDecisionState())
    )
  );
}
