import { Action } from '@ngrx/store';
import {
  HideLoader,
  ShowLoader,
} from 'src/app/core/decorators/loader.decorator';
import { AnalysisAssignmentI } from 'src/app/core/models/analysis-assignment/analysis-assignment.interface';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import { AssignmentEventI } from 'src/app/core/models/assignment/assignment-event.interface';

export enum AnalysisAssignmentActionTypes {
  LoadAnalysisAssignment = '[AnalysisAssignment] Load Analysis Assignment',
  LoadedAnalysisAssignmentSuccess = '[AnalysisAssignment] Loaded Analysis Assignment',
  LoadedAnalysisAssignmentFail = '[AnalysisAssignment] Fail To Load Analysis Assignment',

  LoadAnalysisAssignmentEvents = '[AnalysisAssignment] Load Analysis Assignment Events',
  LoadedAnalysisAssignmentEventsSuccess = '[AnalysisAssignment] Loaded Analysis Assignment Events',
  LoadedAnalysisAssignmentEventsFail = '[AnalysisAssignment] Fail To Load Analysis Assignment Events',

  CleanAnalysisAssignmentState = '[AnalysisAssignment] Clean Analysis Assignment',
  CleanAnalysisAssignmentEventState = '[AnalysisAssignment] Clean Analysis Assignment Event',
}

@ShowLoader()
export class LoadAnalysisAssignment implements Action {
  readonly type = AnalysisAssignmentActionTypes.LoadAnalysisAssignment;

  constructor(public payload: string) {}
}

@HideLoader(AnalysisAssignmentActionTypes.LoadAnalysisAssignment)
export class LoadedAnalysisAssignmentSuccess implements Action {
  readonly type = AnalysisAssignmentActionTypes.LoadedAnalysisAssignmentSuccess;

  constructor(public payload: AnalysisAssignmentI) {}
}

@HideLoader(AnalysisAssignmentActionTypes.LoadAnalysisAssignment)
export class LoadedAnalysisAssignmentFail implements Action {
  readonly type = AnalysisAssignmentActionTypes.LoadedAnalysisAssignmentFail;

  constructor(public payload: ErrorResponseWithIdI) {}
}

@ShowLoader()
export class LoadAnalysisAssignmentEvents implements Action {
  readonly type = AnalysisAssignmentActionTypes.LoadAnalysisAssignmentEvents;

  constructor(public payload: string) {}
}

@HideLoader(AnalysisAssignmentActionTypes.LoadAnalysisAssignmentEvents)
export class LoadedAnalysisAssignmentEventsSuccess implements Action {
  readonly type =
    AnalysisAssignmentActionTypes.LoadedAnalysisAssignmentEventsSuccess;

  constructor(public payload: AssignmentEventI[]) {}
}

@HideLoader(AnalysisAssignmentActionTypes.LoadAnalysisAssignmentEvents)
export class LoadedAnalysisAssignmentEventsFail implements Action {
  readonly type =
    AnalysisAssignmentActionTypes.LoadedAnalysisAssignmentEventsFail;

  constructor(public payload: ErrorResponseWithIdI) {}
}

export class CleanAnalysisAssignmentState implements Action {
  readonly type = AnalysisAssignmentActionTypes.CleanAnalysisAssignmentState;
}

export class CleanAnalysisAssignmentEventState implements Action {
  readonly type =
    AnalysisAssignmentActionTypes.CleanAnalysisAssignmentEventState;
}

export type AnalysisAssignmentActions =
  | LoadAnalysisAssignment
  | LoadedAnalysisAssignmentSuccess
  | LoadedAnalysisAssignmentFail
  | LoadAnalysisAssignmentEvents
  | LoadedAnalysisAssignmentEventsSuccess
  | LoadedAnalysisAssignmentEventsFail
  | CleanAnalysisAssignmentState
  | CleanAnalysisAssignmentEventState;
