import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChildren,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { MakeDecision } from 'src/app/core/store/actions/decision.action';
import { BaseTemplateComponent } from 'src/app/shared/components/base-template/base-template.component';
import {
  getApprovedDecisionNameSelector,
  getDisprovedDecisionNameSelector,
} from 'src/app/dictionaries/store/selectors/decision-types.selectors';
import { AssignmentPartI } from 'src/app/core/models/assignment/assignment-part.interface';
import { WarningI } from 'src/app/core/models/warning.interface';

@Component({
  selector: 'app-make-decision-template',
  templateUrl: './make-decision-template.component.html',
  styleUrls: ['./make-decision-template.component.scss'],
})
export class MakeDecisionTemplateComponent
  extends BaseTemplateComponent
  implements OnInit {
  formGroup: FormGroup;
  submitted = false;

  decisionTypeApproved: string;
  decisionTypeDisproved: string;

  assignmentParts: AssignmentPartI[];
  warnings: WarningI[];

  assignmentId: string;
  assignmentEventId: string;

  @ViewChildren('checkbox')
  vc;

  @HostListener('document:click', ['$event'])
  clickout(event) {
    this.checkIfShouldCloseModal(event);
  }

  constructor(
    store: Store<any>,
    eRef: ElementRef,
    private formBuilder: FormBuilder,
    private translateService: TranslateService
  ) {
    super(store, eRef);
  }

  ngOnInit() {
    this.createFormGroup();

    this.createCheckboxesFromAssignmentParts();
    this.getDecisionTypes();
  }

  private createFormGroup() {
    this.formGroup = this.formBuilder.group({
      decision: ['', Validators.required],
      blame: new FormArray([]),
      comment: '',
    });
  }

  private getDecisionTypes(): void {
    this.store
      .pipe(
        select(getApprovedDecisionNameSelector),
        map((decisionType) => decisionType && decisionType[0]),
        take(1)
      )
      .subscribe((x) => (this.decisionTypeApproved = x));

    this.store
      .pipe(
        select(getDisprovedDecisionNameSelector),
        map((decisionType) => decisionType && decisionType[0], take(1))
      )
      .subscribe((x) => (this.decisionTypeDisproved = x));
  }

  private createCheckboxesFromAssignmentParts() {
    (this.assignmentParts || []).forEach((assignmentPart) => {
      (this.formGroup.controls.blame as FormArray).push(new FormControl(false));
    });
  }

  send(): void {
    this.submitted = true;

    const confirmationText: string = this.translateService.instant(
      'groups.decision.confirmationText'
    );

    const action: MakeDecision = new MakeDecision({
      assignmentId: this.assignmentId,
      decisionResource: {
        assignmentEventId: this.assignmentEventId,
        decision: this.formGroup.value.decision,
        comment: this.formGroup.value.comment,
        blamedPartyNames: this.getSelectedAssignmentParts(),
      },
    });

    if (this.formGroup.valid) {
      if (confirm(confirmationText)) {
        this.dispatchActionAndCloseModal(action);
      }
    }
  }

  getSelectedAssignmentParts(): string[] {
    return this.vc &&
      this.decisionTypeDisproved === this.formGroup.controls.decision.value
      ? this.vc
          .filter(
            (element) => element.nativeElement && element.nativeElement.checked
          )
          .map((element) => element.nativeElement.value)
      : [];
  }

  canDeactivate(): boolean | Observable<boolean> {
    return !this.formGroup.dirty || this.submitted;
  }
}
