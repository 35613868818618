import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { AssignmentPhaseEnum } from 'src/app/core/enums/assignment-phase.enum';
import { AssignmentTableTypeEnum } from 'src/app/core/enums/assignment-table-type.enum';
import { AssignmentI } from 'src/app/core/models/assignment/assignment.interface';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import { TableSortOptionI } from 'src/app/core/models/table-sort-option';
import {
  AssignmentsActions,
  AssignmentsActionTypes,
} from 'src/app/core/store/actions/assignments.action';
import { enumValues } from 'src/app/core/utils/enum-values';
import { TablePaginationI } from 'src/app/shared/models/pagination.interface';
import {
  PusherActions,
  PusherActionTypes,
} from 'src/app/core/store/actions/pusher.action';
import { AssignmentUserEnum } from 'src/app/core/enums/assignment-user.enum';
import { AssignmentSearchTypeEnum } from 'src/app/core/enums/assignment-search-type.enum';
import { SortEnum } from 'src/app/core/enums/sort.enum';

const selectId = (assignment: AssignmentI) => assignment.assignmentId;
export const adapter: EntityAdapter<AssignmentI> = createEntityAdapter<AssignmentI>(
  { selectId }
);

export interface AssignmentsReducerState extends EntityState<AssignmentI> {
  selectedAssignments: AssignmentI[];

  tableType: AssignmentTableTypeEnum;

  query: string;

  sorting: TableSortOptionI;

  userFilterOptions: AssignmentUserEnum[];
  userFilter: AssignmentUserEnum;

  phasesFilterOptions: AssignmentPhaseEnum[];
  phasesFilter: AssignmentPhaseEnum;

  searchTypeOptions: AssignmentSearchTypeEnum[];
  searchType: AssignmentSearchTypeEnum;

  pagination: TablePaginationI;

  showMobileFilter: boolean;
  showMobileSearchFilter: boolean;
  showMobileExport: boolean;

  mobileSortField: string;
  mobileSortOrderOptions: SortEnum[];

  requestedTime: number;
  total: number;

  loading: boolean;
  loaded: boolean;
  newVersionIsAvailable: boolean;
  error: ErrorResponseWithIdI;
}

export const initialAssignmentsReducerState: AssignmentsReducerState = adapter.getInitialState(
  {
    selectedAssignments: [],

    tableType: undefined,

    query: '',

    sorting: {
      field: 'lastEvent',
      ordering: SortEnum.DESC,
    },

    userFilterOptions: enumValues(AssignmentUserEnum),
    userFilter: null,

    phasesFilterOptions: enumValues(AssignmentPhaseEnum).filter(
      (x) => x !== AssignmentPhaseEnum.ARCHIVE
    ),
    phasesFilter: null,

    searchTypeOptions: enumValues(AssignmentSearchTypeEnum),
    searchType: AssignmentSearchTypeEnum.ALL,

    pagination: {
      page: 1,
      limit: 25,
      limitOptions: [10, 25, 50],
    },

    showMobileFilter: false,
    showMobileSearchFilter: false,
    showMobileExport: false,

    mobileSortField: 'lastEvent',
    mobileSortOrderOptions: enumValues(SortEnum),

    requestedTime: 0,
    total: 0,

    newVersionIsAvailable: false,
    loading: false,
    loaded: false,
    error: null,
  }
);

export function assignmentsReducer(
  state: AssignmentsReducerState = initialAssignmentsReducerState,
  action: AssignmentsActions | PusherActions
): AssignmentsReducerState {
  switch (action.type) {
    case PusherActionTypes.LoadPusherMessageSuccess:
      return {
        ...state,
        newVersionIsAvailable: true,
      };

    case AssignmentsActionTypes.LoadAssignmentsList: {
      return {
        ...initialAssignmentsReducerState,
        loading: true,
        query: state.query,
        phasesFilter: state.phasesFilter,
        userFilter: state.userFilter,
        searchType: state.searchType,
        selectedAssignments: [...state.selectedAssignments],
        tableType: state.tableType,
        pagination: { ...state.pagination },
        sorting: state.sorting,
      };
    }

    case AssignmentsActionTypes.LoadedAssignmentsListSuccess: {
      return adapter.upsertMany(<AssignmentI[]>action.payload.assignments, {
        ...state,
        requestedTime: action.payload.created,
        total: action.payload.numberFound,
        loading: false,
        loaded: true,
      });
    }

    case AssignmentsActionTypes.LoadAssignment: {
      return {
        ...state,
        loading: true,
      };
    }

    case AssignmentsActionTypes.ChangeAssignmentsPaginationLimit: {
      return {
        ...state,
        pagination: {
          ...initialAssignmentsReducerState.pagination,
          limit: action.payload,
        },
      };
    }

    case AssignmentsActionTypes.LoadedAssignmentSuccess: {
      return adapter.upsertOne(<AssignmentI>action.payload, {
        ...state,
        loading: false,
      });
    }

    case AssignmentsActionTypes.LoadedAssignmentFail:
    case AssignmentsActionTypes.LoadedAssignmentsListFail: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    case AssignmentsActionTypes.SetAssignmentsTableType: {
      return {
        ...initialAssignmentsReducerState,
        tableType: action.payload.tableType,
        phasesFilter: action.payload.phaseFilter,
        userFilter: action.payload.userFilter,
        searchType: action.payload.searchType,
        query: action.payload.query,
      };
    }

    case AssignmentsActionTypes.ChangeAssignmentsQuery: {
      return {
        ...state,
        query: action.payload,
        selectedAssignments: initialAssignmentsReducerState.selectedAssignments,
        pagination: {
          ...initialAssignmentsReducerState.pagination,
          limit: state.pagination.limit,
        },
      };
    }

    case AssignmentsActionTypes.ChangeAssignmentsPhaseFilter: {
      return {
        ...state,
        selectedAssignments: initialAssignmentsReducerState.selectedAssignments,
        phasesFilter: action.payload,
      };
    }

    case AssignmentsActionTypes.ChangeAssignmentsUserFilter: {
      return {
        ...state,
        selectedAssignments: initialAssignmentsReducerState.selectedAssignments,
        userFilter: action.payload,
      };
    }

    case AssignmentsActionTypes.ChangeAssignmentsSearchType: {
      return {
        ...state,
        selectedAssignments: initialAssignmentsReducerState.selectedAssignments,
        searchType: action.payload,
        showMobileSearchFilter: false,
      };
    }

    case AssignmentsActionTypes.ChangeAssignmentSorting: {
      return {
        ...state,
        sorting: action.payload,
      };
    }

    case AssignmentsActionTypes.ChangeAssignmentsPagination: {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: action.payload,
        },
      };
    }

    case AssignmentsActionTypes.ShowAssignmentMobileFilter: {
      return {
        ...state,
        showMobileFilter: action.payload,
      };
    }

    case AssignmentsActionTypes.ShowAssignmentMobileSearchFilter: {
      return {
        ...state,
        showMobileSearchFilter: action.payload,
      };
    }

    case AssignmentsActionTypes.ShowAssignmentMobileExport: {
      return {
        ...state,
        showMobileExport: action.payload,
      };
    }

    case AssignmentsActionTypes.ChangeAssignmentMobileStatusFilterAndSort: {
      return {
        ...state,
        showMobileFilter: false,
        sorting: action.payload.sort,
        phasesFilter: action.payload.filter,
      };
    }

    case AssignmentsActionTypes.ClearAssignmentMobileStatusFilterAndSort: {
      return {
        ...state,
        showMobileFilter: false,
        sorting: null,
        phasesFilter: null,
      };
    }

    case AssignmentsActionTypes.UpdateSelectedAssignments: {
      return {
        ...state,
        selectedAssignments: [...action.payload],
      };
    }

    case AssignmentsActionTypes.CleanAssignmentsState: {
      return {
        ...initialAssignmentsReducerState,
      };
    }

    default: {
      return state;
    }
  }
}

export const getSelectedAssignments = (state: AssignmentsReducerState) =>
  state.selectedAssignments;

export const isNewVersionIsAvailable = (state: AssignmentsReducerState) =>
  state.newVersionIsAvailable;
export const getAssignmentsLoading = (state: AssignmentsReducerState) =>
  state.loading;
export const getAssignmentsLoaded = (state: AssignmentsReducerState) =>
  state.loaded;
export const getAssignmentsError = (state: AssignmentsReducerState) =>
  state.error;

export const getAssignmentsUserFilter = (state: AssignmentsReducerState) =>
  state.userFilter;
export const getAssignmentsUserFilterOptions = (
  state: AssignmentsReducerState
) => state.userFilterOptions;

export const getAssignmentsPhaseFilter = (state: AssignmentsReducerState) =>
  state.phasesFilter;
export const getAssignmentsPhaseFilterOptions = (
  state: AssignmentsReducerState
) => state.phasesFilterOptions;

export const getAssignmentsSearchType = (state: AssignmentsReducerState) =>
  state.searchType;
export const getAssignmentsSearchTypeOptions = (
  state: AssignmentsReducerState
) => state.searchTypeOptions;

export const getAssignmentsTableType = (state: AssignmentsReducerState) =>
  state.tableType;
export const getAssignmentsPagination = (state: AssignmentsReducerState) =>
  state.pagination;

export const getAssignmentsShowMobileFilter = (
  state: AssignmentsReducerState
) => state.showMobileFilter;

export const getAssignmentsShowMobileSearchFilter = (
  state: AssignmentsReducerState
) => state.showMobileSearchFilter;

export const getAssignmentsShowMobileExport = (
  state: AssignmentsReducerState
) => state.showMobileExport;

export const getAssignmentMobileSortField = (state: AssignmentsReducerState) =>
  state.mobileSortField;
export const getAssignmentMobileSortOrderOptions = (
  state: AssignmentsReducerState
) => state.mobileSortOrderOptions;

export const getAssignmentSorting = (state: AssignmentsReducerState) =>
  state.sorting;

export const getAssignmentsRequestedTime = (state: AssignmentsReducerState) =>
  state.requestedTime;
export const getAllAssignmentsTotal = (state: AssignmentsReducerState) =>
  state.total;

export const getAssignmentQuery = (state: AssignmentsReducerState) =>
  state.query;
