import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import { PropertyDistrictI } from 'src/app/core/models/property-register/property-district.interface';
import { CleanPropertyAddress } from 'src/app/core/modules/start/store/actions/property-address.actions';
import { CleanPropertyBuilding } from 'src/app/core/modules/start/store/actions/property-building.actions';
import {
  LoadPropertyDistrictsFail,
  LoadPropertyDistrictsSuccess,
  PropertyDistrictActionTypes,
  PropertyDistrictsChanged,
} from 'src/app/core/modules/start/store/actions/property-district.actions';
import { CleanPropertyObject } from 'src/app/core/modules/start/store/actions/property-object.actions';
import {
  CleanPropertyRealEstate,
  LoadPropertyRealEstates,
} from 'src/app/core/modules/start/store/actions/property-real-estate.actions';
import { PropertyRegisterService } from 'src/app/core/services/api/property-register/property-register.service';

@Injectable()
export class PropertyDistrictEffects {
  constructor(
    private actions$: Actions,
    private propertyRegisterService: PropertyRegisterService
  ) {}

  public handleNewPropertyDistrict$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PropertyDistrictActionTypes.PropertyDistrictsChanged),
      switchMap((action: PropertyDistrictsChanged) => [
        new CleanPropertyRealEstate(),
        new CleanPropertyBuilding(),
        new CleanPropertyAddress(),
        new CleanPropertyObject(),
        new LoadPropertyRealEstates(action.payload),
      ])
    )
  );

  public getPropertyDistrict$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PropertyDistrictActionTypes.LoadPropertyDistricts),
      switchMap((_) =>
        this.propertyRegisterService.getDistrict().pipe(
          map(
            (propertyDistrict: PropertyDistrictI) =>
              new LoadPropertyDistrictsSuccess(propertyDistrict)
          ),
          catchError((error: ErrorResponseWithIdI) =>
            of(new LoadPropertyDistrictsFail(error))
          )
        )
      )
    )
  );
}
