<form novalidate class="mb-4 mt-4" [formGroup]="formGroup">
  <div class="form-row">
    <div class="form-group col-12 col-md-6">
      <label
        for="select-contractor"
        [translate]="'contractor.formControls.contractor'"
      ></label>

      <span
        class="form-control-mandatory"
        *ngIf="isRequired('agreementId')"
        [translate]="'mandatory'"
      ></span>

      <select
        id="select-contractor"
        formControlName="agreementId"
        class="custom-select"
        [ngClass]="{
          'is-invalid': isValid(formGroup.controls['agreementId'])
        }"
      >
        <option value="" selected disabled translate="common.select"></option>
        <option *ngFor="let contractor of contractors" [value]="contractor.id">
          {{ contractor.value }}
        </option>
      </select>

      <div
        class="invalid-feedback"
        *ngIf="formGroup.controls['agreementId']?.invalid"
        [translate]="'contractor.errorMessages.contractor'"
      ></div>
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col-12 col-md-6">
      <label
        for="select-assignment-type"
        [translate]="'contractor.formControls.assignmentType'"
      ></label>

      <span
        class="form-control-mandatory"
        *ngIf="isRequired('assignmentType')"
        [translate]="'mandatory'"
      ></span>

      <select
        id="select-assignment-type"
        formControlName="assignmentType"
        class="custom-select"
        [ngClass]="{
          'is-invalid': isValid(formGroup.controls['assignmentType'])
        }"
      >
        <option value="" selected disabled translate>common.select</option>
        <option
          *ngFor="let assignmentType of assignmentTypes"
          [value]="assignmentType.id"
        >
          {{ assignmentType.value }}
        </option>
      </select>

      <div
        class="invalid-feedback"
        *ngIf="formGroup.controls['assignmentType']?.invalid"
        [translate]="'contractor.errorMessages.assignmentType'"
      ></div>
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col-12 col-md-6">
      <label
        for="select-assignment-type"
        [translate]="'contractor.formControls.message'"
      ></label>

      <span
        class="form-control-mandatory"
        *ngIf="isRequired('message')"
        [translate]="'mandatory'"
      ></span>

      <input type="text" class="form-control" formControlName="message" />
    </div>
  </div>
</form>
