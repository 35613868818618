import { Component, Input, OnInit } from '@angular/core';
import { MyActionI } from 'src/app/core/models/user/start-page.interface';

@Component({
  selector: 'app-my-actions-card-content',
  templateUrl: './my-actions-card-content.component.html',
  styleUrls: ['./my-actions-card-content.component.scss'],
})
export class MyActionsCardContentComponent implements OnInit {
  @Input()
  action: MyActionI;

  constructor() {}

  ngOnInit(): void {}
}
