import { createSelector } from '@ngrx/store';
import { getDecisionTypesStateSelector } from 'src/app/dictionaries/store/index';
import {
  getAllDecisionTypes,
  getDecisionTypesEntities,
  getDecisionTypesError,
  getDecisionTypesIds,
  getDecisionTypesLoaded,
  getDecisionTypesLoading,
  getDecisionTypesTotal,
} from 'src/app/dictionaries/store/reducers/decision-types.reducer';
import { SelectI } from '../../../core/models/select.interface';
import { getSelectedLanguageSelector } from '../../../core/store/selectors/language.selectors';
import {
  mapDictionaryToSelectForGivenLanguage,
  mapSelectArrayToSelectMap,
} from '../../../core/utils/dictionary-utils';
import { DictionaryI } from '../../models/dictionary.interface';

export const getDecisionTypesIdsSelector = createSelector(
  getDecisionTypesStateSelector,
  getDecisionTypesIds
);

export const getDecisionTypesEntitiesSelector = createSelector(
  getDecisionTypesStateSelector,
  getDecisionTypesEntities
);

export const getAllDecisionTypesTypesSelector = createSelector(
  getDecisionTypesStateSelector,
  getAllDecisionTypes
);

export const getDecisionTypesTotalSelector = createSelector(
  getDecisionTypesStateSelector,
  getDecisionTypesTotal
);

export const getDecisionTypesLoadingSelector = createSelector(
  getDecisionTypesStateSelector,
  getDecisionTypesLoading
);

export const getDecisionTypesLoadedSelector = createSelector(
  getDecisionTypesStateSelector,
  getDecisionTypesLoaded
);

export const getDecisionTypesErrorSelector = createSelector(
  getDecisionTypesStateSelector,
  getDecisionTypesError
);

// Custom
export const getApprovedDecisionTypeIdsSelector = createSelector(
  getDecisionTypesEntitiesSelector,
  decisionTypesEntities => [
    decisionTypesEntities['approved'] && decisionTypesEntities['approved'].id,
  ]
);

export const getApprovedDecisionNameSelector = createSelector(
  getDecisionTypesEntitiesSelector,
  decisionTypesEntities => [
    decisionTypesEntities['approved'] && decisionTypesEntities['approved'].name,
  ]
);

export const getDisprovedDecisionNameSelector = createSelector(
  getDecisionTypesEntitiesSelector,
  decisionTypesEntities => [
    decisionTypesEntities['declined'] && decisionTypesEntities['declined'].name,
  ]
);

export const getDisapprovedAndCompletionTypeIdsSelector = createSelector(
  getDecisionTypesEntitiesSelector,
  decisionTypesEntities => [
    decisionTypesEntities['completionNeeded'] &&
      decisionTypesEntities['completionNeeded'].id,
    decisionTypesEntities['declined'] && decisionTypesEntities['declined'].id,
  ]
);

export const getAllDecisionTypesAsSelectObjectForCurrentLanguageSelector = createSelector(
  getAllDecisionTypesTypesSelector,
  getSelectedLanguageSelector,
  (decisionTypes: DictionaryI[], selectedLanguage: string): SelectI[] =>
    mapDictionaryToSelectForGivenLanguage(decisionTypes, selectedLanguage)
);

export const getAllDecisionTypesAsMapForCurrentLanguageSelector = createSelector(
  getAllDecisionTypesAsSelectObjectForCurrentLanguageSelector,
  (selectIList: SelectI[]) => mapSelectArrayToSelectMap(selectIList)
);
