<app-modal-template
  [header]="'inspection.header'"
  [button]="'inspection.buttons.create'"
  (closeModal)="closeModal()"
  (submit)="bookInspection()"
>
  <div *ngIf="(customerPublic$ | async).enableInspection">
    <app-inspection-form
      [formGroup]="formGroup"
      [inspectors]="inspectors$ | async"
      [isSystemEnabled]="(customerPublic$ | async)?.enableUwInspections"
      [systemTypes]="inspectionSystemTypeEnum"
      [attemptedToSubmit]="submitted"
    ></app-inspection-form>
  </div>
</app-modal-template>
