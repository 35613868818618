import { createSelector } from '@ngrx/store';

import { SelectI } from 'src/app/core/models/select.interface';
import { getStartAssignmentPropertyTypeSelector } from 'src/app/core/modules/start/store/selectors/start-assignment.selectors';
import { getSelectedLanguageSelector } from 'src/app/core/store/selectors/language.selectors';
import {
  mapDictionaryToSelectForGivenLanguage,
  mapSelectArrayToSelectMap,
} from 'src/app/core/utils/dictionary-utils';
import { DictionaryI } from 'src/app/dictionaries/models/dictionary.interface';
import { getEstablishmentTypesStateSelector } from 'src/app/dictionaries/store/index';
import {
  getAllEstablishmentTypes,
  getEstablishmentTypesEntities,
  getEstablishmentTypesError,
  getEstablishmentTypesIds,
  getEstablishmentTypesLoaded,
  getEstablishmentTypesLoading,
  getEstablishmentTypesTotal,
} from 'src/app/dictionaries/store/reducers/establishment-types.reducer';
import { getAllRealEstateTypesSelector } from 'src/app/dictionaries/store/selectors/real-estate-types.selectors';

export const getEstablishmentIdsSelector = createSelector(
  getEstablishmentTypesStateSelector,
  getEstablishmentTypesIds
);

export const getEstablishmentTypesEntitiesSelector = createSelector(
  getEstablishmentTypesStateSelector,
  getEstablishmentTypesEntities
);

export const getAllEstablishmentTypesSelector = createSelector(
  getEstablishmentTypesStateSelector,
  getAllEstablishmentTypes
);

export const getEstablishmentTypesTotalSelector = createSelector(
  getEstablishmentTypesStateSelector,
  getEstablishmentTypesTotal
);

export const getEstablishmentTypesLoadingSelector = createSelector(
  getEstablishmentTypesStateSelector,
  getEstablishmentTypesLoading
);

export const getEstablishmentTypesLoadedSelector = createSelector(
  getEstablishmentTypesStateSelector,
  getEstablishmentTypesLoaded
);

export const getEstablishmentTypesErrorSelector = createSelector(
  getEstablishmentTypesStateSelector,
  getEstablishmentTypesError
);

// Custom Selectors
export const getAllEstablishmentTypesFilterByRealEstateSelector = createSelector(
  getAllEstablishmentTypesSelector,
  getAllRealEstateTypesSelector,
  getStartAssignmentPropertyTypeSelector,
  (
    establishmentTypes: DictionaryI[],
    realEstateTypes: DictionaryI[],
    selectedPropertyType: string
  ): DictionaryI[] => {
    let filteredEstablishmentTypes = [];

    const realEstateTypeId: number = realEstateTypes
      .filter((x) => x.name === (selectedPropertyType || ''))
      .map((x) => x.id)
      .pop();

    if (realEstateTypeId > 0 && realEstateTypeId <= 5 && !!realEstateTypeId) {
      filteredEstablishmentTypes = establishmentTypes.filter(
        (x) => x.id === 4 || x.id === 1
      );
    }
    if (realEstateTypeId > 5 && realEstateTypeId <= 33 && !!realEstateTypeId) {
      filteredEstablishmentTypes = establishmentTypes.filter((x) => x.id !== 1);
    }

    return filteredEstablishmentTypes;
  }
);

export const getAllEstablishmentTypesAsSelectObjectForCurrentLanguageFilterByRealEstateSelector = createSelector(
  getAllEstablishmentTypesFilterByRealEstateSelector,
  getSelectedLanguageSelector,
  (EstablishmentTypes: DictionaryI[], selectedLanguage: string): SelectI[] =>
    mapDictionaryToSelectForGivenLanguage(EstablishmentTypes, selectedLanguage)
);

export const getAllEstablishmentTypesAsSelectObjectForCurrentLanguageSelector = createSelector(
  getAllEstablishmentTypesSelector,
  getSelectedLanguageSelector,
  (EstablishmentTypes: DictionaryI[], selectedLanguage: string): SelectI[] =>
    mapDictionaryToSelectForGivenLanguage(EstablishmentTypes, selectedLanguage)
);

export const getAllEstablishmentTypesAsMapForCurrentLanguageSelector = createSelector(
  getAllEstablishmentTypesAsSelectObjectForCurrentLanguageSelector,
  (selectIList: SelectI[]) => mapSelectArrayToSelectMap(selectIList)
);
