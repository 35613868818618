import { createSelector } from '@ngrx/store';
import { getPropertyRealEstateStateSelector } from 'src/app/core/modules/start/store/index';
import {
  getPropertyRealEstateError,
  getPropertyRealEstateLoaded,
  getPropertyRealEstateLoading,
} from 'src/app/core/modules/start/store/reducers/property-real-estate.reducer';
import { idNameResourceAdapter } from 'src/app/core/modules/start/store/utils/id-name-resource.adapter';

export const getPropertyRealEastateLoadingSelector = createSelector(
  getPropertyRealEstateStateSelector,
  getPropertyRealEstateLoading
);

export const getPropertyRealEstateLoadedSelector = createSelector(
  getPropertyRealEstateStateSelector,
  getPropertyRealEstateLoaded
);

export const getPropertyRealEstateErrorSelector = createSelector(
  getPropertyRealEstateStateSelector,
  getPropertyRealEstateError
);

export const getPropertyRealEstateEntitiesSelector = idNameResourceAdapter.getSelectors(
  getPropertyRealEstateStateSelector
).selectEntities;
export const getPropertyRealEstateTotalSelector = idNameResourceAdapter.getSelectors(
  getPropertyRealEstateStateSelector
).selectTotal;
export const getPropertyRealEstateIdsSelector = idNameResourceAdapter.getSelectors(
  getPropertyRealEstateStateSelector
).selectIds;
export const getPropertyAllRealEstateSelector = idNameResourceAdapter.getSelectors(
  getPropertyRealEstateStateSelector
).selectAll;
