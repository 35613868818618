// TODO should be moved to dictionary
export enum UserRolesEnum {
  ADMIN = 'admin',
  CONTROLLER = 'controller',
  PURCHASING = 'purchasing',
  REGION = 'region',
  DISTRICT = 'district',
  ENGINEER = 'engineer',
  MAINTENANCE = 'maintenance',
}
