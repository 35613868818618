<app-section
  [headerTitle]="'overview.volume.header'"
  [emptyText]="'overview.volume.empty'"
  [showEmptyText]="!volumeChart || volumeChart?.length === 0"
  (buttonClicked)="onBrowse()"
>
  <div class="volume-chart">
    <ngx-charts-line-chart
      #chart
      [results]="volumeChart"
      [xAxis]="config.showXAxis"
      [yAxis]="config.showYAxis"
      [scheme]="config.colorScheme"
      [xAxisTickFormatting]="formatX"
      [yAxisTickFormatting]="formatY"
    >
      <ng-template #tooltipTemplate let-model="model">
        <p>
          {{ model.series }} <br />
          {{ model.value }} <br />
        </p>
      </ng-template>

      <ng-template #seriesTooltipTemplate let-model="model">
        <p *ngFor="let m of model">
          {{ m.series }} <br />
          {{ (m.value | uwCurrency: 'splitValue').value }} <br />
        </p>
      </ng-template>
    </ngx-charts-line-chart>
  </div>
</app-section>
