import { Action } from '@ngrx/store';
import {
  HideLoader,
  ShowLoader,
} from 'src/app/core/decorators/loader.decorator';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import { PropertyAddressI } from 'src/app/core/models/property-register/property-address.interface';
import { PropertyAddressRequestI } from 'src/app/core/models/property-register/request/property-address-request.interface';
import { PropertyObjectRequestI } from 'src/app/core/models/property-register/request/property-object-request.interface';

export enum PropertyAddressActionTypes {
  PropertyAddressChanged = '[PropertyAddress] Property Address Changed',

  LoadPropertyAddress = '[PropertyAddress] Load Property Address',
  LoadPropertyAddressSuccess = '[PropertyAddress] Load Property Address Success',
  LoadPropertyAddressFail = '[PropertyAddress] Load Property Address Fail',

  CleanPropertyAddress = '[PropertyAddress] Clean Property Address',
}

export class PropertyAddressChanged implements Action {
  readonly type = PropertyAddressActionTypes.PropertyAddressChanged;

  constructor(public payload: PropertyObjectRequestI) {}
}

@ShowLoader()
export class LoadPropertyAddress implements Action {
  readonly type = PropertyAddressActionTypes.LoadPropertyAddress;

  constructor(public payload: PropertyAddressRequestI) {}
}

@HideLoader(PropertyAddressActionTypes.LoadPropertyAddress)
export class LoadPropertyAddressSuccess implements Action {
  readonly type = PropertyAddressActionTypes.LoadPropertyAddressSuccess;

  constructor(public payload: PropertyAddressI) {}
}

@HideLoader(PropertyAddressActionTypes.LoadPropertyAddress)
export class LoadPropertyAddressFail implements Action {
  readonly type = PropertyAddressActionTypes.LoadPropertyAddressFail;

  constructor(public payload: ErrorResponseWithIdI) {}
}

export class CleanPropertyAddress implements Action {
  readonly type = PropertyAddressActionTypes.CleanPropertyAddress;
}

export type PropertyAddressActions =
  | PropertyAddressChanged
  | LoadPropertyAddress
  | LoadPropertyAddressSuccess
  | LoadPropertyAddressFail
  | CleanPropertyAddress;
