import { NgModule } from '@angular/core';
import { BarChartModule, LineChartModule } from '@swimlane/ngx-charts';
import { MyActionsCardContentComponent } from 'src/app/core/modules/overview/components/my-actions-card-content/my-actions-card-content.component';
import { OverviewComponent } from 'src/app/core/modules/overview/containers/overview/overview.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { MyActionsCardButtonComponent } from 'src/app/core/modules/overview/components/my-actions-card-button/my-actions-card-button.component';
import { MyActionsCardHeaderComponent } from 'src/app/core/modules/overview/components/my-actions-card-header/my-actions-card-header.component';
import { MyActionsCardComponent } from 'src/app/core/modules/overview/components/my-actions-card/my-actions-card.component';
import { MyActionsComponent } from 'src/app/core/modules/overview/components/my-actions/my-actions.component';
import { MyAssignmentsComponent } from 'src/app/core/modules/overview/components/my-assignments/my-assignments.component';
import { VolumeComponent } from 'src/app/core/modules/overview/components/volume/volume.component';
import { WatchlistComponent } from 'src/app/core/modules/overview/components/watchlist/watchlist.component';
import { MyActionCardButtonMobileComponent } from 'src/app/core/modules/overview/components/my-action-card-button-mobile/my-action-card-button-mobile.component';
import { BaseMyActionCardButtonComponent } from 'src/app/core/modules/overview/components/base-my-action-card-button/base-my-action-card-button.component';

@NgModule({
  imports: [SharedModule, BarChartModule, LineChartModule],
  declarations: [
    OverviewComponent,
    MyActionsComponent,
    MyAssignmentsComponent,
    WatchlistComponent,
    VolumeComponent,
    MyActionsCardComponent,
    MyActionsCardHeaderComponent,
    MyActionsCardContentComponent,
    MyActionsCardButtonComponent,
    MyActionCardButtonMobileComponent,
    BaseMyActionCardButtonComponent,
  ],
})
export class OverviewModule {}
