import { Injectable } from '@angular/core';
import { CanActivateChild } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AuthState } from 'src/app/auth/store';
import { isAdminSelector } from 'src/app/auth/store/selectors/user.selectors';

@Injectable({
  providedIn: 'root',
})
export class IsAdminGuard implements CanActivateChild {
  constructor(private store: Store<AuthState>) {}

  canActivateChild(): Observable<boolean> {
    return this.checkIfCanActivate();
  }

  checkIfCanActivate(): Observable<boolean> {
    return this.isAdmin();
  }

  isAdmin(): Observable<boolean> {
    return this.store.pipe(
      select(isAdminSelector),
      map((isAdmin: boolean) => isAdmin),
      take(1)
    );
  }
}
