import { AgmCoreModule } from '@agm/core';
import { APP_BASE_HREF, PlatformLocation } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import {
  APP_INITIALIZER,
  ErrorHandler,
  LOCALE_ID,
  NgModule,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import {
  DefaultRouterStateSerializer,
  RouterStateSerializer,
  StoreRouterConnectingModule,
} from '@ngrx/router-store';
import { Store, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { AuthModule } from 'src/app/auth/auth.module';
import { BootstrapModule } from 'src/app/bootstrap/bootstrap.module';
import { AppComponent } from 'src/app/core/containers/app/app.component';
import { AppRoutingModule } from 'src/app/core/core-routing.module';
import { CoreModule } from 'src/app/core/core.module';
import { AngularErrorHandler } from 'src/app/core/handlers/angular-error.handler';
import { HttpErrorInterceptor } from 'src/app/core/interceptors/http-error.interceptor';
import {
  metaReducers,
  reducerProvider,
  reducerToken,
} from 'src/app/core/store';
import { AgreementsEffects } from 'src/app/core/store/effects/agreements.effects';
import { AlertMessagesEffects } from 'src/app/core/store/effects/alert-messages.effects';
import { AssignmentChangeStatusEffects } from 'src/app/core/store/effects/assignment-change-status.effects';
import { AssignmentEditEffects } from 'src/app/core/store/effects/assignment-edit.effects';
import { AssignmentsEffects } from 'src/app/core/store/effects/assignments.effects';
import { ChangeOwnerEffects } from 'src/app/core/store/effects/change-owner.effects';
import { CompanyUsersEffects } from 'src/app/core/store/effects/company-users.effects';
import { ContractorEffects } from 'src/app/core/store/effects/contractor.effects';
import { ExcelEffects } from 'src/app/core/store/effects/excel.effects';
import { ExternalRedirectEffects } from 'src/app/core/store/effects/external-redirect.effects';
import { GoogleAnalyticsEffects } from 'src/app/core/store/effects/google-analytics.effects';
import { InspectionEffects } from 'src/app/core/store/effects/inspection.effects';
import { LanguageEffects } from 'src/app/core/store/effects/language.effects';
import { ModalEffects } from 'src/app/core/store/effects/modal.effects';
import { PDFEffects } from 'src/app/core/store/effects/pdf.effects';
import { PWAEffects } from 'src/app/core/store/effects/pwa.effects';
import { RouterEffects } from 'src/app/core/store/effects/router.effects';
import { SpinnerEffects } from 'src/app/core/store/effects/spinner.effects';
import { CustomRouterStateSerializer } from 'src/app/core/store/reducers/router.reducer';
import {
  getBaseHref,
  HttpLoaderFactory,
} from 'src/app/core/utils/aot-functions';
import { initApplication } from 'src/app/core/utils/init-app.utils';
import { DictionariesModule } from 'src/app/dictionaries/dictionaries.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { environment } from 'src/environments/environment';
import { ServiceWorkerModule } from '@angular/service-worker';
import { BaseUrlInterceptor } from './core/interceptors/base-url.interceptor';
import { StartPageEffects } from 'src/app/core/store/effects/start-page.effects';
import { DecisionEffects } from 'src/app/core/store/effects/decision.effects';
import { ManageProjectEffects } from 'src/app/core/store/effects/manage-project.effects';
import { PusherEffects } from 'src/app/core/store/effects/pusher.effects';
import { LocalStorageService } from 'src/app/core/services/local-storage/local-storage.service';
import { LanguageEnum } from 'src/app/core/enums/language.enum';

@NgModule({
  imports: [
    // Our Modules
    BootstrapModule,
    SharedModule,
    DictionariesModule.forRoot(),
    AuthModule.forRoot(),
    CoreModule.forRoot(),
    AppRoutingModule,

    // Angular Modules
    BrowserModule,
    BrowserAnimationsModule,

    // Tools Modules
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCf4-kGL_5q0XhkN6U-EAlyaE-D3cR82_Y&libraries=places',
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),

    // Store Modules
    StoreRouterConnectingModule.forRoot({
      serializer: DefaultRouterStateSerializer,
      stateKey: 'router',
    }),
    StoreModule.forRoot(reducerToken, {
      metaReducers,
    }),
    EffectsModule.forRoot([
      LanguageEffects,
      RouterEffects,
      AssignmentsEffects,
      AgreementsEffects,
      ExternalRedirectEffects,
      ExcelEffects,
      ContractorEffects,
      SpinnerEffects,
      AlertMessagesEffects,
      AssignmentChangeStatusEffects,
      InspectionEffects,
      CompanyUsersEffects,
      GoogleAnalyticsEffects,
      ModalEffects,
      ChangeOwnerEffects,
      AssignmentEditEffects,
      PDFEffects,
      PWAEffects,
      StartPageEffects,
      DecisionEffects,
      ManageProjectEffects,
      PusherEffects,
    ]),
    StoreDevtoolsModule.instrument({
      name: 'Assignment App',
      logOnly: environment.production,
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
  ],
  providers: [
    reducerProvider,
    {
      provide: RouterStateSerializer,
      useClass: CustomRouterStateSerializer,
    },
    {
      provide: APP_BASE_HREF,
      deps: [PlatformLocation],
      useFactory: getBaseHref,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BaseUrlInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: AngularErrorHandler,
    },
    {
      provide: APP_INITIALIZER,
      deps: [Store],
      useFactory: initApplication,
      multi: true,
    },
    {
      provide: LOCALE_ID,
      deps: [LocalStorageService],
      useFactory: (localStorageService) =>
        localStorageService.getLanguage() || LanguageEnum.SE,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
