import { Action } from '@ngrx/store';
import {
  HideLoader,
  ShowLoader,
} from 'src/app/core/decorators/loader.decorator';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import { PropertyObjectI } from 'src/app/core/models/property-register/property-object.interface';
import { PropertyObjectRequestI } from 'src/app/core/models/property-register/request/property-object-request.interface';

export enum PropertyObjectActionTypes {
  LoadPropertyObjects = '[PropertyObject] Load Property Objects',
  LoadPropertyObjectsSuccess = '[PropertyObject] Load Property Objects Success',
  LoadPropertyObjectsFail = '[PropertyObject] Load Property Objects Fail',

  CleanPropertyObject = '[PropertyObject] Clean Property Object',
}

@ShowLoader()
export class LoadPropertyObjects implements Action {
  readonly type = PropertyObjectActionTypes.LoadPropertyObjects;

  constructor(public payload: PropertyObjectRequestI) {}
}

@HideLoader(PropertyObjectActionTypes.LoadPropertyObjects)
export class LoadPropertyObjectsSuccess implements Action {
  readonly type = PropertyObjectActionTypes.LoadPropertyObjectsSuccess;

  constructor(public payload: PropertyObjectI) {}
}

@HideLoader(PropertyObjectActionTypes.LoadPropertyObjects)
export class LoadPropertyObjectsFail implements Action {
  readonly type = PropertyObjectActionTypes.LoadPropertyObjectsFail;

  constructor(public payload: ErrorResponseWithIdI) {}
}

export class CleanPropertyObject implements Action {
  readonly type = PropertyObjectActionTypes.CleanPropertyObject;
}

export type PropertyObjectActions =
  | LoadPropertyObjects
  | LoadPropertyObjectsSuccess
  | LoadPropertyObjectsFail
  | CleanPropertyObject;
