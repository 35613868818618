import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NUMBERS_CHART_CONFIG } from 'src/app/shared/charts/chart-config.utils';
import { DatePipe } from '@angular/common';
import { ChartResultI } from 'src/app/core/models/log/chart-result.interface';

@Component({
  selector: 'app-volume',
  templateUrl: './volume.component.html',
  styleUrls: ['./volume.component.scss'],
})
export class VolumeComponent implements OnInit {
  config = NUMBERS_CHART_CONFIG;

  @Input()
  volumeChart: ChartResultI[];

  @Output()
  browse: EventEmitter<void> = new EventEmitter<void>();

  formatX = (value) => this.datePipe.transform(`2020-${value}-01`, 'MMM');
  formatY = (val) => (val % 1 === 0 ? val.toLocaleString() : '');

  constructor(private datePipe: DatePipe) {}

  ngOnInit(): void {}

  onBrowse() {
    this.browse.emit();
  }
}
