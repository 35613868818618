import { Component, OnInit } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';
import { AuthorizationService } from 'src/app/auth/services/authorization/authorization.service';
import {
  DisablePWAClicked,
  InstructionsPWAClicked,
} from 'src/app/core/store/actions/google-analytics.actions';
import { PWAStateAction } from 'src/app/core/store/actions/pwa.action';
import { RouterGo } from 'src/app/core/store/actions/router.action';
import { shouldDisablePWASelector } from 'src/app/core/store/selectors/pwa.selectors';
import { StoreDispatcher } from 'src/app/shared/base-classes/store-dispatcher/store-dispatcher';
import { AlertMessage } from 'src/app/core/models/alert-message.interface';
import {
  getAllAlertsByStatus,
  getShowMissingExternalIdSelector,
} from 'src/app/core/store/selectors/alert-messages.selectors';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends StoreDispatcher<any> implements OnInit {
  translateKey = 'newVersion';

  alerts$: Observable<{ [id: string]: AlertMessage[] }>;
  showMissingExternalId$: Observable<boolean>;

  doesTokenExists$: Observable<boolean>;
  disablePWA$: Observable<boolean>;

  // TODO: Replace this test data when we will have BE implementation
  // mostRecentAssignments: Observable<RecentItem[]> = of([
  //   { id: '5048957394747392', name: 'Assignment 1' },
  //   { id: '2', name: 'Assignment 2' },
  //   { id: '3', name: 'Assignment 3' },
  // ]);
  //
  // mostRecentProjects: Observable<RecentItem[]> = of([
  //   { id: '4705085133160448', name: 'Project 1' },
  //   { id: '2', name: 'Project 2' },
  //   { id: '3', name: 'Project 3' },
  // ]);
  //

  mostRecentAssignments: Observable<RecentItem[]> = of([]);
  mostRecentProjects: Observable<RecentItem[]> = of([]);

  constructor(
    private swUpdate: SwUpdate,
    private authService: AuthorizationService,
    private translateService: TranslateService,
    store: Store<any>
  ) {
    super(store);
  }

  ngOnInit() {
    this.doesTokenExists$ = this.authService.doesTokenExists$;
    this.disablePWA$ = this.store.pipe(select(shouldDisablePWASelector));

    this.alerts$ = this.store.pipe(select(getAllAlertsByStatus));
    this.showMissingExternalId$ = this.store.pipe(
      select(getShowMissingExternalIdSelector)
    );

    this.swUpdate.available
      .pipe(withLatestFrom(this.translateService.stream(this.translateKey)))
      .subscribe(([event, translation]) => {
        if (confirm(translation)) {
          window.location.reload();
        }
      });
  }

  onSkipPWA(action: PWAStateAction) {
    const gaAction = new DisablePWAClicked();
    this.dispatchActions([action, gaAction]);
  }

  onRedirectToSupport(action: RouterGo) {
    const gaAction = new InstructionsPWAClicked();
    this.dispatchActions([action, gaAction]);
  }

  onGoToRecentAssignment(id: string) {}

  onGoToRecentProject(id: string) {}
}

// TODO: Move this interfaces when BE will be implemented
export interface RecentItem {
  id: string;
  name: string;
}
