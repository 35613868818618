import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import { PropertyAddressI } from 'src/app/core/models/property-register/property-address.interface';
import {
  LoadPropertyAddress,
  LoadPropertyAddressFail,
  LoadPropertyAddressSuccess,
  PropertyAddressActionTypes,
  PropertyAddressChanged,
} from 'src/app/core/modules/start/store/actions/property-address.actions';
import {
  CleanPropertyObject,
  LoadPropertyObjects,
} from 'src/app/core/modules/start/store/actions/property-object.actions';
import { PropertyRegisterService } from 'src/app/core/services/api/property-register/property-register.service';

@Injectable()
export class PropertyAddressEffects {
  constructor(
    private actions$: Actions,
    private propertyRegisterService: PropertyRegisterService
  ) {}

  public handlePropertyAddressChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PropertyAddressActionTypes.PropertyAddressChanged),
      switchMap((action: PropertyAddressChanged) => [
        new CleanPropertyObject(),
        new LoadPropertyObjects(action.payload),
      ])
    )
  );

  public getPropertyAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PropertyAddressActionTypes.LoadPropertyAddress),
      switchMap((action: LoadPropertyAddress) =>
        this.propertyRegisterService.getAddress(action.payload).pipe(
          map(
            (propertyAddress: PropertyAddressI) =>
              new LoadPropertyAddressSuccess(propertyAddress)
          ),
          catchError((error: ErrorResponseWithIdI) =>
            of(new LoadPropertyAddressFail(error))
          )
        )
      )
    )
  );
}
