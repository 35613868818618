import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { LoadAnalysisAssignment } from 'src/app/assignments/modules/analysis/store/actions/analysis-assignment.actions';

import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';

import { AssignmentsService } from 'src/app/core/services/api/assignments/assignments.service';
import {
  AssignmentEditActionTypes,
  EditAssignment,
  EditAssignmentFail,
  EditAssignmentSuccess,
} from 'src/app/core/store/actions/assignment-edit.action';
import { LoadAssignment } from 'src/app/core/store/actions/assignments.action';

@Injectable()
export class AssignmentEditEffects {
  constructor(
    private actions$: Actions,
    private assignmentService: AssignmentsService
  ) {}

  public assignmentEdit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssignmentEditActionTypes.EditAssignment),
      switchMap((action: EditAssignment) =>
        this.assignmentService.editAssignment(action.payload).pipe(
          map(() => new EditAssignmentSuccess(action.payload.assignmentId)),
          catchError((error: ErrorResponseWithIdI) =>
            of(new EditAssignmentFail(error))
          )
        )
      )
    )
  );

  public handleAssignmentEditSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssignmentEditActionTypes.EditAssignmentSuccess),
      switchMap((action: EditAssignmentSuccess) =>
        of(
          new LoadAssignment(action.payload),
          new LoadAnalysisAssignment(action.payload)
        )
      )
    )
  );
}
