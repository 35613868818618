<div
  class="bottom-drower-mobile"
  [ngClass]="{ 'bottom-drower-mobile--small': small }"
>
  <div class="bottom-drower-mobile__content">
    <div class="bottom-drower-mobile__header-section">
      <div class="bottom-drower-mobile__header">
        <span class="bottom-drower-mobile__header-text">
          {{ header }}
        </span>
        <span
          class="bottom-drower-mobile__header-close"
          [translate]="'mobileActions.close'"
          (click)="onCloseMobile()"
        ></span>
      </div>

      <div class="bottom-drower-mobile__sub-header" *ngIf="!!subheader">
        {{ subheader }}
      </div>
    </div>

    <ng-content></ng-content>
  </div>
</div>
