import { SettingsI } from '../models/settings.interface';

export abstract class Settings {
  private static _appSettings: SettingsI;

  // Disable option to instance this class
  private constructor() {}

  static get appSettings(): SettingsI {
    return !this._appSettings
      ? this._appSettings
      : {
          ...this._appSettings,
          whiteListedDomains: [...this._appSettings.whiteListedDomains],
          auth0: { ...this._appSettings.auth0 },
          pusher: { ...this._appSettings.pusher },
        };
  }

  static set appSettings(settings: SettingsI) {
    if (!!this._appSettings) {
      return;
    }
    this._appSettings = settings;
  }
}
