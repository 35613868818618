import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/auth/guards/auth.guard';
import { ConfirmGuard } from 'src/app/auth/guards/confirm.guard';
import { UserGuard } from 'src/app/auth/guards/user.guard';
import { NotificationsComponent } from 'src/app/core/containers/notifications/notifications.component';
import { SupportComponent } from 'src/app/core/containers/support/support.component';
import { CoreUrlEnums } from 'src/app/core/enums/url-paths.enum';
import { PropertyDistrictGuard } from 'src/app/core/modules/start/guards/property-district.guard';
import { ClientAssignmentTypesGuard } from 'src/app/dictionaries/guards/client-assignment-types.guard';
import { EstablishmentTypesGuard } from 'src/app/dictionaries/guards/establishment-types.guard';
import { RealEstateTypesGuard } from 'src/app/dictionaries/guards/real-estate-types.guard';
import { SpaceTypesGuard } from 'src/app/dictionaries/guards/space-types.guard';
import { PusherMessagesGuard } from 'src/app/core/guards/pusher-messages-guard.service';
import { IsAdminGuard } from 'src/app/core/guards/is-admin.guard';
import { CompanyUsersGuard } from 'src/app/core/guards/company-users-guard.service';
import { OverviewComponent } from 'src/app/core/modules/overview/containers/overview/overview.component';
import { StartPageGuard } from 'src/app/core/guards/start-page.guard';
import { AgreementsGuard } from 'src/app/core/guards/agreements.guard';
import { AssignmentTypesGuard } from 'src/app/dictionaries/guards/assignment-types.guard';

// TODO: Add not found page
// TODO: Add parent url to avoid such guards duplication
export const appRoutes: Routes = [
  { path: '', redirectTo: CoreUrlEnums.OVERVIEW, pathMatch: 'full' },
  {
    path: CoreUrlEnums.OVERVIEW,
    component: OverviewComponent,
    canActivate: [
      AuthGuard,
      UserGuard,
      ConfirmGuard,
      PusherMessagesGuard,
      PropertyDistrictGuard,
      SpaceTypesGuard,
      RealEstateTypesGuard,
      ClientAssignmentTypesGuard,
      EstablishmentTypesGuard,
      CompanyUsersGuard,
      StartPageGuard,
      AgreementsGuard,
      AssignmentTypesGuard,
    ],
  },
  {
    path: CoreUrlEnums.NOTIFICATIONS,
    canActivate: [
      AuthGuard,
      UserGuard,
      ConfirmGuard,
      PusherMessagesGuard,
      PropertyDistrictGuard,
      SpaceTypesGuard,
      RealEstateTypesGuard,
      ClientAssignmentTypesGuard,
      EstablishmentTypesGuard,
      CompanyUsersGuard,
    ],
    component: NotificationsComponent,
  },
  {
    path: CoreUrlEnums.SUPPORT,
    component: SupportComponent,
  },
  {
    path: CoreUrlEnums.ASSIGNMENTS,
    data: { breadcrumb: { label: 'home', disable: true } },
    canActivate: [
      AuthGuard,
      UserGuard,
      ConfirmGuard,
      PusherMessagesGuard,
      PropertyDistrictGuard,
      SpaceTypesGuard,
      RealEstateTypesGuard,
      ClientAssignmentTypesGuard,
      EstablishmentTypesGuard,
      CompanyUsersGuard,
    ],
    canActivateChild: [AuthGuard],
    loadChildren: () =>
      import('src/app/assignments/assignments.module').then(
        (m) => m.AssignmentsModule
      ),
  },
  {
    path: CoreUrlEnums.NUMBERS,
    data: { breadcrumb: { label: 'home', disable: true } },
    canActivate: [
      AuthGuard,
      UserGuard,
      ConfirmGuard,
      PusherMessagesGuard,
      PropertyDistrictGuard,
      SpaceTypesGuard,
      RealEstateTypesGuard,
      ClientAssignmentTypesGuard,
      EstablishmentTypesGuard,
      CompanyUsersGuard,
    ],
    canActivateChild: [AuthGuard],
    loadChildren: () =>
      import('src/app/numbers/numbers.module').then((m) => m.NumbersModule),
  },
  {
    path: CoreUrlEnums.ADMINISTRATION,
    data: { breadcrumb: { label: 'home', disable: true } },
    canActivate: [
      AuthGuard,
      UserGuard,
      ConfirmGuard,
      PusherMessagesGuard,
      PropertyDistrictGuard,
      SpaceTypesGuard,
      RealEstateTypesGuard,
      ClientAssignmentTypesGuard,
      EstablishmentTypesGuard,
      CompanyUsersGuard,
    ],
    canActivateChild: [AuthGuard, IsAdminGuard],
    loadChildren: () =>
      import('src/app/administration/administration.module').then(
        (m) => m.AdministrationModule
      ),
  },
  // TODO: Disabled for now. It will be added in future
  // {
  //   path: CoreUrlEnums.ACCOUNT,
  //   canActivate: [
  //     AuthGuard,
  //     UserGuard,
  //     ConfirmGuard,
  //     PusherMessagesGuard,
  //     PropertyDistrictGuard,
  //     SpaceTypesGuard,
  //     RealEstateTypesGuard,
  //     ClientAssignmentTypesGuard,
  //     EstablishmentTypesGuard,
  //   ],
  //   canActivateChild: [AuthGuard],
  //   loadChildren: () =>
  //     import('src/app/account/account.module').then((m) => m.AccountModule),
  // },
  {
    path: CoreUrlEnums.PROJECTS,
    data: { breadcrumb: { label: 'home', disable: true } },
    canActivate: [
      AuthGuard,
      UserGuard,
      ConfirmGuard,
      PusherMessagesGuard,
      PropertyDistrictGuard,
      SpaceTypesGuard,
      RealEstateTypesGuard,
      ClientAssignmentTypesGuard,
      EstablishmentTypesGuard,
      CompanyUsersGuard,
    ],
    canActivateChild: [AuthGuard],
    loadChildren: () =>
      import('src/app/projects/projects.module').then((m) => m.ProjectsModule),
  },
  { path: '**', redirectTo: CoreUrlEnums.OVERVIEW },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      useHash: true,
      scrollPositionRestoration: 'top',
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
