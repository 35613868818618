<span *ngIf="!!action?.mainAction">
  <button
    class="btn btn-primary btn-block mt-3"
    *ngIf="action?.secondaryActions?.length === 0; else complexButton"
    (click)="onMakeAction(action?.mainAction)"
  >
    {{ 'assignmentActions.' + action?.mainAction | translate }}
  </button>

  <ng-template #complexButton>
    <div class="btn-group w-100 mt-3">
      <button
        type="button"
        class="btn btn-primary btn-block"
        (click)="onMakeAction(action?.mainAction)"
      >
        {{ 'assignmentActions.' + action?.mainAction | translate }}
      </button>

      <div class="btn-group" dropdown>
        <button
          dropdownToggle
          class="btn btn-primary dropdown-toggle"
          (click)="onOpenMobileActions(action)"
        >
          <span class="caret"></span>
        </button>
        <span *ngIf="!isSmallScreen">
          <ul *dropdownMenu class="dropdown-menu">
            <span
              *ngFor="let actionName of action?.secondaryActions"
              class="dropdown-item btn-third"
              (click)="onMakeAction(actionName)"
              >{{ 'assignmentActions.' + actionName | translate }}</span
            >
          </ul>
        </span>
      </div>
    </div>
  </ng-template>
</span>
