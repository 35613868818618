import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AssignmentActionsEnum } from 'src/app/core/enums/assignment-actions.enum';
import { BaseTemplateComponent } from 'src/app/shared/components/base-template/base-template.component';
import { SelectI } from 'src/app/core/models/select.interface';
import { getContractorFromAgreementAsSelectObjectSelector } from 'src/app/core/store/selectors/agreements.selectors';
import { getAllAssignmentTypesAsSelectObjectForCurrentLanguageSelector } from 'src/app/dictionaries/store/selectors/assignment-types.selectors';
import { InviteContractor } from 'src/app/core/store/actions/contractor.action';

@Component({
  selector: 'app-invite-contractor-template',
  templateUrl: './invite-contractor-template.component.html',
  styleUrls: ['./invite-contractor-template.component.scss'],
})
export class InviteContractorTemplateComponent
  extends BaseTemplateComponent
  implements OnInit {
  assignmentId: string;

  contractors$: Observable<SelectI[]>;
  assignmentTypes$: Observable<SelectI[]>;

  assignmentActionsEnum = AssignmentActionsEnum;

  formGroup: FormGroup;
  submitted = false;

  @HostListener('document:click', ['$event'])
  clickout(event) {
    this.checkIfShouldCloseModal(event);
  }

  constructor(store: Store<any>, eRef: ElementRef, private fb: FormBuilder) {
    super(store, eRef);
  }

  ngOnInit() {
    this.createFormGroup();
    this.getDataFromStore();
  }

  createFormGroup() {
    this.formGroup = this.fb.group({
      agreementId: ['', Validators.required],
      assignmentType: ['', Validators.required],
      message: [''],
    });
  }

  inviteContractor() {
    this.submitted = true;

    if (this.formGroup.valid) {
      const action = new InviteContractor({
        assignmentId: this.assignmentId,
        message: this.formGroup.controls['message'].value,
        agreementId: this.formGroup.controls['agreementId'].value,
        assignmentType: this.formGroup.controls['assignmentType'].value,
      });

      this.dispatchActionAndCloseModal(action);
    }
  }

  private getDataFromStore(): void {
    this.contractors$ = this.store.pipe(
      select(getContractorFromAgreementAsSelectObjectSelector)
    );
    this.assignmentTypes$ = this.store.pipe(
      select(getAllAssignmentTypesAsSelectObjectForCurrentLanguageSelector)
    );
  }

  canDeactivate(): boolean | Observable<boolean> {
    return !this.formGroup.dirty || this.submitted;
  }
}
