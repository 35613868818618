import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-base-form',
  templateUrl: './base-form.component.html',
  styleUrls: ['./base-form.component.scss'],
})
export class BaseFormComponent<T> implements OnInit {
  @Input()
  attemptedToSubmit: boolean;

  @Input()
  formGroup: FormGroup;

  ngOnInit() {}

  isValid(control: AbstractControl) {
    return (
      !!control &&
      !!control.invalid &&
      (!!control.dirty || !!this.attemptedToSubmit)
    );
  }

  isRequired(fieldName: string) {
    if (!this.formGroup.get(fieldName).validator) {
      return false;
    }

    const validator = this.formGroup
      .get(fieldName)
      .validator({} as AbstractControl);

    return !!validator && !!validator.required;
  }
}
