import { createSelector } from '@ngrx/store';

import { getAssignmentsStateSelector } from 'src/app/core/store/index';
import {
  adapter,
  getAllAssignmentsTotal,
  getAssignmentMobileSortField,
  getAssignmentMobileSortOrderOptions,
  getAssignmentQuery,
  getAssignmentsError,
  getAssignmentsLoaded,
  getAssignmentsLoading,
  getAssignmentSorting,
  getAssignmentsPagination,
  getAssignmentsPhaseFilter,
  getAssignmentsPhaseFilterOptions,
  getAssignmentsRequestedTime,
  getAssignmentsSearchType,
  getAssignmentsSearchTypeOptions,
  getAssignmentsShowMobileExport,
  getAssignmentsShowMobileFilter,
  getAssignmentsShowMobileSearchFilter,
  getAssignmentsTableType,
  getAssignmentsUserFilter,
  getAssignmentsUserFilterOptions,
  getSelectedAssignments,
  isNewVersionIsAvailable,
} from 'src/app/core/store/reducers/assignments.reducer';
import { getRouterParamsSelector } from 'src/app/core/store/selectors/router.selectors';

export const getSelectedAssignmentSelector = createSelector(
  getAssignmentsStateSelector,
  getSelectedAssignments
);

export const isNewVersionIsAvailableSelector = createSelector(
  getAssignmentsStateSelector,
  isNewVersionIsAvailable
);

export const getAssignmentsLoadingSelector = createSelector(
  getAssignmentsStateSelector,
  getAssignmentsLoading
);

export const getAssignmentsLoadedSelector = createSelector(
  getAssignmentsStateSelector,
  getAssignmentsLoaded
);

export const getAssignmentsErrorSelector = createSelector(
  getAssignmentsStateSelector,
  getAssignmentsError
);

export const getAssignmentsTableTypeSelector = createSelector(
  getAssignmentsStateSelector,
  getAssignmentsTableType
);

export const getAssignmentsPaginationSelector = createSelector(
  getAssignmentsStateSelector,
  getAssignmentsPagination
);

export const getAssignmentsShowMobileFilterSelector = createSelector(
  getAssignmentsStateSelector,
  getAssignmentsShowMobileFilter
);

export const getAssignmentsShowMobileSearchFilterSelector = createSelector(
  getAssignmentsStateSelector,
  getAssignmentsShowMobileSearchFilter
);

export const getAssignmentsShowMobileExportSelector = createSelector(
  getAssignmentsStateSelector,
  getAssignmentsShowMobileExport
);

export const getAssignmentMobileSortFieldSelector = createSelector(
  getAssignmentsStateSelector,
  getAssignmentMobileSortField
);

export const getAssignmentMobileSortOrderOptionsSelector = createSelector(
  getAssignmentsStateSelector,
  getAssignmentMobileSortOrderOptions
);

export const getAssignmentSortingSelector = createSelector(
  getAssignmentsStateSelector,
  getAssignmentSorting
);

export const getAssignmentSortingMappedToTableObjectSelector = createSelector(
  getAssignmentSortingSelector,
  (sort) => {
    return !!sort ? [{ dir: sort.ordering, prop: sort.field }] : [];
  }
);

export const getAssignmentSortMobileSelector = createSelector(
  getAssignmentSortingSelector,
  getAssignmentMobileSortFieldSelector,
  (sort, sortField) => !!sort && sort.field === sortField
);

export const getAssignmentsRequestedTimeSelector = createSelector(
  getAssignmentsStateSelector,
  getAssignmentsRequestedTime
);

export const getAllAssignmentsTotalSelector = createSelector(
  getAssignmentsStateSelector,
  getAllAssignmentsTotal
);

export const getAssignmentQuerySelector = createSelector(
  getAssignmentsStateSelector,
  getAssignmentQuery
);

export const getAssignmentsUserFilterSelector = createSelector(
  getAssignmentsStateSelector,
  getAssignmentsUserFilter
);

export const getAssignmentsUserFilterOptionsSelector = createSelector(
  getAssignmentsStateSelector,
  getAssignmentsUserFilterOptions
);

export const getAssignmentPhaseFilterSelector = createSelector(
  getAssignmentsStateSelector,
  getAssignmentsPhaseFilter
);

export const getAssignmentPhaseFilterOptionsSelector = createSelector(
  getAssignmentsStateSelector,
  getAssignmentsPhaseFilterOptions
);

export const getAssignmentsSearchTypeSelector = createSelector(
  getAssignmentsStateSelector,
  getAssignmentsSearchType
);

export const getAssignmentsSearchTypeOptionsSelector = createSelector(
  getAssignmentsStateSelector,
  getAssignmentsSearchTypeOptions
);

export const getAssignmentsEntitiesSelector = adapter.getSelectors(
  getAssignmentsStateSelector
).selectEntities;
export const getAssignmentsTotalSelector = adapter.getSelectors(
  getAssignmentsStateSelector
).selectTotal;
export const getAssignmentsIdsSelector = adapter.getSelectors(
  getAssignmentsStateSelector
).selectIds;
export const getAllAssignmentsSelector = adapter.getSelectors(
  getAssignmentsStateSelector
).selectAll;

export const getAssignmentForGivenUrlIdSelector = createSelector(
  getAssignmentsEntitiesSelector,
  getRouterParamsSelector,
  (entities, params) =>
    !!entities[params && params.assignmentId] &&
    entities[params && params.assignmentId]
);

export const getAssignmentPartsForGivenUrlIdSelector = createSelector(
  getAssignmentForGivenUrlIdSelector,
  (assignment) =>
    assignment.assignmentParts?.filter((part) => part.assignmentRoleId !== '1')
);

export const getAssignmentByIdSelector = createSelector(
  getAssignmentsEntitiesSelector,
  (entities, assignmentId) => (!!assignmentId ? entities[assignmentId] : [])
);

export const getTableSizeLimit = createSelector(
  getAssignmentsPaginationSelector,
  (pagination) => pagination.limit
);

export const getTablePaginationLimitOptions = createSelector(
  getAssignmentsPaginationSelector,
  (pagination) => pagination.limitOptions
);

export const getTablePaginationPage = createSelector(
  getAssignmentsPaginationSelector,
  (pagination) => pagination.page
);
