import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MyActionI } from 'src/app/core/models/user/start-page.interface';
import { Action } from '@ngrx/store';

@Component({
  selector: 'app-my-actions-card',
  templateUrl: './my-actions-card.component.html',
  styleUrls: ['./my-actions-card.component.scss'],
})
export class MyActionsCardComponent implements OnInit {
  @Input()
  actions: MyActionI[];

  @Input()
  showMobileActions: MyActionI;

  @Input()
  isSmallScreen: boolean;

  @Output()
  goToDetails: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  makeAction: EventEmitter<Action> = new EventEmitter<Action>();

  @Output()
  openMobileActions: EventEmitter<MyActionI> = new EventEmitter<MyActionI>();

  constructor() {}

  ngOnInit(): void {}

  onGoToDetails(assignmentId: string) {
    this.goToDetails.emit(assignmentId);
  }

  onMakeAction(action: Action) {
    this.makeAction.emit(action);
  }

  onOpenMobileActions(myAction: MyActionI) {
    this.openMobileActions.emit(myAction);
  }
}
