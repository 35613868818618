import { createSelector } from '@ngrx/store';

import { SelectI } from 'src/app/core/models/select.interface';
import { getSelectedLanguageSelector } from 'src/app/core/store/selectors/language.selectors';
import {
  mapDictionaryToSelectForGivenLanguage,
  mapSelectArrayToSelectMap,
} from 'src/app/core/utils/dictionary-utils';
import { DictionaryI } from 'src/app/dictionaries/models/dictionary.interface';
import { getAssignmentTypesStateSelector } from 'src/app/dictionaries/store/index';
import {
  getAllAssignmentTypes,
  getAssignmentTypesEntities,
  getAssignmentTypesError,
  getAssignmentTypesIds,
  getAssignmentTypesLoaded,
  getAssignmentTypesLoading,
  getAssignmentTypesTotal,
} from 'src/app/dictionaries/store/reducers/assignment-types.reducer';

export const getAssignmentTypesIdsSelector = createSelector(
  getAssignmentTypesStateSelector,
  getAssignmentTypesIds
);

export const getAssignmentTypesEntitiesSelector = createSelector(
  getAssignmentTypesStateSelector,
  getAssignmentTypesEntities
);

export const getAllAssignmentTypesTypesSelector = createSelector(
  getAssignmentTypesStateSelector,
  getAllAssignmentTypes
);

export const getAssignmentTypesTotalSelector = createSelector(
  getAssignmentTypesStateSelector,
  getAssignmentTypesTotal
);

export const getAssignmentTypesLoadingSelector = createSelector(
  getAssignmentTypesStateSelector,
  getAssignmentTypesLoading
);

export const getAssignmentTypesLoadedSelector = createSelector(
  getAssignmentTypesStateSelector,
  getAssignmentTypesLoaded
);

export const getAssignmentTypesErrorSelector = createSelector(
  getAssignmentTypesStateSelector,
  getAssignmentTypesError
);

// Custom Selectors

export const getAllAssignmentTypesAsSelectObjectForCurrentLanguageSelector = createSelector(
  getAllAssignmentTypesTypesSelector,
  getSelectedLanguageSelector,
  (assignmentTypes: DictionaryI[], selectedLanguage: string): SelectI[] =>
    mapDictionaryToSelectForGivenLanguage(assignmentTypes, selectedLanguage)
);

export const getAllAssignmentTypesAsMapForCurrentLanguageSelector = createSelector(
  getAllAssignmentTypesAsSelectObjectForCurrentLanguageSelector,
  (selectIList: SelectI[]) => mapSelectArrayToSelectMap(selectIList)
);
