import { Action } from '@ngrx/store';
import {
  HideLoader,
  ShowLoader,
} from 'src/app/core/decorators/loader.decorator';
import { AssignmentPhaseEnum } from 'src/app/core/enums/assignment-phase.enum';
import { AssignmentTableTypeEnum } from 'src/app/core/enums/assignment-table-type.enum';
import { AssignmentI } from 'src/app/core/models/assignment/assignment.interface';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import { AssignmentSearchRequest } from 'src/app/core/models/search/assignment-search-request.interface';
import { AssignmentSearchResponse } from 'src/app/core/models/search/assignment-search-response.interface';
import { TableSortOptionI } from 'src/app/core/models/table-sort-option';
import { AssignmentUserEnum } from 'src/app/core/enums/assignment-user.enum';
import { AssignmentSearchTypeEnum } from 'src/app/core/enums/assignment-search-type.enum';
import { MobileFilterSortI } from 'src/app/shared/models/mobile-filter-sort.interface';

export enum AssignmentsActionTypes {
  LoadAssignment = '[Assignments] Load Assignment',
  LoadedAssignmentSuccess = '[Assignments] Loaded Assignment Success',
  LoadedAssignmentFail = '[Assignments] Fail To Load Assignment',

  LoadAssignmentsList = '[Assignments] Load Assignments List',
  LoadedAssignmentsListSuccess = '[Assignments] Loaded Assignments List Success',
  LoadedAssignmentsListFail = '[Assignments] Fail To Load Assignments List',

  UpdateSelectedAssignments = '[Assignments] Update Selected Assignments',

  ChangeAssignmentsQuery = '[Assignment] Change Assignments Query',
  ChangeAssignmentsPagination = '[Assignments] Change Assignments Pagination',
  ChangeAssignmentsPaginationLimit = '[Assignments] Change Assignments Pagination Limit',
  SetAssignmentsTableType = '[Assignments] Set Assignments Table Type',
  ChangeAssignmentsPhaseFilter = '[Assignments] Change Assignments Phase Filter',
  ChangeAssignmentsUserFilter = '[Assignment] Change Assignments User Filter',
  ChangeAssignmentsSearchType = '[Assignments] Change Assignments Search Type',
  ChangeAssignmentSorting = '[Assignments] Change Sorting',

  ShowAssignmentMobileFilter = '[Assignments] Show Assignment Mobile Filter',
  ShowAssignmentMobileSearchFilter = '[Assignments] Show Assignment Mobile Search Filter',
  ShowAssignmentMobileExport = '[Assignments] Show Assignment Mobile Export',
  ChangeAssignmentMobileStatusFilterAndSort = '[Assignments] Change Assignments Mobile Status Filter And Sort',
  ClearAssignmentMobileStatusFilterAndSort = '[Assignments] Clear Assignments Mobile Status Filter And Sort',

  RefreshAssignmentTable = '[Assignments] Refresh Assignment Table',

  CleanAssignmentsState = '[Assignments] Clean Assignments State',
}

// ------ Load Assignment ------

@ShowLoader()
export class LoadAssignment implements Action {
  readonly type = AssignmentsActionTypes.LoadAssignment;

  constructor(public payload: string) {}
}

@HideLoader(AssignmentsActionTypes.LoadAssignment)
export class LoadedAssignmentSuccess implements Action {
  readonly type = AssignmentsActionTypes.LoadedAssignmentSuccess;

  constructor(public payload: AssignmentI) {}
}

@HideLoader(AssignmentsActionTypes.LoadAssignment)
export class LoadedAssignmentFail implements Action {
  readonly type = AssignmentsActionTypes.LoadedAssignmentFail;

  constructor(public payload: ErrorResponseWithIdI) {}
}

// ------ Load Assignment List ------

@ShowLoader()
export class LoadAssignmentsList implements Action {
  readonly type = AssignmentsActionTypes.LoadAssignmentsList;

  constructor(public payload: AssignmentSearchRequest) {}
}

@HideLoader(AssignmentsActionTypes.LoadAssignmentsList)
export class LoadedAssignmentsListSuccess implements Action {
  readonly type = AssignmentsActionTypes.LoadedAssignmentsListSuccess;

  constructor(public payload: AssignmentSearchResponse) {}
}

@HideLoader(AssignmentsActionTypes.LoadAssignmentsList)
export class LoadedAssignmentsListFail implements Action {
  readonly type = AssignmentsActionTypes.LoadedAssignmentsListFail;

  constructor(public payload: ErrorResponseWithIdI) {}
}

// ------ Change Table Details ------

export class ChangeAssignmentsQuery implements Action {
  readonly type = AssignmentsActionTypes.ChangeAssignmentsQuery;

  constructor(public payload: string) {}
}

export class ChangeAssignmentsPagination implements Action {
  readonly type = AssignmentsActionTypes.ChangeAssignmentsPagination;

  constructor(public payload: number) {}
}

export class ChangeAssignmentsPaginationLimit implements Action {
  readonly type = AssignmentsActionTypes.ChangeAssignmentsPaginationLimit;

  constructor(public payload: number) {}
}

export class SetAssignmentsTableType implements Action {
  readonly type = AssignmentsActionTypes.SetAssignmentsTableType;

  constructor(
    public payload: {
      tableType: AssignmentTableTypeEnum;
      phaseFilter?: AssignmentPhaseEnum;
      userFilter?: AssignmentUserEnum;
      searchType?: AssignmentSearchTypeEnum;
      query?: string;
    }
  ) {}
}

export class ChangeAssignmentsPhaseFilter implements Action {
  readonly type = AssignmentsActionTypes.ChangeAssignmentsPhaseFilter;

  constructor(public payload: AssignmentPhaseEnum) {}
}

export class ChangeAssignmentsUserFilter implements Action {
  readonly type = AssignmentsActionTypes.ChangeAssignmentsUserFilter;

  constructor(public payload: AssignmentUserEnum) {}
}

export class ChangeAssignmentsSearchType implements Action {
  readonly type = AssignmentsActionTypes.ChangeAssignmentsSearchType;

  constructor(public payload: AssignmentSearchTypeEnum) {}
}

export class ChangeAssignmentSorting implements Action {
  readonly type = AssignmentsActionTypes.ChangeAssignmentSorting;

  constructor(public payload: TableSortOptionI) {}
}

export class ShowAssignmentMobileFilter implements Action {
  readonly type = AssignmentsActionTypes.ShowAssignmentMobileFilter;

  constructor(public payload: boolean) {}
}

export class ShowAssignmentMobileSearchFilter implements Action {
  readonly type = AssignmentsActionTypes.ShowAssignmentMobileSearchFilter;

  constructor(public payload: boolean) {}
}

export class ShowAssignmentMobileExport implements Action {
  readonly type = AssignmentsActionTypes.ShowAssignmentMobileExport;

  constructor(public payload: boolean) {}
}

export class ChangeAssignmentMobileStatusFilterAndSort implements Action {
  readonly type =
    AssignmentsActionTypes.ChangeAssignmentMobileStatusFilterAndSort;

  constructor(public payload: MobileFilterSortI) {}
}

export class ClearAssignmentMobileStatusFilterAndSort implements Action {
  readonly type =
    AssignmentsActionTypes.ClearAssignmentMobileStatusFilterAndSort;
}

export class UpdateSelectedAssignments implements Action {
  readonly type = AssignmentsActionTypes.UpdateSelectedAssignments;

  constructor(public payload: AssignmentI[]) {}
}

// Clean

export class RefreshAssignmentTable implements Action {
  readonly type = AssignmentsActionTypes.RefreshAssignmentTable;
}

export class CleanAssignmentsState implements Action {
  readonly type = AssignmentsActionTypes.CleanAssignmentsState;
}

export type AssignmentsActions =
  | LoadAssignment
  | LoadedAssignmentSuccess
  | LoadedAssignmentFail
  | LoadAssignmentsList
  | LoadedAssignmentsListFail
  | LoadedAssignmentsListSuccess
  | ChangeAssignmentsPagination
  | ChangeAssignmentsPaginationLimit
  | SetAssignmentsTableType
  | ChangeAssignmentsPhaseFilter
  | ChangeAssignmentsUserFilter
  | ChangeAssignmentsSearchType
  | ChangeAssignmentSorting
  | ShowAssignmentMobileFilter
  | ShowAssignmentMobileSearchFilter
  | ShowAssignmentMobileExport
  | ChangeAssignmentMobileStatusFilterAndSort
  | ClearAssignmentMobileStatusFilterAndSort
  | ChangeAssignmentsQuery
  | UpdateSelectedAssignments
  | RefreshAssignmentTable
  | CleanAssignmentsState;
