import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  AssignmentChangeStatusAction,
  CloseAssignment,
  RemoveAssignment,
  ReopenAssignment,
} from 'src/app/core/store/actions/assignment-change-status.action';

@Component({
  selector: 'app-base-action',
  templateUrl: './base-action.component.html',
  styleUrls: ['./base-action.component.scss'],
})
export class BaseActionComponent implements OnInit {
  private closeConfirmTextKey = 'dropdown-menu.changeStatus.confirmation.close';
  private reopenConfirmTextKey =
    'dropdown-menu.changeStatus.confirmation.reopen';
  private removeConfirmTextKey =
    'dropdown-menu.changeStatus.confirmation.remove';

  @Output()
  statusChange: EventEmitter<AssignmentChangeStatusAction> = new EventEmitter<AssignmentChangeStatusAction>();

  constructor(private translationService: TranslateService) {}

  ngOnInit(): void {}

  onClose(id: string) {
    this.emitActionAfterConfirmation(
      this.closeConfirmTextKey,
      new CloseAssignment(id)
    );
  }

  onRemove(removeInfo: { assignmentId: string; wasCreatedInUw: boolean }) {
    this.emitActionAfterConfirmation(
      this.removeConfirmTextKey,
      new RemoveAssignment(removeInfo)
    );
  }

  onReopen(id: string) {
    this.emitActionAfterConfirmation(
      this.reopenConfirmTextKey,
      new ReopenAssignment(id)
    );
  }

  private emitActionAfterConfirmation(
    confirmText: string,
    action: AssignmentChangeStatusAction
  ) {
    if (confirm(this.translationService.instant(confirmText))) {
      this.statusChange.emit(action);
    }
  }
}
