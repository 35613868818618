import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';

import { MeService } from 'src/app/core/services/api/me/me.service';
import { StartPageI } from 'src/app/core/models/user/start-page.interface';
import {
  CleanStartPageState,
  LoadedStartPageFail,
  LoadedStartPageSuccess,
  StartPageActionTypes,
} from 'src/app/core/store/actions/start-page.action';

@Injectable()
export class StartPageEffects {
  constructor(private actions$: Actions, private meService: MeService) {}

  public loadStartPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StartPageActionTypes.LoadStartPage),
      switchMap(() =>
        this.meService.getStartPage().pipe(
          map((startPage: StartPageI) => new LoadedStartPageSuccess(startPage)),
          catchError((error: ErrorResponseWithIdI) =>
            of(new LoadedStartPageFail(error))
          )
        )
      )
    )
  );

  public handleLoadedStartPageFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StartPageActionTypes.LoadedStartPageFail),
      map(() => new CleanStartPageState())
    )
  );
}
