<app-modal-template
  [header]="'groups.decision.header'"
  [button]="'groups.decision.buttons.send'"
  (closeModal)="closeModal()"
  (submit)="send()"
>
  <app-make-decision-form
    [formGroup]="formGroup"
    [warnings]="warnings"
    [assignmentParts]="assignmentParts"
    [decisionTypeApproved]="decisionTypeApproved"
    [decisionTypeDisproved]="decisionTypeDisproved"
    [attemptedToSubmit]="submitted"
  ></app-make-decision-form>
</app-modal-template>
