import { DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { NgxSpinnerModule } from 'ngx-spinner';

import { AppComponent } from 'src/app/core/containers/app/app.component';
import { HeaderComponent } from 'src/app/core/containers/header/header.component';
import { NotificationsComponent } from 'src/app/core/containers/notifications/notifications.component';
import { SupportComponent } from 'src/app/core/containers/support/support.component';
import { AppRoutingModule } from 'src/app/core/core-routing.module';
import { AgreementsGuard } from 'src/app/core/guards/agreements.guard';
import { AssignmentGuard } from 'src/app/core/guards/assignment-guard.service';
import { CompanyUsersGuard } from 'src/app/core/guards/company-users-guard.service';
import { StartModule } from 'src/app/core/modules/start/start.module';
import { AgreementsService } from 'src/app/core/services/api/agreements/agreements.service';
import { AssignmentEventService } from 'src/app/core/services/api/assignment-event/assignment-event.service';
import { AssignmentsService } from 'src/app/core/services/api/assignments/assignments.service';
import { ChartService } from 'src/app/core/services/api/chart/chart.service';
import { ExcelService } from 'src/app/core/services/api/excel/excel.service';
import { MeService } from 'src/app/core/services/api/me/me.service';
import { ProjectsService } from 'src/app/core/services/api/projects/projects.service';
import { UserService } from 'src/app/core/services/api/user/user.service';
import { AssetService } from 'src/app/core/services/asset/asset.service';
import { ExternalRedirectService } from 'src/app/core/services/external-redirect/external-redirect.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { A2hsComponent } from './containers/a2hs/a2hs.component';
import { HeaderMenuComponent } from './components/header-menu/header-menu.component';
import { HeaderIconsComponent } from './components/header-icons/header-icons.component';
import { NotificationsListComponent } from './components/notifications-list/notifications-list.component';
import { StartPageGuard } from 'src/app/core/guards/start-page.guard';
import { InviteContractorTemplateComponent } from 'src/app/core/modals/containers/invite-contractor-template/invite-contractor-template.component';
import { MakeDecisionTemplateComponent } from 'src/app/core/modals/containers/make-decision-template/make-decision-template.component';
import { InspectionFormComponent } from 'src/app/core/modals/components/inspection-form/inspection-form.component';
import { InviteFormComponent } from 'src/app/core/modals/components/invite-form/invite-form.component';
import { BookInspectionTemplateComponent } from 'src/app/core/modals/containers/book-inspection-template/book-inspection-template.component';
import { AssignmentNotFromMepsDeletedTemplateComponent } from 'src/app/core/modals/containers/assignment-not-from-meeps-deleted-template/assignment-not-from-meps-deleted-template.component';
import { MakeDecisionFormComponent } from './modals/components/make-decision-form/make-decision-form.component';
import { ProjectTemplateComponent } from 'src/app/core/modals/containers/project-template/project-template.component';
import { ProjectFormComponent } from './modals/components/project-form/project-form.component';
import { PusherMessagesGuard } from 'src/app/core/guards/pusher-messages-guard.service';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { NotificationListMobileComponent } from './components/notification-list-mobile/notification-list-mobile.component';
import { OverviewModule } from 'src/app/core/modules/overview/overview.module';

const COMPONENTS = [
  AppComponent,
  NotificationsComponent,
  NotificationsListComponent,
  SupportComponent,
  HeaderComponent,
  A2hsComponent,
  HeaderMenuComponent,
  HeaderIconsComponent,
  BookInspectionTemplateComponent,
  InviteContractorTemplateComponent,
  MakeDecisionTemplateComponent,
  InspectionFormComponent,
  InviteFormComponent,
  AssignmentNotFromMepsDeletedTemplateComponent,
  ProjectTemplateComponent,
  MakeDecisionFormComponent,
  ProjectFormComponent,
  SideNavComponent,
  NotificationListMobileComponent,
];

@NgModule({
  declarations: [COMPONENTS],
  imports: [
    SharedModule,
    StartModule,
    OverviewModule,
    AppRoutingModule,
    HttpClientModule,
    NgxSpinnerModule,
  ],
  providers: [DatePipe],
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        AgreementsService,
        AssetService,
        AssignmentsService,
        AssignmentEventService,
        AgreementsGuard,
        ExternalRedirectService,
        ExcelService,
        ChartService,
        AssignmentGuard,
        PusherMessagesGuard,
        CompanyUsersGuard,
        MeService,
        UserService,
        ProjectsService,
        StartPageGuard,
      ],
    };
  }
}
