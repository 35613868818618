import {
  AfterViewChecked,
  Directive,
  ElementRef,
  Renderer2,
} from '@angular/core';

@Directive({
  selector: 'input, select, textarea',
})
export class InputsHaveValueDirective implements AfterViewChecked {
  private parentInputGroupName = 'input-group';
  private className = 'have-value';

  constructor(private element: ElementRef, private renderer: Renderer2) {}

  ngAfterViewChecked(): void {
    this.element.nativeElement.value ? this.addClasses() : this.removeClasses();
  }

  private addClasses() {
    this.addClassToInput();
    this.addClassToParent();
  }

  private addClassToInput() {
    this.renderer.addClass(this.element.nativeElement, this.className);
  }
  private addClassToParent() {
    if (
      this.element.nativeElement.parentNode.classList.contains(
        this.parentInputGroupName
      )
    ) {
      this.renderer.addClass(
        this.element.nativeElement.parentNode,
        this.className
      );
    }
  }

  private removeClasses() {
    this.removeClassFromInput();
    this.removeClassFromParent();
  }

  private removeClassFromInput() {
    this.renderer.removeClass(this.element.nativeElement, this.className);
  }
  private removeClassFromParent() {
    if (
      this.element.nativeElement.parentNode.classList.contains(
        this.parentInputGroupName
      )
    ) {
      this.renderer.removeClass(
        this.element.nativeElement.parentNode,
        this.className
      );
    }
  }
}
