import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'uwCurrency',
})
export class UwCurrencyPipe extends CurrencyPipe implements PipeTransform {
  transform(value: number, option?: string, showPlusSymbol?: boolean): any {
    const currency = 'SEK';
    const display = 'code';
    const format = '1.0-0';
    const locale = 'sv';

    if (!!option && option === SPLIT_VALUE) {
      const formattedValue = !!value
        ? this.addPlusSymbol(
            super.transform(value || 0, ' ', display, format, locale),
            showPlusSymbol
          )
        : '-';
      return { value: formattedValue, currency: currency };
    } else {
      return !!value
        ? this.addPlusSymbol(
            super.transform(value || 0, currency, display, format, locale),
            showPlusSymbol
          )
        : '-';
    }
  }

  addPlusSymbol(value: string, shouldAddPlusSymbol): string {
    if (!shouldAddPlusSymbol) {
      return value;
    }

    return !!value && !value.startsWith('−') ? '+' + value : value;
  }
}

export const SPLIT_VALUE = 'splitValue';
