<app-section
  [headerTitle]="'overview.myActions.header'"
  [subText]="' (' + actions?.length + ')'"
  [smallSubHeader]="true"
>
  <span *ngIf="actions?.length > 0; else emptyCard">
    <app-my-actions-card
      [actions]="actions"
      [showMobileActions]="showMobileActions"
      [isSmallScreen]="isSmallScreen"
      (goToDetails)="onGoToDetails($event)"
      (makeAction)="onMakeAction($event)"
      (openMobileActions)="onOpenMobileActions($event)"
    ></app-my-actions-card>
  </span>
</app-section>

<ng-template #emptyCard>
  <div class="actions-empty-card card bg-light mb-3">
    <div class="card-body">
      <div class="actions-empty-card__title">
        <span
          class="actions-empty-card__title-text"
          [translate]="'overview.myActions.emptyCard.done'"
        ></span>
        <img
          class="actions-empty-card__title-icon"
          src="/assets/emoji/hand.png"
        />
      </div>
      <div class="actions-empty-card__text">
        <span [translate]="'overview.myActions.emptyCard.infoPartOne'"></span>
        <span
          class="actions-empty-card__highlight pointer"
          [translate]="'overview.myActions.emptyCard.assignments'"
          (click)="onGoToAssignments()"
        ></span>
        <span [translate]="'overview.myActions.emptyCard.infoPartTwo'"></span>
      </div>
    </div>
  </div>
</ng-template>

<app-my-action-card-button-mobile
  *ngIf="isSmallScreen && !!showMobileActions"
  [action]="showMobileActions"
  [isSmallScreen]="isSmallScreen"
  (makeAction)="onMakeAction($event)"
  (openMobileActions)="onOpenMobileActions($event)"
></app-my-action-card-button-mobile>
