import { Injectable, OnDestroy } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { BaseTemplateComponent } from 'src/app/shared/components/base-template/base-template.component';
import {
  CleanModalState,
  HiddenModal,
  ModalActionTypes,
  ShowModal,
} from 'src/app/core/store/actions/modal.action';

@Injectable()
export class ModalEffects implements OnDestroy {
  translationKey = 'discardChanges';

  private bsModalRef: BsModalRef;
  private subscription: Subscription;

  private modalDefaultOptions: ModalOptions = {
    ignoreBackdropClick: true,
    keyboard: false,
    animated: true,
  };

  private unloadHandler = (evt) => {
    const modalContent = this.getModalContent();

    if (!!modalContent && !modalContent.canDeactivate()) {
      evt.returnValue = 'Question';
    }
  };

  constructor(
    private actions$: Actions,
    private modalService: BsModalService,
    private translationService: TranslateService,
    private router: Router,
    private store: Store
  ) {
    window.addEventListener('beforeunload', this.unloadHandler);
    this.subscription = this.router.events.subscribe((event) => {
      if (
        event instanceof NavigationStart &&
        event.navigationTrigger === 'popstate' &&
        event.restoredState &&
        !!this.bsModalRef
      ) {
        this.store.dispatch(new CleanModalState());
      }
    });
  }

  public cleanModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ModalActionTypes.CleanModalState),
        tap(() => {
          this.bsModalRef = null;
        })
      ),
    { dispatch: false }
  );

  public openModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ModalActionTypes.ShowModal),
        filter(() => this.modalService.getModalsCount() === 0),
        map((action: ShowModal) => {
          this.bsModalRef = this.modalService.show(action.payload.content(), {
            ...this.modalDefaultOptions,
            ...action.payload.config,
          });
        })
      ),
    { dispatch: false }
  );

  public hideModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ModalActionTypes.HideModal),
      filter(() => !!this.bsModalRef),
      tap(() => {
        const component = this.getModalContent();
        const translation = this.translationService.instant(
          this.translationKey
        );

        component.canDeactivate()
          ? this.hideModal()
          : this.askForUnsavedChanged(translation);
      }),
      filter((_) => !this.bsModalRef),
      map((_) => new HiddenModal())
    )
  );

  private getModalContent(): BaseTemplateComponent {
    if (!!this.bsModalRef) {
      return this.bsModalRef.content as BaseTemplateComponent;
    }
  }

  private askForUnsavedChanged(translation: string) {
    if (confirm(translation)) {
      this.hideModal();
    }
  }

  private hideModal() {
    this.bsModalRef.hide();
    this.bsModalRef = null;
  }

  ngOnDestroy(): void {
    window.removeEventListener('beforeunload', this.unloadHandler);
    if (!!this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
