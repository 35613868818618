import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Settings } from '../utils/settings';
import { AuthTokenEnum } from '../../auth/enums/auth-token.enum';

@Injectable()
export class BaseUrlInterceptor implements HttpInterceptor {
  constructor() {}

  // TODO: reconsider this approach when we will be using OpenAPI for generating rest client
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (request instanceof HttpRequest) {
      request = this.replaceBackendUrl(request);
      request = this.addAuthHeader(request);
    }

    return next.handle(request);
  }

  private replaceBackendUrl(request: HttpRequest<any>) {
    const shouldReplace = request.url.includes(environment.url);

    return shouldReplace
      ? request.clone({
          url: request.url.replace(environment.url, Settings.appSettings.url),
        })
      : request;
  }

  private addAuthHeader(request: HttpRequest<any>) {
    const token = localStorage.getItem(AuthTokenEnum.ACCESS_TOKEN);
    const shouldAddHeader = Settings.appSettings.whiteListedDomains.some(
      (url) => request.url.includes(url)
    );

    return !!token && shouldAddHeader
      ? request.clone({
          headers: request.headers.set('Authorization', `Bearer ${token}`),
        })
      : request;
  }
}
