import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-bottom-drower-mobile',
  templateUrl: './bottom-drower-mobile.component.html',
  styleUrls: ['./bottom-drower-mobile.component.scss'],
})
export class BottomDrowerMobileComponent implements OnInit {
  @Input()
  header: string;

  @Input()
  subheader: string;

  @Input()
  small: boolean;

  @Output()
  close: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}

  onCloseMobile() {
    this.close.emit();
  }
}
