import {
  DecisionActions,
  DecisionActionTypes,
} from 'src/app/core/store/actions/decision.action';
import { DecisionI } from 'src/app/core/models/decision/decision.interface';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';

export interface DecisionReducerState {
  loading: boolean;
  loaded: boolean;
  creating: boolean;
  error: ErrorResponseWithIdI;
  decision: DecisionI | undefined;
}

export const initialDecisionReducerState: DecisionReducerState = {
  loading: false,
  loaded: false,
  creating: false,
  error: null,
  decision: undefined,
};

export function decisionReducer(
  state: DecisionReducerState = initialDecisionReducerState,
  action: DecisionActions
): DecisionReducerState {
  switch (action.type) {
    // Make Decision
    case DecisionActionTypes.MakeDecision: {
      return {
        ...state,
        creating: true,
      };
    }

    case DecisionActionTypes.MadeDecisionSuccess: {
      return {
        ...state,
        creating: false,
      };
    }

    case DecisionActionTypes.MadeDecisionFail: {
      return {
        ...state,
        creating: false,
        error: action.payload,
      };
    }

    case DecisionActionTypes.CleanDecisionState: {
      return {
        ...initialDecisionReducerState,
      };
    }

    default: {
      return state;
    }
  }
}

export const getDecisionCreating = (state: DecisionReducerState) =>
  state.creating;
export const getDecisionLoading = (state: DecisionReducerState) =>
  state.loading;
export const getDecisionLoaded = (state: DecisionReducerState) => state.loaded;
export const getDecisionError = (state: DecisionReducerState) => state.error;
export const getDecision = (state: DecisionReducerState) => state.decision;
