import { createSelector } from '@ngrx/store';
import { getPropertyAddressStateSelector } from 'src/app/core/modules/start/store/index';
import {
  getPropertyAddressError,
  getPropertyAddressLoaded,
  getPropertyAddressLoading,
} from 'src/app/core/modules/start/store/reducers/property-address.reducer';
import { idNameResourceAdapter } from 'src/app/core/modules/start/store/utils/id-name-resource.adapter';

export const getPropertyAddressLoadingSelector = createSelector(
  getPropertyAddressStateSelector,
  getPropertyAddressLoading
);

export const getPropertyAddressLoadedSelector = createSelector(
  getPropertyAddressStateSelector,
  getPropertyAddressLoaded
);

export const getPropertyAddressErrorSelector = createSelector(
  getPropertyAddressStateSelector,
  getPropertyAddressError
);

export const getPropertyAddressEntitiesSelector = idNameResourceAdapter.getSelectors(
  getPropertyAddressStateSelector
).selectEntities;
export const getPropertyAddressTotalSelector = idNameResourceAdapter.getSelectors(
  getPropertyAddressStateSelector
).selectTotal;
export const getPropertyAddressIdsSelector = idNameResourceAdapter.getSelectors(
  getPropertyAddressStateSelector
).selectIds;
export const getPropertyAllAddressSelector = idNameResourceAdapter.getSelectors(
  getPropertyAddressStateSelector
).selectAll;
