import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Action, select, Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AuthorizationService } from 'src/app/auth/services/authorization/authorization.service';
import { Logout } from 'src/app/auth/store/actions/auth.action';
import { RecentItem } from 'src/app/core/containers/app/app.component';
import { LanguageEnum } from 'src/app/core/enums/language.enum';
import {
  AdministrationUrlEnum,
  AssignmentUrlEnum,
  CoreUrlEnums,
  NumbersUrlEnum,
} from 'src/app/core/enums/url-paths.enum';
import { StartFormTemplateComponent } from 'src/app/core/modules/start/modals/start/start-form-template.component';
import {
  GoToMessagesClickedAnalytics,
  LogoutClickedAnalytics,
  NavBarMenuClickedAnalytics,
  StartAssignmentClickedAnalytics,
  UserChangedLanguageAnalytics,
  UwHomePageClicked,
} from 'src/app/core/store/actions/google-analytics.actions';
import { LanguageStateAction } from 'src/app/core/store/actions/language.action';
import { ShowModal } from 'src/app/core/store/actions/modal.action';
import { RouterGo } from 'src/app/core/store/actions/router.action';
import { LanguageState } from 'src/app/core/store/reducers/language.reducer';
import {
  getAvailableLanguagesSelector,
  getSelectedLanguageSelector,
} from 'src/app/core/store/selectors/language.selectors';
import { StoreDispatcher } from 'src/app/shared/base-classes/store-dispatcher/store-dispatcher';
import { PusherMessageI } from 'src/app/core/models/message/pusher-message.interface';
import { getRecentPusherMessagesSelector } from 'src/app/core/store/selectors/pusher-messages.selectors';
import { ProjectTemplateComponent } from 'src/app/core/modals/containers/project-template/project-template.component';
import {
  getUserNameSelector,
  isAdminSelector,
} from 'src/app/auth/store/selectors/user.selectors';
import { PageSizeService } from 'src/app/core/services/page-size/page-size.service';
import {
  CloseSideBar,
  OpenSideBar,
} from 'src/app/core/store/actions/side-bar.action';
import { getShowSideBarSelector } from 'src/app/core/store/selectors/side-bar.selectors';
import getAdministrationUrl = AdministrationUrlEnum.getAdministrationUrl;
import getAssignmentUrl = AssignmentUrlEnum.getAssignmentUrl;
import getNumbersUrl = NumbersUrlEnum.getNumbersUrl;
import getAssignmentListAnalysisUrl = AssignmentUrlEnum.getAssignmentListAnalysisUrl;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent extends StoreDispatcher<any> implements OnInit {
  showSideBar$: Observable<boolean>;
  doesTokenExists$: Observable<boolean>;
  isAdmin$: Observable<boolean>;
  messages$: Observable<PusherMessageI[]>;
  userName$: Observable<string>;

  messagesPanelUrl = CoreUrlEnums.NOTIFICATIONS;

  selectedLanguage$: Observable<LanguageEnum>;
  languages$: Observable<LanguageEnum[]>;

  shadowElement: HTMLElement;
  navbarElement: HTMLElement;

  @Input()
  mostRecentAssignments: RecentItem[];

  @Input()
  mostRecentProjects: RecentItem[];

  @Output()
  goToRecentAssignment: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  goToRecentProject: EventEmitter<string> = new EventEmitter<string>();

  @HostListener('window:scroll', ['$event'])
  onClick() {
    if (document.documentElement.scrollTop > 40) {
      this.shadowElement.classList.add('shadow-header-show');
      this.navbarElement.style.paddingBottom = '12px';
    }
    if (document.documentElement.scrollTop < 9) {
      this.shadowElement.classList.remove('shadow-header-show');
      this.navbarElement.style.paddingBottom = '20px';
    }
  }

  startComponent = () => StartFormTemplateComponent;
  modalFunction = () => ProjectTemplateComponent;

  constructor(
    private authService: AuthorizationService,
    public pageSizeService: PageSizeService,
    store: Store<LanguageState>
  ) {
    super(store);
  }

  ngOnInit() {
    this.getData();
    this.getElements();
  }

  getElements() {
    this.shadowElement = document.getElementById('shadow');
    this.navbarElement = document.getElementById('navbar');
  }

  goToSupport() {
    const gaAction = new NavBarMenuClickedAnalytics('support page');
    const action = new RouterGo({
      path: [CoreUrlEnums.SUPPORT],
    });
    this.dispatchActions([action, gaAction]);
  }

  goToProjects() {
    const gaAction = new NavBarMenuClickedAnalytics('projects page');
    const action = new RouterGo({
      path: [CoreUrlEnums.PROJECTS],
    });
    this.dispatchActions([action, gaAction]);
  }

  onGoToStartProject() {
    this.dispatchAction(
      new ShowModal({
        content: this.modalFunction,
      })
    );
  }

  goToAdministration() {
    const gaAction = new NavBarMenuClickedAnalytics('administration page');
    const action = new RouterGo({
      path: [getAdministrationUrl(AdministrationUrlEnum.USERS)],
    });
    this.dispatchActions([action, gaAction]);
  }

  goToAccountDetails() {
    const gaAction = new NavBarMenuClickedAnalytics('account details');
    const action = new RouterGo({
      path: [CoreUrlEnums.ACCOUNT],
    });
    this.dispatchActions([action, gaAction]);
  }

  goToNumbers() {
    const gaAction = new NavBarMenuClickedAnalytics('numbers page');
    const action = new RouterGo({
      path: [getNumbersUrl(NumbersUrlEnum.VOLUME)],
    });
    this.dispatchActions([action, gaAction]);
  }

  goToWatchlist() {
    const gaAction = new NavBarMenuClickedAnalytics('watch list page');
    const action = new RouterGo({
      path: [getAssignmentUrl(AssignmentUrlEnum.WATCH_LIST)],
    });
    this.dispatchActions([action, gaAction]);
  }

  goToMakeAction() {
    const gaAction = new NavBarMenuClickedAnalytics('make action list page');
    const action = new RouterGo({
      path: [getAssignmentUrl(AssignmentUrlEnum.MAKE_ACTION_LIST)],
    });
    this.dispatchActions([action, gaAction]);
  }

  goToProduction() {
    const gaAction = new NavBarMenuClickedAnalytics('production page');
    const action = new RouterGo({
      path: [getAssignmentUrl(AssignmentUrlEnum.ASSIGNMENT_LIST)],
    });
    this.dispatchActions([action, gaAction]);
  }

  // old
  goToStartAssignment() {
    this.dispatchActions([
      new ShowModal({ content: this.startComponent }),
      new StartAssignmentClickedAnalytics(),
    ]);
  }

  goToAssignmentDetails(assignmentId: string) {
    this.hideAndDispatchActions([
      new RouterGo({
        path: [
          getAssignmentListAnalysisUrl(
            AssignmentUrlEnum.ASSIGNMENT,
            assignmentId
          ),
        ],
      }),
    ]);
  }

  goToMessages() {
    this.hideAndDispatchActions([
      new RouterGo({ path: [this.messagesPanelUrl] }),
      new GoToMessagesClickedAnalytics(),
    ]);
  }

  goToMainPage() {
    this.doesTokenExists$
      .pipe(take(1))
      .subscribe((tokenExists: boolean) =>
        tokenExists ? this.redirectToRootDir() : this.redirectToLoginPage()
      );
  }

  private redirectToRootDir(): void {
    this.dispatchActions([
      new RouterGo({ path: ['/'] }),
      new UwHomePageClicked(),
    ]);
  }

  private redirectToLoginPage(): void {
    this.dispatchActions([
      new RouterGo({ path: [CoreUrlEnums.LOGIN] }),
      new UwHomePageClicked(),
    ]);
  }

  logout() {
    this.hideAndDispatchActions([new Logout(), new LogoutClickedAnalytics()]);
  }

  dispatchLanguageAction(action: LanguageStateAction) {
    this.dispatchActions([
      action,
      new UserChangedLanguageAnalytics(action.payload),
    ]);
  }

  hideAndDispatchActions(action: Action[]) {
    this.dispatchActions(action);
  }

  private getData() {
    this.languages$ = this.store.pipe(select(getAvailableLanguagesSelector));
    this.selectedLanguage$ = this.store.pipe(
      select(getSelectedLanguageSelector)
    );
    this.doesTokenExists$ = this.authService.doesTokenExists$;
    this.messages$ = this.store.pipe(select(getRecentPusherMessagesSelector));
    this.isAdmin$ = this.store.pipe(select(isAdminSelector));
    this.showSideBar$ = combineLatest([
      this.pageSizeService.isSmallScreen$,
      this.store.pipe(select(getShowSideBarSelector)),
    ]).pipe(map(([isSmall, show]) => isSmall && show));
    this.userName$ = this.store.pipe(select(getUserNameSelector));
  }

  onGoToRecentAssignment(id: string) {
    this.goToRecentAssignment.emit(id);
  }

  onGoToRecentProject(id: string) {
    this.goToRecentProject.emit(id);
  }

  onHideSideBar() {
    this.dispatchAction(new CloseSideBar());
  }

  onOpenSideBar() {
    this.dispatchAction(new OpenSideBar());
  }
}
