import { EntityState } from '@ngrx/entity';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import { IdNameResourceI } from 'src/app/core/models/property-register/id-name-resource.interface';
import {
  PropertyAddressActions,
  PropertyAddressActionTypes,
} from 'src/app/core/modules/start/store/actions/property-address.actions';
import {
  StartAssignmentsActions,
  StartAssignmentsActionTypes,
} from 'src/app/core/modules/start/store/actions/start-assignments.action';
import { idNameResourceAdapter } from 'src/app/core/modules/start/store/utils/id-name-resource.adapter';

export interface PropertyAddressReducerState
  extends EntityState<IdNameResourceI> {
  loading: boolean;
  loaded: boolean;
  error: ErrorResponseWithIdI;
}

export const initialPropertyAddressReducerState: PropertyAddressReducerState = idNameResourceAdapter.getInitialState(
  {
    loading: false,
    loaded: false,
    error: null,
  }
);

export function propertyAddressReducer(
  state = initialPropertyAddressReducerState,
  action: PropertyAddressActions | StartAssignmentsActions
): PropertyAddressReducerState {
  switch (action.type) {
    case PropertyAddressActionTypes.LoadPropertyAddress: {
      return {
        ...state,
        loading: true,
      };
    }

    case PropertyAddressActionTypes.LoadPropertyAddressSuccess: {
      return idNameResourceAdapter.addMany(action.payload.addresses, {
        ...initialPropertyAddressReducerState,
        loaded: true,
      });
    }

    case PropertyAddressActionTypes.LoadPropertyAddressFail: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload,
      };
    }

    case StartAssignmentsActionTypes.CleanStartAssignmentsState:
    case PropertyAddressActionTypes.CleanPropertyAddress: {
      return initialPropertyAddressReducerState;
    }

    default:
      return state;
  }
}

export const getPropertyAddressLoading = (state: PropertyAddressReducerState) =>
  state.loading;
export const getPropertyAddressLoaded = (state: PropertyAddressReducerState) =>
  state.loaded;
export const getPropertyAddressError = (state: PropertyAddressReducerState) =>
  state.error;
