<div
  class="actions-card-header card-title"
  (click)="onGoToDetails(action?.assignmentId)"
>
  <div class="actions-card-header__details">
    <mat-icon
      class="actions-card-header__status-icon {{ icon?.colour }}"
      *ngIf="getColour(action?.status); let icon"
    >
      {{ icon.icon }}
    </mat-icon>
    <span class="actions-card-header__name">{{ action?.name }}</span>
  </div>

  <div class="actions-card-header__address">
    {{ action?.address }}
  </div>

  <div class="actions-card-header__status">
    <span
      class="actions-card-header__new-status"
      *ngIf="!!action?.status; else noUpdated"
    >
      <mat-icon class="actions-card-header__notification-icon"
        >notifications_active</mat-icon
      >
      <span class="actions-card-header__status-name">{{
        'assignmentStatus.' + action?.status | translate | uppercase
      }}</span>
    </span>
  </div>
</div>

<ng-template #noUpdated>
  <span class="actions-card-header__no-updates">
    <mat-icon class="actions-card-header__done-icon">done_all</mat-icon>
    <span
      class="actions-card-header__no-updates-text"
      [translate]="'overview.myActions.card.noUpdates'"
    ></span>
  </span>
</ng-template>
