import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  getCustomerPublicSelector,
  isPropertyRegisterEnabledSelector,
} from 'src/app/auth/store/selectors/user.selectors';

import { BaseTemplateComponent } from 'src/app/shared/components/base-template/base-template.component';
import { CreateAssignmentDetailsI } from 'src/app/core/models/assignment/assignment-details.interface';
import { IdNameResourceI } from 'src/app/core/models/property-register/id-name-resource.interface';
import { SelectI } from 'src/app/core/models/select.interface';
import { CustomerPublicI } from 'src/app/core/models/user/user-resource.interface';
import {
  CleanStartAssignmentsState,
  StartAssignment,
} from 'src/app/core/modules/start/store/actions/start-assignments.action';
import { getPropertyAllAddressSelector } from 'src/app/core/modules/start/store/selectors/propery-address.selectors';
import { getPropertyAllBuildingSelector } from 'src/app/core/modules/start/store/selectors/propery-building.selectors';
import { getPropertyAllDistrictSelector } from 'src/app/core/modules/start/store/selectors/propery-district.selectors';
import { getPropertyAllObjectSelector } from 'src/app/core/modules/start/store/selectors/propery-object.selectors';
import { getPropertyAllRealEstateSelector } from 'src/app/core/modules/start/store/selectors/propery-real-estate.selectors';
import { ApplicationState } from 'src/app/core/store';
import { getAllClientAssignmentTypesAsSelectObjectForCurrentLanguageSelector } from 'src/app/dictionaries/store/selectors/client-assignment-types.selectors';
import { getAllEstablishmentTypesAsSelectObjectForCurrentLanguageFilterByRealEstateSelector } from 'src/app/dictionaries/store/selectors/establishment-types.selectors';
import { getAllRealEstateTypesAsSelectObjectForCurrentLanguageSelector } from 'src/app/dictionaries/store/selectors/real-estate-types.selectors';
import { ComponentCanDeactivate } from 'src/app/shared/models/can-deactivate.interface';

@Component({
  selector: 'app-start-form-template',
  templateUrl: './start-form-template.component.html',
  styleUrls: ['./start-form-template.component.scss'],
})
export class StartFormTemplateComponent
  extends BaseTemplateComponent
  implements OnInit, OnDestroy, ComponentCanDeactivate {
  defaultRoomHeight = 2.4;
  // Passed from modal invocation
  projectNumber = '';

  confirmationTextKey = 'create.confirmationText';

  addressSelectName = 'locationSearch';
  propertySelectName = 'propertyList';
  addressTypeList = [this.addressSelectName, this.propertySelectName];
  currentAddressType = this.propertySelectName;

  realEstateTypes$: Observable<SelectI[]>;
  establishmentTypes$: Observable<SelectI[]>;
  clientAssignmentTypes$: Observable<SelectI[]>;

  userPublic$: Observable<CustomerPublicI>;

  propertyAddress$: Observable<IdNameResourceI[]>;
  propertyBuilding$: Observable<IdNameResourceI[]>;
  propertyDistrict$: Observable<IdNameResourceI[]>;
  propertyObject$: Observable<IdNameResourceI[]>;
  propertyRealEstate$: Observable<IdNameResourceI[]>;

  attemptedToSubmit = false;
  submitted = false;
  isPropertyRegisterEnabled = false;
  showTenant = false;

  mainForm: FormGroup;
  generalForm: FormGroup;
  tenantForm: FormGroup;
  addressForm: FormGroup;
  propertyForm: FormGroup;

  @HostListener('window:beforeunload')
  canUnload(): boolean | Observable<boolean> {
    return this.canDeactivate();
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    this.checkIfShouldCloseModal(event);
  }

  constructor(
    store: Store<ApplicationState>,
    eRef: ElementRef,
    private fb: FormBuilder,
    private translateService: TranslateService
  ) {
    super(store, eRef);
  }

  ngOnInit() {
    this.getData();
    this.createMainFormBasedOnProperRegisterEnabled();
  }

  getData() {
    this.getUserData();
    this.getTypesData();
    this.getPropertyData();
  }

  createMainFormBasedOnProperRegisterEnabled() {
    this.store
      .pipe(select(isPropertyRegisterEnabledSelector), take(1))
      .subscribe((isEnabled) => {
        this.createMainForm();

        if (isEnabled) {
          this.addPropertyRegisterForm();
          this.disableAddressTab();

          this.isPropertyRegisterEnabled = isEnabled;
        }
      });
  }

  onAddressTypeChange(addressType: string) {
    this.currentAddressType = addressType;
    switch (addressType) {
      case this.propertySelectName:
        this.disableAddressTab();
        break;
      case this.addressSelectName:
        this.disablePropertyTab();
        break;
    }
  }

  submit() {
    this.attemptedToSubmit = true;

    const confirmationText = this.translateService.instant(
      this.confirmationTextKey
    );

    if (this.mainForm.valid && confirm(confirmationText)) {
      const startForm = this.createStartForm();

      this.submitted = true;

      this.closeModal();
      this.dispatchAction(new StartAssignment(startForm));
    }
  }

  ngOnDestroy(): void {
    this.dispatchAction(new CleanStartAssignmentsState());
  }

  private getUserData() {
    this.userPublic$ = this.store.pipe(select(getCustomerPublicSelector));
  }

  private getTypesData() {
    this.realEstateTypes$ = this.store.pipe(
      select(getAllRealEstateTypesAsSelectObjectForCurrentLanguageSelector)
    );
    this.establishmentTypes$ = this.store.pipe(
      select(
        getAllEstablishmentTypesAsSelectObjectForCurrentLanguageFilterByRealEstateSelector
      )
    );
    this.clientAssignmentTypes$ = this.store.pipe(
      select(
        getAllClientAssignmentTypesAsSelectObjectForCurrentLanguageSelector
      )
    );
  }

  private getPropertyData() {
    this.propertyAddress$ = this.store.pipe(
      select(getPropertyAllAddressSelector)
    );
    this.propertyBuilding$ = this.store.pipe(
      select(getPropertyAllBuildingSelector)
    );
    this.propertyDistrict$ = this.store.pipe(
      select(getPropertyAllDistrictSelector)
    );
    this.propertyObject$ = this.store.pipe(
      select(getPropertyAllObjectSelector)
    );
    this.propertyRealEstate$ = this.store.pipe(
      select(getPropertyAllRealEstateSelector)
    );
  }

  private createMainForm() {
    this.createGeneralForm();
    this.createAddressForm();
    this.createTenantForm();

    this.mainForm = this.fb.group({
      generalForm: this.generalForm,
      addressForm: this.addressForm,
      tenant: this.tenantForm,
    });
  }

  private addPropertyRegisterForm() {
    this.createPropertyForm();
    this.mainForm.addControl('propertyRegister', this.propertyForm);
  }

  private createGeneralForm() {
    this.generalForm = this.fb.group({
      assignmentName: this.fb.control('', Validators.required),
      externalAssignmentId: this.fb.control(''),
      externalProjectId: this.fb.control(this.projectNumber),
      clientAssignmentType: this.fb.control('', Validators.required),
      startDate: this.fb.control(''),
      endDate: this.fb.control(''),
      budget: this.fb.control(''),
      scope: this.fb.control('', Validators.required),
      propertyDesignation: this.fb.control(''),
      propertyType: this.fb.control('', Validators.required),
      establishmentType: this.fb.control(
        { value: '', disabled: true },
        Validators.required
      ),
    });
  }

  private createTenantForm() {
    this.tenantForm = this.fb.group({
      name: this.fb.control(''),
      email: this.fb.control('', Validators.email),
      phone: this.fb.control(''),
    });
    this.tenantForm.setValidators(this.nameShouldBeIncludedValidator());
  }

  public nameShouldBeIncludedValidator(): ValidatorFn {
    return (group: FormGroup): ValidationErrors => {
      const nameControl = group.controls['name'];

      const hasFormGroupValue = Object.keys(group.controls)
        .filter((key) => key !== 'name')
        .some(
          (key) =>
            !!group.controls[key].value && group.controls[key].value !== ''
        );

      hasFormGroupValue && (!nameControl.value || nameControl.value === '')
        ? nameControl.setErrors({ missingName: true })
        : nameControl.setErrors(null);

      return;
    };
  }

  private createAddressForm() {
    this.addressForm = this.fb.group({
      address: this.fb.control('', Validators.required),
      postalCode: this.fb.control('', Validators.required),
      city: this.fb.control('', Validators.required),
      objectNumber: this.fb.control('', Validators.required),
    });
  }

  private createPropertyForm() {
    this.propertyForm = this.fb.group({
      districtId: this.fb.control('', Validators.required),
      realEstateId: this.fb.control('', Validators.required),
      buildingId: this.fb.control('', Validators.required),
      addressId: this.fb.control('', Validators.required),
      objectId: this.fb.control('', Validators.required),
    });

    // Set timeout is needed because of Angular bug
    // https://github.com/angular/angular/issues/22556
    // Remove this timout when Angular team solve problem
    setTimeout(() => {
      this.propertyForm.controls['realEstateId'].disable({ emitEvent: false });
      this.propertyForm.controls['buildingId'].disable({ emitEvent: false });
      this.propertyForm.controls['addressId'].disable({ emitEvent: false });
      this.propertyForm.controls['objectId'].disable({ emitEvent: false });
    }, 1);
  }

  private disableAddressTab() {
    this.addressForm.disable({ onlySelf: false });
    this.propertyForm.enable({ onlySelf: false });
  }

  private disablePropertyTab() {
    this.propertyForm.disable({ onlySelf: false });
    this.addressForm.enable({ onlySelf: false });
  }

  // TODO: Make this code simpler
  private createStartForm(): CreateAssignmentDetailsI {
    let startRequest: CreateAssignmentDetailsI = {
      ...this.generalForm.value,
      tenant: this.checkIfTenantFormHaveAnyValueNotNull()
        ? this.tenantForm.value
        : null,
    };

    if (this.isPropertyRegisterEnabled) {
      if (this.propertyForm.enabled) {
        startRequest.property = this.propertyForm.value;
      } else {
        startRequest = {
          ...startRequest,
          ...this.addressForm.value,
        };
      }
    } else {
      startRequest = {
        ...startRequest,
        ...this.addressForm.value,
      };
    }

    return startRequest;
  }

  private checkIfTenantFormHaveAnyValueNotNull(): boolean {
    return Object.keys(this.tenantForm.controls).some(
      (key) => !!this.tenantForm.get(key).value
    );
  }

  canDeactivate(): boolean | Observable<boolean> {
    return !this.mainForm.dirty || this.submitted;
  }

  addTenant() {
    this.showTenant = true;
  }

  removeTenant() {
    this.showTenant = false;
    this.tenantForm.reset();
  }
}
