export enum AssignmentActionsEnum {
  CLOSE = 'close',
  REMOVE = 'remove',
  REOPEN = 'reOpen',

  INVITE = 'invite',
  DECISION = 'decision',
  DECISION_APPROVE = 'decisionApprove',
  DECISION_REJECT = 'decisionReject',

  BOOK_INSPECTION = 'bookInspection',
}
