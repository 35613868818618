import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ExternalRedirectService } from 'src/app/core/services/external-redirect/external-redirect.service';
import {
  ExternalRedirectActionTypes,
  GoToEmail,
  GoToMeps,
  GoToMepsConversation,
  GoToNewWindow,
  GoToPhoneNumber,
  GoToSearch,
} from 'src/app/core/store/actions/external-redirect.action';

@Injectable()
export class ExternalRedirectEffects {
  constructor(
    private externalRedirectService: ExternalRedirectService,
    private actions$: Actions
  ) {}

  goToSearch$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ExternalRedirectActionTypes.GoToSearch),
        tap((action: GoToSearch) =>
          this.externalRedirectService.goToSearch(action.payload)
        )
      ),
    { dispatch: false }
  );

  goToMeps$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ExternalRedirectActionTypes.GoToMeps),
        tap((action: GoToMeps) =>
          this.externalRedirectService.goToMeps(action.payload)
        )
      ),
    { dispatch: false }
  );

  goToMepsConversation$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ExternalRedirectActionTypes.GoToMepsConversation),
        tap((action: GoToMepsConversation) =>
          this.externalRedirectService.goToMepsConversation(action.payload)
        )
      ),
    { dispatch: false }
  );

  goToNewWindow$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ExternalRedirectActionTypes.GoToNewWindow),
        tap((action: GoToNewWindow) =>
          this.externalRedirectService.goToNewWindow(action.payload)
        )
      ),
    { dispatch: false }
  );

  goToEmail$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ExternalRedirectActionTypes.GoToEmail),
        tap((action: GoToEmail) =>
          this.externalRedirectService.goToEmail(action.payload)
        )
      ),
    { dispatch: false }
  );

  goToPhoneNumber$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ExternalRedirectActionTypes.GoToPhoneNumber),
        tap((action: GoToPhoneNumber) =>
          this.externalRedirectService.goToPhoneNumber(action.payload)
        )
      ),
    { dispatch: false }
  );
}
