import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import { PropertyRealEstateI } from 'src/app/core/models/property-register/property-real-estate.interface';
import { CleanPropertyAddress } from 'src/app/core/modules/start/store/actions/property-address.actions';
import {
  CleanPropertyBuilding,
  LoadPropertyBuildings,
} from 'src/app/core/modules/start/store/actions/property-building.actions';
import { CleanPropertyObject } from 'src/app/core/modules/start/store/actions/property-object.actions';
import {
  LoadPropertyRealEstates,
  LoadPropertyRealEstatesFail,
  LoadPropertyRealEstatesSuccess,
  PropertyRealEstateActionTypes,
  PropertyRealEstatesChanged,
} from 'src/app/core/modules/start/store/actions/property-real-estate.actions';
import { PropertyRegisterService } from 'src/app/core/services/api/property-register/property-register.service';

@Injectable()
export class PropertyRealEstateEffects {
  constructor(
    private actions$: Actions,
    private propertyRegisterService: PropertyRegisterService
  ) {}

  public handleNewPropertyRealEstate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PropertyRealEstateActionTypes.PropertyRealEstatesChanged),
      switchMap((action: PropertyRealEstatesChanged) => [
        new CleanPropertyBuilding(),
        new CleanPropertyAddress(),
        new CleanPropertyObject(),
        new LoadPropertyBuildings(action.payload),
      ])
    )
  );

  public getPropertyRealEstate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PropertyRealEstateActionTypes.LoadPropertyRealEstates),
      switchMap((action: LoadPropertyRealEstates) =>
        this.propertyRegisterService.getRealEstate(action.payload).pipe(
          map(
            (propertyRealEstate: PropertyRealEstateI) =>
              new LoadPropertyRealEstatesSuccess(propertyRealEstate)
          ),
          catchError((error: ErrorResponseWithIdI) =>
            of(new LoadPropertyRealEstatesFail(error))
          )
        )
      )
    )
  );
}
