import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { getRoomElementsTypesStateSelector } from 'src/app/dictionaries/store';
import { LoadRoomElementsTypes } from 'src/app/dictionaries/store/actions/room-elements-types.action';
import { RoomElementsTypesReducerState } from 'src/app/dictionaries/store/reducers/room-elements-types.reducer';

@Injectable()
export class RoomElementsTypesGuard implements CanActivate, CanActivateChild {
  constructor(private store: Store<RoomElementsTypesReducerState>) {}

  canActivate() {
    return this.waitForRoomElementsTypesToLoad().pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  canActivateChild() {
    return this.waitForRoomElementsTypesToLoad().pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  waitForRoomElementsTypesToLoad(): Observable<boolean> {
    return this.store.pipe(
      select(getRoomElementsTypesStateSelector),
      tap((spaceTypesState: RoomElementsTypesReducerState) => {
        if (
          !spaceTypesState.loaded &&
          !spaceTypesState.loading &&
          !spaceTypesState.error
        ) {
          this.store.dispatch(new LoadRoomElementsTypes());
        }
      }),
      filter(
        (spaceTypesState: RoomElementsTypesReducerState) =>
          spaceTypesState.loaded || !!spaceTypesState.error
      ),
      map((spaceTypesState: RoomElementsTypesReducerState) => {
        if (!!spaceTypesState.error) {
          throw new Error(spaceTypesState.error.error.message);
        } else {
          return spaceTypesState.loaded;
        }
      }),
      take(1)
    );
  }
}
