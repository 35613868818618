<app-display-value
  [label]="'administration.users.name'"
  [value]="user?.contact?.name"
></app-display-value>

<app-display-value
  [label]="'administration.users.department'"
  [value]="user?.department"
></app-display-value>

<app-display-value
  [label]="'administration.users.role'"
  [value]="'administration.userRoles.' + user?.role | translate"
></app-display-value>
<span *ngIf="user?.inspector" class="inspector">
  {{ 'administration.users.inspector' | translate | uppercase }}
</span>

<app-display-value
  [label]="'administration.users.externalId'"
  [value]="user?.externalId"
></app-display-value>

<app-display-value
  [label]="'administration.users.email'"
  [value]="user?.contact?.email"
></app-display-value>

<app-display-value
  [label]="'administration.users.phone'"
  [value]="user?.contact?.phone"
></app-display-value>

<app-display-value
  [label]="'administration.users.language'"
  [value]="user?.language | translate"
></app-display-value>

<div
  class="btn-secondary-link btn-secondary-link--small mt-3"
  [translate]="'administration.users.editUser'"
  (click)="onEditDetails()"
></div>
