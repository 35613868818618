import { Component, Input, OnInit } from '@angular/core';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { SelectI } from 'src/app/core/models/select.interface';
import { BaseFormComponent } from 'src/app/shared/components/base-form/base-form.component';

@Component({
  selector: 'app-general-information',
  templateUrl: './general-information.component.html',
  styleUrls: ['./general-information.component.scss'],
})
export class GeneralInformationComponent
  extends BaseFormComponent<GeneralInformationComponent>
  implements OnInit {
  @Input()
  clientAssignmentTypes: SelectI[];

  bsConfig: Partial<BsDatepickerConfig> = {
    containerClass: 'theme-default',
    adaptivePosition: true,
  };

  constructor() {
    super();
  }

  ngOnInit() {}
}
