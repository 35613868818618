import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { PropertyAddressI } from 'src/app/core/models/property-register/property-address.interface';
import { PropertyBuildingI } from 'src/app/core/models/property-register/property-building.interface';
import { PropertyDistrictI } from 'src/app/core/models/property-register/property-district.interface';
import { PropertyObjectI } from 'src/app/core/models/property-register/property-object.interface';
import { PropertyRealEstateI } from 'src/app/core/models/property-register/property-real-estate.interface';
import { PropertyAddressRequestI } from 'src/app/core/models/property-register/request/property-address-request.interface';
import { PropertyBuildingRequestI } from 'src/app/core/models/property-register/request/property-building-request.interface';
import { PropertyObjectRequestI } from 'src/app/core/models/property-register/request/property-object-request.interface';
import { PropertyRealEstateRequestI } from 'src/app/core/models/property-register/request/property-real-estate-request.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PropertyRegisterService {
  private districtUrl = environment.url + 'api/v1/district/';
  private realEstateUrl = environment.url + 'api/v1/real-estate/district/';
  private buildingUrl = environment.url + 'api/v1/building/district/';
  private addressUrl = environment.url + 'api/v1/address/district/';
  private objectUrl = environment.url + 'api/v1/object/district/';

  constructor(private http: HttpClient) {}

  getDistrict(): Observable<PropertyDistrictI> {
    return this.http.get<PropertyDistrictI>(this.districtUrl);
  }

  getRealEstate({
    districtId,
  }: PropertyRealEstateRequestI): Observable<PropertyRealEstateI> {
    return this.http.get<PropertyRealEstateI>(
      `${this.realEstateUrl + districtId}`
    );
  }

  getBuilding({
    districtId,
    realEstateId,
  }: PropertyBuildingRequestI): Observable<PropertyBuildingI> {
    return this.http.get<PropertyBuildingI>(
      `${this.buildingUrl + districtId}/real-estate/${realEstateId}`
    );
  }

  getAddress({
    districtId,
    realEstateId,
    buildingId,
  }: PropertyAddressRequestI): Observable<PropertyAddressI> {
    return this.http.get<PropertyAddressI>(
      `${this.addressUrl +
        districtId}/real-estate/${realEstateId}/building/${buildingId}`
    );
  }

  getObject({
    districtId,
    realEstateId,
    buildingId,
    addressId,
  }: PropertyObjectRequestI): Observable<PropertyObjectI> {
    return this.http.get<PropertyObjectI>(
      `${this.objectUrl +
        districtId}/real-estate/${realEstateId}/building/${buildingId}/address/${addressId}`
    );
  }
}
