import { Action } from '@ngrx/store';
import {
  HideLoader,
  ShowLoader,
} from 'src/app/core/decorators/loader.decorator';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import { PropertyDistrictI } from 'src/app/core/models/property-register/property-district.interface';
import { PropertyRealEstateRequestI } from 'src/app/core/models/property-register/request/property-real-estate-request.interface';

export enum PropertyDistrictActionTypes {
  PropertyDistrictsChanged = '[PropertyDistrict] Property Districts Changed',

  LoadPropertyDistricts = '[PropertyDistrict] Load Property Districts',
  LoadPropertyDistrictsSuccess = '[PropertyDistrict] Load Property Districts Success',
  LoadPropertyDistrictsFail = '[PropertyDistrict] Load Property Districts Fail',
}

export class PropertyDistrictsChanged implements Action {
  readonly type = PropertyDistrictActionTypes.PropertyDistrictsChanged;

  constructor(public payload: PropertyRealEstateRequestI) {}
}

@ShowLoader()
export class LoadPropertyDistricts implements Action {
  readonly type = PropertyDistrictActionTypes.LoadPropertyDistricts;
}

@HideLoader(PropertyDistrictActionTypes.LoadPropertyDistricts)
export class LoadPropertyDistrictsSuccess implements Action {
  readonly type = PropertyDistrictActionTypes.LoadPropertyDistrictsSuccess;

  constructor(public payload: PropertyDistrictI) {}
}

@HideLoader(PropertyDistrictActionTypes.LoadPropertyDistricts)
export class LoadPropertyDistrictsFail implements Action {
  readonly type = PropertyDistrictActionTypes.LoadPropertyDistrictsFail;

  constructor(public payload: ErrorResponseWithIdI) {}
}

export type PropertyDistrictActions =
  | PropertyDistrictsChanged
  | LoadPropertyDistricts
  | LoadPropertyDistrictsSuccess
  | LoadPropertyDistrictsFail;
