import { Component, OnInit, ViewChild } from '@angular/core';
import { AssignmentStatusEnum } from 'src/app/core/enums/assignment-status.enum';
import { getStatusToIcon } from 'src/app/core/utils/status-to-icon';
import { TableSettings } from 'src/app/core/utils/table-utils';
import { BaseListTableComponent } from 'src/app/shared/components/base-list-table/base-list-table.component';

@Component({
  selector: 'app-notifications-list',
  templateUrl: './notifications-list.component.html',
  styleUrls: ['./notifications-list.component.scss'],
})
export class NotificationsListComponent
  extends BaseListTableComponent
  implements OnInit {
  @ViewChild('table', { static: true }) table: any;

  tableSettings = TableSettings;

  constructor() {
    super();
  }

  ngOnInit(): void {}

  getColour(type: AssignmentStatusEnum): { icon: string; colour: string } {
    return getStatusToIcon(type);
  }
}
