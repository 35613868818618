import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CalculationsI } from 'src/app/assignments/modules/analysis/store/models/calculations.interface';
import { ChangeRequestRequestI } from 'src/app/assignments/modules/analysis/store/models/change-request.interface';

import { CreateAssignmentDetailsI } from 'src/app/core/models/assignment/assignment-details.interface';
import { ChangeRequestModelI } from 'src/app/core/models/assignment/change-request-model.interface';
import { BookInspectionI } from 'src/app/core/models/book-inspection/book-inspection.interface';
import { ChangeOwnerRequestI } from 'src/app/core/models/change-owner/change-owner-request.interface';
import { DecisionRequestI } from 'src/app/core/models/decision/decision-request.interface';
import { AssignmentEditPayloadI } from 'src/app/core/models/edit-assignment/assignment-edit-payload.interface';
import { InviteContractorI } from 'src/app/core/models/invite/invite-contractor.interface';
import { PdfPayloadI } from 'src/app/core/models/pdf-payload.interface';
import { environment } from 'src/environments/environment';
import { StringResourceI } from 'src/app/core/models/string-resource.interface';
import { AssignmentI } from 'src/app/core/models/assignment/assignment.interface';
import { AssignmentSearchRequest } from 'src/app/core/models/search/assignment-search-request.interface';
import { AssignmentSearchResponse } from 'src/app/core/models/search/assignment-search-response.interface';
import { AnalysisAssignmentI } from 'src/app/core/models/analysis-assignment/analysis-assignment.interface';
import { AssignmentLogResourceI } from 'src/app/core/models/log/assignment-log-resource.interface';
import { AssignmentEventI } from 'src/app/core/models/assignment/assignment-event.interface';
import { MaterialI } from 'src/app/core/models/assignment/material.interface';

@Injectable({ providedIn: 'root' })
export class AssignmentsService {
  assignmentsUrl = environment.url + 'api/v1/assignment/';

  // Actions
  closeAssignmentUrl = this.assignmentsUrl + 'close/';
  removeAssignmentUrl = this.assignmentsUrl + 'remove/';
  reopenAssignmentUrl = this.assignmentsUrl + 'reopen/';

  inviteUrl = this.assignmentsUrl + 'invite/';
  decisionUrl = this.assignmentsUrl + 'decision/';

  bookInspectionUrl = this.assignmentsUrl + 'inspection/';

  // Edit
  editAssignmentUrl = this.assignmentsUrl + 'edit/';
  changeOwnerUrl = this.assignmentsUrl + 'change-owner/';

  // Details
  searchAssignmentsUrl = this.assignmentsUrl + 'search/';
  singleAssignmentsUrl = this.assignmentsUrl;
  assignmentEventsUrl = this.assignmentsUrl + 'events/';

  // Infos
  analysisUrl = this.assignmentsUrl + 'analysis/';
  logUrl = this.assignmentsUrl + 'log/';

  changeRequestUrl = this.assignmentsUrl + 'change-request/';
  changeRequestCalculationsUrl =
    this.assignmentsUrl + 'change-request-calculations/';

  materialsUrl = this.assignmentsUrl + 'material/';
  // Other
  printPdfUrl = this.assignmentsUrl + 'contractor-operations';

  constructor(private http: HttpClient) {}

  startAssignment(
    assignment: CreateAssignmentDetailsI
  ): Observable<StringResourceI> {
    return this.http.post<StringResourceI>(this.assignmentsUrl, assignment);
  }

  closeAssignment(assignmentId: string): Observable<string> {
    return this.http.post<string>(this.closeAssignmentUrl + assignmentId, {});
  }

  removeAssignment(assignmentId: string): Observable<string> {
    return this.http.post<string>(this.removeAssignmentUrl + assignmentId, {});
  }

  reopenAssignment(assignmentId: string): Observable<string> {
    return this.http.post<string>(this.reopenAssignmentUrl + assignmentId, {});
  }

  inviteContractor({
    agreementId,
    assignmentType,
    assignmentId,
    message,
  }: InviteContractorI): Observable<void> {
    return this.http.post<void>(this.inviteUrl + assignmentId, {
      agreementId,
      assignmentType,
      message,
    });
  }

  bookInspection(bookInspectionI: BookInspectionI) {
    return this.http.post<number>(this.bookInspectionUrl, bookInspectionI);
  }

  makeDecision(decision: DecisionRequestI): Observable<void> {
    return this.http.post<void>(
      this.decisionUrl + decision.assignmentId,
      decision.decisionResource
    );
  }

  changeOwner(
    request: ChangeOwnerRequestI,
    assignmentId: string
  ): Observable<void> {
    return this.http.put<void>(this.changeOwnerUrl + assignmentId, request);
  }

  editAssignment(payload: AssignmentEditPayloadI): Observable<void> {
    return this.http.put<void>(
      this.editAssignmentUrl + payload.assignmentId,
      payload.assignmentEdit
    );
  }

  getAssignment(assignmentId: string): Observable<AssignmentI> {
    return this.http.get<AssignmentI>(this.singleAssignmentsUrl + assignmentId);
  }

  getAssignmentEvents(assignmentId: string): Observable<AssignmentEventI[]> {
    return this.http.get<AssignmentEventI[]>(
      this.assignmentEventsUrl + assignmentId
    );
  }

  searchForAssignments(
    assignmentSearchRequest: AssignmentSearchRequest
  ): Observable<AssignmentSearchResponse> {
    return this.http.post<AssignmentSearchResponse>(
      this.searchAssignmentsUrl,
      assignmentSearchRequest
    );
  }

  getAnalysisAssignment(assignmentId: string): Observable<AnalysisAssignmentI> {
    return this.http.get<AnalysisAssignmentI>(this.analysisUrl + assignmentId);
  }

  getAssignmentLog(assignmentId: string): Observable<AssignmentLogResourceI> {
    return this.http.get<any>(this.logUrl + assignmentId);
  }

  getChangeRequest(
    changeRequestRequest: ChangeRequestRequestI
  ): Observable<ChangeRequestModelI> {
    let params = new HttpParams();
    Object.keys(changeRequestRequest)
      .filter((x) => x !== 'assignmentId')
      .filter((x) => !!changeRequestRequest[x])
      .forEach((x) => {
        params = params.append(x, changeRequestRequest[x]);
      });

    return this.http.get<ChangeRequestModelI>(
      this.changeRequestUrl + changeRequestRequest.assignmentId,
      { params }
    );
  }

  getCalculations(assignmentId: string): Observable<CalculationsI> {
    return this.http.get<CalculationsI>(
      this.changeRequestCalculationsUrl + assignmentId
    );
  }

  getMaterialCost(assignmentId: string): Observable<MaterialI[]> {
    return this.http.get<MaterialI[]>(this.materialsUrl + assignmentId);
  }

  printPdf(payload: PdfPayloadI): Observable<StringResourceI> {
    return this.http.post<StringResourceI>(
      `${this.printPdfUrl}/${payload.assignmentId}/${payload.newEventId}/${payload.oldEventId}`,
      {}
    );
  }
}
