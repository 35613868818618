import { createSelector } from '@ngrx/store';

import { getAlertMessagesStateSelector } from 'src/app/core/store/index';
import {
  getAlertMessagesEntities,
  getAlertMessagesIds,
  getAlertMessagesTotal,
  getAllAlertMessages,
  getShowMissingExternalId,
} from 'src/app/core/store/reducers/alert-messages.reducer';
import { AlertMessage } from 'src/app/core/models/alert-message.interface';
import { AlertMessagesTypes } from 'src/app/core/enums/alert-messages-types.enum';
import { enumValues } from 'src/app/core/utils/enum-values';

export const getAlertMessagesIdsSelector = createSelector(
  getAlertMessagesStateSelector,
  getAlertMessagesIds
);

export const getAlertMessagesEntitiesSelector = createSelector(
  getAlertMessagesStateSelector,
  getAlertMessagesEntities
);

export const getAllAlertMessagesSelector = createSelector(
  getAlertMessagesStateSelector,
  getAllAlertMessages
);

export const getAlertMessagesTotalSelector = createSelector(
  getAlertMessagesStateSelector,
  getAlertMessagesTotal
);

export const getShowMissingExternalIdSelector = createSelector(
  getAlertMessagesStateSelector,
  getShowMissingExternalId
);

export const getAllAlertsByStatus = createSelector(
  getAllAlertMessagesSelector,
  (alerts: AlertMessage[]) =>
    enumValues(AlertMessagesTypes).reduce((previous, current) => {
      return {
        ...previous,
        [current]: alerts.filter((x) => x.type === current),
      };
    }, {})
);
