import { Action } from '@ngrx/store';
import {
  HideLoader,
  ShowLoader,
} from 'src/app/core/decorators/loader.decorator';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import { PropertyBuildingI } from 'src/app/core/models/property-register/property-building.interface';
import { PropertyAddressRequestI } from 'src/app/core/models/property-register/request/property-address-request.interface';
import { PropertyBuildingRequestI } from 'src/app/core/models/property-register/request/property-building-request.interface';

export enum PropertyBuildingActionTypes {
  PropertyBuildingsChanged = '[PropertyBuilding] Property Buildings Changed',

  LoadPropertyBuildings = '[PropertyBuilding] Load Property Buildings',
  LoadPropertyBuildingsSuccess = '[PropertyBuilding] Load Property Buildings Success',
  LoadPropertyBuildingsFail = '[PropertyBuilding] Load Property Buildings Fail',

  CleanPropertyBuilding = '[PropertyBuilding] Clean Property Building',
}

export class PropertyBuildingsChanged implements Action {
  readonly type = PropertyBuildingActionTypes.PropertyBuildingsChanged;

  constructor(public payload: PropertyAddressRequestI) {}
}

@ShowLoader()
export class LoadPropertyBuildings implements Action {
  readonly type = PropertyBuildingActionTypes.LoadPropertyBuildings;

  constructor(public payload: PropertyBuildingRequestI) {}
}

@HideLoader(PropertyBuildingActionTypes.LoadPropertyBuildings)
export class LoadPropertyBuildingsSuccess implements Action {
  readonly type = PropertyBuildingActionTypes.LoadPropertyBuildingsSuccess;

  constructor(public payload: PropertyBuildingI) {}
}

@HideLoader(PropertyBuildingActionTypes.LoadPropertyBuildings)
export class LoadPropertyBuildingsFail implements Action {
  readonly type = PropertyBuildingActionTypes.LoadPropertyBuildingsFail;

  constructor(public payload: ErrorResponseWithIdI) {}
}

export class CleanPropertyBuilding implements Action {
  readonly type = PropertyBuildingActionTypes.CleanPropertyBuilding;
}

export type PropertyBuildingActions =
  | PropertyBuildingsChanged
  | LoadPropertyBuildings
  | LoadPropertyBuildingsSuccess
  | LoadPropertyBuildingsFail
  | CleanPropertyBuilding;
