import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import { DictionaryI } from 'src/app/dictionaries/models/dictionary.interface';
import {
  CleanRoomElementsTypesState,
  LoadedRoomElementsTypesFail,
  LoadedRoomElementsTypesSuccess,
  RoomElementsTypesActionTypes,
} from 'src/app/dictionaries/store/actions/room-elements-types.action';
import { AssetService } from 'src/app/core/services/asset/asset.service';

@Injectable()
export class RoomElementsTypesEffects {
  constructor(private actions$: Actions, private assetService: AssetService) {}

  public loadRoomElementsTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoomElementsTypesActionTypes.LoadRoomElementsTypes),
      switchMap(() =>
        this.assetService.getRoomElementsTypes().pipe(
          map(
            (commonTypes: DictionaryI[]) =>
              new LoadedRoomElementsTypesSuccess(commonTypes)
          ),
          catchError((error: ErrorResponseWithIdI) =>
            of(new LoadedRoomElementsTypesFail(error))
          )
        )
      )
    )
  );

  public handleLoadedRoomElementsTypesFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoomElementsTypesActionTypes.LoadedRoomElementsTypesFail),
      map(() => new CleanRoomElementsTypesState())
    )
  );
}
