<app-modal-template
  [header]="'contractor.invite'"
  [button]="'contractor.buttons.invite'"
  (closeModal)="closeModal()"
  (submit)="inviteContractor()"
>
  <app-invite-form
    [formGroup]="formGroup"
    [contractors]="contractors$ | async"
    [assignmentTypes]="assignmentTypes$ | async"
    [attemptedToSubmit]="submitted"
  ></app-invite-form>
</app-modal-template>
