import { SortEnum } from 'src/app/core/enums/sort.enum';

export const mobilePagination = (allItems, tableSizeLimit, currentPage) => {
  const startItem = (currentPage - 1) * tableSizeLimit;
  const endItem = currentPage * tableSizeLimit;
  return allItems.slice(startItem, endItem);
};

export const mobileSort = (firstItem, secondItem, order) => {
  if (order === SortEnum.DESC) {
    return secondItem - firstItem;
  }
  if (order === SortEnum.ASC) {
    return firstItem - secondItem;
  }
};
