<app-bottom-drower-mobile
  [header]="'mobileActions.headerActions' | translate"
  [small]="true"
  (click)="onCloseMobileActions()"
>
  <div class="analysis-dropdown-single-assignment-mobile__options">
    <button
      class="analysis-dropdown-single-assignment-mobile__options-button btn btn-third"
      (click)="onGoToMeps(assignment?.mepsId)"
      [translate]="'dropdown-menu.goToMeps'"
    ></button>

    <button
      class="analysis-dropdown-single-assignment-mobile__options-button btn btn-third"
      (click)="onGoToChatInMeps(assignment?.mepsId)"
      [translate]="'dropdown-menu.goToMepsConversation'"
    ></button>

    <ng-container
      *ngIf="
        !assignment?.anyPending &&
        !!assignment?.possibleActions &&
        assignment?.possibleActions.length > 0
      "
    >
      <button
        class="analysis-dropdown-single-assignment-mobile__options-button btn btn-third"
        (click)="onClose(assignment?.assignmentId)"
        *ngIf="
          assignment?.possibleActions?.includes(assignmentActionsEnum.CLOSE) &&
          assignment?.createdInUnityWorks
        "
        [translate]="'assignmentActions.close'"
      ></button>

      <button
        class="analysis-dropdown-single-assignment-mobile__options-button btn btn-third"
        (click)="onReopen(assignment?.assignmentId)"
        *ngIf="
          assignment?.possibleActions?.includes(assignmentActionsEnum.REOPEN) &&
          assignment?.createdInUnityWorks
        "
        [translate]="'assignmentActions.reOpen'"
      ></button>
    </ng-container>
  </div>

  <div
    class="analysis-dropdown-single-assignment-mobile__buttons"
    *ngIf="
      !assignment?.anyPending &&
      !!assignment?.possibleActions &&
      assignment?.possibleActions?.includes(assignmentActionsEnum.REMOVE)
    "
  >
    <button
      class="analysis-dropdown-single-assignment-mobile__buttons-remove btn btn-primary btn-danger info w-100"
      [translate]="'assignmentActions.remove'"
      (click)="
        onRemove(assignment?.assignmentId, assignment?.createdInUnityWorks)
      "
    ></button>
  </div>
</app-bottom-drower-mobile>
