import { NavigationExtras } from '@angular/router';
import { Action } from '@ngrx/store';

export enum RouterActionTypes {
  Go = '[Router] Go',
  GoRelative = '[Router] Go Relative',
  UpdateQueryParams = '[Router] Update Query Params',
  Back = '[Router] Back',
  Forward = '[Router] Set Forward',
}

export class RouterGo implements Action {
  readonly type = RouterActionTypes.Go;

  constructor(
    public payload: {
      path: any[];
      query?: object;
      extras?: NavigationExtras;
    }
  ) {}
}

export class RouterGoRelative implements Action {
  readonly type = RouterActionTypes.GoRelative;

  constructor(
    public payload: {
      path: any[];
      query?: object;
      extras?: NavigationExtras;
      relativeToComponent?: string;
    }
  ) {}
}

export class RouterUpdateQueryParams implements Action {
  readonly type = RouterActionTypes.UpdateQueryParams;

  constructor(public payload: { query: object }) {}
}

export class RouterBack implements Action {
  readonly type = RouterActionTypes.Back;
}

export class RouterForward implements Action {
  readonly type = RouterActionTypes.Forward;
}

export type RouterActions =
  | RouterGo
  | RouterGoRelative
  | RouterUpdateQueryParams
  | RouterBack
  | RouterForward;
