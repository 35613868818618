import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss'],
})
export class SupportComponent implements OnInit {
  constructor() {}

  ngOnInit() {}

  reload() {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .getRegistration('/')
        .then((x) => x.unregister())
        .then(() => caches.keys())
        .then((cacheNames) => {
          cacheNames.forEach(function (cacheName) {
            caches.delete(cacheName);
          });
          location.reload();
        })
        .catch((x) => console.log('unable to unregister'));
    }
  }
}
