import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ChartSeriesCalculation } from 'src/app/shared/models/chart.interface';
import { environment } from 'src/environments/environment';

@Injectable()
export class ChartService {
  chartUrl = environment.url + 'api/v1/chart/';

  // TODO: Remove all this from be
  numberChartUrl = this.chartUrl + 'customer-assignments-numbers';
  periodFilterUrl = this.chartUrl + 'period-filters';
  districtNumberChartUrl = this.chartUrl + 'customer-organization-number';
  districtVolumeChartUrl = this.chartUrl + 'customer-organization-volume';
  contractorsDeclinedChartUrl = this.chartUrl + 'customer-contractors-decline';
  contractorsNumberChartUrl = this.chartUrl + 'customer-contractors-number';
  contractorsResponseChartUrl = this.chartUrl + 'customer-contractors-response';
  volumeChartUrl = this.chartUrl + 'customer-assignments-volume';

  constructor(private http: HttpClient) {}

  getActivityChartData(): Observable<ChartSeriesCalculation[]> {
    // TODO: We need new endpoint for that
    return this.http.get<ChartSeriesCalculation[]>(this.numberChartUrl);
  }

  getVolumeChartData(): Observable<ChartSeriesCalculation[]> {
    // TODO: We need new endpoint for that
    return this.http.get<ChartSeriesCalculation[]>(this.numberChartUrl);
  }
}
