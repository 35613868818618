import { createSelector } from '@ngrx/store';
import { getPropertyObjectStateSelector } from 'src/app/core/modules/start/store/index';
import {
  getPropertyObjectError,
  getPropertyObjectLoaded,
  getPropertyObjectLoading,
} from 'src/app/core/modules/start/store/reducers/property-object.reducer';
import { idNameResourceAdapter } from 'src/app/core/modules/start/store/utils/id-name-resource.adapter';

export const getPropertyObjectLoadingSelector = createSelector(
  getPropertyObjectStateSelector,
  getPropertyObjectLoading
);

export const getPropertyObjectLoadedSelector = createSelector(
  getPropertyObjectStateSelector,
  getPropertyObjectLoaded
);

export const getPropertyObjectErrorSelector = createSelector(
  getPropertyObjectStateSelector,
  getPropertyObjectError
);

export const getPropertyObjectEntitiesSelector = idNameResourceAdapter.getSelectors(
  getPropertyObjectStateSelector
).selectEntities;
export const getPropertyObjectTotalSelector = idNameResourceAdapter.getSelectors(
  getPropertyObjectStateSelector
).selectTotal;
export const getPropertyObjectIdsSelector = idNameResourceAdapter.getSelectors(
  getPropertyObjectStateSelector
).selectIds;
export const getPropertyAllObjectSelector = idNameResourceAdapter.getSelectors(
  getPropertyObjectStateSelector
).selectAll;
