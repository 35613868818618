<app-modal-template
  [icon]="'assignment'"
  [header]="'create.headerAssignment'"
  [button]="'create.buttons.createAssignment'"
  (closeModal)="closeModal()"
  (submit)="submit()"
>
  <form [formGroup]="mainForm" novalidate>
    <app-modal-template-form-section [title]="'create.subHeaders.general'">
      <app-general-information
        [formGroup]="generalForm"
        [clientAssignmentTypes]="clientAssignmentTypes$ | async"
        [attemptedToSubmit]="attemptedToSubmit"
      >
      </app-general-information>
    </app-modal-template-form-section>

    <app-modal-template-form-section [title]="'create.subHeaders.object'">
      <div slot="form">
        <div class="mb-3">
          <app-general-object-information
            [formGroup]="generalForm"
            [attemptedToSubmit]="attemptedToSubmit"
            [realEstateTypes]="realEstateTypes$ | async"
            [establishmentTypes]="establishmentTypes$ | async"
            [isPropertyRegisterEnabled]="isPropertyRegisterEnabled"
            [addressTypeList]="addressTypeList"
            [currentAddressType]="currentAddressType"
            (propertyTypeChanged)="dispatchAction($event)"
            (addressTypeChanged)="onAddressTypeChange($event)"
          >
          </app-general-object-information>
        </div>

        <span *ngIf="isPropertyRegisterEnabled; else searchLocation">
          <span *ngIf="currentAddressType === propertySelectName">
            <app-property-register
              [formGroup]="propertyForm"
              [attemptedToSubmit]="attemptedToSubmit"
              [propertyBuilding]="propertyBuilding$ | async"
              [propertyDistrict]="propertyDistrict$ | async"
              [propertyObject]="propertyObject$ | async"
              [propertyRealEstate]="propertyRealEstate$ | async"
              [propertyAddress]="propertyAddress$ | async"
              (propertyChanged)="dispatchAction($event)"
            >
            </app-property-register>
          </span>

          <span *ngIf="currentAddressType === addressSelectName">
            <app-location-search
              [formGroup]="addressForm"
              [attemptedToSubmit]="attemptedToSubmit"
            >
            </app-location-search
          ></span>
        </span>

        <ng-template #searchLocation>
          <app-location-search
            [formGroup]="addressForm"
            [attemptedToSubmit]="attemptedToSubmit"
          >
          </app-location-search>
        </ng-template>
      </div>
    </app-modal-template-form-section>

    <app-modal-template-form-section
      [title]="'create.subHeaders.tenant'"
      [subtitle]="'create.summary.tenant'"
    >
      <app-tenant-information
        *ngIf="showTenant"
        [formGroup]="tenantForm"
        [attemptedToSubmit]="attemptedToSubmit"
      >
      </app-tenant-information>

      <div class="row">
        <div class="col-12 col-md-4">
          <button
            *ngIf="!showTenant"
            class="btn btn-block btn-light"
            type="button"
            (click)="addTenant()"
            [translate]="'create.buttons.addTenant'"
          ></button>

          <button
            class="btn btn-third btn-tenant"
            *ngIf="showTenant"
            (click)="removeTenant()"
            [translate]="'create.buttons.removeTenant'"
          ></button>
        </div>
      </div>
    </app-modal-template-form-section>
  </form>
</app-modal-template>
