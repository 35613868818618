<div class="search-bar form-group">
  <div class="input-group">
    <input
      type="text"
      placeholder="{{ placeHolder | translate }}"
      class="search-bar__input form-control"
      #input="ngModel"
      [(ngModel)]="query"
    />
    <ng-select
      *ngIf="searchTypeList?.length > 0"
      class="search-bar-select"
      [items]="searchTypeList"
      [ngModel]="searchType"
      [searchable]="false"
      [clearable]="false"
      [disabled]="isSmall"
      (change)="onChangeSearchType($event)"
      (click)="onOpenMobileSearchFilter()"
    >
      <ng-template ng-label-tmp let-item="item">
        <span [translate]="translationKeyForSearchTypes + item"></span>
      </ng-template>
      <ng-template ng-option-tmp let-item="item">
        <span [translate]="translationKeyForSearchTypes + item"></span>
      </ng-template>
    </ng-select>
  </div>
</div>
