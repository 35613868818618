<div class="header-menu">
  <span
    class="header-menu__navbar-brand pointer"
    [translate]="'common.main-page'"
    (click)="onGoToMainPage()"
  >
  </span>

  <ul class="navbar-nav" *ngIf="!isSmallScreen">
    <li class="nav-item dropdown" dropdown [autoClose]="true">
      <span
        class="header-menu__dropdown-toggle nav-link pointer"
        dropdownToggle
      >
        <span [translate]="'go.elements.assignment'"></span>
        <mat-icon class="header-menu__dropdown-toggle-icon"
          >expand_more</mat-icon
        >
      </span>

      <div class="dropdown-menu" *dropdownMenu>
        <span *ngIf="mostRecentAssignments?.length > 0">
          <span
            class="dropdown-menu__dropdown-title"
            [translate]="'go.mostRecent'"
          ></span>
          <div class="dropdown-menu__recent">
            <span
              class="dropdown-item dropdown-menu__recent-item btn-third"
              *ngFor="
                let mostRecentAssignment of mostRecentAssignments;
                let first = first
              "
              (click)="onGoToRecentAssignment(mostRecentAssignment.id)"
              [ngClass]="{ 'first-recent-item': first }"
            >
              {{ mostRecentAssignment.name }}
            </span>
          </div>
        </span>

        <span
          class="dropdown-item btn-third"
          (click)="onGoToMakeAction()"
          [translate]="'go.assignmentPanel.makeAction'"
        ></span>

        <span
          class="dropdown-item btn-third"
          (click)="onGoToWatchlist()"
          [translate]="'go.assignmentPanel.watchList'"
        ></span>

        <span
          class="dropdown-item btn-third"
          (click)="onGoToProduction()"
          [translate]="'go.assignmentPanel.assignments'"
        ></span>

        <span
          class="dropdown-item btn-third"
          (click)="onGoToStartAssignment()"
          [translate]="'go.assignmentPanel.newAssignment'"
        ></span>
      </div>
    </li>

    <li class="nav-item dropdown" dropdown [autoClose]="true">
      <span
        class="nav-link header-menu__dropdown-toggle pointer"
        dropdownToggle
      >
        <span [translate]="'go.elements.projects'"></span>
        <mat-icon class="header-menu__dropdown-toggle-icon"
          >expand_more</mat-icon
        >
      </span>

      <div class="dropdown-menu" *dropdownMenu>
        <span *ngIf="mostRecentProjects?.length > 0">
          <span
            class="dropdown-menu__dropdown-title"
            [translate]="'go.mostRecent'"
          ></span>
          <div class="dropdown-menu__recent">
            <span
              class="dropdown-item dropdown-menu__recent-item btn-third"
              *ngFor="
                let mostRecentProject of mostRecentProjects;
                let first = first
              "
              (click)="onGoToRecentProject(mostRecentProject.id)"
              [ngClass]="{ 'first-recent-item': first }"
            >
              {{ mostRecentProject.name }}
            </span>
          </div>
        </span>

        <span
          class="dropdown-item btn-third"
          (click)="onGoToProjects()"
          [translate]="'projects.menu.all'"
        ></span>

        <span
          class="dropdown-item btn-third"
          (click)="onGoToStartProject()"
          [translate]="'projects.menu.new'"
        ></span>
      </div>
    </li>

    <!--  TODO: Uncomment when implementation will be ready  -->
    <!--    <li class="nav-item dropdown">-->
    <!--      <span-->
    <!--        class="header-menu__dropdown-toggle nav-link pointer"-->
    <!--        (click)="onGoToNumbers()"-->
    <!--      >-->
    <!--        <span [translate]="'go.elements.numbers'"></span>-->
    <!--      </span>-->
    <!--    </li>-->

    <li class="nav-item">
      <span
        class="header-menu__new-assignment nav-link pointer"
        (click)="onGoToStartAssignment()"
        [translate]="'header.newAssignment'"
      ></span>
    </li>
  </ul>
</div>
