import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import {
  StartAssignmentsActions,
  StartAssignmentsActionTypes,
} from 'src/app/core/modules/start/store/actions/start-assignments.action';

export interface StartAssignmentsReducerState {
  starting: boolean;
  propertyType: string;
  error: ErrorResponseWithIdI;
}

export const initialStartAssignmentsReducerState: StartAssignmentsReducerState = {
  starting: false,
  propertyType: '',
  error: null,
};

export function startAssignmentsReducer(
  state: StartAssignmentsReducerState = initialStartAssignmentsReducerState,
  action: StartAssignmentsActions
): StartAssignmentsReducerState {
  switch (action.type) {
    // Create Assignment
    case StartAssignmentsActionTypes.StartAssignment: {
      return {
        ...state,
        starting: true,
      };
    }

    case StartAssignmentsActionTypes.AssignmentStartedSuccess: {
      return {
        ...state,
        starting: false,
      };
    }

    case StartAssignmentsActionTypes.AssignmentStartedFail: {
      return {
        ...state,
        starting: false,
        error: action.payload,
      };
    }

    case StartAssignmentsActionTypes.PropertyTypeChanged: {
      return {
        ...state,
        propertyType: action.payload,
      };
    }

    case StartAssignmentsActionTypes.CleanStartAssignmentsState: {
      return {
        ...initialStartAssignmentsReducerState,
      };
    }

    default: {
      return state;
    }
  }
}

export const getAssignmentStarting = (state: StartAssignmentsReducerState) =>
  state.starting;
export const getAssignmentStartingError = (
  state: StartAssignmentsReducerState
) => state.error;

export const getPropertyType = (state: StartAssignmentsReducerState) =>
  state.propertyType;
