import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MeResourceI } from 'src/app/core/models/user/user-resource.interface';
import { environment } from 'src/environments/environment';
import { StartPageI } from '../../../models/user/start-page.interface';

@Injectable()
export class MeService {
  private baseMeUrl = environment.url + 'api/v1/me/';
  private assignmentEventUrl = this.baseMeUrl + 'with-resource';
  private pendingAssignmentIdsUrl = this.baseMeUrl + 'pending-assignments';
  private startPage = this.baseMeUrl + 'start-page';

  constructor(private http: HttpClient) {}

  getUserDetailsWithResource(): Observable<MeResourceI> {
    return this.http.get<MeResourceI>(this.assignmentEventUrl);
  }

  getPendingAssignmentIds(): Observable<string[]> {
    return this.http.get<string[]>(this.pendingAssignmentIdsUrl);
  }

  getStartPage(): Observable<StartPageI> {
    return this.http.get<StartPageI>(this.startPage);
  }
}
