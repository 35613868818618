import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserPublicI } from 'src/app/core/models/user/user-resource.interface';

@Component({
  selector: 'app-user-view',
  templateUrl: './user-view.component.html',
  styleUrls: ['./user-view.component.scss'],
})
export class UserViewComponent implements OnInit {
  @Input()
  user: UserPublicI;

  @Output()
  editUser: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}

  onEditDetails() {
    this.editUser.emit();
  }
}
