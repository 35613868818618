import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { AlertMessage } from 'src/app/core/models/alert-message.interface';
import {
  AlertMessages,
  AlertMessagesActions,
} from 'src/app/core/store/actions/alert-messages.action';

function sortByDate(a: AlertMessage, b: AlertMessage): number {
  return b.timestamp - a.timestamp;
}

const selectId = (alertMessage: AlertMessage) => alertMessage.id;

const adapter: EntityAdapter<AlertMessage> = createEntityAdapter<AlertMessage>({
  selectId,
  sortComparer: sortByDate,
});

export interface AlertMessagesReducerState extends EntityState<AlertMessage> {
  showMissingExternalId: boolean;
}

export const initialAlertMessagesReducerState: AlertMessagesReducerState = adapter.getInitialState(
  {
    showMissingExternalId: false,
  }
);

export function alertMessagesReducer(
  state: AlertMessagesReducerState = initialAlertMessagesReducerState,
  action: AlertMessagesActions
): AlertMessagesReducerState {
  switch (action.type) {
    case AlertMessages.ShowMessage: {
      return adapter.addOne(action.payload, state);
    }

    case AlertMessages.HideMessage: {
      return adapter.removeOne(action.payload.id, state);
    }

    case AlertMessages.HideMultipleMessages: {
      return adapter.removeMany(action.payload, state);
    }

    case AlertMessages.ShowMessageMissingExternalId: {
      return {
        ...state,
        showMissingExternalId: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}

export const getAlertMessagesIds = adapter.getSelectors().selectIds;
export const getAlertMessagesEntities = adapter.getSelectors().selectEntities;
export const getAllAlertMessages = adapter.getSelectors().selectAll;
export const getAlertMessagesTotal = adapter.getSelectors().selectTotal;
export const getShowMissingExternalId = (state: AlertMessagesReducerState) =>
  state.showMissingExternalId;
