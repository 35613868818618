import { Action } from '@ngrx/store';
import { CalculationViewEnum } from 'src/app/assignments/modules/analysis/modules/compare/enums/calculation-view.enum';
import {
  ErrorAnalyticsPayload,
  SpinnerAnalyticsPayload,
  TableChangeQueryAnalyticsPayload,
  TableFilteredAnalyticCleanPayload,
} from 'src/app/core/models/google-analytics';

export enum GoogleAnalyticsActionTypes {
  PageChangedAnalytics = '[GoogleAnalytics] Page Changed',

  SpinnerTimeAnalytics = '[GoogleAnalytics] Spinner Time',

  TableCleanFilteredAnalytics = '[GoogleAnalytics] Table Clean Filtered',
  TableSortedAnalytics = '[GoogleAnalytics] Table Sorted',
  TableCleanSortedAnalytics = '[GoogleAnalytics] Table Clean Sorted',
  TableChangeQueryAnalytics = '[GoogleAnalytics] Table Change Query',
  TableGoToDetailsAnalytics = '[GoogleAnalytics] Table Go To Details',

  AssignmentCostChangeCalculationAnalytics = '[GoogleAnalytics] Assignment Cost Change Calculation',
  AssignmentCostAddCalculationAnalytics = '[GoogleAnalytics] Assignment Cost Add Calculation',
  AssignmentCostRemoveCalculationAnalytics = '[GoogleAnalytics] Assignment Cost Remove Calculation',

  AssignmentCostSelectCalculationViewAnalytics = '[GoogleAnalytics] Assignment Cost Select Calculation View',

  AssignmentCostSearchByRoomNameAnalytics = '[GoogleAnalytics] Assignment Cost Search By Room Name',
  AssignmentCostFilterByRoomTypeAnalytics = '[GoogleAnalytics] Assignment Cost Select Filter By Room Type',
  AssignmentCostFilterByRoomTypeCleanAnalytics = '[GoogleAnalytics] Assignment Cost Select Filter By Room Type Clean',
  AssignmentCostSortRoomsAnalytics = '[GoogleAnalytics] Assignment Cost Sort Rooms',
  AssignmentCostSortRoomsCleanAnalytics = '[GoogleAnalytics] Assignment Cost Sort Rooms Clean',

  AssignmentCostGoToRoomDetailsAnalytics = '[GoogleAnalytics] Assignment Cost Go To Room Details',

  AssignmentLogSelectCalculationAnalytics = '[GoogleAnalytics] Assignment Log Select Calculation Analytics',

  navBarMenuClickedAnalytics = '[GoogleAnalytics] Nav Bar Clicked',

  StartAssignmentClickedAnalytics = '[GoogleAnalytics] Start Assignment Clicked',
  GoToMessagesClickedAnalytics = '[GoogleAnalytics] Go To Messages Clicked',
  LogoutClickedAnalytics = '[GoogleAnalytics] Logout Clicked',

  HomePageNavigationClickedAnalytics = '[GoogleAnalytics] Home Page Navigation Clicked',
  HomePageChartClickedAnalytics = '[GoogleAnalytics] Home Page Chart Clicked',

  ExcelExportAnalytics = '[GoogleAnalytics] Excel Export',
  SimpleExcelExportAnalytics = '[GoogleAnalytics] Simple Excel Export',
  AssignmentEventsExcelExportAnalytics = '[GoogleAnalytics] Assignment Events Excel Export',

  UserChangedLanguageAnalytics = '[GoogleAnalytics] User Changed Language',
  UserLogoutAnalytics = '[GoogleAnalytics] User Logout',

  NativeBackButtonAnalytics = '[GoogleAnalytics] Native Back Button',
  ErrorAnalytics = '[GoogleAnalytics] Error',

  UwHomePageClicked = '[GoogleAnalytics] Uw HomePage Clicked',
  UwSupportPageClicked = '[GoogleAnalytics] Uw Support Page Clicked',
  GoToMepsPageClicked = '[GoogleAnalytics] Go To Meps Page Clicked',
  GoToMepsChatPageClicked = '[GoogleAnalytics] Go To Meps Chat Page Clicked',

  CloseAssignmentClicked = '[GoogleAnalytics] Close Assignment Clicked',
  RemoveAssignmentClicked = '[GoogleAnalytics] Remove Assignment Clicked',
  ReopenAssignmentClicked = '[GoogleAnalytics] Reopen Assignment Clicked',

  InstallPWAClicked = '[GoogleAnalytics] Install PWA',
  DismissedPWAClicked = '[GoogleAnalytics] Dismissed PWA',

  DisablePWAClicked = '[GoogleAnalytics] Disable PWA',
  InstructionsPWAClicked = '[GoogleAnalytics] Instructions PWA',

  CalculationNotAvailableAnyMoreAnalytics = '[GoogleAnalytics] Calculation Not Available Any More',

  CleanGoogleAnalytics = '[GoogleAnalytics] Clean Google Analytics',
}

export class PageChangedAnalytics implements Action {
  readonly type = GoogleAnalyticsActionTypes.PageChangedAnalytics;

  constructor(public payload: string) {}
}

export class SpinnerTimeAnalytics implements Action {
  readonly type = GoogleAnalyticsActionTypes.SpinnerTimeAnalytics;

  constructor(public payload: SpinnerAnalyticsPayload) {}
}

export class TableCleanFilteredAnalytics implements Action {
  readonly type = GoogleAnalyticsActionTypes.TableCleanFilteredAnalytics;

  constructor(public payload: TableFilteredAnalyticCleanPayload) {}
}

export class TableChangeQueryAnalytics implements Action {
  readonly type = GoogleAnalyticsActionTypes.TableChangeQueryAnalytics;

  constructor(public payload: TableChangeQueryAnalyticsPayload) {}
}

export class TableGoToDetailsAnalytics implements Action {
  readonly type = GoogleAnalyticsActionTypes.TableGoToDetailsAnalytics;
}

export class TableCleanSortedAnalytics implements Action {
  readonly type = GoogleAnalyticsActionTypes.TableCleanSortedAnalytics;

  constructor(public payload: string) {}
}

export class NavBarMenuClickedAnalytics implements Action {
  readonly type = GoogleAnalyticsActionTypes.navBarMenuClickedAnalytics;

  constructor(public payload: string) {}
}

export class StartAssignmentClickedAnalytics implements Action {
  readonly type = GoogleAnalyticsActionTypes.StartAssignmentClickedAnalytics;
}

export class GoToMessagesClickedAnalytics implements Action {
  readonly type = GoogleAnalyticsActionTypes.GoToMessagesClickedAnalytics;
}

export class LogoutClickedAnalytics implements Action {
  readonly type = GoogleAnalyticsActionTypes.LogoutClickedAnalytics;
}

export class HomePageNavigationClickedAnalytics implements Action {
  readonly type = GoogleAnalyticsActionTypes.HomePageNavigationClickedAnalytics;

  constructor(public payload: string) {}
}

export class HomePageChartClickedAnalytics implements Action {
  readonly type = GoogleAnalyticsActionTypes.HomePageChartClickedAnalytics;

  constructor(public payload: string) {}
}

export class AssignmentCostChangeCalculationAnalytics implements Action {
  readonly type =
    GoogleAnalyticsActionTypes.AssignmentCostChangeCalculationAnalytics;

  constructor(public payload: CalculationViewEnum) {}
}

export class AssignmentCostAddCalculationAnalytics implements Action {
  readonly type =
    GoogleAnalyticsActionTypes.AssignmentCostAddCalculationAnalytics;

  constructor(public payload: CalculationViewEnum) {}
}

export class AssignmentCostRemoveCalculationAnalytics implements Action {
  readonly type =
    GoogleAnalyticsActionTypes.AssignmentCostRemoveCalculationAnalytics;

  constructor(public payload: CalculationViewEnum) {}
}

export class AssignmentCostSelectCalculationViewAnalytics implements Action {
  readonly type =
    GoogleAnalyticsActionTypes.AssignmentCostSelectCalculationViewAnalytics;

  constructor(
    public payload: { view: CalculationViewEnum; component: string }
  ) {}
}

export class AssignmentCostSearchByRoomNameAnalytics implements Action {
  readonly type =
    GoogleAnalyticsActionTypes.AssignmentCostSearchByRoomNameAnalytics;
}

export class AssignmentCostFilterByRoomTypeAnalytics implements Action {
  readonly type =
    GoogleAnalyticsActionTypes.AssignmentCostFilterByRoomTypeAnalytics;

  constructor(public payload: string) {}
}

export class AssignmentCostFilterByRoomTypeCleanAnalytics implements Action {
  readonly type =
    GoogleAnalyticsActionTypes.AssignmentCostFilterByRoomTypeCleanAnalytics;
}

export class AssignmentCostSortRoomsAnalytics implements Action {
  readonly type = GoogleAnalyticsActionTypes.AssignmentCostSortRoomsAnalytics;

  constructor(public payload: string) {}
}

export class AssignmentCostSortRoomsCleanAnalytics implements Action {
  readonly type =
    GoogleAnalyticsActionTypes.AssignmentCostSortRoomsCleanAnalytics;
}

export class AssignmentCostGoToRoomDetailsAnalytics implements Action {
  readonly type =
    GoogleAnalyticsActionTypes.AssignmentCostGoToRoomDetailsAnalytics;
}

export class AssignmentLogSelectCalculationAnalytics implements Action {
  readonly type =
    GoogleAnalyticsActionTypes.AssignmentLogSelectCalculationAnalytics;
}

export class ExcelExportAnalytics implements Action {
  readonly type = GoogleAnalyticsActionTypes.ExcelExportAnalytics;

  constructor(public payload: number) {}
}

export class SimpleExcelExportAnalytics implements Action {
  readonly type = GoogleAnalyticsActionTypes.SimpleExcelExportAnalytics;

  constructor(public payload: number) {}
}

export class AssignmentEventsExcelExportAnalytics implements Action {
  readonly type =
    GoogleAnalyticsActionTypes.AssignmentEventsExcelExportAnalytics;

  constructor(public payload: number) {}
}

export class UserChangedLanguageAnalytics implements Action {
  readonly type = GoogleAnalyticsActionTypes.UserChangedLanguageAnalytics;

  constructor(public payload: string) {}
}

export class UserLogoutAnalytics implements Action {
  readonly type = GoogleAnalyticsActionTypes.UserLogoutAnalytics;
}

export class NativeBackButtonAnalytics implements Action {
  readonly type = GoogleAnalyticsActionTypes.NativeBackButtonAnalytics;
}

export class ErrorAnalytics implements Action {
  readonly type = GoogleAnalyticsActionTypes.ErrorAnalytics;

  constructor(public payload: ErrorAnalyticsPayload) {}
}

export class UwHomePageClicked implements Action {
  readonly type = GoogleAnalyticsActionTypes.UwHomePageClicked;
}

export class UwSupportPageClicked implements Action {
  readonly type = GoogleAnalyticsActionTypes.UwSupportPageClicked;
}

export class GoToMepsPageClicked implements Action {
  readonly type = GoogleAnalyticsActionTypes.GoToMepsPageClicked;
}

export class GoToMepsChatPageClicked implements Action {
  readonly type = GoogleAnalyticsActionTypes.GoToMepsChatPageClicked;
}

export class CloseAssignmentClicked implements Action {
  readonly type = GoogleAnalyticsActionTypes.CloseAssignmentClicked;
}

export class RemoveAssignmentClicked implements Action {
  readonly type = GoogleAnalyticsActionTypes.RemoveAssignmentClicked;
}

export class ReopenAssignmentClicked implements Action {
  readonly type = GoogleAnalyticsActionTypes.ReopenAssignmentClicked;
}

export class DisablePWAClicked implements Action {
  readonly type = GoogleAnalyticsActionTypes.DisablePWAClicked;
}

export class InstallPWAClicked implements Action {
  readonly type = GoogleAnalyticsActionTypes.InstallPWAClicked;
}

export class DismissedPWAClicked implements Action {
  readonly type = GoogleAnalyticsActionTypes.DismissedPWAClicked;
}

export class InstructionsPWAClicked implements Action {
  readonly type = GoogleAnalyticsActionTypes.InstructionsPWAClicked;
}

export class CalculationNotAvailableAnyMoreAnalytics implements Action {
  readonly type =
    GoogleAnalyticsActionTypes.CalculationNotAvailableAnyMoreAnalytics;

  constructor(public payload: string) {}
}

export class CleanGoogleAnalytics implements Action {
  readonly type = GoogleAnalyticsActionTypes.CleanGoogleAnalytics;
}

export type GoogleAnalyticsActions =
  | PageChangedAnalytics
  | SpinnerTimeAnalytics
  | TableCleanFilteredAnalytics
  | TableChangeQueryAnalytics
  | TableGoToDetailsAnalytics
  | TableCleanSortedAnalytics
  | AssignmentCostChangeCalculationAnalytics
  | AssignmentCostAddCalculationAnalytics
  | AssignmentCostRemoveCalculationAnalytics
  | AssignmentCostSelectCalculationViewAnalytics
  | AssignmentCostSearchByRoomNameAnalytics
  | AssignmentCostFilterByRoomTypeAnalytics
  | AssignmentCostFilterByRoomTypeCleanAnalytics
  | AssignmentCostSortRoomsAnalytics
  | AssignmentCostSortRoomsCleanAnalytics
  | AssignmentCostGoToRoomDetailsAnalytics
  | AssignmentLogSelectCalculationAnalytics
  | NavBarMenuClickedAnalytics
  | StartAssignmentClickedAnalytics
  | GoToMessagesClickedAnalytics
  | LogoutClickedAnalytics
  | HomePageNavigationClickedAnalytics
  | HomePageChartClickedAnalytics
  | ExcelExportAnalytics
  | SimpleExcelExportAnalytics
  | AssignmentEventsExcelExportAnalytics
  | CleanGoogleAnalytics
  | UserChangedLanguageAnalytics
  | UserLogoutAnalytics
  | NativeBackButtonAnalytics
  | ErrorAnalytics
  | UwHomePageClicked
  | UwSupportPageClicked
  | GoToMepsPageClicked
  | GoToMepsChatPageClicked
  | CloseAssignmentClicked
  | RemoveAssignmentClicked
  | ReopenAssignmentClicked
  | DisablePWAClicked
  | InstallPWAClicked
  | DismissedPWAClicked
  | InstructionsPWAClicked
  | CalculationNotAvailableAnyMoreAnalytics;
