<div class="actions-card-content card-text">
  <div class="actions-card-content__info" *ngIf="action?.info?.currentCost">
    <span
      class="actions-card-content__label"
      [translate]="'overview.myActions.card.currentCost'"
    ></span>
    <span class="actions-card-content__value">
      {{ action?.info?.currentCost | uwCurrency }}
    </span>
  </div>
  <div class="actions-card-content__info" *ngIf="action?.info?.leadContractor">
    <span
      class="actions-card-content__label"
      [translate]="'overview.myActions.card.leadContractor'"
    ></span>
    <span class="actions-card-content__value">{{
      action?.info?.leadContractor
    }}</span>
  </div>
  <div
    class="actions-card-content__info"
    *ngIf="action?.info?.estConstructionDate"
  >
    <span
      class="actions-card-content__label"
      [translate]="'overview.myActions.card.estEnd'"
    ></span>
    <span class="actions-card-content__value">{{
      action?.info?.estConstructionDate | date
    }}</span>
  </div>
</div>
