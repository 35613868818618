import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import { AssignmentsService } from 'src/app/core/services/api/assignments/assignments.service';
import {
  ContractorActionTypes,
  InviteContractor,
  InviteContractorFail,
  InviteContractorSuccess,
} from 'src/app/core/store/actions/contractor.action';
import {
  AssignmentUrlEnum,
  CoreUrlEnums,
} from 'src/app/core/enums/url-paths.enum';
import { RouterGo } from 'src/app/core/store/actions/router.action';
import { select, Store } from '@ngrx/store';
import { getRouterUrlWithSlashAtBeginningAndQueryParamsSelector } from 'src/app/core/store/selectors/router.selectors';
import getAssignmentListAnalysisUrl = AssignmentUrlEnum.getAssignmentListAnalysisUrl;

@Injectable()
export class ContractorEffects {
  constructor(
    private actions$: Actions,
    private assignmentService: AssignmentsService,
    private store$: Store
  ) {}

  public inviteContractor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContractorActionTypes.InviteContractor),
      switchMap((action: InviteContractor) =>
        this.assignmentService.inviteContractor(action.payload).pipe(
          map(() => new InviteContractorSuccess(action.payload.assignmentId)),
          catchError((error: ErrorResponseWithIdI) =>
            of(new InviteContractorFail(error))
          )
        )
      )
    )
  );

  public handleInviteContractor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContractorActionTypes.InviteContractorSuccess),
      withLatestFrom(
        this.store$.pipe(
          select(getRouterUrlWithSlashAtBeginningAndQueryParamsSelector)
        )
      ),
      filter(([action, url]) => url.includes(CoreUrlEnums.OVERVIEW)),
      switchMap(([action, url]: [InviteContractorSuccess, string]) =>
        of(
          new RouterGo({
            path: [
              getAssignmentListAnalysisUrl(
                AssignmentUrlEnum.ASSIGNMENT,
                action.payload
              ),
            ],
          })
        )
      )
    )
  );
}
