import { AssignmentStatusEnum } from 'src/app/core/enums/assignment-status.enum';

export function getStatusToIcon(
  type: AssignmentStatusEnum
): { icon: string; colour: string } {
  switch (type) {
    case AssignmentStatusEnum.NEW_FINAL_CALCULATION:
    case AssignmentStatusEnum.NEW_CALCULATION: {
      return { icon: 'assignment_late', colour: 'text-warning' };
    }
    case AssignmentStatusEnum.APPROVED_FINAL_CALCULATION:
    case AssignmentStatusEnum.APPROVED_CALCULATION: {
      return { icon: 'assignment_turned_in', colour: 'text-success' };
    }
    case AssignmentStatusEnum.DECLINED_FINAL_CALCULATION:
    case AssignmentStatusEnum.DECLINED_CALCULATION: {
      return { icon: 'assignment_turned_in', colour: 'text-danger' };
    }
    case AssignmentStatusEnum.PENDING_INVITATION: {
      return { icon: 'assignment_turned_in', colour: 'text-warning' };
    }
    case AssignmentStatusEnum.INVITATION_DECLINED: {
      return { icon: 'assignment_turned_in', colour: 'text-danger' };
    }
    default: {
      return { icon: 'assignment', colour: 'text-dark' };
    }
  }
}
