<div class="sticky-top bg-white">
  <div class="container">
    <nav id="navbar" class="navbar navbar-light navbar-expand">
      <app-header-menu
        [isSmallScreen]="pageSizeService.isSmallScreen$ | async"
        [mostRecentProjects]="mostRecentProjects"
        [mostRecentAssignments]="mostRecentAssignments"
        (goToStartAssignment)="goToStartAssignment()"
        (goToNumbers)="goToNumbers()"
        (goToMainPage)="goToMainPage()"
        (goToProduction)="goToProduction()"
        (goToWatchlist)="goToWatchlist()"
        (goToMakeAction)="goToMakeAction()"
        (goToProjects)="goToProjects()"
        (goToStartProject)="onGoToStartProject()"
        (goToRecentProject)="onGoToRecentAssignment($event)"
        (goToRecentAssignment)="onGoToRecentAssignment($event)"
      ></app-header-menu>

      <div class="icons">
        <app-header-icons
          [isSmallScreen]="pageSizeService.isSmallScreen$ | async"
          [userName]="userName$ | async"
          [messages]="messages$ | async"
          [isAdmin]="isAdmin$ | async"
          (goToAdministration)="goToAdministration()"
          (goToAssignmentDetails)="goToAssignmentDetails($event)"
          (goToMessages)="goToMessages()"
          (goToSupport)="goToSupport()"
          (goToAccountDetails)="goToAccountDetails()"
          (logout)="logout()"
          (showSideBar)="onOpenSideBar()"
        ></app-header-icons>
      </div>
    </nav>
  </div>
  <div id="shadow"></div>
</div>

<app-side-nav
  *ngIf="showSideBar$ | async"
  [isAdmin]="isAdmin$ | async"
  (goToStartAssignment)="goToStartAssignment()"
  (goToNumbers)="goToNumbers()"
  (goToMainPage)="goToMainPage()"
  (goToProduction)="goToProduction()"
  (goToWatchlist)="goToWatchlist()"
  (goToMakeAction)="goToMakeAction()"
  (goToProjects)="goToProjects()"
  (goToStartProject)="onGoToStartProject()"
  (goToAdministration)="goToAdministration()"
  (goToMessages)="goToMessages()"
  (goToSupport)="goToSupport()"
  (goToAccountDetails)="goToAccountDetails()"
  (logout)="logout()"
  (hideSideBar)="onHideSideBar()"
></app-side-nav>
