import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import { PusherMessageService } from 'src/app/core/services/api/pusher-message/pusher-message.service';
import {
  ClearPusherMessages,
  LoadAllPusherMessages,
  LoadPusherAllMessagesFail,
  LoadPusherAllMessagesSuccess,
  LoadPusherMessage,
  LoadPusherMessageFail,
  LoadPusherMessageSuccess,
  MessageConnectToChanel,
  MessageDisconnectFromChanel,
  MessageEventErrorReceived,
  MessageEventReceived,
  PusherActionTypes,
} from 'src/app/core/store/actions/pusher.action';
import {
  PusherAllMessagesI,
  PusherMessageI,
} from 'src/app/core/models/message/pusher-message.interface';
import {
  LoadedUserResourceSuccess,
  UserActionTypes,
} from 'src/app/auth/store/actions/user.action';
import Pusher, { Channel } from 'pusher-js';
import { select, Store } from '@ngrx/store';
import { PusherEventI } from 'src/app/core/models/message/pusher-event.interface';
import { Settings } from 'src/app/core/utils/settings';
import { AuthActionTypes } from 'src/app/auth/store/actions/auth.action';
import { environment } from 'src/environments/environment';
import { getRouterUrlWithSlashAtBeginningAndQueryParamsSelector } from 'src/app/core/store/selectors/router.selectors';
import {
  AssignmentUrlEnum,
  CoreUrlEnums,
} from 'src/app/core/enums/url-paths.enum';
import { LoadStartPage } from 'src/app/core/store/actions/start-page.action';
import { LoadAssignment } from 'src/app/core/store/actions/assignments.action';
import { LoadAnalysisAssignment } from 'src/app/assignments/modules/analysis/store/actions/analysis-assignment.actions';

@Injectable()
export class PusherEffects {
  private pusherClient: Pusher;

  constructor(
    private actions$: Actions,
    private store$: Store,
    private pusherMessageService: PusherMessageService
  ) {}

  // Client Connections
  public startConnectionToChannel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.LoadedUserResourceSuccess),
      map(
        (user: LoadedUserResourceSuccess) =>
          new MessageConnectToChanel(user.payload.userPublic.userId)
      )
    )
  );

  public stopConnectionToChannel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActionTypes.LoggedOut, AuthActionTypes.Unauthorized),
      map(() => new MessageDisconnectFromChanel())
    )
  );

  public connectToChannel$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PusherActionTypes.MessageConnectToChanel),
        tap((user: MessageConnectToChanel) => {
          this.createPusherClient();
          this.connectToChannel(user.payload);
        })
      ),
    { dispatch: false }
  );

  public disconnectFromChannel$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PusherActionTypes.MessageDisconnectFromChanel),
        tap(() => this.disconnectFromPusher())
      ),
    { dispatch: false }
  );

  // Received event
  public receivedPusherEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PusherActionTypes.MessageEventReceived),
      map(
        (action: MessageEventReceived) =>
          new LoadPusherMessage(action.payload.message)
      )
    )
  );

  public loadPusherMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PusherActionTypes.LoadPusherMessage),
      switchMap((action: LoadPusherMessage) =>
        this.pusherMessageService.getMessageInfo(action.payload).pipe(
          map(
            (message: PusherMessageI) => new LoadPusherMessageSuccess(message)
          ),
          catchError((error: ErrorResponseWithIdI) =>
            of(new LoadPusherMessageFail(error))
          )
        )
      )
    )
  );

  public loadPusherMessageSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PusherActionTypes.LoadPusherMessageSuccess),
      withLatestFrom(
        this.store$.pipe(
          select(getRouterUrlWithSlashAtBeginningAndQueryParamsSelector)
        )
      ),
      switchMap(([action, url]: [LoadPusherMessageSuccess, string]) => {
        const refreshAllMessages = new LoadAllPusherMessages();
        if (url.includes(CoreUrlEnums.OVERVIEW)) {
          return of(new LoadStartPage(), refreshAllMessages);
        }

        if (
          url.includes(
            AssignmentUrlEnum.ANALYSIS +
              '/' +
              AssignmentUrlEnum.ASSIGNMENT +
              '/' +
              action.payload.assignmentId
          )
        ) {
          return of(
            new LoadAssignment(action.payload.assignmentId),
            new LoadAnalysisAssignment(action.payload.assignmentId),
            refreshAllMessages
          );
        }

        if (
          url.includes(
            AssignmentUrlEnum.ANALYSIS +
              '/' +
              AssignmentUrlEnum.COMPARE +
              '/' +
              action.payload.assignmentId
          )
        ) {
          return of(
            new LoadAssignment(action.payload.assignmentId),
            refreshAllMessages
          );
        }

        return of(refreshAllMessages);
      })
    )
  );

  // Load all pusher messages
  public loadPusherAllMessages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PusherActionTypes.LoadAllPusherMessages),
      switchMap((action: LoadAllPusherMessages) =>
        this.pusherMessageService.getAllMessages().pipe(
          map(
            (message: PusherAllMessagesI) =>
              new LoadPusherAllMessagesSuccess(message)
          ),
          catchError((error: ErrorResponseWithIdI) =>
            of(new LoadPusherAllMessagesFail(error))
          )
        )
      )
    )
  );

  // Clear
  public handleFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        PusherActionTypes.LoadPusherMessageFail,
        PusherActionTypes.LoadPusherAllMessagesFail
      ),
      map((_) => new ClearPusherMessages())
    )
  );

  private createPusherClient() {
    if (!!this.pusherClient) {
      this.disconnectFromPusher();
    }

    const appKey = Settings.appSettings.pusher.appKey;
    const token = localStorage.getItem('access_token');
    const authUrl = this.pusherMessageService.authMessageUrl.replace(
      environment.url,
      Settings.appSettings.url
    );

    this.pusherClient = new Pusher(appKey, {
      auth: {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
      forceTLS: true,
      authEndpoint: authUrl,
      cluster: 'eu',
    });

    this.pusherClient.connection.bind('error', (err) =>
      this.store$.dispatch(new MessageEventErrorReceived(err))
    );
  }

  private connectToChannel(userId: string): string {
    const channel: Channel = this.pusherClient.subscribe('private-' + userId);

    channel.bind('event', (event: PusherEventI) =>
      this.store$.dispatch(new MessageEventReceived(event))
    );

    return channel.name;
  }

  private disconnectFromPusher(): string {
    if (!this.pusherClient) {
      return;
    }

    this.pusherClient.unbind_all();
    this.pusherClient.disconnect();
    this.pusherClient = null;
  }
}
