<div class="card bg-light mb-3" *ngFor="let action of actions">
  <div class="card-body">
    <app-my-actions-card-header
      [action]="action"
      (goToDetails)="onGoToDetails($event)"
    >
    </app-my-actions-card-header>
    <app-my-actions-card-content
      *ngIf="!!action.info"
      [action]="action"
    ></app-my-actions-card-content>
    <app-my-actions-card-button
      [action]="action"
      [showMobileActions]="showMobileActions"
      [isSmallScreen]="isSmallScreen"
      (makeAction)="onMakeAction($event)"
      (openMobileActions)="onOpenMobileActions($event)"
    ></app-my-actions-card-button>
  </div>
</div>
