import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';

@Component({
  selector: 'app-simple-list',
  templateUrl: './simple-list.component.html',
  styleUrls: ['./simple-list.component.scss'],
})
export class SimpleListComponent implements OnInit {
  @Input()
  options: any[];

  @Input()
  border: boolean;

  @Input()
  simple: boolean;

  @Input()
  clickable = true;

  @Input()
  template: TemplateRef<any>;

  @Output()
  clicked: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  clickableFunction = (param: any, border: boolean) => false;

  constructor() {}

  ngOnInit(): void {}

  onClick(option: any, border: boolean) {
    if (this.clickable || this.clickableFunction(option, border)) {
      this.clicked.emit(option);
    }
  }
}
