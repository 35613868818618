import { Injectable } from '@angular/core';
import { AssignmentStatusEnum } from 'src/app/core/enums/assignment-status.enum';
import {
  ErrorAnalyticsPayload,
  SpinnerAnalyticsPayload,
  TableChangeQueryAnalyticsPayload,
  TablePaginatedAnalyticsPayload,
  TableSortedAnalyticsPayload,
} from 'src/app/core/models/google-analytics';
import { View } from 'src/app/numbers/enums/view.enum';
import { Settings } from '../../../utils/settings';

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  constructor() {}

  static load() {
    GoogleAnalyticsService.addTempGaBeforeScriptIsLoaded();
    GoogleAnalyticsService.loadGaScriptFromServer();
  }

  private static addTempGaBeforeScriptIsLoaded() {
    window['dataLayer'] = window['dataLayer'] || [];
    window['gtag'] = function () {
      window['dataLayer'].push(arguments);
    };

    // @ts-ignore
    gtag('js', new Date());
  }

  private static loadGaScriptFromServer() {
    const head = document.getElementsByTagName('head')[0];

    const googleAnalyticsFirstScript = document.createElement('script');
    googleAnalyticsFirstScript.async = true;
    googleAnalyticsFirstScript.src = `https://www.googletagmanager.com/gtag/js?id=${Settings.appSettings.googleAnalyticsCode}`;

    head.insertBefore(googleAnalyticsFirstScript, head.firstChild);
  }

  pageChanged(url: string) {
    gtag('config', Settings.appSettings.googleAnalyticsCode, {
      page_path: url,
    });
  }

  spinnerTimeAnalysis(spinnerTime: SpinnerAnalyticsPayload) {
    gtag('event', 'System', {
      event_category: 'Loading',
      event_label: `action name: ${spinnerTime.actions.toString()}`,
      value: spinnerTime.timeInSeconds,
    });
  }

  tablePaginated(tablePaginated: TablePaginatedAnalyticsPayload) {
    gtag('event', 'Behavior', {
      event_category: 'Table Pagination',
      event_label: `Table: ${tablePaginated.table} page:${tablePaginated.settings.page}/${tablePaginated.settings.total}`,
    });
  }

  tableFilteredClean(table: string, filter: string) {
    gtag('event', 'Behavior', {
      event_category: 'Table Filtering',
      event_label: `Table: ${table} Filters: ${filter} cleaned`,
    });
  }

  tableSorted(tablePaginated: TableSortedAnalyticsPayload) {
    gtag('event', 'Behavior', {
      event_category: 'Table Sorted',
      event_label: `Table: ${tablePaginated.table} order:${tablePaginated.settings.order} field:${tablePaginated.settings.field}`,
    });
  }

  tableSortedClean(table: string) {
    gtag('event', 'Behavior', {
      event_category: 'Table Sorted',
      event_label: `Table: ${table} sort: cleaned`,
    });
  }

  tableChangeQuery(changeQuery: TableChangeQueryAnalyticsPayload) {
    gtag('event', 'Behavior', {
      event_category: 'Table Change Query',
      event_label: `Table: ${changeQuery.table}, query changed: ${changeQuery.query}`,
    });
  }

  tableGoToDetails() {
    gtag('event', 'Behavior', {
      event_category: 'Table Go To Details',
    });
  }

  numbersSearchViewChanged(view: string) {
    gtag('event', 'Behavior', {
      event_category: 'Numbers Search',
      event_label: view,
    });
  }

  numbersGoToAssignmentDetails(
    view: View,
    assignmentStatus: AssignmentStatusEnum
  ) {
    gtag('event', 'Behavior', {
      event_category: 'Numbers Search',
      event_label: `Go To Assignment With Status: ${assignmentStatus} from view: ${view}`,
    });
  }

  numbersGoToAnalysisFromMenu(type: string) {
    gtag('event', 'Behavior', {
      event_category: 'Numbers Search',
      event_label: `Go To Analysis: ${type}`,
    });
  }

  numbersDashboardChanged(chart: string) {
    gtag('event', 'Behavior', {
      event_category: 'Numbers Dashboard',
      event_label: chart,
    });
  }

  assignmentCostChangeCalculation(calculation: string) {
    gtag('event', 'Behavior', {
      event_category: 'Assignment Cost',
      event_label: `Change Calculation: ${calculation}`,
    });
  }

  assignmentCostAddCalculation(calculation: string) {
    gtag('event', 'Behavior', {
      event_category: 'Assignment Cost',
      event_label: `Add Calculation: ${calculation}`,
    });
  }

  assignmentCostRemoveCalculation(calculation: string) {
    gtag('event', 'Behavior', {
      event_category: 'Assignment Cost',
      event_label: `Remove Calculation: ${calculation}`,
    });
  }

  assignmentCostSelectCalculationView(calculation: string, component: string) {
    gtag('event', 'Behavior', {
      event_category: 'Assignment Cost',
      event_label:
        'Select Calculation View: ' +
        calculation +
        'from component: ' +
        component,
    });
  }

  assignmentCostSearchByRoomName() {
    gtag('event', 'Behavior', {
      event_category: 'Assignment Cost',
      event_label: `Search By Room Name`,
    });
  }

  assignmentCostFilterByRoomType(type: string) {
    gtag('event', 'Behavior', {
      event_category: 'Assignment Cost',
      event_label: `Filter By Room Type: ${type}`,
    });
  }

  assignmentCostFilterByRoomTypeClean() {
    gtag('event', 'Behavior', {
      event_category: 'Assignment Cost',
      event_label: `Filter By Room Type Cleared`,
    });
  }

  assignmentCostSortRooms(sort: string) {
    gtag('event', 'Behavior', {
      event_category: 'Assignment Cost',
      event_label: `Sort Rooms: ${sort}`,
    });
  }

  assignmentCostSortRoomsClean() {
    gtag('event', 'Behavior', {
      event_category: 'Assignment Cost',
      event_label: `Sort Rooms Cleared`,
    });
  }

  assignmentCostGoToRoomDetails() {
    gtag('event', 'Behavior', {
      event_category: 'Assignment Cost',
      event_label: `Go To Room Details`,
    });
  }

  assignmentLogSelectCalculation() {
    gtag('event', 'Behavior', {
      event_category: 'Assignment Log',
      event_label: `Selected log table row`,
    });
  }

  navBarClicked(page: string) {
    gtag('event', 'Behavior', {
      event_category: 'Navbar',
      event_label: `Clicked: ${page}`,
    });
  }

  timePeriodChanged(period: string) {
    gtag('event', 'Behavior', {
      event_category: 'Numbers Dashboard',
      event_label: `Filter: ${period}`,
    });
  }

  numbersSearch(numberOfHits: number) {
    gtag('event', 'Behavior', {
      event_category: 'Numbers Search',
      event_label: `Search`,
      value: numberOfHits,
    });
  }

  excelExportAnalysis(numberOfAssignments: number) {
    gtag('event', 'Behavior', {
      event_category: 'Export Full',
      event_label: `total: ${numberOfAssignments}`,
    });
  }

  excelExportSimpleAnalysis(numberOfAssignments: number) {
    gtag('event', 'Behavior', {
      event_category: 'Export Simple',
      event_label: `total: ${numberOfAssignments}`,
    });
  }

  excelExportAssignmentEventsAnalysis(numberOfAssignments: number) {
    gtag('event', 'Behavior', {
      event_category: 'Export Assignment Events',
      event_label: `total: ${numberOfAssignments}`,
    });
  }

  userChangedLanguage(language: string) {
    gtag('event', 'Behavior', {
      event_category: 'Profile',
      event_label: `Change Language: ${language}`,
    });
  }

  userLogout() {
    gtag('event', 'Behavior', {
      event_category: 'Profile',
      event_label: `Logout`,
    });
  }

  goBackNative() {
    gtag('event', 'Browsing', {
      event_category: 'Navigation',
      event_label: `Native Back`,
    });
  }

  error(error: ErrorAnalyticsPayload) {
    gtag('event', 'System', {
      event_category: `Error: ${error.reason}`,
      event_label: `Error Message: ${error.message}`,
    });
  }

  httpError(error: ErrorAnalyticsPayload) {
    gtag('event', 'HTTP', {
      event_category: `Error: ${error.reason}`,
      event_label: `Error Message: ${error.message}`,
    });
  }

  uwHomePageClicked() {
    gtag('event', 'Behavior', {
      event_category: `Navigation`,
      event_label: `GO Icon`,
    });
  }

  startAssignmentClicked() {
    gtag('event', 'Behavior', {
      event_category: `Navigation`,
      event_label: `Start Assignment`,
    });
  }

  goToMessagesClicked() {
    gtag('event', 'Behavior', {
      event_category: `Navigation`,
      event_label: `Go To Messages`,
    });
  }

  logoutClicked() {
    gtag('event', 'Behavior', {
      event_category: `Navigation`,
      event_label: `Logout`,
    });
  }

  homePageNavigationClicked(page: string) {
    gtag('event', 'Behavior', {
      event_category: `Home Page`,
      event_label: `Navigation Clicked: ${page}`,
    });
  }

  homePageChartClicked(chart: string) {
    gtag('event', 'Behavior', {
      event_category: `Home Page`,
      event_label: `Chart Clicked: ${chart}`,
    });
  }

  uwSupportPageClicked() {
    gtag('event', 'Behavior', {
      event_category: `Navigation`,
      event_label: `Support Footer Link`,
    });
  }

  mepsAssignmentPageClicked() {
    gtag('event', 'Behavior', {
      event_category: `Navigation`,
      event_label: `Go to Meps`,
    });
  }

  mepsChatPageClicked() {
    gtag('event', 'Behavior', {
      event_category: `Navigation`,
      event_label: `Show Chat in Meps`,
    });
  }

  assignmentClosedClicked() {
    gtag('event', 'Behavior', {
      event_category: `Assignment Status`,
      event_label: `Assignment Closed`,
    });
  }

  assignmentRemovedClicked() {
    gtag('event', 'Behavior', {
      event_category: `Assignment Status`,
      event_label: `Assignment Removed`,
    });
  }

  assignmentReopenClicked() {
    gtag('event', 'Behavior', {
      event_category: `Assignment Status`,
      event_label: `Assignment Reopen`,
    });
  }

  disablePWAClicked() {
    gtag('event', 'Behavior', {
      event_category: `PWA`,
      event_label: `Disable clicked`,
    });
  }

  installPWAClicked() {
    gtag('event', 'Behavior', {
      event_category: `PWA`,
      event_label: `Install clicked`,
    });
  }

  dismissedPWAClicked() {
    gtag('event', 'Behavior', {
      event_category: `PWA`,
      event_label: `Dismissed clicked`,
    });
  }

  instructionsPWAClicked() {
    gtag('event', 'Behavior', {
      event_category: `PWA`,
      event_label: `Instructions clicked`,
    });
  }

  calculationNotAvailableAnyMore(page: string) {
    gtag('event', 'Behavior', {
      event_category: `Compare Calculation`,
      event_label: `There is no matching event id on page: ${page}`,
    });
  }
}
