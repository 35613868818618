import PlaceResult = google.maps.places.PlaceResult;

export const getValueFromPlaceResult = (valueName: string, placeResult: PlaceResult): string => {
  let value = '';

  if (placeResult && placeResult.address_components) {
    placeResult.address_components.forEach((x) => {
      if (x.types.indexOf(valueName) !== -1) {
        value = x.short_name;
      }
    });
  }

  return value;
};
