import { Component, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-display-value',
  templateUrl: './display-value.component.html',
  styleUrls: ['./display-value.component.scss'],
})
export class DisplayValueComponent implements OnInit {
  @Input()
  label: string;

  @Input()
  value: any;

  @Input()
  addSpaceTop = true;

  @Input()
  small = false;

  @Input()
  inline = false;

  @Input()
  values: any[];

  @Input()
  template: TemplateRef<any>;

  constructor() {}

  ngOnInit(): void {}
}
