<ngx-datatable
  #table
  [rows]="elementsList"
  [columnMode]="tableSettings.columnMode"
  [externalSorting]="tableSettings.externalSorting"
  [footerHeight]="tableSettings.footerHeight"
  [limit]="tableSizeLimit"
  [messages]="{ emptyMessage: 'noDataToDisplay' | translate }"
  (sort)="onSort($event)"
  (activate)="onGoToDetails($event)"
>
  <!-- Table custom columns -->
  <ngx-datatable-column name="issue" [flexGrow]="5" [resizeable]="false">
    <ng-template ngx-datatable-header-template let-sort="sortFn">
      <span
        (click)="sort()"
        [translate]="'notifications.tableHeaders.issue'"
        [tooltip]="'notifications.tableHeaders.issue' | translate"
        container="body"
      ></span>
    </ng-template>
    <ng-template let-row="row" ngx-datatable-cell-template>
      <div class="status">
        <mat-icon
          class="status__icon"
          [ngClass]="getColour(row.status)?.colour"
          >{{ getColour(row.status)?.icon }}</mat-icon
        >

        <span
          class="status__text"
          [translate]="'assignmentStatus.' + row.status"
        ></span>
      </div>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name="assignment" [flexGrow]="5" [resizeable]="false">
    <ng-template ngx-datatable-header-template let-sort="sortFn">
      <span
        (click)="sort()"
        [translate]="'notifications.tableHeaders.assignment'"
        [tooltip]="'notifications.tableHeaders.assignment' | translate"
        container="body"
      ></span>
    </ng-template>
    <ng-template let-row="row" ngx-datatable-cell-template>
      <span>{{ row.assignmentName }}</span>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name="created" [flexGrow]="2" [resizeable]="false">
    <ng-template ngx-datatable-header-template let-sort="sortFn">
      <span
        (click)="sort()"
        [translate]="'notifications.tableHeaders.date'"
        [tooltip]="'notifications.tableHeaders.date' | translate"
        container="body"
      ></span>
    </ng-template>
    <ng-template let-row="row" ngx-datatable-cell-template>
      <span class="body-row-secondary">{{ row.created | date }}</span>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name="icon" [flexGrow]="1" [sortable]="false">
    <ng-template ngx-datatable-header-template></ng-template>
    <ng-template ngx-datatable-cell-template>
      <mat-icon class="body-row-arrow">chevron_right</mat-icon>
    </ng-template>
  </ngx-datatable-column>

  <!-- Table custom footer template -->
  <ngx-datatable-footer>
    <ng-template ngx-datatable-footer-template let-pageSize="pageSize">
      <div class="footer-pagination-select">
        <span [translate]="'table.footer.show'"></span>
        <ng-select
          class="footer-pagination-select__button"
          appendTo="body"
          [items]="tableSizeLimitOptions"
          [ngModel]="tableSizeLimit"
          [searchable]="false"
          [clearable]="false"
          (change)="onPaginationLimitChange($event)"
        >
        </ng-select>
        <span [translate]="'table.footer.perPage'"></span>
      </div>

      <div class="footer-results-count">
        {{ allElementsTotal }}
        <span [translate]="'table.footer.results'"></span>
      </div>

      <div class="footer-pager">
        <datatable-pager
          [pagerLeftArrowIcon]="'datatable-icon-left'"
          [pagerRightArrowIcon]="'datatable-icon-right'"
          [pagerPreviousIcon]="'datatable-icon-prev'"
          [pagerNextIcon]="'datatable-icon-skip'"
          [page]="currentPage"
          [size]="pageSize"
          [count]="allElementsTotal"
          [hidden]="!(allElementsTotal / pageSize > 1)"
          (change)="changePagination($event)"
        >
          >
        </datatable-pager>
      </div>
    </ng-template>
  </ngx-datatable-footer>
</ngx-datatable>
