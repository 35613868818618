import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AssignmentPhaseEnum } from 'src/app/core/enums/assignment-phase.enum';
import { CHART_CONFIG } from 'src/app/shared/charts/chart-config.utils';
import { noFormatting } from 'src/app/shared/utils/formaters';
import { ChartResultI } from 'src/app/core/models/log/chart-result.interface';

@Component({
  selector: 'app-my-assignments',
  templateUrl: './my-assignments.component.html',
  styleUrls: ['./my-assignments.component.scss'],
})
export class MyAssignmentsComponent implements OnInit {
  config = CHART_CONFIG;
  yAxisTickFormattingFn = noFormatting;

  @Input()
  phaseChart: ChartResultI[];

  @Input()
  isSmallScreen: boolean;

  @Output()
  browse: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  goToPhase: EventEmitter<AssignmentPhaseEnum> = new EventEmitter<AssignmentPhaseEnum>();

  constructor() {}

  ngOnInit(): void {}

  phaseChartClicked(type) {
    this.goToPhase.emit(type?.toLowerCase());
  }

  onBrowse() {
    this.browse.emit();
  }

  shouldShowEmptyText(): boolean {
    return !!this.phaseChart
      ? this.phaseChart.every((x) => x.value === 0)
      : true;
  }
}
