<app-modal-template
  [icon]="'assessment'"
  [header]="
    !projectInfo?.projectId
      ? 'projects.add.addProject'
      : 'projects.add.editProject'
  "
  [button]="'projects.add.button'"
  (closeModal)="closeModal()"
  (submit)="submit()"
>
  <app-project-form
    [formGroup]="formGroup"
    [attemptedToSubmit]="submitted"
    [users]="users$ | async"
  ></app-project-form>
</app-modal-template>
