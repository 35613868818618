<div
  *ngIf="inline; else normal"
  class="display-value"
  [ngClass]="{
    'space-top': addSpaceTop,
    'display-value--small': small,
    'display-value--inline': inline
  }"
>
  <div class="row">
    <div class="col-6">
      <div
        class="display-value__label"
        *ngIf="!!label"
        [translate]="label"
      ></div>
    </div>
    <div class="col-6">
      <div class="display-value__info" *ngIf="!(values?.length > 0)">
        <ng-container
          [ngTemplateOutlet]="template || defaultTemplate"
          [ngTemplateOutletContext]="{ $implicit: value }"
        ></ng-container>
      </div>
      <div class="display-value__info" *ngFor="let value of values">
        <ng-container
          [ngTemplateOutlet]="template || defaultTemplate"
          [ngTemplateOutletContext]="{ $implicit: value }"
        ></ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #normal>
  <div
    class="display-value"
    [ngClass]="{ 'space-top': addSpaceTop, 'display-value--small': small }"
  >
    <div class="display-value__label" *ngIf="!!label" [translate]="label"></div>
    <div class="display-value__info" *ngIf="!(values?.length > 0)">
      <ng-container
        [ngTemplateOutlet]="template || defaultTemplate"
        [ngTemplateOutletContext]="{ $implicit: value }"
      ></ng-container>
    </div>
    <div class="display-value__info" *ngFor="let value of values">
      <ng-container
        [ngTemplateOutlet]="template || defaultTemplate"
        [ngTemplateOutletContext]="{ $implicit: value }"
      ></ng-container>
    </div>
  </div>
</ng-template>

<ng-template #defaultTemplate let-templateValue>
  {{ templateValue }}
</ng-template>
