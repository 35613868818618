import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SpaceSummaryRequestI } from 'src/app/assignments/modules/analysis/store/models/space-summary-request.interface';
import { SpaceSummaryModelResponseI } from 'src/app/core/models/assignment/space-summary-model.interface';
import { environment } from 'src/environments/environment';

@Injectable()
export class AssignmentEventService {
  assignmentEventsUrl = environment.url + 'api/v1/assignment-event/';
  decisionUrl = this.assignmentEventsUrl + 'decision/';
  spaceCostUrl = this.assignmentEventsUrl + 'space/';

  constructor(private http: HttpClient) {}

  getSpaceSummary(
    request: SpaceSummaryRequestI
  ): Observable<SpaceSummaryModelResponseI> {
    const params = this.getParams(request);

    return this.http.get<SpaceSummaryModelResponseI>(this.spaceCostUrl, {
      params,
    });
  }

  private getParams(request: SpaceSummaryRequestI): HttpParams {
    let params = new HttpParams();

    Object.keys(request || {})
      .filter((x) => !!request[x] || request[x] === 0)
      .forEach((x) => {
        params = params.append(x, request[x]);
      });

    return params;
  }
}
