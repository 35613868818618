<!-- TODO: Add warnings to backend -->
<span *ngIf="warnings?.length > 0">
  <div
    class="warnings-header"
    [translate]="'groups.decision.warningsHeader'"
  ></div>
  <div *ngFor="let warning of warnings">
    <span
      class="warnings-element"
      [translate]="'groups.decision.warnings.' + warning?.translation"
      [translateParams]="warning"
    ></span>
  </div>
</span>

<form novalidate [formGroup]="formGroup">
  <div class="form-row">
    <div class="form-group col-12">
      <label
        [translate]="'groups.decision.labels.decision'"
        for="radio-approve"
      ></label>

      <span
        class="form-control-mandatory"
        *ngIf="isRequired('decision')"
        [translate]="'mandatory'"
      ></span>

      <div id="radio-approve">
        <div class="custom-control mt-2 custom-radio custom-control-inline">
          <input
            type="radio"
            id="decision-approve"
            class="custom-control-input"
            [value]="decisionTypeApproved"
            formControlName="decision"
            [ngClass]="{
              'is-invalid': isValid(formGroup.controls['decision'])
            }"
          />
          <label
            class="custom-control-label lead"
            for="decision-approve"
            [translate]="'groups.decision.labels.approve'"
          ></label>
        </div>

        <div class="custom-control custom-radio custom-control-inline ml-4">
          <input
            type="radio"
            id="decision-decline"
            class="custom-control-input"
            [value]="decisionTypeDisproved"
            formControlName="decision"
            [ngClass]="{
              'is-invalid': isValid(formGroup.controls['decision'])
            }"
          />
          <label
            class="custom-control-label lead"
            for="decision-decline"
            [translate]="'groups.decision.labels.decline'"
          ></label>
        </div>
      </div>

      <div
        class="invalid-feedback"
        [ngClass]="{
          show: isValid(formGroup.controls['decision'])
        }"
        *ngIf="formGroup.controls['decision']?.invalid"
        [translate]="'groups.decision.errorMessages.decision'"
      ></div>
    </div>
  </div>

  <div
    class="form-row"
    *ngIf="decisionTypeDisproved === formGroup.controls.decision.value"
  >
    <div class="form-group col-12">
      <label
        [translate]="'groups.decision.labels.assignmentPart'"
        for="radio-parts"
      ></label>

      <div id="radio-parts">
        <div class="custom-control custom-checkbox mt-2 text-left">
          <div
            formArrayName="blame"
            *ngFor="
              let blame of formGroup.controls.blame['controls'];
              let i = index;
              let isFirst = first
            "
          >
            <input
              #checkbox
              [id]="'blame' + i"
              type="checkbox"
              class="custom-control-input"
              value="{{ assignmentParts[i].name }}"
              [formControlName]="i"
            />
            <label
              [for]="'blame' + i"
              [ngClass]="{ 'mt-3': !isFirst }"
              class="custom-control-label lead"
            >
              {{ assignmentParts[i].name }}
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col-12">
      <label
        for="decision-comment"
        [translate]="'groups.decision.labels.comment'"
      ></label>

      <span
        class="form-control-mandatory"
        *ngIf="isRequired('comment')"
        [translate]="'mandatory'"
      ></span>

      <textarea
        class="form-control mt-2"
        id="decision-comment"
        rows="3"
        formControlName="comment"
      >
      </textarea>
    </div>
  </div>
</form>
