import { createSelector } from '@ngrx/store';

import { getPusherMessagesStateSelector } from 'src/app/core/store/index';
import {
  getAllPusherMessages,
  getPusherMessagesEntities,
  getPusherMessagesError,
  getPusherMessagesIds,
  getPusherMessagesLoaded,
  getPusherMessagesLoading,
  getPusherMessagesPagination,
  getPusherMessagesTotal,
} from 'src/app/core/store/reducers/pusher.reducer';
import { PusherMessageI } from 'src/app/core/models/message/pusher-message.interface';
import { mobilePagination } from 'src/app/shared/utils/mobile-table-utils';

export const getPusherMessagesIdsSelector = createSelector(
  getPusherMessagesStateSelector,
  getPusherMessagesIds
);

export const getPusherMessagesEntitiesSelector = createSelector(
  getPusherMessagesStateSelector,
  getPusherMessagesEntities
);

export const getAllPusherMessagesSelector = createSelector(
  getPusherMessagesStateSelector,
  getAllPusherMessages
);

export const getPusherMessagesTotalSelector = createSelector(
  getPusherMessagesStateSelector,
  getPusherMessagesTotal
);

export const getPusherMessagesPaginationSelector = createSelector(
  getPusherMessagesStateSelector,
  getPusherMessagesPagination
);

export const getPusherMessagesLoadedSelector = createSelector(
  getPusherMessagesStateSelector,
  getPusherMessagesLoaded
);

export const getPusherMessagesLoadingSelector = createSelector(
  getPusherMessagesStateSelector,
  getPusherMessagesLoading
);

export const getAlertMessagesErrorSelector = createSelector(
  getPusherMessagesStateSelector,
  getPusherMessagesError
);

export const getRecentPusherMessagesSelector = createSelector(
  getAllPusherMessagesSelector,
  (pusherMessages: PusherMessageI[]) => (pusherMessages || []).slice(0, 4)
);

export const getPusherMessagesTableSizeLimitSelector = createSelector(
  getPusherMessagesPaginationSelector,
  (pagination) => pagination.limit
);

export const getPusherMessagesTablePaginationLimitOptionsSelector = createSelector(
  getPusherMessagesPaginationSelector,
  (pagination) => pagination.limitOptions
);

export const getPusherMessagesTablePaginationPageSelector = createSelector(
  getPusherMessagesPaginationSelector,
  (pagination) => pagination.page
);

export const getAllPusherMessagesForMobileSelector = createSelector(
  getAllPusherMessagesSelector,
  getPusherMessagesTableSizeLimitSelector,
  getPusherMessagesTablePaginationPageSelector,
  mobilePagination
);
