import { HttpParams } from '@angular/common/http';
import { AssignmentPhaseEnum } from 'src/app/core/enums/assignment-phase.enum';

export interface QueryUrlParamsI {
  phase?: AssignmentPhaseEnum;
  query?: string;
  externalProjectId?: string;
  filterOnUser?: string;
  searchType?: string;
  anyCalculation?: boolean;
  noCalculation?: boolean;
}

export class QueryUrlParams implements QueryUrlParamsI {
  phase?: AssignmentPhaseEnum;
  query?: string;
  externalProjectId?: string;
  filterOnUser?: string;
  searchType?: string;
  anyCalculation?: boolean;
  noCalculation?: boolean;

  static fromAnyObject(object: any) {
    const params = new QueryUrlParams();
    params.phase = object['phase'];
    params.query = object['query'];
    params.externalProjectId = object['externalProjectId'];
    params.filterOnUser = object['filterOnUser'];
    params.searchType = object['searchType'];
    params.anyCalculation = this.convertToBoolean(object['anyCalculation']);
    params.noCalculation = this.convertToBoolean(object['noCalculation']);
    return params;
  }

  static fromArguments(
    phase: AssignmentPhaseEnum,
    query: string,
    externalProjectId: string,
    filterOnUser: string,
    searchType: string,
    anyCalculation: boolean,
    noCalculation: boolean
  ) {
    const params = new QueryUrlParams();
    params.phase = phase;
    params.query = query;
    params.externalProjectId = externalProjectId;
    params.filterOnUser = filterOnUser;
    params.searchType = searchType;
    params.anyCalculation = this.convertToBoolean(anyCalculation);
    params.noCalculation = this.convertToBoolean(noCalculation);
    return params;
  }

  static fromHttpParams(params: HttpParams) {
    const object = params
      .keys()
      .reduce((o, key) => ({ ...o, [key]: params.get(key) }), {});
    return QueryUrlParams.fromAnyObject(object);
  }

  private static convertToBoolean(value: any): boolean {
    return value === 'true' || value === true ? true : undefined;
  }
}
