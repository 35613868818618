export function enumValues(enumObj): any[] {
  return Object.keys(enumObj).map(key => enumObj[key]);
}

export function checkIfValueIsPresentInEnum(enumObject: any, value: string) {
  return Object.values(enumObject).includes(value);
}

export function checkIfKeyIsPresentInEnum(enumObject: any, key: string) {
  return Object.keys(enumObject).includes(key);
}

export function checkIfAnyElementFromEnumArrayIsEnum(
  comparedArray: any[],
  enumObject: any
): boolean {
  if (!!comparedArray && !!enumObject) {
    const array = Object.values(enumObject);
    return comparedArray.some(r => array.includes(r));
  } else {
    return false;
  }
}
