<div class="progress-bar">
  <div
    class="progress-bar__value"
    [ngStyle]="{ width: _percent + '%' }"
    [ngClass]="{
      'bg-success': _percent >= 0 && _percent <= 89,
      'bg-warning': _percent >= 90 && _percent <= 99,
      'bg-danger': _percent >= 100
    }"
  ></div>
</div>
