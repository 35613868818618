import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-template-form-section',
  templateUrl: './modal-template-form-section.component.html',
  styleUrls: ['./modal-template-form-section.component.scss'],
})
export class ModalTemplateFormSectionComponent implements OnInit {
  @Input()
  title: string;

  @Input()
  subtitle: string;

  @Input()
  showLine = true;

  constructor() {}

  ngOnInit() {}
}
