<app-section
  [headerTitle]="'overview.myAssignments.header'"
  [emptyText]="'overview.myAssignments.emptyChart'"
  [showEmptyText]="shouldShowEmptyText()"
  [buttonLabel]="'overview.myAssignments.browsing'"
  (buttonClicked)="onBrowse()"
>
  <div>
    <div *ngIf="!!phaseChart">
      <div class="chart" *ngIf="!isSmallScreen">
        <ngx-charts-bar-horizontal
          class="chart-my-assignments"
          [results]="phaseChart"
          [scheme]="config.colorScheme"
          [yAxis]="true"
          [showXAxisLabel]="false"
          [yAxisTickFormatting]="yAxisTickFormattingFn"
          [roundEdges]="true"
          (select)="phaseChartClicked($event.name)"
        >
        </ngx-charts-bar-horizontal>
      </div>

      <app-phase-chart-mobile
        *ngIf="isSmallScreen"
        [series]="phaseChart"
        (goToPhase)="phaseChartClicked($event)"
      ></app-phase-chart-mobile>
    </div>
  </div>
</app-section>
