import { Component, OnInit } from '@angular/core';
import { StoreDispatcher } from 'src/app/shared/base-classes/store-dispatcher/store-dispatcher';
import { Observable } from 'rxjs';
import { UserPublicI } from 'src/app/core/models/user/user-resource.interface';
import { Store } from '@ngrx/store';
import {
  getEnableUserEditSelector,
  getUserPublicSelector,
} from 'src/app/auth/store/selectors/user.selectors';
import {
  EnableEditUser,
  ResendEmailToUser,
  UpdateUser,
} from 'src/app/auth/store/actions/user.action';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
})
export class AccountComponent extends StoreDispatcher<any> implements OnInit {
  user$: Observable<UserPublicI>;
  enableEdit$: Observable<boolean>;

  constructor(store: Store<any>) {
    super(store);
  }

  ngOnInit(): void {
    this.user$ = this.store.select(getUserPublicSelector);
    this.enableEdit$ = this.store.select(getEnableUserEditSelector);
  }

  onEditUser(userPublicI: UserPublicI) {
    this.dispatchAction(new UpdateUser(userPublicI));
  }

  onResendInvitation(userId: string) {
    this.dispatchAction(new ResendEmailToUser(userId));
  }

  onEnableEdit(shouldEnable: boolean) {
    this.dispatchAction(new EnableEditUser(shouldEnable));
  }
}
