<app-bottom-drower-mobile
  [header]="'mobileActions.headerExport' | translate"
  [small]="true"
  (close)="onCloseMobileExport()"
>
  <div class="actions-dropdown-export-mobile__options">
    <span
      *ngIf="
        assignmentId?.length > 0 &&
        assignmentIds?.length === 0 &&
        selectedAssignmentIds?.length === 0
      "
    >
      <button
        class="actions-dropdown-export-mobile__options-button btn btn-third"
        [translate]="'dropdown-menu.exportToExcel'"
        (click)="onExportToExcelSingle()"
      ></button>

      <button
        class="actions-dropdown-export-mobile__options-button btn btn-third"
        [translate]="'dropdown-menu.exportToSimpleExcel'"
        (click)="onExportToSimpleExcelSingle()"
      ></button>
    </span>

    <span *ngIf="selectedAssignmentIds?.length > 0">
      <button
        class="actions-dropdown-export-mobile__options-button btn btn-third"
        [translate]="'dropdown-menu.exportToExcel'"
        (click)="onExportToExcel()"
      ></button>

      <button
        class="actions-dropdown-export-mobile__options-button btn btn-third"
        [translate]="'dropdown-menu.exportToSimpleExcel'"
        (click)="onExportToSimpleExcel()"
      ></button>
    </span>

    <span
      *ngIf="
        assignmentIds?.length > 0 &&
        selectedAssignmentIds?.length === 0 &&
        assignmentId?.length === 0
      "
    >
      <button
        class="actions-dropdown-export-mobile__options-button btn btn-third"
        [translate]="'dropdown-menu.exportAllToExcel'"
        (click)="onExportAllToExcel()"
      ></button>

      <button
        class="actions-dropdown-export-mobile__options-button btn btn-third"
        [translate]="'dropdown-menu.exportAllToSimpleExcel'"
        (click)="onExportAllToSimpleExcel()"
      ></button>
    </span>

    <span
      *ngIf="selectedCalculationIds?.newId && selectedCalculationIds?.oldId"
    >
      <button
        class="actions-dropdown-export-mobile__options-button btn btn-third"
        [translate]="'dropdown-menu.printToPDF'"
        (click)="onPrintToPDF()"
      ></button>
    </span>
  </div>
</app-bottom-drower-mobile>
