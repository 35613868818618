import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MyActionI } from 'src/app/core/models/user/start-page.interface';
import { Action } from '@ngrx/store';
import { InviteContractorTemplateComponent } from 'src/app/core/modals/containers/invite-contractor-template/invite-contractor-template.component';
import { AssignmentActionsEnum } from 'src/app/core/enums/assignment-actions.enum';
import { MakeDecision } from 'src/app/core/store/actions/decision.action';
import { ShowModal } from 'src/app/core/store/actions/modal.action';
import { CloseAssignment } from 'src/app/core/store/actions/assignment-change-status.action';
import { RouterGo } from 'src/app/core/store/actions/router.action';
import { AssignmentUrlEnum } from 'src/app/core/enums/url-paths.enum';
import getAssignmentListAnalysisUrl = AssignmentUrlEnum.getAssignmentListAnalysisUrl;

@Component({
  selector: 'app-base-my-action-card-button',
  template: '',
  styles: [],
})
export class BaseMyActionCardButtonComponent implements OnInit {
  @Input()
  action: MyActionI;

  @Input()
  showMobileActions: MyActionI;

  @Input()
  isSmallScreen: boolean;

  @Output()
  makeAction: EventEmitter<Action> = new EventEmitter<Action>();

  @Output()
  openMobileActions: EventEmitter<MyActionI> = new EventEmitter<MyActionI>();

  inviteContractorTemplate = () => InviteContractorTemplateComponent;

  constructor() {}

  ngOnInit(): void {}

  onOpenMobileActions(myAction: MyActionI) {
    if (this.isSmallScreen) {
      this.openMobileActions.emit(myAction);
    }
  }

  onMakeAction(assignmentAction: AssignmentActionsEnum) {
    switch (assignmentAction) {
      case AssignmentActionsEnum.DECISION: {
        const action = new RouterGo({
          path: [
            getAssignmentListAnalysisUrl(
              AssignmentUrlEnum.COMPARE,
              this.action.assignmentId
            ),
          ],
        });

        this.makeAction.emit(action);
        break;
      }
      case AssignmentActionsEnum.DECISION_APPROVE: {
        const action = new MakeDecision({
          assignmentId: this.action.assignmentId,
          decisionResource: {
            assignmentEventId: this.action.assignmentEventId,
            decision: 'approved',
            comment: '',
            blamedPartyNames: [],
          },
        });

        this.makeAction.emit(action);
        break;
      }
      case AssignmentActionsEnum.DECISION_REJECT: {
        const action = new MakeDecision({
          assignmentId: this.action.assignmentId,
          decisionResource: {
            assignmentEventId: this.action.assignmentEventId,
            decision: 'declined',
            comment: '',
            blamedPartyNames: [],
          },
        });

        this.makeAction.emit(action);
        break;
      }
      case AssignmentActionsEnum.INVITE: {
        const action = new ShowModal({
          content: this.inviteContractorTemplate,
          config: {
            // @ts-ignore
            initialState: { assignmentId: this.action.assignmentId },
          },
        });

        this.makeAction.emit(action);
        break;
      }
      case AssignmentActionsEnum.CLOSE: {
        const action = new CloseAssignment(this.action.assignmentId);

        this.makeAction.emit(action);
        break;
      }
      default: {
        console.log('unknown action type');
      }
    }
  }
}
