import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AssignmentStatusEnum } from 'src/app/core/enums/assignment-status.enum';
import { getStatusToIcon } from 'src/app/core/utils/status-to-icon';
import { PusherMessageI } from 'src/app/core/models/message/pusher-message.interface';

@Component({
  selector: 'app-header-icons',
  templateUrl: './header-icons.component.html',
  styleUrls: ['./header-icons.component.scss'],
})
export class HeaderIconsComponent implements OnInit {
  @Input()
  isSmallScreen: boolean;

  @Input()
  userName: string;

  @Input()
  messages: PusherMessageI[];

  @Input()
  isAdmin: boolean;

  @Output()
  goToSupport: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  goToAssignmentDetails: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  goToMessages: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  goToAdministration: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  goToAccountDetails: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  logout: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  showSideBar: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}

  onGoToSupport() {
    this.goToSupport.emit();
  }

  onGoToAssignmentDetails(assignmentId: string) {
    this.goToAssignmentDetails.emit(assignmentId);
  }

  onGoToMessages() {
    this.goToMessages.emit();
  }

  onGoToAdministration() {
    this.goToAdministration.emit();
  }

  onGoToAccountDetails() {
    this.goToAccountDetails.emit();
  }

  onLogout() {
    this.logout.emit();
  }

  onShowSideBar() {
    this.showSideBar.emit();
  }

  getIcon(type: AssignmentStatusEnum): { icon: string; colour: string } {
    return getStatusToIcon(type);
  }
}
