import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { ComponentCanDeactivate } from 'src/app/shared/models/can-deactivate.interface';

@Injectable({
  providedIn: 'root',
})
export class UnsavedChangesGuard
  implements CanDeactivate<ComponentCanDeactivate> {
  translationKey = 'discardChanges';

  constructor(private translationService: TranslateService) {}

  canDeactivate(
    component: ComponentCanDeactivate
  ): boolean | Observable<boolean> {
    return this.confirmDeactivation(component.canDeactivate());
  }

  confirmDeactivation(canDeactivate: boolean | Observable<boolean>): boolean {
    if (canDeactivate) {
      return true;
    }

    const translation = this.translationService.instant(this.translationKey);
    return confirm(translation);
  }
}
