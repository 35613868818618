import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { getCustomerPublicSelector } from 'src/app/auth/store/selectors/user.selectors';
import { InspectionSystemTypeEnum } from 'src/app/core/enums/inspection-system-type.enum';
import { BaseTemplateComponent } from 'src/app/shared/components/base-template/base-template.component';
import {
  CustomerPublicI,
  UserResourceI,
} from 'src/app/core/models/user/user-resource.interface';
import { BookInspection } from 'src/app/core/store/actions/inspection.action';
import { getAllCompanyInspectorsSelector } from 'src/app/core/store/selectors/company-users.selectors';
import { enumValues } from 'src/app/core/utils/enum-values';

@Component({
  selector: 'app-book-inspection-template',
  templateUrl: './book-inspection-template.component.html',
  styleUrls: ['./book-inspection-template.component.scss'],
})
export class BookInspectionTemplateComponent
  extends BaseTemplateComponent
  implements OnInit {
  inspectionSystemTypeEnum: InspectionSystemTypeEnum[] = enumValues(
    InspectionSystemTypeEnum
  );

  assignmentId: string;

  inspectors$: Observable<UserResourceI[]>;
  customerPublic$: Observable<CustomerPublicI>;

  formGroup: FormGroup;
  submitted = false;

  @HostListener('document:click', ['$event'])
  clickout(event) {
    this.checkIfShouldCloseModal(event);
  }

  constructor(store: Store<any>, eRef: ElementRef, private fb: FormBuilder) {
    super(store, eRef);
  }

  ngOnInit() {
    this.getData();
    this.createFormControls();
  }

  bookInspection() {
    this.submitted = true;

    if (this.formGroup.valid) {
      this.dispatchActionAndCloseModal(
        new BookInspection({
          assignmentId: this.assignmentId,
          ...this.formGroup.value,
        })
      );
    }
  }

  private getData() {
    this.inspectors$ = this.store.pipe(select(getAllCompanyInspectorsSelector));
    this.customerPublic$ = this.store.pipe(select(getCustomerPublicSelector));
  }

  private createFormControls() {
    this.customerPublic$
      .pipe(
        map((customer: CustomerPublicI) => customer.enableUwInspections),
        take(1)
      )
      .subscribe(
        (enableUwInspection) =>
          (this.formGroup = this.fb.group({
            system: [
              enableUwInspection ? '' : InspectionSystemTypeEnum.MEPS,
              Validators.required,
            ],
            inspectorId: ['', Validators.required],
            bookedTime: ['', Validators.required],
            bookedDate: ['', Validators.required],
            message: [''],
          }))
      );
  }

  canDeactivate(): boolean | Observable<boolean> {
    return !this.formGroup.dirty || this.submitted;
  }
}
