import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import {
  AssignmentStartedFail,
  AssignmentStartedSuccess,
  CleanStartAssignmentsState,
  StartAssignment,
  StartAssignmentsActionTypes,
} from 'src/app/core/modules/start/store/actions/start-assignments.action';
import { AssignmentsService } from 'src/app/core/services/api/assignments/assignments.service';
import { StringResourceI } from 'src/app/core/models/string-resource.interface';
import { RouterGo } from 'src/app/core/store/actions/router.action';
import { AssignmentUrlEnum } from 'src/app/core/enums/url-paths.enum';
import getAssignmentListAnalysisUrl = AssignmentUrlEnum.getAssignmentListAnalysisUrl;

@Injectable()
export class StartAssignmentsEffects {
  constructor(
    private actions$: Actions,
    private assignmentsService: AssignmentsService
  ) {}

  public startAssignment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StartAssignmentsActionTypes.StartAssignment),
      switchMap((action: StartAssignment) =>
        this.assignmentsService.startAssignment(action.payload).pipe(
          map((id: StringResourceI) => new AssignmentStartedSuccess(id.value)),
          catchError((error: ErrorResponseWithIdI) =>
            of(new AssignmentStartedFail(error))
          )
        )
      )
    )
  );

  public handleStartAssignmentSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StartAssignmentsActionTypes.AssignmentStartedSuccess),
      map((action: AssignmentStartedSuccess) => {
        return new RouterGo({
          path: [
            getAssignmentListAnalysisUrl(
              AssignmentUrlEnum.ASSIGNMENT,
              action.payload
            ),
          ],
        });
      })
    )
  );

  public handleAssignmentFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StartAssignmentsActionTypes.AssignmentStartedFail),
      map(() => new CleanStartAssignmentsState())
    )
  );
}
