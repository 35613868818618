import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertMessage } from 'src/app/core/models/alert-message.interface';
import {
  AlertMessagesActions,
  HideMessage,
  HideMultipleMessages,
} from 'src/app/core/store/actions/alert-messages.action';
import { AlertMessagesTypes } from 'src/app/core/enums/alert-messages-types.enum';
import { enumValues } from 'src/app/core/utils/enum-values';

@Component({
  selector: 'app-alert-messages',
  templateUrl: './alert-messages.component.html',
  styleUrls: ['./alert-messages.component.scss'],
})
export class AlertMessagesComponent implements OnInit {
  alertMessagesTypes = AlertMessagesTypes;
  alertMessagesTypesValues = enumValues(AlertMessagesTypes);

  @Input()
  alerts: { [id: string]: AlertMessage[] };

  @Input()
  showMissingExternalId: boolean;

  @Output()
  dismissed: EventEmitter<AlertMessagesActions> = new EventEmitter<AlertMessagesActions>();

  constructor() {}

  ngOnInit() {}

  onDismissed(alertMessage: AlertMessage) {
    this.dismissed.emit(new HideMessage(alertMessage));
  }

  onDismissedMultiple(alertMessage: AlertMessage[]) {
    const ids = alertMessage.map((x) => x.id);

    this.dismissed.emit(new HideMultipleMessages(ids));
  }

  shouldShowPanel() {
    return (
      Object.values(this.alerts || {}).some((x) => x.length > 0) ||
      this.showMissingExternalId
    );
  }
}
