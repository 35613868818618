import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AlertMessage } from 'src/app/core/models/alert-message.interface';
import { StoreDispatcher } from 'src/app/shared/base-classes/store-dispatcher/store-dispatcher';
import { PusherMessageI } from 'src/app/core/models/message/pusher-message.interface';
import {
  getAllPusherMessagesForMobileSelector,
  getAllPusherMessagesSelector,
  getPusherMessagesTablePaginationLimitOptionsSelector,
  getPusherMessagesTablePaginationPageSelector,
  getPusherMessagesTableSizeLimitSelector,
  getPusherMessagesTotalSelector,
} from 'src/app/core/store/selectors/pusher-messages.selectors';
import {
  ChangePusherMessagesPagination,
  ChangePusherMessagesPaginationLimit,
} from 'src/app/core/store/actions/pusher.action';
import { PageSizeService } from 'src/app/core/services/page-size/page-size.service';
import { RouterGo } from 'src/app/core/store/actions/router.action';
import { AssignmentUrlEnum } from 'src/app/core/enums/url-paths.enum';
import getAssignmentListAnalysisUrl = AssignmentUrlEnum.getAssignmentListAnalysisUrl;

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent
  extends StoreDispatcher<any>
  implements OnInit {
  alertMessages$: Observable<PusherMessageI[]>;
  alertMessagesForMobile$: Observable<PusherMessageI[]>;
  alertMessagesTotal$: Observable<number>;

  tableSizeLimit$: Observable<number>;
  tableSizeLimitOptions$: Observable<number[]>;
  currentPage$: Observable<number>;

  constructor(store: Store<any>, public pageSizeService: PageSizeService) {
    super(store);
  }

  ngOnInit() {
    this.alertMessages$ = this.store.pipe(select(getAllPusherMessagesSelector));
    this.alertMessagesForMobile$ = this.store.pipe(
      select(getAllPusherMessagesForMobileSelector)
    );
    this.alertMessagesTotal$ = this.store.pipe(
      select(getPusherMessagesTotalSelector)
    );

    this.tableSizeLimit$ = this.store.pipe(
      select(getPusherMessagesTableSizeLimitSelector)
    );
    this.tableSizeLimitOptions$ = this.store.pipe(
      select(getPusherMessagesTablePaginationLimitOptionsSelector)
    );
    this.currentPage$ = this.store.pipe(
      select(getPusherMessagesTablePaginationPageSelector)
    );
  }

  onGoToDetails(alertMessage: AlertMessage) {
    this.dispatchAction(
      new RouterGo({
        path: [
          getAssignmentListAnalysisUrl(
            AssignmentUrlEnum.ASSIGNMENT,
            alertMessage.assignmentId
          ),
        ],
      })
    );
  }

  onPaginationChanged(page: number) {
    this.dispatchAction(new ChangePusherMessagesPagination(page));
  }

  onPaginationLimitChanged(limit: number) {
    this.dispatchAction(new ChangePusherMessagesPaginationLimit(limit));
  }
}
