import { InjectionToken } from '@angular/core';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { ActionReducerMap, combineReducers, createFeatureSelector, createSelector, MetaReducer } from '@ngrx/store';
import { clearState } from 'src/app/core/store/meta-reducers/clear-state';

import { agreementsReducer, AgreementsReducerState } from 'src/app/core/store/reducers/agreements.reducer';

import { assignmentsReducer, AssignmentsReducerState } from 'src/app/core/store/reducers/assignments.reducer';
import { companyUsersReducer, CompanyUsersReducerState } from 'src/app/core/store/reducers/company-users.reducer';

import {
  googleAnalyticsReducer,
  GoogleAnalyticsReducerState
} from 'src/app/core/store/reducers/google-analytics.reducer';

import { LanguageState, languageStateReducer } from 'src/app/core/store/reducers/language.reducer';
import { modalReducer, ModalReducerState } from 'src/app/core/store/reducers/modal.reducer';

import { PWAState, pwaStateReducer } from 'src/app/core/store/reducers/pwa.reducer';
import { RouterStateUrl } from 'src/app/core/store/reducers/router.reducer';
import { spinnerReducer, SpinnerReducerState } from 'src/app/core/store/reducers/spinner.reducer';
import { environment } from 'src/environments/environment';
import { startPageReducer, StartPageReducerState } from 'src/app/core/store/reducers/start-page.reducer';
import { decisionReducer, DecisionReducerState } from 'src/app/core/store/reducers/decision.reducer';
import { pusherReducer, PusherReducerState } from 'src/app/core/store/reducers/pusher.reducer';
import { alertMessagesReducer, AlertMessagesReducerState } from 'src/app/core/store/reducers/alert-messages.reducer';
import { sideBarReducer, SideBarReducerState } from 'src/app/core/store/reducers/side-bar.reducer';

/**
 * Interface which represent our store structure
 * ApplicationState can be imagine as DB
 * uiState can be imagine as table
 */
export interface ApplicationState {
  state: State;
  data: Data;
}

export interface Data {
  companyUsers: CompanyUsersReducerState;
  agreements: AgreementsReducerState;
  assignments: AssignmentsReducerState;
  startPage: StartPageReducerState;
  decision: DecisionReducerState;
  pusherMessages: PusherReducerState;
}

export interface State {
  language: LanguageState;
  pwa: PWAState;
  router: RouterReducerState<RouterStateUrl>;
  spinner: SpinnerReducerState;
  googleAnalytics: GoogleAnalyticsReducerState;
  modal: ModalReducerState;
  alertMessages: AlertMessagesReducerState;
  sideBar: SideBarReducerState;
}

/**
 * Store composed from reducers ( small parts of store )
 * this is place where our data will be stored base on logic
 * applied in reducers
 */
export const stateReducers = combineReducers({
  language: languageStateReducer,
  pwa: pwaStateReducer,
  router: routerReducer,
  spinner: spinnerReducer,
  googleAnalytics: googleAnalyticsReducer,
  modal: modalReducer,
  alertMessages: alertMessagesReducer,
  sideBar: sideBarReducer,
});

export const dataReducers = combineReducers({
  companyUsers: companyUsersReducer,
  agreements: agreementsReducer,
  assignments: assignmentsReducer,
  startPage: startPageReducer,
  decision: decisionReducer,
  pusherMessages: pusherReducer,
});

export const reducerToken = new InjectionToken<
  ActionReducerMap<ApplicationState>
>('Reducers');

export function getReducers() {
  return {
    state: stateReducers,
    data: dataReducers,
  };
}

export const reducerProvider = [
  { provide: reducerToken, useFactory: getReducers },
];

/**
 * This is are additional reducers which are used only in dev env to help us
 * logger will log for us all dispatched actions in console
 * storeFreeze will make sure that we are not mutating object in store
 */
export const metaReducers: MetaReducer<ApplicationState>[] = !environment.production
  ? <MetaReducer<ApplicationState>[]>[clearState]
  : [clearState];

/**
 * Feature Selectors
 */
export const getApplicationStateSelector = createFeatureSelector<State>(
  'state'
);
export const getApplicationDataSelector = createFeatureSelector<Data>('data');

export const getLanguageStateSelector = createSelector(
  getApplicationStateSelector,
  (applicationState: State) => applicationState.language
);
export const getPWAStateSelector = createSelector(
  getApplicationStateSelector,
  (applicationState: State) => applicationState.pwa
);
export const getRouterStateSelector = createSelector(
  getApplicationStateSelector,
  (applicationState: State) => applicationState.router
);
export const getSpinnerStateSelector = createSelector(
  getApplicationStateSelector,
  (applicationState: State) => applicationState.spinner
);
export const getGoogleAnalyticsStateSelector = createSelector(
  getApplicationStateSelector,
  (applicationState: State) => applicationState.googleAnalytics
);
export const getModalStateSelector = createSelector(
  getApplicationStateSelector,
  (applicationState: State) => applicationState.modal
);
export const getAlertMessagesStateSelector = createSelector(
  getApplicationStateSelector,
  (applicationState: State) => applicationState.alertMessages
);
export const getSideBarStateSelector = createSelector(
  getApplicationStateSelector,
  (applicationState: State) => applicationState.sideBar
);

export const getCompanyUsersStateSelector = createSelector(
  getApplicationDataSelector,
  (applicationState: Data) => applicationState.companyUsers
);
export const getAgreementsStateSelector = createSelector(
  getApplicationDataSelector,
  (applicationState: Data) => applicationState.agreements
);
export const getAssignmentsStateSelector = createSelector(
  getApplicationDataSelector,
  (applicationState: Data) => applicationState.assignments
);
export const getStartPageStateSelector = createSelector(
  getApplicationDataSelector,
  (applicationState: Data) => applicationState.startPage
);
export const getDecisionStateSelector = createSelector(
  getApplicationDataSelector,
  (applicationState: Data) => applicationState.decision
);
export const getPusherMessagesStateSelector = createSelector(
  getApplicationDataSelector,
  (applicationState: Data) => applicationState.pusherMessages
);
