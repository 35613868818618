import { Component, OnInit } from '@angular/core';
import { AssignmentStatusEnum } from 'src/app/core/enums/assignment-status.enum';
import { getStatusToIcon } from 'src/app/core/utils/status-to-icon';
import { BaseListTableComponent } from 'src/app/shared/components/base-list-table/base-list-table.component';

@Component({
  selector: 'app-notification-list-mobile',
  templateUrl: './notification-list-mobile.component.html',
  styleUrls: ['./notification-list-mobile.component.scss'],
})
export class NotificationListMobileComponent
  extends BaseListTableComponent
  implements OnInit {
  constructor() {
    super();
  }

  ngOnInit(): void {}

  getColour(type: AssignmentStatusEnum): { icon: string; colour: string } {
    return getStatusToIcon(type);
  }
}
