import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AssignmentI } from 'src/app/core/models/assignment/assignment.interface';
import { AssignmentActionsEnum } from 'src/app/core/enums/assignment-actions.enum';

@Component({
  selector: 'app-base-actions-dropdown-single-assignment',
  template: '',
  styles: [''],
})
export class BaseActionsDropdownSingleAssignmentComponent implements OnInit {
  @Input()
  assignment: AssignmentI;

  @Output()
  goToMeps: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  goToChatInMeps: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  close: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  remove: EventEmitter<{
    assignmentId: string;
    wasCreatedInUw: boolean;
  }> = new EventEmitter<{
    assignmentId: string;
    wasCreatedInUw: boolean;
  }>();

  @Output()
  reopen: EventEmitter<string> = new EventEmitter<string>();

  assignmentActionsEnum = AssignmentActionsEnum;

  constructor() {}

  ngOnInit() {}

  onGoToMeps(id: string) {
    this.goToMeps.emit(id);
  }

  onGoToChatInMeps(id: string) {
    this.goToChatInMeps.emit(id);
  }

  onClose(id: string) {
    this.close.emit(id);
  }

  onRemove(assignmentId: string, wasCreatedInUw) {
    this.remove.emit({ assignmentId, wasCreatedInUw });
  }

  onReopen(id: string) {
    this.reopen.emit(id);
  }
}
