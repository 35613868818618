// TODO: Add integration with our backend to dynamically load
// RoomElements types
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';

import { DictionaryI } from 'src/app/dictionaries/models/dictionary.interface';
import {
  RoomElementsTypesAction,
  RoomElementsTypesActionTypes,
} from 'src/app/dictionaries/store/actions/room-elements-types.action';

const selectId = (dictionary: DictionaryI) => dictionary.name;
const adapter: EntityAdapter<DictionaryI> = createEntityAdapter<DictionaryI>({
  selectId,
});

export interface RoomElementsTypesReducerState
  extends EntityState<DictionaryI> {
  loading: boolean;
  loaded: boolean;
  error: ErrorResponseWithIdI;
}

export const initialRoomElementsTypesReducerState: RoomElementsTypesReducerState = adapter.getInitialState(
  {
    loading: false,
    loaded: false,
    error: null,
  }
);

export function roomElementsTypesReducer(
  state: RoomElementsTypesReducerState = initialRoomElementsTypesReducerState,
  action: RoomElementsTypesAction
): RoomElementsTypesReducerState {
  switch (action.type) {
    case RoomElementsTypesActionTypes.LoadRoomElementsTypes: {
      return {
        ...state,
        loading: true,
      };
    }

    case RoomElementsTypesActionTypes.LoadedRoomElementsTypesSuccess: {
      return adapter.addMany(action.payload, {
        ...state,
        loading: false,
        loaded: true,
      });
    }

    case RoomElementsTypesActionTypes.LoadedRoomElementsTypesFail: {
      return {
        ...state,
        error: action.payload,
      };
    }

    case RoomElementsTypesActionTypes.CleanRoomElementsTypesState: {
      return {
        ...initialRoomElementsTypesReducerState,
      };
    }

    default: {
      return state;
    }
  }
}

export const getRoomElementsTypesIds = adapter.getSelectors().selectIds;
export const getRoomElementsTypesEntities = adapter.getSelectors()
  .selectEntities;
export const getAllRoomElementsTypes = adapter.getSelectors().selectAll;
export const getRoomElementsTypesTotal = adapter.getSelectors().selectTotal;

export const getRoomElementsTypesLoading = (
  state: RoomElementsTypesReducerState
) => state.loading;
export const getRoomElementsTypesLoaded = (
  state: RoomElementsTypesReducerState
) => state.loaded;
export const getRoomElementsTypesError = (
  state: RoomElementsTypesReducerState
) => state.error;
