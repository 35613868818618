import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AssignmentStatusEnum } from 'src/app/core/enums/assignment-status.enum';
import { getStatusToIcon } from 'src/app/core/utils/status-to-icon';
import { WatchListItemI } from 'src/app/core/models/user/start-page.interface';

@Component({
  selector: 'app-watchlist',
  templateUrl: './watchlist.component.html',
  styleUrls: ['./watchlist.component.scss'],
})
export class WatchlistComponent implements OnInit {
  @Input()
  watchlist: WatchListItemI[];

  @Output()
  browse: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  goToDetails: EventEmitter<string> = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {}

  onBrowse() {
    this.browse.emit();
  }

  onGoToDetails(assignmentId: string) {
    this.goToDetails.emit(assignmentId);
  }

  getColour(type: AssignmentStatusEnum): { icon: string; colour: string } {
    return getStatusToIcon(type);
  }
}
