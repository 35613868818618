export enum CoreUrlEnums {
  OVERVIEW = 'overview',

  ASSIGNMENTS = 'assignments',
  NUMBERS = 'numbers',
  ADMINISTRATION = 'administration',
  ACCOUNT = 'account',
  PROJECTS = 'projects',

  LOGIN = 'login',
  BLOCKED = 'blocked',
  REGISTER = 'register',

  SUPPORT = 'support',
  NOTIFICATIONS = 'notifications',
}

export enum AssignmentUrlEnum {
  WATCH_LIST = 'watch-list',
  MAKE_ACTION_LIST = 'make-action-list',
  ASSIGNMENT_LIST = 'assignment-list',

  ANALYSIS = 'analysis',

  ASSIGNMENT = 'assignment',
  ASSIGNMENT_EVENTS = 'events',
  COMPARE = 'compare',
  ROOM = 'room',
  MATERIAL = 'material',
}

export namespace AssignmentUrlEnum {
  export function getAssignmentUrl(url: string | number) {
    return `${CoreUrlEnums.ASSIGNMENTS}/${url}/`;
  }

  export function getAssignmentWatchListAnalysisUrl(
    url: string | number,
    id: string
  ) {
    return `${CoreUrlEnums.ASSIGNMENTS}/${AssignmentUrlEnum.WATCH_LIST}/${AssignmentUrlEnum.ANALYSIS}/${url}/${id}`;
  }

  export function getAssignmentMakeActionAnalysisUrl(
    url: string | number,
    id: string
  ) {
    return `${CoreUrlEnums.ASSIGNMENTS}/${AssignmentUrlEnum.MAKE_ACTION_LIST}/${AssignmentUrlEnum.ANALYSIS}/${url}/${id}`;
  }

  export function getAssignmentListAnalysisUrl(
    url: string | number,
    id: string
  ) {
    return `${CoreUrlEnums.ASSIGNMENTS}/${AssignmentUrlEnum.ASSIGNMENT_LIST}/${AssignmentUrlEnum.ANALYSIS}/${url}/${id}`;
  }
}

export enum NumbersUrlEnum {
  VOLUME = 'volume',
  ACTIVITY = 'activity',

  CONTRACTORS = 'contractors',
  MATERIALS = 'materials',
}

export namespace NumbersUrlEnum {
  export function getNumbersUrl(url: string | number) {
    return `${CoreUrlEnums.NUMBERS}/${url}/`;
  }
}

export enum AdministrationUrlEnum {
  USERS = 'users',
  AGREEMENTS = 'agreements',

  ADD = 'add',
}

export namespace AdministrationUrlEnum {
  export function getAdministrationUrl(url: string | number) {
    return `${CoreUrlEnums.ADMINISTRATION}/${url}/`;
  }
}

export enum AccountUrlEnum {
  PROFILE = 'profile',
  INVOICING = 'invoicing',
  CONTACT_DETAILS = 'contact-details',
}

export namespace AccountUrlEnum {
  export function getAccountUrl(url: string | number) {
    return `${CoreUrlEnums.ACCOUNT}/${url}/`;
  }
}

export enum ProjectsUrlEnum {
  LIST = 'list',
  DETAILS = 'details',
}

export namespace ProjectsUrlEnum {
  export function getProjectListUrl() {
    return `${CoreUrlEnums.PROJECTS}/${ProjectsUrlEnum.LIST}`;
  }

  export function getProjectDetailsUrl(id: string | number) {
    return `${CoreUrlEnums.PROJECTS}/${ProjectsUrlEnum.DETAILS}/${id}`;
  }
}
