import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-modal-template',
  templateUrl: './modal-template.component.html',
  styleUrls: ['./modal-template.component.scss'],
})
export class ModalTemplateComponent implements OnInit {
  @Input()
  icon: string;

  @Input()
  header: string;

  @Input()
  button: string;

  @Output()
  closeModal: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  submit: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}

  onCloseModal() {
    this.closeModal.emit();
  }

  onSubmit() {
    this.submit.emit();
  }
}
