<span
  class="dropdown-item btn-third"
  (click)="onGoToMeps(assignment?.mepsId)"
  [translate]="'dropdown-menu.goToMeps'"
></span>

<span
  class="dropdown-item btn-third"
  (click)="onGoToChatInMeps(assignment?.mepsId)"
  [translate]="'dropdown-menu.goToMepsConversation'"
>
</span>

<ng-container
  *ngIf="
    !assignment?.anyPending &&
    !!assignment?.possibleActions &&
    assignment?.possibleActions.length > 0
  "
>
  <span
    class="dropdown-item btn-third"
    (click)="onClose(assignment?.assignmentId)"
    *ngIf="
      assignment?.possibleActions?.includes(assignmentActionsEnum.CLOSE) &&
      assignment?.createdInUnityWorks
    "
    [translate]="'assignmentActions.close'"
  >
  </span>

  <span
    class="dropdown-item btn-third"
    (click)="onReopen(assignment?.assignmentId)"
    *ngIf="
      assignment?.possibleActions?.includes(assignmentActionsEnum.REOPEN) &&
      assignment?.createdInUnityWorks
    "
    [translate]="'assignmentActions.reOpen'"
  >
  </span>

  <span
    class="drop-down-item-highlight dropdown-item btn-third"
    (click)="
      onRemove(assignment?.assignmentId, assignment?.createdInUnityWorks)
    "
    *ngIf="assignment?.possibleActions?.includes(assignmentActionsEnum.REMOVE)"
    [translate]="'assignmentActions.remove'"
  >
  </span>
</ng-container>
