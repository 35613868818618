<span
  *ngIf="
    assignmentId?.length > 0 &&
    assignmentIds?.length === 0 &&
    selectedAssignmentIds?.length === 0
  "
>
  <span
    class="dropdown-item btn-third"
    [translate]="'dropdown-menu.exportToExcel'"
    (click)="onExportToExcelSingle()"
  >
  </span>

  <span
    class="dropdown-item btn-third"
    [translate]="'dropdown-menu.exportToSimpleExcel'"
    (click)="onExportToSimpleExcelSingle()"
  >
  </span>
</span>

<span *ngIf="selectedAssignmentIds?.length > 0">
  <span
    class="dropdown-item btn-third"
    [translate]="'dropdown-menu.exportToExcel'"
    (click)="onExportToExcel()"
  >
  </span>

  <span
    class="dropdown-item btn-third"
    [translate]="'dropdown-menu.exportToSimpleExcel'"
    (click)="onExportToSimpleExcel()"
  >
  </span>
</span>

<span
  *ngIf="
    assignmentIds?.length > 0 &&
    selectedAssignmentIds?.length === 0 &&
    assignmentId?.length === 0
  "
>
  <span
    class="dropdown-item btn-third"
    [translate]="'dropdown-menu.exportAllToExcel'"
    (click)="onExportAllToExcel()"
  >
  </span>

  <span
    class="dropdown-item btn-third"
    [translate]="'dropdown-menu.exportAllToSimpleExcel'"
    (click)="onExportAllToSimpleExcel()"
  >
  </span>
</span>

<span *ngIf="selectedCalculationIds?.newId && selectedCalculationIds?.oldId">
  <span
    class="dropdown-item btn-third"
    [translate]="'dropdown-menu.printToPDF'"
    (click)="onPrintToPDF()"
  >
  </span>
</span>
