import { InjectionToken } from '@angular/core';
import {
  ActionReducerMap,
  combineReducers,
  createFeatureSelector,
  createSelector,
} from '@ngrx/store';
import {
  propertyAddressReducer,
  PropertyAddressReducerState,
} from 'src/app/core/modules/start/store/reducers/property-address.reducer';
import {
  propertyBuildingReducer,
  PropertyBuildingReducerState,
} from 'src/app/core/modules/start/store/reducers/property-building.reducer';
import {
  propertyDistrictReducer,
  PropertyDistrictReducerState,
} from 'src/app/core/modules/start/store/reducers/property-district.reducer';
import {
  propertyObjectReducer,
  PropertyObjectReducerState,
} from 'src/app/core/modules/start/store/reducers/property-object.reducer';
import {
  propertyRealEstateReducer,
  PropertyRealEstateReducerState,
} from 'src/app/core/modules/start/store/reducers/property-real-estate.reducer';
import {
  startAssignmentsReducer,
  StartAssignmentsReducerState,
} from 'src/app/core/modules/start/store/reducers/start-assignments.reducer';

export interface PropertyRegisterState {
  district: PropertyDistrictReducerState;
  realEstate: PropertyRealEstateReducerState;
  building: PropertyBuildingReducerState;
  address: PropertyAddressReducerState;
  object: PropertyObjectReducerState;
}

export interface StartAssignmentState {
  startAssignmentForm: StartAssignmentsReducerState;
  propertyRegister: PropertyRegisterState;
}

export const propertyRegisterReducers = combineReducers({
  district: propertyDistrictReducer,
  realEstate: propertyRealEstateReducer,
  building: propertyBuildingReducer,
  address: propertyAddressReducer,
  object: propertyObjectReducer,
});

export const startAssignmentReducers = combineReducers({
  startAssignmentForm: startAssignmentsReducer,
  propertyRegister: propertyRegisterReducers,
});

export const startAssignmentReducerToken = new InjectionToken<
  ActionReducerMap<StartAssignmentState>
>('startAssignmentReducers');

export function getStartAssignmentReducers() {
  return startAssignmentReducers;
}

export const startAssignmentReducerProvider = [
  {
    provide: startAssignmentReducerToken,
    useFactory: getStartAssignmentReducers,
  },
];

export const getStartAssignmentStateSelector = createFeatureSelector<StartAssignmentState>(
  'startAssignment'
);

export const getStartAssignmentFormStateSelector = createSelector(
  getStartAssignmentStateSelector,
  (createAssignmentState: StartAssignmentState) =>
    createAssignmentState.startAssignmentForm
);
export const getPropertyRegisterStateSelector = createSelector(
  getStartAssignmentStateSelector,
  (createAssignmentState: StartAssignmentState) =>
    createAssignmentState.propertyRegister
);

export const getPropertyAddressStateSelector = createSelector(
  getPropertyRegisterStateSelector,
  (propertyRegisterState: PropertyRegisterState) =>
    propertyRegisterState.address
);
export const getPropertyBuildingStateSelector = createSelector(
  getPropertyRegisterStateSelector,
  (propertyRegisterState: PropertyRegisterState) =>
    propertyRegisterState.building
);
export const getPropertyDistrictStateSelector = createSelector(
  getPropertyRegisterStateSelector,
  (propertyRegisterState: PropertyRegisterState) =>
    propertyRegisterState.district
);
export const getPropertyObjectStateSelector = createSelector(
  getPropertyRegisterStateSelector,
  (propertyRegisterState: PropertyRegisterState) => propertyRegisterState.object
);
export const getPropertyRealEstateStateSelector = createSelector(
  getPropertyRegisterStateSelector,
  (propertyRegisterState: PropertyRegisterState) =>
    propertyRegisterState.realEstate
);
