import { Injectable } from '@angular/core';
import { AssignmentUrlEnum } from 'src/app/core/enums/url-paths.enum';
import { Settings } from '../../utils/settings';

@Injectable()
export class ExternalRedirectService {
  mepsConversationUrl = '/conversations';

  constructor() {}

  goToNewWindow(url: string) {
    window.open(url);
  }

  goToSearch(query: string) {
    this.goToNewWindow(
      `#/${AssignmentUrlEnum.getAssignmentUrl(
        AssignmentUrlEnum.ASSIGNMENT_LIST
      )}?query=${query}`
    );
  }

  goToMeps(mepsId: string) {
    this.goToNewWindow(`${Settings.appSettings.mepsAssignmentUrl}${mepsId}`);
  }

  goToMepsConversation(mepsId: string) {
    this.goToNewWindow(
      `${Settings.appSettings.mepsAssignmentUrl}${mepsId}${this.mepsConversationUrl}`
    );
  }

  goToEmail(email: string) {
    this.goToNewWindow(`mailto:${email}`);
  }

  goToPhoneNumber(phoneNumber: string) {
    this.goToNewWindow(`tel:${phoneNumber}`);
  }
}
