import { createSelector } from '@ngrx/store';
import { UserResourceI } from 'src/app/core/models/user/user-resource.interface';
import { getCompanyUsersStateSelector } from 'src/app/core/store/index';
import {
  getAllCompanyUsers,
  getCompanyUsersEntities,
  getCompanyUsersError,
  getCompanyUsersIds,
  getCompanyUsersLoaded,
  getCompanyUsersLoading,
  getCompanyUsersTotal,
} from 'src/app/core/store/reducers/company-users.reducer';
import { getAssignmentForGivenUrlIdSelector } from 'src/app/core/store/selectors/assignment.selectors';
import { AssignmentI } from 'src/app/core/models/assignment/assignment.interface';

export const getCompanyUsersIdsSelector = createSelector(
  getCompanyUsersStateSelector,
  getCompanyUsersIds
);

export const getCompanyUsersEntitiesSelector = createSelector(
  getCompanyUsersStateSelector,
  getCompanyUsersEntities
);

export const getAllCompanyUsersSelector = createSelector(
  getCompanyUsersStateSelector,
  getAllCompanyUsers
);

export const getCompanyUsersTotalSelector = createSelector(
  getCompanyUsersStateSelector,
  getCompanyUsersTotal
);

export const getCompanyUsersLoadingSelector = createSelector(
  getCompanyUsersStateSelector,
  getCompanyUsersLoading
);

export const getCompanyUsersLoadedSelector = createSelector(
  getCompanyUsersStateSelector,
  getCompanyUsersLoaded
);

export const getCompanyUsersErrorSelector = createSelector(
  getCompanyUsersStateSelector,
  getCompanyUsersError
);

export const getAllCompanyInspectorsSelector = createSelector(
  getAllCompanyUsersSelector,
  (companyUsers: UserResourceI[]) =>
    companyUsers.filter((user) => user.inspector)
);

export const getAllCompanyUsersWithoutMeSelector = createSelector(
  getAllCompanyUsersSelector,
  getAssignmentForGivenUrlIdSelector,
  (companyUsers: UserResourceI[], assignment: AssignmentI) =>
    companyUsers.filter((user) => user.userId !== assignment.currentUserId)
);

export const getCompanyUserForCurrentAssignmentSelector = createSelector(
  getAllCompanyUsersSelector,
  getAssignmentForGivenUrlIdSelector,
  (companyUsers: UserResourceI[], assignment: AssignmentI) =>
    companyUsers.find((user) => user.userId === assignment.currentUserId)
);
