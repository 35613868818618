import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TableSettings } from 'src/app/core/utils/table-utils';

@Component({
  selector: 'app-base-list-table',
  templateUrl: './base-list-table.component.html',
  styleUrls: ['./base-list-table.component.scss'],
})
export class BaseListTableComponent implements OnInit {
  table: any;
  tableSettings = TableSettings;

  @Input()
  rowIdentity: (a: any) => {};

  @Input()
  tableSizeLimit: number;

  @Input()
  tableSizeLimitOptions: number[];

  @Input()
  allElementsTotal: number;

  @Input()
  currentSort: any[];

  @Input()
  elementsList: any[] = [];

  @Input()
  selectedElementsList: any[] = [];

  @Input()
  currentPage: number;

  @Output()
  goToDetails: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  paginationChanged: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  paginationLimitChanged: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  sortingChanged: EventEmitter<{ dir; prop }> = new EventEmitter<{
    dir;
    prop;
  }>();

  @Output()
  selected: EventEmitter<any[]> = new EventEmitter<any[]>();

  constructor() {}

  ngOnInit() {}

  changePagination(event: any) {
    this.paginationChanged.emit(event.page);

    if (!!this.table) {
      return this.table.onFooterPage(event);
    }
  }

  onGoToDetails(event) {
    if (event?.column?.prop === 'checkbox' || event?.type !== 'click') {
      return;
    }

    this.goToDetails.emit(event.row);
  }

  onGoToDetailsMobile(event) {
    this.goToDetails.emit(event);
  }

  onSelected(event) {
    this.selected.emit(event.selected);
  }

  onSort(sort: any) {
    this.sortingChanged.emit(sort);
  }

  onPaginationLimitChange(limit: number) {
    this.paginationLimitChanged.emit(limit);
  }
}
