<div
  class="row"
  *ngIf="pageSizeService.isSmallScreen$ | async as isSmallScreen; else desktop"
>
  <div class="col-12">
    <div class="panel">
      <app-my-assignments
        [phaseChart]="phaseChart$ | async"
        [isSmallScreen]="isSmallScreen"
        (goToPhase)="onGoToPhase($event)"
        (browse)="onBrowseAssignments()"
      >
      </app-my-assignments>
    </div>

    <app-my-actions
      [actions]="myActions$ | async"
      (makeAction)="onMakeAction($event)"
      [showMobileActions]="showMobileAction$ | async"
      [isSmallScreen]="isSmallScreen"
      (goToDetails)="onGoToDetails($event)"
      (goToAssignments)="onGoToAssignments()"
      (openMobileActions)="onOpenMobileActions($event)"
    ></app-my-actions>

    <div class="panel">
      <app-watchlist
        [watchlist]="watchlist$ | async"
        (goToDetails)="onGoToDetails($event)"
        (browse)="onBrowseWatchlist()"
      ></app-watchlist>
    </div>

    <div class="panel">
      <app-volume
        [volumeChart]="aggregatedVolumeChart$ | async"
        (browse)="onBrowseVolume()"
      ></app-volume>
    </div>
  </div>
</div>

<ng-template #desktop>
  <div class="row">
    <div class="col-4">
      <app-my-actions
        [actions]="myActions$ | async"
        (makeAction)="onMakeAction($event)"
        (goToDetails)="onGoToDetails($event)"
        (goToAssignments)="onGoToAssignments()"
      ></app-my-actions>
    </div>

    <div class="col-8">
      <div class="position-fixed">
        <div class="panel">
          <app-my-assignments
            [phaseChart]="phaseChart$ | async"
            (goToPhase)="onGoToPhase($event)"
            (browse)="onBrowseAssignments()"
          >
          </app-my-assignments>
        </div>

        <div class="panel">
          <app-watchlist
            [watchlist]="watchlist$ | async"
            (goToDetails)="onGoToDetails($event)"
            (browse)="onBrowseWatchlist()"
          ></app-watchlist>
        </div>

        <div class="panel">
          <app-volume
            [volumeChart]="aggregatedVolumeChart$ | async"
            (browse)="onBrowseVolume()"
          ></app-volume>
        </div>
      </div>
    </div>
  </div>
</ng-template>
