<form novalidate [formGroup]="formGroup" autocomplete="off">
  <div class="form-row">
    <div class="form-group col-12 col-md-6" *ngIf="isSystemEnabled">
      <label
        for="inspection-form-inspection-type"
        [translate]="'inspection.formControls.inspectionType'"
      ></label>

      <span
        class="form-control-mandatory"
        *ngIf="isRequired('system')"
        [translate]="'mandatory'"
      ></span>

      <select
        id="inspection-form-inspection-type"
        class="custom-select"
        [formControlName]="'system'"
        [ngClass]="{
          'is-invalid': isValid(formGroup.controls['system'])
        }"
      >
        <option value="" selected disabled translate="common.select"></option>
        <option *ngFor="let system of systemTypes" [value]="system">
          {{ system | uppercase }}
        </option>
      </select>

      <div
        class="invalid-feedback"
        [translate]="'inspection.errorMessages.system'"
        *ngIf="formGroup.controls['system']?.invalid"
      ></div>
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col-12 col-md-6">
      <label
        for="inspection-form-inspector"
        [translate]="'inspection.formControls.inspector'"
      ></label>

      <span
        class="form-control-mandatory"
        *ngIf="isRequired('inspectorId')"
        [translate]="'mandatory'"
      ></span>

      <select
        id="inspection-form-inspector"
        class="custom-select"
        [formControlName]="'inspectorId'"
        [ngClass]="{
          'is-invalid': isValid(formGroup.controls['inspectorId'])
        }"
      >
        <option value="" selected disabled translate="common.select"></option>
        <option
          *ngFor="let inspector of inspectors"
          [value]="inspector?.userId"
        >
          {{ inspector?.name }}
        </option>
      </select>

      <div
        class="invalid-feedback"
        [translate]="'inspection.errorMessages.inspector'"
        *ngIf="formGroup.controls['inspectorId']?.invalid"
      ></div>
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col-12 col-md-6">
      <label
        for="inspection-form-date"
        [translate]="'inspection.formControls.date'"
      ></label>

      <span
        class="form-control-mandatory"
        *ngIf="isRequired('bookedDate')"
        [translate]="'mandatory'"
      ></span>

      <input
        bsDatepicker
        type="text"
        id="inspection-form-date"
        class="form-control"
        [bsConfig]="bsConfig"
        [formControlName]="'bookedDate'"
        [ngClass]="{
          'is-invalid':
            isValid(formGroup.controls['bookedDate']) ||
            isValid(formGroup.controls['bookedTime'])
        }"
      />

      <div
        class="invalid-feedback"
        [translate]="'inspection.errorMessages.date'"
        *ngIf="
          formGroup.controls['bookedDate'].invalid ||
          formGroup.controls['bookedTime'].invalid
        "
      ></div>
    </div>

    <div class="form-group col-4 col-md-3">
      <timepicker
        id="inspection-form-inspectionTime"
        [formControlName]="'bookedTime'"
        [showMeridian]="false"
        [ngClass]="{
          'bs-timepicker-field-error': isValid(formGroup.controls['bookedTime'])
        }"
      ></timepicker>
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col-12 col-md-6">
      <label
        for="inspection-form-message"
        [translate]="'inspection.formControls.message'"
      ></label>

      <span
        class="form-control-mandatory"
        *ngIf="isRequired('message')"
        [translate]="'mandatory'"
      ></span>

      <textarea
        class="form-control"
        id="inspection-form-message"
        rows="3"
        [formControlName]="'message'"
      ></textarea>
    </div>
  </div>
</form>
