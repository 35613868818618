import { AssignmentPhaseEnum } from 'src/app/core/enums/assignment-phase.enum';
import { AssignmentStatusEnum } from 'src/app/core/enums/assignment-status.enum';

export class AssignmentSearchRequest {
  query: string;
  name: string;
  address: string;
  externalProjectId: string;
  externalAssignmentId: string;
  objectNumber: string;
  statuses: AssignmentStatusEnum[];
  phase: AssignmentPhaseEnum;
  mainContractorName: string;
  sortAsc: string;
  sortDesc: string;
  offset: number;
  limit: number;
  filterOnUser: boolean;
  includeAction: boolean;
  isOnWatchList: boolean;

  constructor(assignmentSearchRequestBuilder: AssignmentSearchRequestBuilder) {
    this.query = assignmentSearchRequestBuilder.query;
    this.name = assignmentSearchRequestBuilder.name;
    this.address = assignmentSearchRequestBuilder.address;
    this.externalProjectId = assignmentSearchRequestBuilder.externalProjectId;
    this.externalAssignmentId =
      assignmentSearchRequestBuilder.externalAssignmentId;
    this.objectNumber = assignmentSearchRequestBuilder.objectNumber;
    this.statuses = assignmentSearchRequestBuilder.statuses;
    this.phase = assignmentSearchRequestBuilder.phase;
    this.mainContractorName = assignmentSearchRequestBuilder.mainContractorName;
    this.sortAsc = assignmentSearchRequestBuilder.sortAsc;
    this.sortDesc = assignmentSearchRequestBuilder.sortDesc;
    this.offset = assignmentSearchRequestBuilder.offset;
    this.limit = assignmentSearchRequestBuilder.limit;
    this.filterOnUser = assignmentSearchRequestBuilder.filterOnUser;
    this.includeAction = assignmentSearchRequestBuilder.includeAction;
    this.isOnWatchList = assignmentSearchRequestBuilder.isOnWatchList;
  }
}

export class AssignmentSearchRequestBuilder {
  private _query: string;
  private _externalProjectId: string;
  private _externalAssignmentId: string;
  private _objectNumber: string;
  private _name: string;
  private _address: string;
  private _statuses: AssignmentStatusEnum[];
  private _phase: AssignmentPhaseEnum;
  private _mainContractorName: string;
  private _sortAsc: string;
  private _sortDesc: string;
  private _offset: number;
  private _limit: number;
  private _filterOnUser: boolean;
  private _includeAction: boolean;
  private _isOnWatchList: boolean;

  get query(): string {
    return this._query;
  }

  setQuery(value: string) {
    this._query = value;
    return this;
  }

  get externalProjectId(): string {
    return this._externalProjectId;
  }

  setExternalProjectId(value: string) {
    this._externalProjectId = value;
    return this;
  }

  get externalAssignmentId(): string {
    return this._externalAssignmentId;
  }

  setExternalAssignmentId(value: string) {
    this._externalAssignmentId = value;
    return this;
  }

  get objectNumber(): string {
    return this._objectNumber;
  }

  setObjectNumber(value: string) {
    this._objectNumber = value;
    return this;
  }

  get name(): string {
    return this._name;
  }

  setName(value: string) {
    this._name = value;
    return this;
  }

  get address(): string {
    return this._address;
  }

  setAddress(value: string) {
    this._address = value;
    return this;
  }

  get statuses(): AssignmentStatusEnum[] {
    return this._statuses;
  }

  setStatuses(value: AssignmentStatusEnum[]) {
    this._statuses = value;
    return this;
  }

  get phase(): AssignmentPhaseEnum {
    return this._phase;
  }

  setPhase(value: AssignmentPhaseEnum) {
    this._phase = value;
    return this;
  }

  get mainContractorName(): string {
    return this._mainContractorName;
  }

  setMainContractorName(value: string) {
    this._mainContractorName = value;
    return this;
  }

  get sortAsc(): string {
    return this._sortAsc;
  }

  setSortAsc(value: string) {
    this._sortAsc = value;
    return this;
  }

  get sortDesc(): string {
    return this._sortDesc;
  }

  setSortDesc(value: string) {
    this._sortDesc = value;
    return this;
  }

  get offset(): number {
    return this._offset;
  }

  setOffset(value: number) {
    this._offset = value;
    return this;
  }

  get limit(): number {
    return this._limit;
  }

  setLimit(value: number) {
    this._limit = value;
    return this;
  }

  get filterOnUser(): boolean {
    return this._filterOnUser;
  }

  setFilterOnUser(value: boolean) {
    this._filterOnUser = value;
    return this;
  }

  get includeAction(): boolean {
    return this._includeAction;
  }

  setIncludeAction(value: boolean) {
    this._includeAction = value;
    return this;
  }

  get isOnWatchList(): boolean {
    return this._isOnWatchList;
  }

  setIsOnWatchList(value: boolean) {
    this._isOnWatchList = value;
    return this;
  }

  setPhaseType(tableTypeName: string) {
    const tableType: AssignmentPhaseEnum = tableTypeName as AssignmentPhaseEnum;

    return this.setPhase(tableType);
  }

  setOrdering(order: string, field: string) {
    return this.setSortAsc(order === 'asc' ? field : undefined).setSortDesc(
      order === 'desc' ? field : undefined
    );
  }

  setPagination(limit: number, page: number) {
    return this.setOffset(limit * (page - 1)).setLimit(limit);
  }

  setAssignmentsFilters(
    assignmentName: string,
    address: string,
    contractor: string
  ) {
    return this.setName(assignmentName)
      .setAddress(address)
      .setMainContractorName(contractor);
  }

  build() {
    return new AssignmentSearchRequest(this);
  }
}
