import { EntityState } from '@ngrx/entity';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import { IdNameResourceI } from 'src/app/core/models/property-register/id-name-resource.interface';
import {
  PropertyObjectActions,
  PropertyObjectActionTypes,
} from 'src/app/core/modules/start/store/actions/property-object.actions';
import {
  StartAssignmentsActions,
  StartAssignmentsActionTypes,
} from 'src/app/core/modules/start/store/actions/start-assignments.action';
import { idNameResourceAdapter } from 'src/app/core/modules/start/store/utils/id-name-resource.adapter';

export interface PropertyObjectReducerState
  extends EntityState<IdNameResourceI> {
  loading: boolean;
  loaded: boolean;
  error: ErrorResponseWithIdI;
}

export const initialPropertyObjectReducerState: PropertyObjectReducerState = idNameResourceAdapter.getInitialState(
  {
    loading: false,
    loaded: false,
    error: null,
  }
);

export function propertyObjectReducer(
  state = initialPropertyObjectReducerState,
  action: PropertyObjectActions | StartAssignmentsActions
): PropertyObjectReducerState {
  switch (action.type) {
    case PropertyObjectActionTypes.LoadPropertyObjects: {
      return {
        ...state,
        loading: true,
      };
    }

    case PropertyObjectActionTypes.LoadPropertyObjectsSuccess: {
      return idNameResourceAdapter.addMany(action.payload.objects, {
        ...initialPropertyObjectReducerState,
        loaded: true,
      });
    }

    case PropertyObjectActionTypes.LoadPropertyObjectsFail: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload,
      };
    }

    case StartAssignmentsActionTypes.CleanStartAssignmentsState:
    case PropertyObjectActionTypes.CleanPropertyObject: {
      return initialPropertyObjectReducerState;
    }

    default:
      return state;
  }
}

export const getPropertyObjectLoading = (state: PropertyObjectReducerState) =>
  state.loading;
export const getPropertyObjectLoaded = (state: PropertyObjectReducerState) =>
  state.loaded;
export const getPropertyObjectError = (state: PropertyObjectReducerState) =>
  state.error;
