<app-section
  [headerTitle]="'overview.watchlist.header'"
  [emptyText]="'overview.watchlist.empty'"
  [buttonLabel]="'overview.watchlist.browsing'"
  [showEmptyText]="watchlist?.length === 0"
  (buttonClicked)="onBrowse()"
>
  <div
    class="watchlist-item"
    *ngFor="let watchlistItem of watchlist"
    (click)="onGoToDetails(watchlistItem.assignmentId)"
  >
    <span class="watchlist-item__name">{{ watchlistItem.name }}</span>
    <span
      class="watchlist-item__status"
      [ngClass]="getColour(watchlistItem.status)?.colour"
    >
      {{ 'assignmentStatus.' + watchlistItem.status | translate | uppercase }}
    </span>


    <mat-icon class="watchlist-item__button">navigate_next</mat-icon>

  </div>
</app-section>
