import { Component, OnInit } from '@angular/core';
import { BaseActionsDropdownSingleAssignmentComponent } from 'src/app/shared/components/base-actions-dropdown-single-assignment/base-actions-dropdown-single-assignment.component';

@Component({
  selector: 'app-actions-dropdown-single-assignment',
  templateUrl: './actions-dropdown-single-assignment.component.html',
  styleUrls: ['./actions-dropdown-single-assignment.component.scss'],
})
export class ActionsDropdownSingleAssignmentComponent
  extends BaseActionsDropdownSingleAssignmentComponent
  implements OnInit {
  constructor() {
    super();
  }
}
