import { Component, OnInit } from '@angular/core';
import { getValueFromPlaceResult } from 'src/app/core/utils/google-autocomplete.utils';
import { BaseFormComponent } from 'src/app/shared/components/base-form/base-form.component';
import PlaceResult = google.maps.places.PlaceResult;

@Component({
  selector: 'app-location-search',
  templateUrl: './location-search.component.html',
  styleUrls: ['./location-search.component.scss'],
})
export class LocationSearchComponent
  extends BaseFormComponent<LocationSearchComponent>
  implements OnInit {
  constructor() {
    super();
  }

  ngOnInit() {}

  onPlaceChanged(placeResult: PlaceResult) {
    this.formGroup.patchValue({
      address:
        getValueFromPlaceResult('route', placeResult) +
        ' ' +
        getValueFromPlaceResult('street_number', placeResult),
      postalCode: getValueFromPlaceResult('postal_code', placeResult),
      city: getValueFromPlaceResult('postal_town', placeResult),
    });
  }
}
