import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mobile-card',
  templateUrl: './mobile-card.component.html',
  styleUrls: ['./mobile-card.component.scss'],
})
export class MobileCardComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
