import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MobileFilterSortI } from 'src/app/shared/models/mobile-filter-sort.interface';
import { TableSortOptionI } from 'src/app/core/models/table-sort-option';

@Component({
  selector: 'app-mobile-filter',
  templateUrl: './mobile-filter.component.html',
  styleUrls: ['./mobile-filter.component.scss'],
})
export class MobileFilterComponent implements OnInit {
  @Input()
  filter: { name: string; translateKey: string; values: any[] };

  @Input()
  currentFilter: any;

  @Input()
  sort: { name: string; field: string; translateKey: string; values: any[] };

  @Input()
  currentSort: TableSortOptionI;

  @Input()
  showAllOption = true;

  @Output()
  closeMobileFilter: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  apply: EventEmitter<MobileFilterSortI> = new EventEmitter<MobileFilterSortI>();

  @Output()
  clear: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}

  onCloseMobileFilter() {
    this.closeMobileFilter.emit();
  }

  onApply() {
    this.apply.emit({ filter: this.currentFilter, sort: this.currentSort });
  }

  onClear() {
    this.clear.emit();
  }

  setCurrentFilter(item: any) {
    this.currentFilter = item;
  }

  setCurrentSort(item: any) {
    this.currentSort = { field: this.sort.field, ordering: item };
  }
}
