/// <reference types="@types/googlemaps" />

import {
  Component,
  EventEmitter,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import { GooglePlaceLoader } from 'src/app/core/services/google/places/google-place-loader.service';
import PlaceResult = google.maps.places.PlaceResult;
import MapsEventListener = google.maps.MapsEventListener;

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
})
export class AutocompleteComponent implements OnInit, OnDestroy {
  private autoComplete;
  private mapsEventListener: MapsEventListener;

  @Output()
  placeChanged: EventEmitter<PlaceResult> = new EventEmitter();

  @ViewChild('el', { static: true })
  element;

  constructor(private ngZone: NgZone) {}

  ngOnInit() {
    GooglePlaceLoader.load().then(() => {
      this.createAutoCompleteObject();
      this.addPlaceChangedEventLister();
    });
  }

  createAutoCompleteObject() {
    this.autoComplete = new google.maps.places.Autocomplete(
      this.element.nativeElement,
      { componentRestrictions: { country: 'se' } }
    );
  }

  addPlaceChangedEventLister() {
    this.mapsEventListener = google.maps.event.addListener(
      this.autoComplete,
      'place_changed',
      () => {
        this.ngZone.run(() => {
          this.placeChanged.emit(this.autoComplete.getPlace());
        });
      }
    );
  }

  ngOnDestroy(): void {
    if (!!this.mapsEventListener) {
      this.mapsEventListener.remove();
    }
  }
}
