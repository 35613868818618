import { Component, OnInit } from '@angular/core';
import { BaseFormComponent } from 'src/app/shared/components/base-form/base-form.component';

@Component({
  selector: 'app-tenant-information',
  templateUrl: './tenant-information.component.html',
  styleUrls: ['./tenant-information.component.scss'],
})
export class TenantInformationComponent
  extends BaseFormComponent<TenantInformationComponent>
  implements OnInit {
  constructor() {
    super();
  }

  ngOnInit() {}
}
