<div class="phase-chart-mobile">
  <div
    class="phase-chart-mobile__item"
    *ngFor="let item of series; let i = index"
    [style.background-color]="config.colorScheme.domain[i]"
    (click)="onGoToPhase(item.name)"
  >
    <mat-icon
      class="phase-chart-mobile__item-icon"
      *ngIf="item.name === 'Preparation'"
      >hourglass_full
    </mat-icon>
    <mat-icon
      class="phase-chart-mobile__item-icon"
      *ngIf="item.name === 'Ordering'"
      >markunread_mailbox
    </mat-icon>
    <mat-icon
      class="phase-chart-mobile__item-icon"
      *ngIf="item.name === 'Production'"
      >build
    </mat-icon>
    <mat-icon
      class="phase-chart-mobile__item-icon"
      *ngIf="item.name === 'Closing'"
      >backspace
    </mat-icon>

    <span class="phase-chart-mobile__item-text">{{ item.value }}</span>
  </div>
</div>
