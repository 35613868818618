export const url =
  'https://maps.googleapis.com/maps/api/js?key=AIzaSyCf4-kGL_5q0XhkN6U-EAlyaE-D3cR82_Y&libraries=places&callback=__onGoogleLoaded';

export class GooglePlaceLoader {
  private static promise;

  public static load() {
    if (!GooglePlaceLoader.promise) {
      GooglePlaceLoader.promise = new Promise(resolve => {
        window['__onGoogleLoaded'] = ev => {
          resolve('google maps api loaded');
        };

        const node = document.createElement('script');
        node.src = url;
        node.type = 'text/javascript';
        document.head.appendChild(node);
      });
    }

    return GooglePlaceLoader.promise;
  }
}
