import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AssignmentActionsEnum } from 'src/app/core/enums/assignment-actions.enum';
import { AssignmentI } from 'src/app/core/models/assignment/assignment.interface';
import { ExcelI } from 'src/app/core/models/excel.interface';
import {
  ExcelActions,
  ExportToExcel,
  ExportToSimpleExcel,
} from 'src/app/core/store/actions/excel.action';
import {
  ExternalRedirectActions,
  GoToMeps,
  GoToMepsConversation,
} from 'src/app/core/store/actions/external-redirect.action';
import { BaseActionComponent } from 'src/app/shared/components/base-action/base-action.component';
import { PdfPayloadI } from 'src/app/core/models/pdf-payload.interface';

@Component({
  selector: 'app-actions-dropdown',
  templateUrl: './actions-dropdown.component.html',
  styleUrls: ['./actions-dropdown.component.scss'],
})
export class ActionsDropdownComponent
  extends BaseActionComponent
  implements OnInit {
  assignmentActionsEnum = AssignmentActionsEnum;

  @Input()
  selectedCalculationIds: { newId: string; oldId: string };

  @Input()
  assignment: AssignmentI;

  @Input()
  selectedAssignmentList: AssignmentI[];

  @Input()
  assignmentList: AssignmentI[];

  @Input()
  isSmall: boolean;

  @Input()
  showMobileActions: boolean;

  @Input()
  showMobileExport: boolean;

  @Output()
  externalRedirectAction: EventEmitter<ExternalRedirectActions> = new EventEmitter<ExternalRedirectActions>();

  @Output()
  excelAction: EventEmitter<ExcelActions> = new EventEmitter<ExcelActions>();

  @Output()
  printToPDF: EventEmitter<PdfPayloadI> = new EventEmitter<PdfPayloadI>();

  @Output()
  showActionMobile: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  showExportMobile: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(translationService: TranslateService) {
    super(translationService);
  }

  ngOnInit() {}

  onGoToMeps(id: string) {
    this.externalRedirectAction.emit(new GoToMeps(id));
  }

  onGoToChatInMeps(id: string) {
    this.externalRedirectAction.emit(new GoToMepsConversation(id));
  }

  onExportToExcel(id: string[]) {
    this.excelAction.emit(
      new ExportToExcel(this.getPayloadForExportExcelAction(id))
    );
  }

  onExportToSimpleExcel(id: string[]) {
    this.excelAction.emit(
      new ExportToSimpleExcel(this.getPayloadForExportExcelAction(id))
    );
  }

  onExportAllToExcel(id: string[]) {
    this.excelAction.emit(
      new ExportToExcel(this.getPayloadForExportExcelAction(id))
    );
  }

  onExportAllToSimpleExcel(id: string[]) {
    this.excelAction.emit(
      new ExportToSimpleExcel(this.getPayloadForExportExcelAction(id))
    );
  }

  onPrintPdf(payload: PdfPayloadI) {
    this.printToPDF.emit(payload);
  }

  onShowActionMobile(show: boolean) {
    if (this.isSmall) {
      this.showActionMobile.emit(show);
    }
  }

  onShowExportMobile(show: boolean) {
    if (this.isSmall) {
      this.showExportMobile.emit(show);
    }
  }

  private getPayloadForExportExcelAction(ids: string[] = []): ExcelI {
    return {
      fileName: `${ids.length} assignment(s)`,
      assignmentIds: ids,
    };
  }
}
