<div class="container mt-3" *ngIf="shouldShowPanel()">
  <alert *ngIf="showMissingExternalId" [dismissible]="false" [type]="'default'">
    <span [translate]="'noExternalIdMessage'"></span>
  </alert>

  <div *ngFor="let alertMessageType of alertMessagesTypesValues">
    <div *ngIf="alerts[alertMessageType].length >= 2">
      <ng-container
        [ngTemplateOutlet]="overLimit"
        [ngTemplateOutletContext]="{ alerts: alerts[alertMessageType] }"
      ></ng-container>
    </div>

    <div *ngIf="alerts[alertMessageType].length < 2">
      <div *ngFor="let alert of alerts[alertMessageType]">
        <alert [type]="alert?.type" [dismissible]="false">
          <span>{{ alert.message }}</span>

        <span
          *ngIf="
            alert.type !== alertMessagesTypes.WARNING &&
            alert.type !== alertMessagesTypes.DANGER"
          class="pointer alert-button"
          [translate]="'ok'"
          (click)="onDismissed(alert)"
        ></span>

        <span
          *ngIf="
            alert.type === alertMessagesTypes.WARNING ||
            alert.type === alertMessagesTypes.DANGER
          "
          class="pointer alert-button"
          [translate]="'close'"
          (click)="onDismissed(alert)"
        ></span>

      </alert>
    </div>
  </div>
</div>

<ng-template #overLimit let-alerts="alerts">
  <alert [type]="alerts[0]?.type" [dismissible]="false">
    <span>
      <span [translate]="'alerts.messagePartOne'"></span>
      <span> {{ alerts.length }} </span>
      <span [translate]="'alerts.messagePartTwo'"></span>
    </span>

    <span
      *ngIf="
        alerts[0]?.type !== alertMessagesTypes.WARNING &&
        alerts[0]?.type !== alertMessagesTypes.DANGER
      "
      class="pointer alert-button"
      [translate]="'ok'"
      (click)="onDismissedMultiple(alerts)"
    ></span>

    <span
      *ngIf="
        alerts[0]?.type === alertMessagesTypes.WARNING ||
        alerts[0]?.type === alertMessagesTypes.DANGER
      "
      class="pointer alert-button"
      [translate]="'close'"
      (click)="onDismissedMultiple(alerts)"
    ></span>
  </alert>
</ng-template>
