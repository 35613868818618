<app-page-header [header]="header" [border]="true">
  <div class="company-menu" slot="content">
    <div
      class="company-menu__item"
      *ngFor="let page of allPages"
      [ngClass]="{ 'company-menu__item--active': currentPage === page }"
      (click)="onPageChanged(page)"
      [translate]="itemsTranslationKey + page"
    ></div>
  </div>
</app-page-header>
