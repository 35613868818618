import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';

export const TableSettings = {
  columnMode: ColumnMode.flex,
  selectionType: SelectionType.checkbox,
  footerHeight: 50,
  combineHeaderHeight: 78,
  innerHeaderHeight: 50,
  innerRowHeight: 50,
  externalSorting: true,
};
