import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { LanguageEnum } from 'src/app/core/enums/language.enum';
import { UserRolesEnum } from 'src/app/core/enums/user-roles.enum';
import { UserPublicI } from 'src/app/core/models/user/user-resource.interface';
import { UnsavedChangesGuard } from 'src/app/shared/guards/unsaved-changes.guard';
import { BaseFormComponent } from 'src/app/shared/components/base-form/base-form.component';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
})
export class UserFormComponent
  extends BaseFormComponent<UserFormComponent>
  implements OnInit {
  userRolesTypes = UserRolesEnum;
  languageTypes = LanguageEnum;

  submitted: boolean;

  @Input()
  userPublic: UserPublicI;

  @Input()
  customerId: number;

  @Output()
  discard: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  save: EventEmitter<UserPublicI> = new EventEmitter<UserPublicI>();

  constructor(
    private guard: UnsavedChangesGuard,
    private formBuilder: FormBuilder
  ) {
    super();
  }

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      userId: [this.userPublic?.userId],
      role: [this.userPublic?.role || this.userRolesTypes.ADMIN],
      customerId: [this.userPublic?.customerId],
      externalId: [this.userPublic?.externalId],
      department: [this.userPublic?.department],
      contact: this.formBuilder.group({
        email: [
          this.userPublic?.contact?.email,
          [Validators.email, Validators.required],
        ],
        mobilePhone: [this.userPublic?.contact?.mobilePhone],
        name: [this.userPublic?.contact?.name, Validators.required],
        phone: [this.userPublic?.contact?.phone],
      }),
      language: [this.userPublic?.language || LanguageEnum.SE],
      confirmedProfile: [this.userPublic?.confirmedProfile],
      blocked: [this.userPublic?.blocked],
      inspector: [
        this.userPublic?.inspector === null ||
        this.userPublic?.inspector === undefined
          ? false
          : this.userPublic?.inspector,
      ],
    });
  }

  onDiscard() {
    if (this.guard.confirmDeactivation(!this.formGroup.dirty)) {
      this.discard.emit();
    }
  }

  onSave() {
    this.submitted = true;

    if (this.formGroup.invalid) {
      return;
    }

    const user: UserPublicI = this.formGroup.value;
    user.customerId = user.customerId || this.customerId;

    this.save.emit(user);
  }
}
