import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-page-header-with-menu',
  templateUrl: './page-header-with-menu.component.html',
  styleUrls: ['./page-header-with-menu.component.scss'],
})
export class PageHeaderWithMenuComponent implements OnInit {
  @Input()
  header: string;

  @Input()
  itemsTranslationKey: string;

  @Input()
  allPages: string[];

  @Input()
  currentPage: string;

  @Output()
  pageChanged: EventEmitter<string> = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {}

  onPageChanged(page: string) {
    this.pageChanged.emit(page);
  }
}
