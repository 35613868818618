<label
  class="form-label"
  for="autocomplete"
  [translate]="'autoComplete'"
></label>
<input
  id="autocomplete"
  type="search"
  class="form-control inverted"
  autocomplete="new"
  #el
/>
