import { EntityState } from '@ngrx/entity';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import { IdNameResourceI } from 'src/app/core/models/property-register/id-name-resource.interface';
import {
  PropertyRealEstateActions,
  PropertyRealEstateActionTypes,
} from 'src/app/core/modules/start/store/actions/property-real-estate.actions';
import {
  StartAssignmentsActions,
  StartAssignmentsActionTypes,
} from 'src/app/core/modules/start/store/actions/start-assignments.action';
import { idNameResourceAdapter } from 'src/app/core/modules/start/store/utils/id-name-resource.adapter';

export interface PropertyRealEstateReducerState
  extends EntityState<IdNameResourceI> {
  loading: boolean;
  loaded: boolean;
  error: ErrorResponseWithIdI;
}

export const initialPropertyRealEstateReducerState: PropertyRealEstateReducerState = idNameResourceAdapter.getInitialState(
  {
    loading: false,
    loaded: false,
    error: null,
  }
);

export function propertyRealEstateReducer(
  state = initialPropertyRealEstateReducerState,
  action: PropertyRealEstateActions | StartAssignmentsActions
): PropertyRealEstateReducerState {
  switch (action.type) {
    case PropertyRealEstateActionTypes.LoadPropertyRealEstates: {
      return {
        ...state,
        loading: true,
      };
    }

    case PropertyRealEstateActionTypes.LoadPropertyRealEstatesSuccess: {
      return idNameResourceAdapter.addMany(action.payload.realEstates, {
        ...initialPropertyRealEstateReducerState,
        loaded: true,
      });
    }

    case PropertyRealEstateActionTypes.LoadPropertyRealEstatesFail: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload,
      };
    }

    case StartAssignmentsActionTypes.CleanStartAssignmentsState:
    case PropertyRealEstateActionTypes.CleanPropertyRealEstate: {
      return initialPropertyRealEstateReducerState;
    }

    default:
      return state;
  }
}

export const getPropertyRealEstateLoading = (
  state: PropertyRealEstateReducerState
) => state.loading;
export const getPropertyRealEstateLoaded = (
  state: PropertyRealEstateReducerState
) => state.loaded;
export const getPropertyRealEstateError = (
  state: PropertyRealEstateReducerState
) => state.error;
