<app-page-header [header]="'go.elements.support'"></app-page-header>

<div class="row">
  <div class="col-12 col-md-6">
    <div class="report-card card bg-light">
      <div class="card-body">
        <div
          class="report-card__title"
          [translate]="'supportPage.contact.header'"
        ></div>
        <div class="report-card__text-big">
          <div>Ric Sandholm</div>
          <a href="tel:0704868160">070-328 26 83</a>
          <a href="mailto:lars.ek@unityworks.se">Ric.sandholm@unityworks.se</a>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12 mt-4">
    <div class="border-bottom w-100"></div>
  </div>

  <div class="col-12">
    <div class="report-panel">
      <div
        class="report-panel__title"
        [translate]="'supportPage.support.header'"
      ></div>
      <div class="report-panel__text">
        <span
          class="mb-4"
          [translate]="'supportPage.support.quickSolution.welcome'"
        ></span>
        <button
          class="btn btn-sm btn-third btn-reload"
          (click)="reload()"
          [translate]="'supportPage.support.quickSolution.refreshButton'"
        ></button>

        <div [translate]="'supportPage.support.quickSolution.refresh'"></div>
        <div
          [translate]="'supportPage.support.quickSolution.otherBrowser'"
        ></div>
        <div
          [translate]="'supportPage.support.quickSolution.submit.header'"
        ></div>

        <ul>
          <li
            [translate]="
              'supportPage.support.quickSolution.submit.options.browserName'
            "
          ></li>
          <li
            [translate]="
              'supportPage.support.quickSolution.submit.options.operatingSystem'
            "
          ></li>
          <li
            [translate]="
              'supportPage.support.quickSolution.submit.options.typeOfDevices'
            "
          ></li>
          <li
            [translate]="
              'supportPage.support.quickSolution.submit.options.error'
            "
          ></li>
          <li
            [translate]="
              'supportPage.support.quickSolution.submit.options.behaviour'
            "
          ></li>
          <li
            [translate]="
              'supportPage.support.quickSolution.submit.options.reproduce'
            "
          ></li>
        </ul>
      </div>
    </div>
  </div>
</div>
