import { Action } from '@ngrx/store';
import { AlertMessage } from 'src/app/core/models/alert-message.interface';

export enum AlertMessages {
  ShowMessage = '[Alert] Show Messages',
  HideMessage = '[Alert] Hide Messages',

  HideMultipleMessages = '[Alert] Hide Multiple Messages',

  ShowMessageMissingExternalId = '[Alert] Show Messages Missing External Id',
}

export class ShowMessage implements Action {
  readonly type = AlertMessages.ShowMessage;

  constructor(public payload: AlertMessage) {}
}

export class HideMessage implements Action {
  readonly type = AlertMessages.HideMessage;

  constructor(public payload: AlertMessage) {}
}

export class HideMultipleMessages implements Action {
  readonly type = AlertMessages.HideMultipleMessages;

  constructor(public payload: string[]) {}
}

export class ShowMessageMissingExternalId implements Action {
  readonly type = AlertMessages.ShowMessageMissingExternalId;

  constructor(public payload: boolean) {}
}

export type AlertMessagesActions =
  | ShowMessage
  | HideMessage
  | HideMultipleMessages
  | ShowMessageMissingExternalId;
